import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TruncatingTextWrapperWithPopover from 'components/TruncatingTextWrapperWithPopover';
import faker from 'faker';
import React, { CSSProperties, useState } from 'react';
import { Dropdown, FormControl, InputGroup } from 'react-bootstrap';

export interface ISelectionItem {
  id: number | undefined | null;
  label: string;
}

export interface ISelectProps {
  selectedId?: number | null;
  items: ISelectionItem[];
  onSelect: (item?: ISelectionItem) => void;
  disabled?: boolean;
  allowNewItems?: boolean;
  selectFirst?: boolean;
  allowEmptySelection?: boolean;
  placeholder?: string;
  dropdownStyle?: CSSProperties;
}

const CustomToggle = React.forwardRef(
  (
    {
      children,
      onClick,
      disabled,
      allowNewItems,
      onInputChange,
      onInputBlur,
    }: any,
    ref,
  ) => {
    if (allowNewItems) {
      return (
        <div
          ref={ref as any}
          onClick={
            disabled
              ? undefined
              : (e: any) => {
                  e.preventDefault();
                  onClick(e);
                }
          }
        >
          <InputGroup className={`seamless ${disabled ? 'disabled' : ''}`}>
            <FormControl
              id={`select-${faker.random.alphaNumeric(10)}`} // randomize to throw off browser auto-fill of fields
              value={children}
              onChange={onInputChange}
              onBlur={onInputBlur}
              disabled={disabled}
            ></FormControl>
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faChevronDown} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
      );
    }

    return (
      <div
        className={`select-dropdown-form-control ${
          disabled ? 'text-muted disabled-dropdown' : 'form-control'
        }`}
        style={{ cursor: disabled ? 'unset' : 'pointer' }}
        ref={ref as any}
        onClick={
          disabled
            ? undefined
            : (e) => {
                e.preventDefault();
                onClick(e);
              }
        }
      >
        <div className="row no-gutters">
          <div className="col-11">
            <TruncatingTextWrapperWithPopover>
              {children}
            </TruncatingTextWrapperWithPopover>
          </div>
          <div className="col-1 text-right">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      </div>
    );
  },
);

export const Select = (props: ISelectProps) => {
  const selectedItem = props.items.find((item) => item.id === props.selectedId);
  const [inputText, setInputText] = useState('');

  const handleSelect = (value: string | null) => {
    const selectedItem = props.items.find((item) => item.id === Number(value));
    props.onSelect(selectedItem);
    setInputText(selectedItem?.label ?? props.placeholder ?? '');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textValue = event.target.value;
    const existingItem = props.items.find(
      (item) => item.label.toLowerCase() === textValue.trim().toLowerCase(),
    );
    if (existingItem) {
      props.onSelect(existingItem);
    } else {
      props.onSelect({
        id: undefined,
        label: event.target.value,
      });
    }
    setInputText(textValue);
  };

  if (props.selectFirst && props.items.length && !!!props.selectedId) {
    props.onSelect(props.items[0]);
  }

  return (
    <Dropdown onSelect={handleSelect} style={props.dropdownStyle}>
      <Dropdown.Toggle
        as={CustomToggle}
        disabled={props.disabled}
        allowNewItems={props.allowNewItems}
        onInputChange={handleInputChange}
        className="disabled-dropdown"
      >
        {!!selectedItem
          ? selectedItem.label
          : props.allowNewItems
          ? inputText
          : props.placeholder ?? 'Select...'}
      </Dropdown.Toggle>

      <Dropdown.Menu className="select-dropdown-menu">
        {!!props.allowEmptySelection && (
          <Dropdown.Item key={''} eventKey={''}>
            {props.placeholder}
          </Dropdown.Item>
        )}
        {props.items.map((item) => (
          <Dropdown.Item key={`${item.id}`} eventKey={`${item.id}`}>
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
