import React, { useEffect, useState } from 'react';
import { BalanceFileAttachmentModal } from './BalanceFileAttachmentModal';
import useModalAlerts from '../Alerts/useModalAlerts';
import useApolloClient from 'useApolloClient';
import {
  PeriodsForBalancesDocument,
  PeriodsForBalancesQuery,
  PeriodsForBalancesQueryVariables,
} from 'types/graphql';
import { PeriodStatusEnum } from 'ar-common';

export interface IBalanceFileAttachmentModalTriggerProps {
  children: (onTrigger: () => void) => any;
  preselectedBalanceId?: number;
  preselectedBalanceIdsForBulkAttachment?: number[];
  isSinglePeriodSelected?: boolean;
  isSelectedPeriodOpen?: boolean;
  onSaved: (updatedBalanceIds: number[]) => Promise<void>;
  readOnly?: boolean;
}

export const BalanceFileAttachmentModalTrigger = (
  props: IBalanceFileAttachmentModalTriggerProps,
) => {
  const { client } = useApolloClient();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError, warning } = useModalAlerts(setAlertModal);

  const [bulkAttachmentModePeriodId, setBulkAttachmentModePeriodId] = useState<
    number | undefined
  >();

  useEffect(() => setBulkAttachmentModePeriodId(undefined), [props]);

  const handleTrigger = async () => {
    const isBulkAttachmentMode: boolean =
      (props.preselectedBalanceIdsForBulkAttachment ?? []).length !== 0;

    if (isBulkAttachmentMode && !props.isSinglePeriodSelected) {
      const bulkAttachmentModePeriodId: number | undefined = await client
        .query<PeriodsForBalancesQuery, PeriodsForBalancesQueryVariables>({
          query: PeriodsForBalancesDocument,
          fetchPolicy: 'no-cache',
          variables: {
            balanceIds: props.preselectedBalanceIdsForBulkAttachment ?? [],
          },
        })
        .then((result) => {
          const periods = result.data.periodsForBalances;

          if (periods.length > 1) {
            warning({
              message:
                'You have selected accounts across different periods. Attachments can only be made one period at a time. Please limit selections to the same period.',
            });
            return undefined;
          }

          const period = periods[0];

          if (period.periodStatus.id !== PeriodStatusEnum.Open) {
            warning({
              message: 'Bulk attachments can only be added to open periods',
            });
            return undefined;
          }

          return period.id;
        })

        .catch((reason: any) => {
          apolloError({
            error: reason,
          });
          return undefined;
        });

      if (!bulkAttachmentModePeriodId) {
        // No valid period, so abort the modal
        return;
      }

      if (!!bulkAttachmentModePeriodId) {
        // Valid period identified, so proceed
        setBulkAttachmentModePeriodId(bulkAttachmentModePeriodId);
      }
    }

    setShowModal(true);
  };

  const handleSaved = async (updatedBalanceIds: number[]) => {
    setShowModal(false);
    return props.onSaved(updatedBalanceIds);
  };

  const handleCancelled = () => {
    setShowModal(false);
  };

  return (
    <>
      {alertModal}
      {props.children(handleTrigger)}
      {showModal && (
        <BalanceFileAttachmentModal
          preselection={
            !!props.preselectedBalanceId
              ? { specificBalanceId: props.preselectedBalanceId }
              : !!props.preselectedBalanceIdsForBulkAttachment
              ? {
                  bulkAttachmentPeriodId: bulkAttachmentModePeriodId ?? 0,
                  bulkAttachmentBalanceIds:
                    props.preselectedBalanceIdsForBulkAttachment,
                }
              : undefined
          }
          onSaved={handleSaved}
          onCancelled={handleCancelled}
          readOnly={props.readOnly}
        ></BalanceFileAttachmentModal>
      )}
    </>
  );
};
