import { BalanceFilter } from 'types/graphql';
import { IBalanceFilterValues, PeriodSelectionType } from './BalanceFilters';

export const balanceFilterValuesToQueryVariables = (
  filterValues: IBalanceFilterValues,
): BalanceFilter => {
  return {
    periodId:
      filterValues.periodSelectionType === PeriodSelectionType.period
        ? filterValues.period?.id
        : undefined,
    startPeriodId:
      filterValues.periodSelectionType === PeriodSelectionType.periodRange
        ? filterValues.startPeriod?.id
        : undefined,
    endPeriodId:
      filterValues.periodSelectionType === PeriodSelectionType.periodRange
        ? filterValues.endPeriod?.id
        : undefined,
    subsidiaryIdIn: filterValues.subsidiaryIds,
    dueToFromSubsidiaryIdIn: filterValues.dueToFromSubsidiaryIds,
    accountIsActive: filterValues.accountActiveStatus,
    accountNumberIdIn: filterValues.accountNumberIds,
    responsibleIdIn: filterValues.responsibleIds,
    reviewerIdIn: filterValues.reviewerIds,
    balanceStatusIdIn: filterValues.balanceStatusIdIn,
    balanceSummary: filterValues.balanceSummary,
  };
};
