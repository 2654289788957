import { BalanceStatusEnum, PeriodStatusEnum } from 'ar-common';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import { IChipOption } from 'components/ChipSelect';
import { SubsidiaryChipSelect } from 'components/Lookups/SubsidiaryLookups/SubsidiaryChipSelect';
import { NonQueryChipSelect } from 'components/Lookups/NonQueryLookups/NonQueryChipSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useEffect, useState } from 'react';
import {
  BalanceSummaryFilter,
  LookupModelFragment,
  PeriodLookupModel,
} from 'types/graphql';
import { AccountNumberChipSelect } from '../Lookups/AccountNumberLookups/AccountNumberChipSelect';
import { Form } from 'react-bootstrap';
import { PeriodSelect } from '../Lookups/PeriodLookups/PeriodSelect';
import { BalanceStatusChipSelect } from '../Lookups/BalanceStatusLookup/BalanceStatusChipSelect';

export interface IBalanceFilterValues {
  periodSelectionType?: PeriodSelectionType | undefined;
  period?: PeriodLookupModel | undefined;
  startPeriod?: PeriodLookupModel | undefined;
  endPeriod?: PeriodLookupModel | undefined;
  subsidiaryIds?: number[];
  dueToFromSubsidiaryIds?: number[];
  accountNumberIds?: number[];
  accountActiveStatus?: boolean;
  responsibleIds?: number[];
  reviewerIds?: number[];
  balanceStatusIdIn?: BalanceStatusEnum[];
  balanceSummary?: BalanceSummaryFilter;
}

export enum PeriodSelectionType {
  period = 1,
  periodRange = 2,
  allPeriods = 3,
  none = 4,
}

interface IProps {
  assigneeLookups: LookupModelFragment[];
  filterValues?: IBalanceFilterValues;
  searchButtonLabel?: string;
  onSearch: (filterValues: IBalanceFilterValues) => Promise<void>;
}

export const BalanceFilters = (props: IProps): JSX.Element => {
  const [periodSelectionType, setPeriodSelectionType] =
    useState<PeriodSelectionType>();
  const [selectedPeriod, setSelectedPeriod] = useState<
    PeriodLookupModel | null | undefined
  >();
  const [selectedStartPeriod, setSelectedStartPeriod] = useState<
    PeriodLookupModel | null | undefined
  >();
  const [selectedEndPeriod, setSelectedEndPeriod] = useState<
    PeriodLookupModel | null | undefined
  >();
  const [selectedSubsidiaryIds, setSelectedSubsidiaryIds] = useState<number[]>(
    [],
  );
  const [selectedDueToFromSubsidiaryIds, setSelectedDueToFromSubsidiaryIds] =
    useState<number[]>([]);
  const [selectedAccountNumberIds, setSelectedAccountNumberIds] = useState<
    number[]
  >([]);
  const [selectedResponsibleIds, setSelectedResponsibleIds] = useState<
    number[]
  >([]);
  const [selectedReviewerIds, setSelectedReviewerIds] = useState<number[]>([]);
  const [selectedBalanceStatuses, setSelectedBalanceStatuses] = useState<
    BalanceStatusEnum[]
  >([]);

  const [selectedBalanceStatusId, setSelectedBalanceStatusId] = useState<
    boolean | undefined
  >(undefined);

  const [alertModal, setAlertModal] = useState<any>();
  const { error } = useModalAlerts(setAlertModal);

  useEffect(() => {
    setPeriodSelectionType(
      props.filterValues?.periodSelectionType || PeriodSelectionType.period,
    );
    setSelectedPeriod(props.filterValues?.period);
    setSelectedStartPeriod(props.filterValues?.startPeriod);
    setSelectedEndPeriod(props.filterValues?.endPeriod);
    setSelectedSubsidiaryIds(props.filterValues?.subsidiaryIds ?? []);
    setSelectedDueToFromSubsidiaryIds(
      props.filterValues?.dueToFromSubsidiaryIds ?? [],
    );
    setSelectedAccountNumberIds(props.filterValues?.accountNumberIds ?? []);
    setSelectedResponsibleIds(props.filterValues?.responsibleIds ?? []);
    setSelectedReviewerIds(props.filterValues?.reviewerIds ?? []);
    setSelectedBalanceStatusId(props.filterValues?.accountActiveStatus);
  }, [props.filterValues]);

  const handlePeriodSelectionTypeChanged = (
    periodSelectionType: PeriodSelectionType,
  ) => {
    setPeriodSelectionType(periodSelectionType);
  };

  const handlePeriodFilterChanged = (
    period: PeriodLookupModel | null | undefined,
  ) => {
    setSelectedPeriod(period);
  };

  const handleStartPeriodFilterChanged = (
    period: PeriodLookupModel | null | undefined,
  ) => {
    setSelectedStartPeriod(period);
  };

  const handleEndPeriodFilterChanged = (
    period: PeriodLookupModel | null | undefined,
  ) => {
    setSelectedEndPeriod(period);
  };

  const handleSubsidiaryFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedSubsidiaryIds(options.map((o) => parseInt(o.id)));
  };

  const handleDueToFromSubsidiaryFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    setSelectedDueToFromSubsidiaryIds(options.map((o) => parseInt(o.id)));
  };

  const handleAccountNumberFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    setSelectedAccountNumberIds(options.map((o) => parseInt(o.id)));
  };

  const handleResponsibleFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedResponsibleIds(options.map((o) => parseInt(o.id)));
  };

  const handleReviewerFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedReviewerIds(options.map((o) => parseInt(o.id)));
  };

  const handleBalanceStatusFilterChanged = (
    value: BalanceStatusEnum[] | undefined,
  ) => {
    setSelectedBalanceStatuses(value ?? []);
  };

  const handleClearSearchClick = () => {
    setPeriodSelectionType(PeriodSelectionType.period);
    setSelectedPeriod(null);
    setSelectedStartPeriod(null);
    setSelectedEndPeriod(null);
    setSelectedSubsidiaryIds([]);
    setSelectedDueToFromSubsidiaryIds([]);
    setSelectedAccountNumberIds([]);
    setSelectedResponsibleIds([]);
    setSelectedReviewerIds([]);
    setSelectedBalanceStatuses([]);
  };

  const handleSearchClick = () => {
    if (
      periodSelectionType === PeriodSelectionType.periodRange &&
      selectedStartPeriod == null &&
      selectedEndPeriod == null
    ) {
      error({
        message: 'Please choose a starting period, ending period or both.',
      });
      return;
    }

    if (
      periodSelectionType === PeriodSelectionType.periodRange &&
      !!selectedStartPeriod &&
      !!selectedEndPeriod &&
      selectedStartPeriod.sortableName > selectedEndPeriod.sortableName
    ) {
      error({
        message:
          'The selected start period is later than the end period. Please set the start period to a period earlier or equal to the end period.',
      });

      return;
    }

    search();
  };

  const search = () => {
    const filterValues: IBalanceFilterValues = {
      periodSelectionType,
      period: selectedPeriod || undefined,
      startPeriod: selectedStartPeriod || undefined,
      endPeriod: selectedEndPeriod || undefined,
      subsidiaryIds: selectedSubsidiaryIds.length
        ? selectedSubsidiaryIds
        : undefined,
      dueToFromSubsidiaryIds: selectedDueToFromSubsidiaryIds.length
        ? selectedDueToFromSubsidiaryIds
        : undefined,
      accountNumberIds: selectedAccountNumberIds.length
        ? selectedAccountNumberIds
        : undefined,
      accountActiveStatus: selectedBalanceStatusId,
      responsibleIds: selectedResponsibleIds.length
        ? selectedResponsibleIds
        : undefined,
      reviewerIds: selectedReviewerIds.length ? selectedReviewerIds : undefined,
      balanceStatusIdIn: selectedBalanceStatuses,
    };

    return props.onSearch(filterValues);
  };

  return (
    <>
      {alertModal}
      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={3}>
              <Form.Check
                type="radio"
                label="Period"
                className="mt-1"
                checked={periodSelectionType === PeriodSelectionType.period}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(PeriodSelectionType.period);
                }}
              />
            </FormLabelColumn>
            <Column>
              <PeriodSelect
                onSelect={handlePeriodFilterChanged}
                selectedId={selectedPeriod?.id}
                periodStatusIds={[
                  PeriodStatusEnum.Open,
                  PeriodStatusEnum.Closed,
                ]}
                disabled={periodSelectionType !== PeriodSelectionType.period}
                selectFirst={true}
              />
            </Column>

            <Column></Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>
              <Form.Check
                type="radio"
                label="Period Range"
                className="mt-1"
                checked={
                  periodSelectionType === PeriodSelectionType.periodRange
                }
                onChange={() => {
                  handlePeriodSelectionTypeChanged(
                    PeriodSelectionType.periodRange,
                  );
                }}
              />
            </FormLabelColumn>
            <Column>
              <PeriodSelect
                onSelect={handleStartPeriodFilterChanged}
                selectedId={selectedStartPeriod?.id}
                periodStatusIds={[
                  PeriodStatusEnum.Open,
                  PeriodStatusEnum.Closed,
                ]}
                disabled={
                  periodSelectionType !== PeriodSelectionType.periodRange
                }
              />
            </Column>
            <Column>
              <PeriodSelect
                onSelect={handleEndPeriodFilterChanged}
                selectedId={selectedEndPeriod?.id}
                periodStatusIds={[
                  PeriodStatusEnum.Open,
                  PeriodStatusEnum.Closed,
                ]}
                disabled={
                  periodSelectionType !== PeriodSelectionType.periodRange
                }
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>
              <Form.Check
                type="radio"
                label="All Periods"
                className="mt-1"
                checked={periodSelectionType === PeriodSelectionType.allPeriods}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(
                    PeriodSelectionType.allPeriods,
                  );
                }}
              />
            </FormLabelColumn>
            <Column></Column>
            <Column></Column>
          </div>
        </FieldSetColumn>

        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={3}>Subsidiary</FormLabelColumn>
            <Column>
              <SubsidiaryChipSelect
                selectedIds={selectedSubsidiaryIds}
                onChange={handleSubsidiaryFilterChanged}
                placeholder="All Subsidiaries"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>Account</FormLabelColumn>
            <Column>
              <AccountNumberChipSelect
                selectedIds={selectedAccountNumberIds}
                onChange={handleAccountNumberFilterChanged}
                placeholder="All Accounts"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>Due To/From Subsidiary</FormLabelColumn>
            <Column>
              <SubsidiaryChipSelect
                selectedIds={selectedDueToFromSubsidiaryIds}
                onChange={handleDueToFromSubsidiaryFilterChanged}
                placeholder="All Subsidiaries"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>Responsible</FormLabelColumn>
            <Column>
              <NonQueryChipSelect
                lookups={props.assigneeLookups.map((l) => ({
                  id: l.id,
                  name: `${l.name}`.trim() || '(no name)',
                  isDeleted: false,
                }))}
                selectedIds={selectedResponsibleIds}
                onChange={handleResponsibleFilterChanged}
                placeholder="All Responsibles"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>Reviewer</FormLabelColumn>
            <Column>
              <NonQueryChipSelect
                lookups={props.assigneeLookups.map((l) => ({
                  id: l.id,
                  name: `${l.name}`.trim() || '(no name)',
                  isDeleted: false,
                }))}
                selectedIds={selectedReviewerIds}
                onChange={handleReviewerFilterChanged}
                placeholder="All Reviewers"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>Status</FormLabelColumn>
            <Column>
              <BalanceStatusChipSelect
                selectedBalanceStatuses={selectedBalanceStatuses}
                onSelect={handleBalanceStatusFilterChanged}
                allowEmptySelection={true}
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row mt-3">
        <Column className="text-right">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            onClick={handleSearchClick}
          >
            {props.searchButtonLabel ?? 'Search'}
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
    </>
  );
};
