import React from 'react';
import Column, { IColumnProps } from './Column';

export interface IFormLabelColumnProps extends IColumnProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'body'
    | 'muted'
    | 'white'
    | 'black-50'
    | 'white-50';
}

export const FormLabelColumn = (props: IFormLabelColumnProps): JSX.Element => {
  const { children, className, ...rest } = props;
  let columnClassName = `form-label-column col-form-label font-weight-bold`;

  if (props.variant) {
    columnClassName = `${columnClassName} text-${props.variant}`;
  }

  columnClassName = `${columnClassName} ${className ?? ''}`;
  return (
    <Column className={columnClassName} {...rest}>
      {children}
    </Column>
  );
};

export default FormLabelColumn;
