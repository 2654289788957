import { ISelectionItem, Select } from 'components/Form/Select';
import { LookupWrapper } from 'components/Lookups/LookupComponentWrapper';
import { DocumentNode } from 'graphql';
import React from 'react';
import { LookupModel } from 'types/graphql';

interface IProps<TQuery, TQueryVariables, TLookup> {
  queryDocument: DocumentNode;
  queryVariables: TQueryVariables;
  extractLookupItems: (query: TQuery) => TLookup[];
  selectedId?: number | null;
  onSelect: (item?: TLookup) => void;
  disabled?: boolean | false;
  selectFirst?: boolean | false;
  allowEmptySelection?: boolean;
}

export const LookupSelect = <
  TQuery,
  TQueryVariables = Record<string, unknown>,
  TLookup extends { id: number; name: string } = LookupModel,
>(
  props: IProps<TQuery, TQueryVariables, TLookup>,
) => {
  const handleSelect = (lookups: TLookup[]) => (option?: ISelectionItem) => {
    props.onSelect(lookups.find((x) => x.id === option?.id));
  };

  return (
    <LookupWrapper<TQuery, TQueryVariables, TLookup>
      extractLookups={props.extractLookupItems}
      selectedValues={!!props.selectedId ? [props.selectedId] : []}
      queryDocument={props.queryDocument}
      queryVariables={props.queryVariables}
    >
      {({ lookups }) => (
        <Select
          selectedId={props.selectedId}
          items={lookups.map((l) => ({ id: l.id, label: l.name }))}
          onSelect={handleSelect(lookups)}
          disabled={props.disabled}
          selectFirst={props.selectFirst}
          allowEmptySelection={props.allowEmptySelection}
        />
      )}
    </LookupWrapper>
  );
};
