import React, { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import useApolloClient from '../../../useApolloClient';
import {
  IAccountDetailBulkUpdateSelections,
  IAccountDetailValues,
} from './AccountDetail';
import {
  BulkUpdateAccountCommonValuesDocument,
  BulkUpdateAccountCommonValuesQuery,
  BulkUpdateAccountCommonValuesQueryVariables,
  BulkUpdateAccountsDocument,
  BulkUpdateAccountsMutation,
  BulkUpdateAccountsMutationVariables,
} from 'types/graphql';
import { AccountDetailDialog } from './AccountDetailDialog';

export interface IBulkUpdateAccountsButtonProps {
  selectedAccountIds: number[];
  onAccountsUpdated?: (message: string) => Promise<void>;
  className?: string;
}

export const BulkUpdateAccountsButton: React.FC<
  IBulkUpdateAccountsButtonProps
> = (props: IBulkUpdateAccountsButtonProps) => {
  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [values, setValues] = useState<IAccountDetailValues>({});
  const [bulkUpdateSelections, setBulkUpdateSelections] =
    useState<IAccountDetailBulkUpdateSelections>({});

  useEffect(() => {
    setValues({ ...values, accountIds: props.selectedAccountIds });
  }, [props.selectedAccountIds]);

  const handleValuesChange = (newValues: IAccountDetailValues): void => {
    setValues(newValues);
  };

  const handleBulkUpdateSelectionsChange = (
    newValues: IAccountDetailBulkUpdateSelections,
  ): void => {
    setBulkUpdateSelections(newValues);
  };

  const updateCommonValues = async (): Promise<void> => {
    return client
      .query<
        BulkUpdateAccountCommonValuesQuery,
        BulkUpdateAccountCommonValuesQueryVariables
      >({
        query: BulkUpdateAccountCommonValuesDocument,
        variables: { accountIds: values.accountIds ?? [] },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const commonValues = result.data.bulkUpdateCommonAccountValues;
        if (!!commonValues) {
          setValues({
            accountIds: values.accountIds,
            accountDescription:
              commonValues.accountNumber?.description ?? undefined,
            subsidiaryId: commonValues.subsidiary?.id,
            dueToFromSubsidiaryId: commonValues.dueToFromSubsidiary?.id,
            isReconciled: commonValues.isReconciled ?? undefined,
            isActive: commonValues.isActive ?? undefined,
            isAttachmentMandatory:
              commonValues.isAttachmentMandatory ?? undefined,
            isIncludedInReminderEmails:
              commonValues.isIncludedInReminderEmails ?? undefined,
            responsibleUserId: commonValues.responsible?.id,
            reviewerUserId: commonValues.reviewer?.id,
          });
        }
      })
      .catch((reason) => {
        apolloError({
          error: reason,
        });
      });
  };

  const handleShow = async (): Promise<void> => {
    setBulkUpdateSelections({});
    return updateCommonValues().then(() => {
      setIsDialogVisible(true);
    });
  };

  useEffect(() => {
    if (!isDialogVisible) {
      return;
    }

    updateCommonValues();
  }, [values.accountIds]);

  const handleSave = async (applyToOpenPeriods?: boolean): Promise<void> => {
    const nullNumberValue = -1;

    return client
      .mutate<BulkUpdateAccountsMutation, BulkUpdateAccountsMutationVariables>({
        mutation: BulkUpdateAccountsDocument,
        variables: {
          input: {
            accountIds: values.accountIds,
            applyToOpenPeriods: applyToOpenPeriods ?? false,
            nullNumberValue: nullNumberValue,
            accountNumberDescription:
              bulkUpdateSelections.isAccountDescriptionSelectedForBulkUpdate
                ? values.accountDescription?.trim()
                : undefined,
            subsidiaryId:
              bulkUpdateSelections.isSubsidiaryIdSelectedForBulkUpdate
                ? values.subsidiaryId
                : undefined,
            dueToFromSubsidiaryId:
              bulkUpdateSelections.isDueToFromSubsidiaryIdSelectedForBulkUpdate
                ? values.dueToFromSubsidiaryId ?? nullNumberValue
                : undefined,
            isReconciled:
              bulkUpdateSelections.isIsReconciledSelectedForBulkUpdate
                ? values.isReconciled ?? true
                : undefined,
            isActive: bulkUpdateSelections.isIsActiveSelectedForBulkUpdate
              ? values.isActive ?? false
              : undefined,
            isAttachmentMandatory:
              bulkUpdateSelections.isIsAttachmentMandatorySelectedForBulkUpdate
                ? values.isAttachmentMandatory ?? false
                : undefined,
            isIncludedInReminderEmails:
              bulkUpdateSelections.isIsIncludedInReminderEmailsSelectedForBulkUpdate
                ? values.isIncludedInReminderEmails ?? false
                : undefined,
            responsibleUserId:
              bulkUpdateSelections.isResponsibleUserIdSelectedForBulkUpdate
                ? values.responsibleUserId ?? nullNumberValue
                : undefined,
            reviewerUserId:
              bulkUpdateSelections.isReviewerUserIdSelectedForBulkUpdate
                ? values.reviewerUserId ?? nullNumberValue
                : undefined,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(() => {
        handleClose();
        if (!!props.onAccountsUpdated) {
          return props.onAccountsUpdated('Accounts updated successfully.');
        }
      })
      .catch((reason) => {
        apolloError({
          error: reason,
        });
      });
  };

  const handleClose = (): void => {
    setIsDialogVisible(false);
  };

  return (
    <>
      {alertModal}
      <Button
        variant="warning"
        size="sm"
        className={`text-uppercase font-weight-bold ${props.className ?? ''}`}
        onClick={handleShow}
        disabled={props.selectedAccountIds.length === 0}
      >
        Bulk Update
      </Button>
      {isDialogVisible && (
        <AccountDetailDialog
          dialogTitle="Bulk Update"
          onSave={handleSave}
          onCancel={handleClose}
          values={values}
          onValuesChange={handleValuesChange}
          isBulkUpdateMode={true}
          bulkUpdateSelections={bulkUpdateSelections}
          onBulkUpdateSelectionsChange={handleBulkUpdateSelectionsChange}
        />
      )}
    </>
  );
};
