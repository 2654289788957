export const updateTitle = (title: string) => {
  let fullTitle = 'Accounts Recon';

  if (!!title) {
    fullTitle += ` - ${title}`;
  }

  document.title = fullTitle;
};

export const updateTitleEffect = (title: string) => () => {
  updateTitle(title);

  return function cleanup() {
    updateTitle('');
  };
};
