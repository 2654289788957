import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import { IntegerInput } from 'components/Form/IntegerInput';
import Column from 'layouts/components/Grid/Column';
import { FieldSetColumn } from 'layouts/components/Grid/FieldSetColumn';
import { FormLabelColumn } from 'layouts/components/Grid/FormLabelColumn';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  CreatePeriodsDocument,
  CreatePeriodsMutation,
  CreatePeriodsMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';

export interface IAddPeriodsButtonProps {
  onPeriodsCreated?: () => void;
}

export default (props: IAddPeriodsButtonProps) => {
  const [showModal, setShowModal] = useState<boolean>();
  const [totalPeriodsToAdd, setTotalPeriodsToAdd] = useState<number | null>(
    null,
  );
  const [isCreating, setIsCreating] = useState<boolean>();
  const { client } = useApolloClient();
  const [modalAlert, setModalAlert] = useState<React.ReactNode>();
  const { apolloError } = useModalAlerts(setModalAlert);

  const handleClose = () => {
    setShowModal(false);
    setTotalPeriodsToAdd(null);
  };

  const handleProceedClick = () => {
    if (isCreating) {
      return;
    }

    if (!totalPeriodsToAdd) {
      return;
    }

    setIsCreating(true);

    client
      .mutate<CreatePeriodsMutation, CreatePeriodsMutationVariables>({
        mutation: CreatePeriodsDocument,
        fetchPolicy: 'no-cache',
        variables: {
          totalPeriods: totalPeriodsToAdd,
        },
      })
      .then(() => {
        if (props.onPeriodsCreated) {
          props.onPeriodsCreated();
        }
        setShowModal(false);
        setTotalPeriodsToAdd(null);
      })
      .catch((error) => {
        apolloError({
          error,
        });
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return (
    <>
      {modalAlert}
      <Modal show={showModal} onHide={handleClose} keyboard={false}>
        <Modal.Header closeButton={!isCreating}>
          <Modal.Title>Add Periods</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This feature allows you to extend the periods in the application.
            You can choose to add any number of periods to a maximum of 99. Once
            added, periods cannot be removed. Any new periods added will be set
            to "Not Initiated"
          </p>
          <div className="row">
            <FieldSetColumn>
              <div className="row">
                <FormLabelColumn width={5}>Periods To Create</FormLabelColumn>
                <Column width={2}>
                  <IntegerInput
                    value={totalPeriodsToAdd}
                    minimumValue={1}
                    maximumValue={99}
                    onChange={(value) => setTotalPeriodsToAdd(value)}
                  />
                </Column>
              </div>
            </FieldSetColumn>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isCreating}
          >
            Cancel
          </Button>
          <Button
            isProcessing={isCreating}
            onClick={handleProceedClick}
            disabled={!totalPeriodsToAdd}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <Button size="sm" onClick={() => setShowModal(true)}>
        Add Periods
      </Button>
    </>
  );
};
