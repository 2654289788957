import { AccountDetail, IAccountDetailProps } from './AccountDetail';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'components/Button';
import useSpinnerModal from 'components/SpinnerModal/useSpinnerModal';
import useModalAlerts from 'components/Alerts/useModalAlerts';

export interface IAccountDetailDialogProps extends IAccountDetailProps {
  dialogTitle: string;
  hideSaveAndApply?: boolean;
  onSave: (applyToOpenPeriods?: boolean) => Promise<void>;
  onCancel: () => void;
}

export const AccountDetailDialog: React.FC<IAccountDetailDialogProps> = ({
  dialogTitle,
  onSave,
  onCancel,
  hideSaveAndApply,
  ...accountDetailProps
}: IAccountDetailDialogProps) => {
  const [alertModal, setAlertModal] = useState<any>();
  const { error } = useModalAlerts(setAlertModal);
  const { openSpinnerModal, closeSpinnerModal } = useSpinnerModal({
    message: 'Processing...',
  });

  useEffect(() => {
    if (accountDetailProps.isBulkUpdateMode) {
      // const { values, bulkUpdateSelections } = accountDetailProps;
      // const selections: Record<string, boolean> =
      //   (bulkUpdateSelections as Record<string, boolean>) ?? {};
      // const accountsSpecified = (values.accountIds ?? []).length !== 0;
      // const selectionsMade = !!Object.keys(selections).find(
      //   (key) => selections[key] === true,
      // );
    }
  }, [accountDetailProps.values, accountDetailProps.bulkUpdateSelections]);

  const canSave = (): boolean => {
    if (
      !!accountDetailProps.values.accountIds?.length ||
      !!accountDetailProps.values.accountNumberValue
    ) {
      return true;
    }

    error({ message: 'Account is required.' });
    return false;
  };

  const handleSaveAndApplyClick = () => {
    if (!canSave()) {
      return;
    }

    openSpinnerModal();
    return onSave(true).finally(closeSpinnerModal);
  };

  const handleSaveClick = () => {
    if (!canSave()) {
      return;
    }

    openSpinnerModal();
    return onSave(false).finally(closeSpinnerModal);
  };

  return (
    <>
      {alertModal}
      <Modal size="xl" show={true} onHide={onCancel} keyboard={false}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AccountDetail {...accountDetailProps} />
        </Modal.Body>
        <Modal.Footer>
          {!hideSaveAndApply && (
            <Button onClick={handleSaveAndApplyClick}>Save & Apply</Button>
          )}
          <Button onClick={handleSaveClick}>Save</Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
