import React from 'react';
import CommentContent from './CommentContent';
import CommentInput from './CommentInput';
import { IComment } from './IComment';
import { ICommentsProps } from './ICommentsProps';
import { IOnCommentChangeParameters } from './IOnCommentChangeParameters';

interface ICommentsState {
  commentBody: string;
}

export default class Comments extends React.PureComponent<
  ICommentsProps,
  ICommentsState
> {
  private commentHasChanges = false;

  constructor(props: ICommentsProps) {
    super(props);
    this.state = {
      commentBody: '',
    };
  }

  public render = () => {
    const rootComments = this.props.comments;

    return (
      <div className={'comments-wrapper'}>
        {!this.props.readOnly && (
          <CommentInput
            body={this.state.commentBody}
            visible={true}
            onSave={this.handleAddComment}
            saveButtonText="Add Comment"
            onChange={this.onCommentInputChange}
          />
        )}

        <div className="posted-comments-wrapper">
          {rootComments.map((comment: IComment) => {
            return (
              <>
                <hr />
                <div
                  className="comment-content-wrapper root-comment"
                  key={comment.id}
                >
                  <CommentContent
                    comment={comment}
                    onUpdateComment={this.handleUpdateComment}
                    onDeleteComment={this.handleDeleteComment}
                  />
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  private notifyChangeListener = () => {
    if (this.props.onCommentsChanged) {
      this.props.onCommentsChanged({
        hasChanges: this.commentHasChanges,
        body: '',
      });
    }
  };

  private handleAddComment = async (): Promise<boolean> => {
    if (this.props.onAddComment) {
      try {
        const succeeded = await this.props.onAddComment(this.state.commentBody);
        this.setState({
          commentBody: '',
        });
        if (this.props.refresh) {
          await this.props.refresh();
        }

        return succeeded;
      } catch {
        return false;
      }
    }

    return true;
  };

  private handleUpdateComment = async (
    commentId: number,
    body: string,
  ): Promise<boolean> => {
    if (this.props.onUpdateComment) {
      try {
        const succeeded = await this.props.onUpdateComment(commentId, body);

        if (this.props.refresh) {
          await this.props.refresh();
        }

        return succeeded;
      } catch {
        return false;
      }
    }

    return true;
  };

  private handleDeleteComment = async (commentId: number): Promise<boolean> => {
    if (this.props.onDeleteComment) {
      try {
        const succeeded = await this.props.onDeleteComment(commentId);

        if (this.props.refresh) {
          await this.props.refresh();
        }

        return succeeded;
      } catch {
        return false;
      }
    }

    return true;
  };

  private onCommentInputChange = (params: IOnCommentChangeParameters) => {
    this.setState({
      commentBody: params.body,
    });
    this.commentHasChanges = params.hasChanges;
    this.notifyChangeListener();
  };
}
