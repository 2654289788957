import { IChipOption } from 'components/ChipSelect';
import React, { useEffect, useState } from 'react';
import { LookupChipSelect } from '../LookupChipSelect';
import { AccountNumberLookupWrapper } from './AccountNumberLookupWrapper';

interface IProps {
  includeIds?: number[];
  selectedIds?: number[];
  includeDeleted?: boolean;
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  placeholder?: string;
  refreshAccountNumbersTrigger?: number;
}

export const AccountNumberChipSelect = (props: IProps): JSX.Element => {
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

  useEffect(() => {
    setRefreshTrigger(refreshTrigger + 1);
  }, [props.refreshAccountNumbersTrigger]);

  return (
    <AccountNumberLookupWrapper
      includeIds={props.includeIds}
      includeDeleted={props.includeDeleted}
    >
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupChipSelect
          extractLookups={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          onChange={props.onChange}
          selectedIds={props.selectedIds}
          placeholder={props.placeholder}
        />
      )}
    </AccountNumberLookupWrapper>
  );
};
