import { IChipOption } from 'components/ChipSelect';
import { AccountLookupWrapper } from './AccountLookupWrapper';
import React from 'react';
import { LookupChipSelect } from '../LookupChipSelect';

interface IProps {
  take: number;
  includeIds?: number[];
  selectedIds?: number[];
  isActive?: boolean;
  subsidiaryIds?: number[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  placeholder?: string;
}

export const AccountChipSelect = (props: IProps): JSX.Element => {
  return (
    <AccountLookupWrapper
      take={props.take}
      includeIds={props.includeIds}
      isActive={props.isActive}
    >
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupChipSelect
          take={props.take}
          extractLookups={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          onChange={props.onChange}
          selectedIds={(props.includeIds ?? []).concat(props.selectedIds ?? [])}
          placeholder={props.placeholder}
        />
      )}
    </AccountLookupWrapper>
  );
};
