import { useAuthContext } from 'auth/AuthContext';
import React from 'react';

interface SecuredContentProps {
  roles?: string[];
  children: any;
}

const SecuredContent = ({
  roles,
  children,
}: SecuredContentProps): JSX.Element => {
  const { isAuthenticated, user } = useAuthContext();

  if (!isAuthenticated) {
    return <></>;
  }

  if (roles && !user?.roles.find((userRole) => roles.includes(userRole))) {
    return <></>;
  }

  return <>{children}</>;
};

export default SecuredContent;
