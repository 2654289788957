import TextAreaInput from 'components/Form/TextAreaInput';
// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import React from 'react';
import { Button } from 'react-bootstrap';
import { IOnCommentChangeParameters } from './IOnCommentChangeParameters';

interface ICommentInputProps {
  body: string;
  labelText?: string;
  saveButtonText?: string;
  cancelButtonText?: string;
  visible?: boolean;
  onSave: () => Promise<boolean>;
  onCancel?: () => void;
  onChange?: (params: IOnCommentChangeParameters) => void;
}

interface ICommentInputState {
  originalComment: string;
  model: any;
  isVisibilityDropdownOpen: boolean;
}

export default class extends React.PureComponent<
  ICommentInputProps,
  ICommentInputState
> {
  constructor(props: ICommentInputProps) {
    super(props);
    this.state = {
      originalComment: props.body,
      model: null,
      isVisibilityDropdownOpen: false,
    };
  }

  public render = () => {
    const { saveButtonText, cancelButtonText, visible } = this.props;

    return visible ? (
      <div className="comment-input-wrapper">
        <div className="editor-wrapper">
          {!!this.props.labelText && <label>{this.props.labelText}</label>}
          <TextAreaInput
            className="comment-input"
            value={this.props.body ?? ''}
            onChange={this.handleModelChange}
          />
        </div>

        <div className="action-buttons">
          <Button
            variant="primary"
            size="sm"
            disabled={this.props.body === this.state.originalComment}
            onClick={this.handleSaveOnClick}
          >
            {saveButtonText || 'Save'}
          </Button>

          {this.props.onCancel ? (
            <Button
              variant="outline-primary"
              size="sm"
              onClick={this.handleCancelOnClick}
            >
              {cancelButtonText || 'Cancel'}
            </Button>
          ) : null}
        </div>
      </div>
    ) : null;
  };

  private handleSaveOnClick = async (): Promise<void> => {
    const succeeded = await this.props.onSave();

    if (succeeded) {
      this.setState({
        originalComment: this.props.body,
      });
    }
  };

  private handleCancelOnClick = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  private handleModelChange = (comment: string): void => {
    if (this.props.onChange) {
      this.props.onChange({
        hasChanges: this.state.originalComment !== comment,
        body: comment,
      });
    }
  };
}
