import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import React, { useEffect, useState } from 'react';
import Paginator from './components/Paginator';

export interface IPaginationControl {
  refresh: () => Promise<void>;
}

export interface IPaginationProps {
  children: any;
  pageSize?: number;
  totalNumberOfItems: number;
  fetchDataTablePage: (skip?: number, take?: number) => void;
  controlRef?: React.MutableRefObject<IPaginationControl | undefined>;
}

export const Pagination: React.FC<IPaginationProps> = ({
  children,
  totalNumberOfItems,
  fetchDataTablePage,
  pageSize,
  controlRef,
}: IPaginationProps) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize ?? 25);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const handleFetchDataTablePage = async (): Promise<void> => {
    return fetchDataTablePage(pageIndex * rowsPerPage, rowsPerPage);
  };

  if (controlRef) {
    controlRef.current = {
      refresh: handleFetchDataTablePage,
    };
  }

  useEffect(() => {
    handleFetchDataTablePage();
  }, [rowsPerPage, pageIndex]);

  useEffect(() => {
    if (totalNumberOfItems === 0) {
      setPageIndex(0);
      return;
    }

    if (pageIndex * rowsPerPage > totalNumberOfItems) {
      // a refresh may have resulted in fewer items, so make
      // change to the last available page
      setPageIndex(Math.floor((totalNumberOfItems - 1) / rowsPerPage));
      return;
    }
  }, [rowsPerPage, pageIndex, totalNumberOfItems]);

  const handleChangePage = (pageNumber: number) => {
    setPageIndex(pageNumber - 1);
  };

  const handleChangeRowsPerPage = (rowsPerPage?: number) => {
    setRowsPerPage(rowsPerPage ?? AllRowsPerPage);
  };

  return (
    <>
      {children}
      <Paginator
        page={pageIndex + 1}
        rowsPerPage={rowsPerPage}
        count={totalNumberOfItems}
        showAllRowsOption={true}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
