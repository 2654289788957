import { DocumentNode } from 'graphql';
import React from 'react';
import {
  LookupModel,
  UserLookupsDocument,
  UserLookupsQuery,
  UserLookupsQueryVariables,
} from 'types/graphql';

interface IProps {
  includeIds?: number[];
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: UserLookupsQueryVariables;
    extractLookups: (query: UserLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const UserLookupWrapper = (props: IProps) => {
  return (
    <>
      {props.children({
        queryDocument: UserLookupsDocument,
        queryVariables: { includeIds: props.includeIds },
        extractLookups: (query) => query.userLookups,
      })}
    </>
  );
};
