import { DocumentNode } from 'graphql';
import React from 'react';
import {
  PeriodLookupModel,
  PeriodLookupsDocument,
  PeriodLookupsQuery,
  PeriodLookupsQueryVariables,
} from 'types/graphql';

interface IProps {
  includeIds?: number[];
  periodStatusIds?: number[];
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: PeriodLookupsQueryVariables;
    extractLookups: (query: PeriodLookupsQuery) => PeriodLookupModel[];
  }) => React.ReactNode;
}

export const PeriodLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: PeriodLookupsDocument,
        queryVariables: {
          includeIds: props.includeIds,
          periodStatusIds: props.periodStatusIds,
        },
        extractLookups: (query) => query.periodLookups,
      })}
    </>
  );
};
