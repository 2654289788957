import { DocumentNode } from 'graphql';
import React from 'react';
import {
  AccountNumberLookupsDocument,
  AccountNumberLookupsQuery,
  AccountNumberLookupsQueryVariables,
  LookupModel,
} from 'types/graphql';

interface IProps {
  includeIds?: number[];
  isActive?: boolean;
  includeDeleted?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: AccountNumberLookupsQueryVariables;
    extractLookups: (query: AccountNumberLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const AccountNumberLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: AccountNumberLookupsDocument,
        queryVariables: {
          includeIds: props.includeIds,
          includeDeleted: props.includeDeleted,
        },
        extractLookups: (query) => query.accountNumberLookups,
      })}
    </>
  );
};
