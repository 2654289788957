import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BalanceImportBatchStatusEnum, PeriodStatusEnum } from 'ar-common';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import useSpinnerModal from 'components/SpinnerModal/useSpinnerModal';
import React, { useEffect, useState } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import {
  BalanceImportOpenPeriodsListDocument,
  BalanceImportOpenPeriodsListQuery,
  BalanceImportOpenPeriodsListQueryVariables,
  BatchImportStatusDocument,
  BatchImportStatusFragment,
  BatchImportStatusQuery,
  BatchImportStatusQueryVariables,
  InitiateBalancesImportDocument,
  InitiateBalancesImportMutation,
  PeriodSortBy,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
  onImportComplete: (args: BatchImportStatusFragment) => Promise<void>;
}

export default (props: IProps) => {
  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError, error, confirm } = useModalAlerts(setAlertModal);
  const { openSpinnerModal, closeSpinnerModal } = useSpinnerModal({
    message: 'Initiating...',
  });

  const [importStatus, setImportStatus] =
    useState<BatchImportStatusFragment | null>();

  const [batchId, setBatchId] = useState<number | null>();

  const isImportComplete = (batchImportStatus: BatchImportStatusFragment) => {
    return [
      BalanceImportBatchStatusEnum.Completed,
      BalanceImportBatchStatusEnum.Error,
    ].includes(batchImportStatus.status);
  };

  const refreshImportStatus = async (): Promise<void> => {
    if (!batchId) {
      return;
    }

    await client
      .query<BatchImportStatusQuery, BatchImportStatusQueryVariables>({
        query: BatchImportStatusDocument,
        variables: {
          batchId: batchId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        if (isImportComplete(result.data.batchImportStatus)) {
          if (props.onImportComplete) {
            props.onImportComplete(result.data.batchImportStatus);
          }
          setImportStatus(null);
          return;
        }

        setImportStatus(result.data.batchImportStatus);
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      });
  };

  const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const handleImportButtonClick = async (): Promise<void> => {
    openSpinnerModal({ message: 'Checking open periods...' });
    await client
      .query<
        BalanceImportOpenPeriodsListQuery,
        BalanceImportOpenPeriodsListQueryVariables
      >({
        query: BalanceImportOpenPeriodsListDocument,
        variables: {
          filter: {
            periodStatusIds: [PeriodStatusEnum.Open],
          },
          sortOptions: {
            sortBy: PeriodSortBy.PeriodYearMonth,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        closeSpinnerModal();
        if (result.data.periods.items.length === 0) {
          error({ message: 'There are no open periods.' });
          return;
        }

        const hasUnsetReportIds = !!result.data.periods.items.find(
          (period) =>
            !period.usdReportId ||
            !period.usdIcReportId ||
            !period.foreignCurrencyReportId ||
            !period.foreignCurrencyIcReportId ||
            !period.usdEquityReportId,
        );

        return confirm({
          message: (
            <div>
              <p>
                The following periods are open and eligible for the import of
                balances.
              </p>
              <p className="font-weight-bold text-danger">
                It is critical that the correct reports are assigned to each
                period, otherwise the data in the Accounts Reconciliation System
                may become invalid and existing reconciliations and reviews may
                be reversed.
              </p>
              <p>
                <strong>NOTE:</strong> The report ID listed is the value that
                appears at the end of "customsearch" value in the "Id" field
                when editing the report, not the ID appearing in the report URL
                in Netsuite.
              </p>
              <table width="100%" className="mb-2">
                <thead>
                  <tr>
                    <th style={{ width: '15%' }}>Period</th>
                    <th style={{ width: '15%' }}>USD</th>
                    <th style={{ width: '15%' }}>USD IC</th>
                    <th style={{ width: '15%' }}>FC</th>
                    <th style={{ width: '15%' }}>FC IC</th>
                    <th style={{ width: '20%' }}>USD Equity</th>
                  </tr>
                </thead>
                <tbody>
                  {result.data.periods.items.map((period) => (
                    <tr key={period.id}>
                      <td>
                        {period.year}-{period.month}
                      </td>
                      <td>
                        {!!period.usdReportId && period.usdReportId}
                        {!period.usdReportId && (
                          <span className="text-danger">--</span>
                        )}
                      </td>
                      <td>
                        {!!period.usdIcReportId && period.usdIcReportId}
                        {!period.usdIcReportId && (
                          <span className="text-danger">--</span>
                        )}
                      </td>
                      <td>
                        {!!period.foreignCurrencyReportId &&
                          period.foreignCurrencyReportId}
                        {!period.foreignCurrencyReportId && (
                          <span className="text-danger">--</span>
                        )}
                      </td>
                      <td>
                        {!!period.foreignCurrencyIcReportId &&
                          period.foreignCurrencyIcReportId}
                        {!period.foreignCurrencyIcReportId && (
                          <span className="text-danger">--</span>
                        )}
                      </td>
                      <td>
                        {!!period.usdEquityReportId && period.usdEquityReportId}
                        {!period.usdEquityReportId && (
                          <span className="text-danger">--</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {hasUnsetReportIds && (
                <>
                  <p>
                    If a report ID has not been set, the data for those accounts
                    in that period will not be imported.
                  </p>
                  <p>
                    You may use the Manage Periods page to assign report IDs to
                    periods.
                  </p>
                </>
              )}
              <p>Click OK to proceed.</p>
            </div>
          ),
          onConfirm: initiateImport,
        });
      })
      .catch((reason: any) => {
        closeSpinnerModal();

        if (reason !== 'cancelled') {
          apolloError({
            error: reason,
          });
        }
      });
  };

  const initiateImport = async (): Promise<void> => {
    openSpinnerModal();
    return client
      .mutate<InitiateBalancesImportMutation>({
        mutation: InitiateBalancesImportDocument,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        if (result.data?.initiateBalancesImport.isError) {
          error({
            message: result.data.initiateBalancesImport.message,
          });
          return;
        }

        if (!result.data?.initiateBalancesImport.batchId) {
          error({
            message:
              result.data?.initiateBalancesImport.message ??
              'No import was initiated.  Note that there must be at least one open period in order to import.',
          });
          return;
        }

        setBatchId(result.data.initiateBalancesImport.batchId);
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      })
      .finally(() => {
        closeSpinnerModal();
      });
  };

  useEffect(() => {
    if (!!batchId) {
      refreshImportStatus();
    }
  }, [batchId]);

  useEffect(() => {
    if (!!importStatus) {
      sleep(1000).then(refreshImportStatus);
    }
  }, [importStatus]);

  return (
    <>
      {alertModal}
      <Modal show={!!importStatus} backdrop={'static'} keyboard={false}>
        <Modal.Body>
          {importStatus?.status === BalanceImportBatchStatusEnum.Initiated && (
            <>
              <p>Import has been initialized and will begin shortly...</p>
              <p className="text-center">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              </p>
            </>
          )}
          {importStatus?.status === BalanceImportBatchStatusEnum.Staging && (
            <>
              <p>Retrieving balances from Netsuite...</p>
              <p className="text-center">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              </p>
            </>
          )}
          {importStatus?.status === BalanceImportBatchStatusEnum.Importing && (
            <>
              <div className="row">
                <div className="col col-3">To Process:</div>
                <div className="col col-1">{importStatus?.totalToProcess}</div>
              </div>
              <div className="row">
                <div className="col col-3">Processed:</div>
                <div className="col col-1">{importStatus?.totalProcessed}</div>
              </div>
              <ProgressBar
                max={importStatus?.totalToProcess}
                now={importStatus?.totalProcessed}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
      <Button
        variant="primary"
        label="Import"
        onClick={handleImportButtonClick}
      />
    </>
  );
};
