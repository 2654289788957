import React from 'react';
import styles from './RequiredMarker.module.scss';

export interface IRequiredMarkerProps {
  isRequired?: boolean;
  className?: string;
}

export const RequiredMarker: React.FC<IRequiredMarkerProps> = (
  props: IRequiredMarkerProps,
) => {
  return props.isRequired ?? true ? (
    <span className={`${styles.requiredMarker} ${props.className ?? ''}`}>
      *
    </span>
  ) : null;
};
