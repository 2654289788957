import React, { useContext } from 'react';

export interface IAuthContext {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  user?: {
    userId: number | null;
    name: string | null;
    roles: string[];
  };
  //setUser: (user?: IAccessTokenPayload) => void;
}

const defaultContext: IAuthContext = {
  isAuthenticated: false,
  logout: async () => {
    console.warn('noop');
  },
};

export const AuthContext = React.createContext(defaultContext);

export const useAuthContext = (): IAuthContext => useContext(AuthContext);
