import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import Column from 'layouts/components/Grid/Column';
import React, { useEffect, useRef, useState } from 'react';
import {
  AccountSortBy,
  ManageAccountsListDocument,
  ManageAccountsListItemFragment,
  ManageAccountsListQuery,
  ManageAccountsListQueryVariables,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { IManageAssigneesFilterValues } from 'views/ManageAccounts/components/ManageAccountsFilters';
import { AddAccountButton } from './AddAccountButton';
import { BulkUpdateAccountsButton } from './BulkUpdateAccountsButton';
import { manageAccountsColumns } from './ManageAccountsColumns';

export interface IManageAccountsTableControl {
  applyFilter: (values: IManageAssigneesFilterValues) => void;
  export: (args: { filename: string }) => Promise<void>;
}

interface IManageAccountsTableProps {
  controlRef?: React.MutableRefObject<IManageAccountsTableControl | undefined>;
  onRefreshAccountNumbers: () => void;
}

export const ManageAccountsTable: React.FC<IManageAccountsTableProps> = (
  props: IManageAccountsTableProps,
) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();

  const [totalAccounts, setTotalAccounts] = useState<number>(0);
  const [accounts, setAccounts] = useState<ManageAccountsListItemFragment[]>(
    [],
  );

  const [sortBy, setSortBy] = useState<AccountSortBy>(
    AccountSortBy.AccountNumber,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [filterValues, setFilterValues] =
    useState<IManageAssigneesFilterValues>({});

  const [alertModal, setAlertModal] = useState<any>();
  const { success, apolloError } = useModalAlerts(setAlertModal);

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: AccountSortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<
      ManageAccountsListQuery,
      ManageAccountsListQueryVariables
    >({
      query: ManageAccountsListDocument,
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          subsidiaryIdIn: filterValues.subsidiaryIds,
          accountNumberIdIn: filterValues.accountNumberIds,
          dueToFromSubsidiaryIdIn: filterValues.dueToFromSubsidiaryIds,
          isReconciled: filterValues.isReconciled,
          isActive: filterValues.isActive,
          isAttachmentMandatory: filterValues.isAttachmentMandatory,
          isIncludedInReminderEmails: filterValues.isIncludedInReminderEmails,
          responsibleIdIn: filterValues.responsibleIds,
          reviewerIdIn: filterValues.reviewerIds,
        },
        paginationOptions:
          options.take === AllRowsPerPage
            ? undefined
            : {
                skip: options.skip,
                take: options.take,
              },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
    });
  };

  const fetchDataTablePage = (skip?: number, take?: number) => {
    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalAccounts(value.data.accounts.totalItems);
        setAccounts(value.data.accounts.items);
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const refreshGrid = async (): Promise<void> => {
    if (!!paginationControl.current?.refresh) {
      return paginationControl.current?.refresh();
    }
  };

  const handleSort = (sortBy: AccountSortBy, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  let exportFunction: DataTableExportFunction<ManageAccountsListItemFragment>;
  const handleExport = (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection }).then((value) => {
      return exportFunction({
        items: value.data.accounts.items,
        filename: args.filename,
      });
    });
  };

  const handleAccountsUpdated = async (message: string): Promise<void> => {
    handleDeselectAll();
    success({ message });
    props.onRefreshAccountNumbers();
    return refreshGrid();
  };

  useEffect(() => {
    refreshGrid();
  }, [filterValues, sortBy, sortDirection]);

  useEffect(() => {
    if (props.controlRef) {
      props.controlRef.current = {
        applyFilter: setFilterValues,
        export: handleExport,
      };
    }
  });

  const handleToggleAccountSelected = (id: number) => () => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => id !== selectedId));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSelectAll = () => {
    const newSelectedIds: number[] = [...selectedIds];
    for (const account of accounts) {
      if (!newSelectedIds.includes(account.id)) {
        newSelectedIds.push(account.id);
      }
    }

    setSelectedIds(newSelectedIds);
  };

  const handleDeselectAll = () => {
    let newSelectedIds: number[] = [...selectedIds];
    for (const account of accounts) {
      newSelectedIds = newSelectedIds.filter(
        (selectedId) => account.id !== selectedId,
      );
    }

    setSelectedIds(newSelectedIds);
  };

  const columns = manageAccountsColumns(
    selectedIds,
    handleToggleAccountSelected,
    handleAccountsUpdated,
  );

  return (
    <>
      {alertModal}
      <div className="row ml-1 mb-2">
        <Column width="auto">
          <div className="row">
            <a className="pointer mr-3" onClick={handleSelectAll}>
              Select All
            </a>
            <a className="pointer" onClick={handleDeselectAll}>
              Clear All
            </a>
          </div>
        </Column>
        <Column>
          <div className="d-flex justify-content-end">
            <AddAccountButton
              className="mr-3"
              onAccountAdded={handleAccountsUpdated}
            />
            <BulkUpdateAccountsButton
              selectedAccountIds={selectedIds}
              onAccountsUpdated={handleAccountsUpdated}
            />
          </div>
        </Column>
      </div>
      <Pagination
        totalNumberOfItems={totalAccounts}
        fetchDataTablePage={fetchDataTablePage}
        controlRef={paginationControl}
      >
        <DataTable<ManageAccountsListItemFragment, AccountSortBy>
          rowClassName={(item) =>
            selectedIds.includes(item.id) ? 'bg-light' : undefined
          }
          columns={columns}
          data={accounts}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          exportRef={(fn) => {
            exportFunction = fn;
          }}
          stickyHeaders={true}
        />
      </Pagination>
    </>
  );
};

export default ManageAccountsTable;
