"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumToValuesArray = void 0;
const StringIsNumber = (value) => isNaN(Number(value)) === false;
// Turn enum into array
const enumToValuesArray = (enumme) => {
    return Object.keys(enumme)
        .filter(StringIsNumber)
        .map((key) => enumme[key]);
};
exports.enumToValuesArray = enumToValuesArray;
