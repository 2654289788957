import React, { useState } from 'react';
import CommentsModal from './CommentsModal';
import { ApolloClient, DocumentNode } from '@apollo/client';
import { IComment } from './IComment';
import { IComments } from './IComments';
import useApolloClient from 'useApolloClient';

export interface ICommentsTriggerProps<TCommentsQuery> {
  title: string;
  objectId: number;
  readOnly: boolean;
  commentsQuery: DocumentNode;
  addCommentMutation: DocumentNode;
  updateCommentMutation?: DocumentNode;
  deleteCommentMutation?: DocumentNode;
  extractCommentsFromQueryResultData: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: TCommentsQuery,
  ) => IComments;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children: (params: { toggleModal: () => void }) => JSX.Element;
  refreshCommentsOnOpen?: boolean;
  client?: ApolloClient<unknown>;
  onCommentSaved?: () => Promise<void>;
}

export const CommentsTrigger = <TCommentsQuery,>(
  props: ICommentsTriggerProps<TCommentsQuery>,
): React.ReactElement => {
  const defaultClient = useApolloClient().client;

  const client = props.client ?? defaultClient;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [comments, setComments] = useState<IComment[]>([]);

  const toggleModal = async () => {
    setIsOpen(!isOpen);
    try {
      if (props.refreshCommentsOnOpen ?? true) {
        await refresh();
      }
      return true;
    } catch (err) {}
  };

  const refresh = async () => {
    return client
      .query<TCommentsQuery>({
        query: props.commentsQuery,
        fetchPolicy: 'no-cache',
        variables: { id: props.objectId },
      })
      .then((result) => {
        const resultFragment = props.extractCommentsFromQueryResultData(
          result.data,
        );

        setComments(
          resultFragment.comments.map((comment) => ({
            body: comment.body ?? '',
            user: {
              id: comment.user.id,
              name: comment.user.name ?? '',
            },
            date: comment.date,
            editedDate: comment.editedDate,
            id: comment.id,
            parentCommentId: comment.parentCommentId ?? undefined,
            isEditable: comment.isEditable,
            isDeletable: comment.isDeletable,
            period: comment.period,
          })),
        );
      });
  };

  const onAddComment = async (body: string) => {
    try {
      await client.mutate({
        mutation: props.addCommentMutation,
        fetchPolicy: 'no-cache',
        variables: {
          id: props.objectId,
          input: {
            body,
          },
        },
      });

      if (props.onCommentSaved) {
        await props.onCommentSaved();
      }

      return true;
    } catch (err) {
      console.error(err);
    }

    return false;
  };

  const onUpdateComment = async (commentId: number, body: string) => {
    if (props.updateCommentMutation) {
      try {
        await client.mutate({
          mutation: props.updateCommentMutation,
          fetchPolicy: 'no-cache',
          variables: {
            id: commentId,
            body,
          },
        });
        if (props.onCommentSaved) {
          await props.onCommentSaved();
        }
        return true;
      } catch (err) {
        console.error(err);
      }
    }
    return false;
  };

  const onDeleteComment = async (commentId: number) => {
    if (props.deleteCommentMutation) {
      try {
        await client.mutate({
          mutation: props.deleteCommentMutation,
          fetchPolicy: 'no-cache',
          variables: {
            id: commentId,
          },
        });

        if (props.onCommentSaved) {
          await props.onCommentSaved();
        }

        return true;
      } catch (err) {}
    }
    return false;
  };

  return (
    <>
      <CommentsModal
        title={props.title}
        comments={comments}
        refresh={refresh}
        onAddComment={onAddComment}
        onUpdateComment={
          props.updateCommentMutation ? onUpdateComment : undefined
        }
        onDeleteComment={
          props.deleteCommentMutation ? onDeleteComment : undefined
        }
        show={isOpen}
        onClose={toggleModal}
        readOnly={props.readOnly}
      />
      {props.children({
        toggleModal,
      })}
    </>
  );
};
