import useModalAlerts from 'components/Alerts/useModalAlerts';
import { getFirstAndLastOpenPeriods } from 'components/Lookups/PeriodLookups/PeriodLookupUtility';
import useSpinnerModal from 'components/SpinnerModal/useSpinnerModal';
import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useEffect, useRef, useState } from 'react';
import {
  AssigneeLookupsDocument,
  AssigneeLookupsQuery,
  AssigneeLookupsQueryVariables,
  BalancesSummary,
  BalancesSummaryDocument,
  BalancesSummaryQuery,
  BalancesSummaryQueryVariables,
  BalanceSummaryFilter,
  LookupModelFragment,
  SendRemindersForBalancesAwaitingReconciliationDocument,
  SendRemindersForBalancesAwaitingReconciliationMutation,
  SendRemindersForBalancesAwaitingReconciliationMutationVariables,
  SendRemindersForBalancesAwaitingReviewDocument,
  SendRemindersForBalancesAwaitingReviewMutation,
  SendRemindersForBalancesAwaitingReviewMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import {
  BalanceFilters,
  IBalanceFilterValues,
  PeriodSelectionType,
} from 'components/BalanceFilters/BalanceFilters';
import AccountStatusReportTable, {
  IBalanceStatusTableControl,
} from './components/AccountStatusReportTable';
import { balanceFilterValuesToQueryVariables } from 'components/BalanceFilters/BalanceFilters.util';
import { updateTitleEffect } from 'utils/page-util';
import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';
import { AccountStatusBlock } from './components/AccountStatusBlock';
import { Legend } from '../../components/Legend/Legend';

export const AccountStatusReport: React.FC = () => {
  const tableControl = useRef<IBalanceStatusTableControl>();

  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { warning, success, apolloError } = useModalAlerts(setAlertModal);
  const { openSpinnerModal, closeSpinnerModal } = useSpinnerModal({
    message: 'Working...',
  });

  // const [isFilterSectionCollapsed, setIsFilterSectionCollapsed] =
  //   useState<boolean>(false);
  const [assigneeLookups, setAssigneeLookups] = useState<
    LookupModelFragment[] | undefined
  >();

  const [filterValues, setFilterValues] = useState<IBalanceFilterValues>({});
  const [selectedBalanceSummaryFilter, setSelectedBalanceSummaryFilter] =
    useState<BalanceSummaryFilter>(BalanceSummaryFilter.All);
  const [balancesSummary, setBalancesSummary] = useState<BalancesSummary>();

  useEffect(updateTitleEffect('Account Status Report'), []);

  const initialize = async () => {
    const { earliestOpenPeriod, latestOpenPeriod } =
      await getFirstAndLastOpenPeriods(client);

    const initialFilterValues = {
      ...filterValues,
      periodSelectionType: PeriodSelectionType.period,
      period: latestOpenPeriod,
      startPeriod: earliestOpenPeriod,
      endPeriod: latestOpenPeriod,
      balanceSummary: selectedBalanceSummaryFilter,
    };

    await handleSearch(initialFilterValues);

    await client
      .query<AssigneeLookupsQuery, AssigneeLookupsQueryVariables>({
        query: AssigneeLookupsDocument,
        variables: {},
      })
      .then((result) => {
        if (result.data.userLookups) {
          setAssigneeLookups(result.data.userLookups);
        }
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );

    await refreshBalancesSummary(initialFilterValues);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleBalanceSummaryLinkClick =
    (balanceSummaryFilter: BalanceSummaryFilter) => () => {
      setSelectedBalanceSummaryFilter(balanceSummaryFilter);
      handleSearch({
        ...filterValues,
        balanceSummary: balanceSummaryFilter,
      });
    };

  const refreshBalancesSummary = async (values: IBalanceFilterValues) => {
    client
      .query<BalancesSummaryQuery, BalancesSummaryQueryVariables>({
        query: BalancesSummaryDocument,
        variables: {
          filter: balanceFilterValuesToQueryVariables(values),
        },
      })
      .then((r) => {
        setBalancesSummary(r.data.balancesSummary);
      });
  };

  const handleSearch = async (filter: IBalanceFilterValues) => {
    setFilterValues(filter);
    refreshBalancesSummary({
      ...filter,
      balanceSummary: filter.balanceSummary ?? selectedBalanceSummaryFilter,
    });
  };

  const handleExport = async () => {
    return tableControl.current?.export({
      filename: 'account-status.xlsx',
    });
  };

  const handleRemindResponsibles = async () => {
    if (!balancesSummary?.totalWithNoActivity) {
      warning({
        message:
          'There are no accounts with No Activity, so no emails will be sent.',
      });
      return;
    }

    openSpinnerModal();

    return client
      .mutate<
        SendRemindersForBalancesAwaitingReconciliationMutation,
        SendRemindersForBalancesAwaitingReconciliationMutationVariables
      >({
        mutation: SendRemindersForBalancesAwaitingReconciliationDocument,
        fetchPolicy: 'no-cache',
        variables: {
          filter: balanceFilterValuesToQueryVariables(filterValues),
        },
      })
      .then(() => {
        closeSpinnerModal();
        success({
          message: 'Emails sent',
        });
      })
      .catch((reason: any) => {
        closeSpinnerModal();
        apolloError({
          error: reason,
        });
      });
  };

  const handleRemindReviewers = async () => {
    if (!balancesSummary?.totalReconciledOnly) {
      warning({
        message:
          'There are no accounts that are Reconciled, so no emails will be sent.',
      });
      return;
    }

    openSpinnerModal();

    return client
      .mutate<
        SendRemindersForBalancesAwaitingReviewMutation,
        SendRemindersForBalancesAwaitingReviewMutationVariables
      >({
        mutation: SendRemindersForBalancesAwaitingReviewDocument,
        fetchPolicy: 'no-cache',
        variables: {
          filter: balanceFilterValuesToQueryVariables(filterValues),
        },
      })
      .then(() => {
        closeSpinnerModal();
        success({
          message: 'Emails sent',
        });
      })
      .catch((reason: any) => {
        closeSpinnerModal();
        apolloError({
          error: reason,
        });
      });
  };

  if (!assigneeLookups) {
    return <></>;
  }

  return (
    <>
      {alertModal}
      <Page title="Account Status Report">
        <SectionHeaderRow
        // isCollapsible={true}
        // isCollapsed={isFilterSectionCollapsed}
        // onCollapsedToggle={() =>
        //   setIsFilterSectionCollapsed(!isFilterSectionCollapsed)
        // }
        >
          <Column>
            <h1>View Accounts</h1>
          </Column>
        </SectionHeaderRow>
        <hr />

        {/*{!isFilterSectionCollapsed && (*/}
        <BalanceFilters
          assigneeLookups={assigneeLookups}
          searchButtonLabel="Display Status"
          onSearch={handleSearch}
          filterValues={filterValues}
        />
        {/*)}*/}

        <SectionHeaderRow className="mt-2">
          <Column width="auto">
            <h1>Accounts Status</h1>
          </Column>
          <Column width="auto" className="d-flex align-items-center">
            <Legend
              legendItems={[
                {
                  label: 'Unexpected, Active',
                  backgroundClassName: 'bg-warning',
                },
                {
                  label: 'Unexpected, Inactive',
                  backgroundClassName: 'bg-unexpected-inactive',
                },
                {
                  label: 'Expected, No data in import',
                  backgroundClassName: 'bg-no-netsuite-data',
                },
              ]}
            />
          </Column>
        </SectionHeaderRow>
        <hr />

        <div className="d-flex">
          <AccountStatusBlock
            label="No Activity"
            count={balancesSummary?.totalWithNoActivity ?? 0}
            onClickLabel={handleBalanceSummaryLinkClick(
              BalanceSummaryFilter.NoActivity,
            )}
            emailIcon={{
              title: 'Remind Responsibles',
              onClick: handleRemindResponsibles,
            }}
          />
          <AccountStatusBlock
            label="Reconciled"
            count={balancesSummary?.totalReconciledOnly ?? 0}
            onClickLabel={handleBalanceSummaryLinkClick(
              BalanceSummaryFilter.ReconciledOnly,
            )}
            emailIcon={{
              title: 'Remind Reviewers',
              onClick: handleRemindReviewers,
            }}
          />
          <AccountStatusBlock
            label="Reviewed"
            count={balancesSummary?.totalReconciledAndReviewed ?? 0}
            onClickLabel={handleBalanceSummaryLinkClick(
              BalanceSummaryFilter.ReconciledAndReviewed,
            )}
          />
          <AccountStatusBlock
            label="All"
            count={balancesSummary?.totalBalances ?? 0}
            onClickLabel={handleBalanceSummaryLinkClick(
              BalanceSummaryFilter.All,
            )}
          />
        </div>

        <SectionHeaderRow className="mt-5">
          <Column width="auto">
            <h1>
              Account Details for status:{' '}
              {selectedBalanceSummaryFilter ===
                BalanceSummaryFilter.NoActivity && 'No Activity'}
              {selectedBalanceSummaryFilter ===
                BalanceSummaryFilter.ReconciledOnly && 'Reconciled'}
              {selectedBalanceSummaryFilter ===
                BalanceSummaryFilter.ReconciledAndReviewed && 'Reviewed'}
              {selectedBalanceSummaryFilter === BalanceSummaryFilter.All &&
                'All'}
            </h1>
          </Column>
          <Column className="text-right">
            <ExportToExcelButton onClick={handleExport} />
          </Column>
        </SectionHeaderRow>
        <hr />

        <AccountStatusReportTable
          filterValues={filterValues}
          controlRef={tableControl}
          assigneeLookups={assigneeLookups}
        />
      </Page>
    </>
  );
};
