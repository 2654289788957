import { ChipSelect, IChipOption } from 'components/ChipSelect';
import React from 'react';
import { LookupModel } from 'types/graphql';

interface IProps {
  lookups: LookupModel[];
  selectedIds?: number[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const NonQueryChipSelect = (props: IProps): JSX.Element => {
  const toChipOption = (lookup: LookupModel): IChipOption => {
    return {
      id: lookup.id.toString(),
      label: lookup.name,
    };
  };

  return (
    <ChipSelect
      availableOptions={props.lookups
        .filter((l) => props.selectedIds?.includes(l.id) || !l.isDeleted)
        .map(toChipOption)}
      selectedOptions={props.lookups
        .filter((l) => props.selectedIds?.includes(l.id))
        .map(toChipOption)}
      onSelect={props.onChange}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
};
