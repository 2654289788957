import { Modal } from 'react-bootstrap';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import Column from 'layouts/components/Grid/Column';
import { IntegerInput } from 'components/Form/IntegerInput';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import {
  UpdatePeriodReportIdsDocument,
  UpdatePeriodReportIdsMutation,
  UpdatePeriodReportIdsMutationVariables,
} from 'types/graphql';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import useApolloClient from 'useApolloClient';

export interface IEditPeriodReportIdsModalProps {
  periodId: number;
  usdReportId: number | null;
  usdIcReportId: number | null;
  foreignCurrencyReportId: number | null;
  foreignCurrencyIcReportId: number | null;
  usdEquityReportId: number | null;
  onClose: (options: { refresh: boolean }) => Promise<void>;
}

export const EditPeriodReportIdsModal: React.FC<
  IEditPeriodReportIdsModalProps
> = (props: IEditPeriodReportIdsModalProps) => {
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const [usdReportId, setUsdReportId] = useState<number | null>(
    props.usdReportId,
  );
  const [usdIcReportId, setUsdIcReportId] = useState<number | null>(
    props.usdIcReportId,
  );
  const [foreignCurrencyReportId, setForeignCurrencyReportId] = useState<
    number | null
  >(props.foreignCurrencyReportId);
  const [foreignCurrencyIcReportId, setForeignCurrencyIcReportId] = useState<
    number | null
  >(props.foreignCurrencyIcReportId);
  const [usdEquityReportId, setUsdEquityReportId] = useState<number | null>(
    props.usdEquityReportId,
  );

  const handleUsdReportIdChange = (value: number | null): void => {
    setUsdReportId(value);
  };

  const handleUsdIcReportIdChange = (value: number | null) => {
    setUsdIcReportId(value);
  };

  const handleForeignCurrencyReportIdChange = (value: number | null): void => {
    setForeignCurrencyReportId(value);
  };

  const handleForeignCurrencyIcReportIdChange = (value: number | null) => {
    setForeignCurrencyIcReportId(value);
  };

  const handleUsdEquityReportIdChange = (value: number | null): void => {
    setUsdEquityReportId(value);
  };

  const handleCancel = (): void => {
    props.onClose({ refresh: false });
  };

  const handleSave = async (): Promise<void> => {
    const { client } = useApolloClient();

    await client
      .mutate<
        UpdatePeriodReportIdsMutation,
        UpdatePeriodReportIdsMutationVariables
      >({
        mutation: UpdatePeriodReportIdsDocument,
        variables: {
          input: {
            id: props.periodId,
            usdReportId,
            usdIcReportId,
            foreignCurrencyReportId,
            foreignCurrencyIcReportId,
            usdEquityReportId,
          },
        },
      })
      .then(() => {
        props.onClose({ refresh: true });
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      });
  };

  return (
    <>
      {alertModal}{' '}
      <Modal show={true} onHide={handleCancel} keyboard={false}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Edit Report IDs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>NOTE:</strong> The report ID listed is the value that
            appears at the end of "customsearch" value in the "Id" field when
            editing the report, not the ID appearing in the report URL in
            Netsuite.
          </p>
          <div className="row">
            <FieldSetColumn>
              <div className="row">
                <FormLabelColumn>USD</FormLabelColumn>
                <Column>
                  <IntegerInput
                    value={usdReportId}
                    minimumValue={0}
                    maximumValue={99999}
                    onChange={handleUsdReportIdChange}
                  />
                </Column>
              </div>
              <div className="row mt-3">
                <FormLabelColumn>USD IC</FormLabelColumn>
                <Column>
                  <IntegerInput
                    value={usdIcReportId}
                    minimumValue={0}
                    maximumValue={99999}
                    onChange={handleUsdIcReportIdChange}
                  />
                </Column>
              </div>
              <div className="row mt-3">
                <FormLabelColumn>Foreign Currency</FormLabelColumn>
                <Column>
                  <IntegerInput
                    value={foreignCurrencyReportId}
                    minimumValue={0}
                    maximumValue={99999}
                    onChange={handleForeignCurrencyReportIdChange}
                  />
                </Column>
              </div>
              <div className="row mt-3">
                <FormLabelColumn>Foreign Currency IC</FormLabelColumn>
                <Column>
                  <IntegerInput
                    value={foreignCurrencyIcReportId}
                    minimumValue={0}
                    maximumValue={99999}
                    onChange={handleForeignCurrencyIcReportIdChange}
                  />
                </Column>
              </div>
              <div className="row mt-3">
                <FormLabelColumn>USD Equity</FormLabelColumn>
                <Column>
                  <IntegerInput
                    value={usdEquityReportId}
                    minimumValue={0}
                    maximumValue={99999}
                    onChange={handleUsdEquityReportIdChange}
                  />
                </Column>
              </div>
            </FieldSetColumn>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
