import React from 'react';
import { useAuthContext } from 'auth/AuthContext';

interface LoggedOutContentProps {
  children: any;
}

export const LoggedOutContent = ({
  children,
}: LoggedOutContentProps): JSX.Element => {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) {
    return <></>;
  }

  return <>{children}</>;
};

export default LoggedOutContent;
