import { formatCurrency } from 'ar-common';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import React, { useEffect, useRef, useState } from 'react';
import {
  BalanceImportUnrecognizedAccountsDocument,
  BalanceImportUnrecognizedAccountsListItemFragment,
  BalanceImportUnrecognizedAccountSortBy,
  BalanceImportUnrecognizedAccountsQuery,
  BalanceImportUnrecognizedAccountsQueryVariables,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import Column from 'layouts/components/Grid/Column';

interface IBalanceImportUnrecognizedAccountsTableProps {
  balanceImportBatchId: number | null;
}

export const BalanceImportUnrecognizedAccountsTable: React.FC<
  IBalanceImportUnrecognizedAccountsTableProps
> = (props: IBalanceImportUnrecognizedAccountsTableProps) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const [
    totalBalanceImportUnrecognizedAccounts,
    setTotalBalanceImportUnrecognizedAccounts,
  ] = useState<number>(0);
  const [
    balanceImportUnrecognizedAccounts,
    setBalanceImportUnrecognizedAccounts,
  ] = useState<BalanceImportUnrecognizedAccountsListItemFragment[]>([]);
  const [sortBy, setSortBy] = useState<BalanceImportUnrecognizedAccountSortBy>(
    BalanceImportUnrecognizedAccountSortBy.SubsidiaryName,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: BalanceImportUnrecognizedAccountSortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<
      BalanceImportUnrecognizedAccountsQuery,
      BalanceImportUnrecognizedAccountsQueryVariables
    >({
      query: BalanceImportUnrecognizedAccountsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          balanceImportBatchId: props.balanceImportBatchId ?? 0,
        },
        paginationOptions:
          options.take === AllRowsPerPage
            ? undefined
            : {
                skip: options.skip,
                take: options.take,
              },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
    });
  };

  const fetchDataTablePage = async (skip?: number, take?: number) => {
    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalBalanceImportUnrecognizedAccounts(
          value.data.balanceImportUnrecognizedAccounts.totalItems,
        );
        setBalanceImportUnrecognizedAccounts(
          value.data.balanceImportUnrecognizedAccounts.items,
        );
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      });
  };

  const handleSort = (
    sortBy: BalanceImportUnrecognizedAccountSortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  // let exportFunction: DataTableExportFunction<BalanceImportUnrecognizedAccountsListItemFragment>;
  // const handleBatchImportUnrecognizedAccountsExport = async () => {
  //   return fetch({ sortBy, sortDirection }).then((value) => {
  //     return exportFunction({
  //       items: value.data.balanceImportUnrecognizedAccounts.items,
  //       filename: 'balance-import-unrecognized-accounts.xlsx',
  //     });
  //   });
  // };

  useEffect(() => {
    if (!props.balanceImportBatchId) {
      return;
    }

    if (!!paginationControl.current?.refresh) {
      paginationControl.current.refresh();
    }
  }, [props.balanceImportBatchId, sortBy, sortDirection]);

  const columns: IDataTableColumnDefinition<
    BalanceImportUnrecognizedAccountsListItemFragment,
    BalanceImportUnrecognizedAccountSortBy
  >[] = [
    {
      dataFieldName: 'periodId',
      heading: 'Period',
      width: '7rem',
      render: (balanceImport) =>
        !balanceImport.period
          ? 'Not recog.'
          : `${balanceImport.period.month < 10 ? '0' : ''}${
              balanceImport.period.month
            }-${balanceImport.period.year.toString()}`,
      excelExport: (balanceImport) =>
        !balanceImport.period
          ? 'Not recog.'
          : `${balanceImport.period.month < 10 ? '0' : ''}${
              balanceImport.period.month
            }-${balanceImport.period.year.toString()}`,
      sortBy: BalanceImportUnrecognizedAccountSortBy.Period,
    },
    {
      width: '8rem',
      dataFieldName: 'accountNumber',
      heading: 'Account',
      render: (balanceImport) => balanceImport.netsuiteAccountNumber.toString(),
      excelExport: (balanceImport) => balanceImport.netsuiteAccountNumber,
      sortBy: BalanceImportUnrecognizedAccountSortBy.AccountNumber,
    },
    {
      dataFieldName: 'subsidiary',
      heading: 'Subsidiary',
      width: '9rem',
      render: (balanceImport) => balanceImport.netsuiteSubsidiary,
      excelExport: (balanceImport) => balanceImport.netsuiteSubsidiary,
      sortBy: BalanceImportUnrecognizedAccountSortBy.SubsidiaryName,
    },
    {
      dataFieldName: 'dueToFromSubsidiary',
      heading: 'Due To/From',
      render: (balanceImport) =>
        balanceImport.netsuiteDueToFromSubsidiary ?? '',
      excelExport: (balanceImport) =>
        balanceImport.netsuiteDueToFromSubsidiary ?? '',
      sortBy: BalanceImportUnrecognizedAccountSortBy.DueToFromSubsidiaryName,
    },
    {
      dataFieldName: 'usdBalance',
      heading: 'USD Balance',
      headClassName: 'text-right',
      width: '10rem',
      cellClassName: (balanceImport) => (!balanceImport ? '' : 'text-right'),
      render: (balanceImport) =>
        !balanceImport.usdBalance && !balanceImport.foreignBalance
          ? '--'
          : formatCurrency(balanceImport.usdBalance),
      excelExport: (balanceImport) =>
        !balanceImport.usdBalance && !balanceImport.foreignBalance
          ? '--'
          : formatCurrency(balanceImport.usdBalance),
      sortBy: BalanceImportUnrecognizedAccountSortBy.UsdBalance,
    },
    {
      dataFieldName: 'foreignBalance',
      heading: 'For. Period Balance',
      headClassName: 'text-right',
      width: '20rem',
      cellClassName: (balanceImport) => (!balanceImport ? '' : 'text-right'),
      render: (balanceImport) =>
        !balanceImport.usdBalance && !balanceImport.foreignBalance
          ? '--'
          : formatCurrency(balanceImport.foreignBalance),
      excelExport: (balanceImport) =>
        !balanceImport.usdBalance && !balanceImport.foreignBalance
          ? '--'
          : formatCurrency(balanceImport.foreignBalance),
      sortBy: BalanceImportUnrecognizedAccountSortBy.ForeignBalance,
    },
  ];

  return (
    <div
      style={{
        visibility: !!props.balanceImportBatchId ? 'visible' : 'hidden',
      }}
    >
      {alertModal}
      <SectionHeaderRow className="mt-4">
        <Column width="auto">
          <h1>Unrecognized Accounts in Import</h1>
        </Column>
        <Column className="d-flex align-content-end justify-content-end ml-5">
          {/*<ExportToExcelButton onClick={handleBatchImportDetailExport} />*/}
        </Column>
      </SectionHeaderRow>
      <hr />

      <Pagination
        totalNumberOfItems={totalBalanceImportUnrecognizedAccounts}
        fetchDataTablePage={fetchDataTablePage}
        controlRef={paginationControl}
      >
        <DataTable<
          BalanceImportUnrecognizedAccountsListItemFragment,
          BalanceImportUnrecognizedAccountSortBy
        >
          columns={columns}
          data={balanceImportUnrecognizedAccounts}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          // exportRef={(fn) => {
          //   exportFunction = fn;
          // }}
          rowClassName={() => 'bg-warning'}
        />
      </Pagination>
    </div>
  );
};

export default BalanceImportUnrecognizedAccountsTable;
