"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriodTransitionEnum = void 0;
var PeriodTransitionEnum;
(function (PeriodTransitionEnum) {
    PeriodTransitionEnum[PeriodTransitionEnum["Initiated"] = 1] = "Initiated";
    PeriodTransitionEnum[PeriodTransitionEnum["Closed"] = 2] = "Closed";
    PeriodTransitionEnum[PeriodTransitionEnum["Reopened"] = 3] = "Reopened";
})(PeriodTransitionEnum = exports.PeriodTransitionEnum || (exports.PeriodTransitionEnum = {}));
exports.default = PeriodTransitionEnum;
