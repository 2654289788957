import { ISelectionItem, Select } from 'components/Form/Select';
import React from 'react';
import { BooleanLookupWrapper } from './BooleanLookupWrapper';

interface IProps {
  trueLabel?: string;
  falseLabel?: string;
  isChecked?: boolean;
  onCheck: (value: boolean | undefined) => void;
  disabled?: boolean;
}

export const BooleanSelect = (props: IProps): JSX.Element => {
  const handleSelect = (item?: ISelectionItem) => {
    if (!item || item.id === undefined) {
      props.onCheck(undefined);
      return;
    }

    props.onCheck(!!item.id ? true : false);
  };

  return (
    <BooleanLookupWrapper>
      <Select
        selectedId={
          props.isChecked === undefined ? undefined : props.isChecked ? 1 : 0
        }
        items={[
          { id: 1, label: props.trueLabel ?? 'Yes' },
          { id: 0, label: props.falseLabel ?? 'No' },
          { id: undefined, label: '' },
        ]}
        onSelect={handleSelect}
        disabled={props.disabled}
      />
    </BooleanLookupWrapper>
  );
};
