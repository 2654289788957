import { useAuthContext } from 'auth/AuthContext';
import AppVersion from 'components/AppVersion';
import React from 'react';
import MenuDropdown from '../MenuDropdown';
import SecuredContent from '../SecuredContent';

export const SiteHeader = (): JSX.Element => {
  const { user } = useAuthContext();

  return (
    <header className="site-header container-fluid">
      <div className="row">
        <div className="col">
          <MenuDropdown />
        </div>
        <div className="col text-right">
          Version: <AppVersion />
          <SecuredContent> | {user?.name}</SecuredContent>
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
