import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { AccountSortBy, ManageAccountsListItemFragment } from 'types/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { EditAccountLink } from './EditAccountLink';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

export const manageAccountsColumns = (
  selectedIds: number[],
  handleToggleAccountSelected: (accountId: number) => () => void,
  handleAccountsUpdated: (message: string) => Promise<void>,
): IDataTableColumnDefinition<
  ManageAccountsListItemFragment,
  AccountSortBy
>[] => {
  return [
    {
      dataFieldName: 'select',
      width: '2rem',
      cellClassName: () => 'text-center',
      render: (account) => {
        const isSelected = selectedIds.includes(account.id);

        return (
          <FontAwesomeIcon
            icon={isSelected ? faCheckSquare : faSquare}
            onClick={handleToggleAccountSelected(account.id)}
            className="pointer"
          />
        );
      },
      excludeFromExport: true,
    },
    {
      dataFieldName: 'subsidiary',
      heading: 'Subsidiary',
      width: '9em',
      render: (account) => account.subsidiary.name,
      excelExport: (account) => account.subsidiary.name,
      sortBy: AccountSortBy.SubsidiaryName,
    },
    {
      dataFieldName: 'accountNumber',
      heading: 'Account',
      width: '8rem',
      render: (account) => account.accountNumber.name,
      excelExport: (account) => account.accountNumber.name,
      sortBy: AccountSortBy.AccountNumber,
    },
    {
      dataFieldName: 'dueToFromSubsidiary',
      heading: 'Due To/From',
      width: '8em',
      render: (account) => account.dueToFromSubsidiary?.name ?? '',
      excelExport: (account) => account.dueToFromSubsidiary?.name ?? '',
      sortBy: AccountSortBy.DueToFromSubsidiaryName,
    },
    {
      dataFieldName: 'accountNumberDescription',
      heading: 'Description',
      render: (account) => (
        <EditAccountLink
          accountId={account.id}
          accountDescription={
            account.accountNumber.description ?? '(no description)'
          }
          onAccountEdited={handleAccountsUpdated}
        />
      ),
      excelExport: (account) => account.accountNumber.description ?? '',
      sortBy: AccountSortBy.AccountNumberDescription,
    },
    {
      dataFieldName: 'foreignCurrency',
      heading: 'Currency',
      width: '7rem',
      render: (account) => account.subsidiary.foreignCurrency?.name ?? '',
      excelExport: (account) => account.subsidiary.foreignCurrency?.name ?? '',
      // sortBy: AccountSortBy.foreignCurrencyName,
    },
    {
      dataFieldName: 'responsible',
      heading: 'Responsible',
      cellClassName: (account) =>
        !account?.responsible ? 'bg-primary text-light' : undefined,
      render: (account) =>
        !!account.responsible
          ? account.responsible?.name ?? '(no name)'
          : 'Unassigned',
      excelExport: (account) => account.responsible?.name ?? '',
      sortBy: AccountSortBy.Responsible,
    },
    {
      dataFieldName: 'reviewer',
      heading: 'Reviewer',
      cellClassName: (account) =>
        !account?.reviewer ? 'bg-primary text-light' : undefined,
      render: (account) =>
        !!account.reviewer
          ? account.reviewer?.name ?? '(no name)'
          : 'Unassigned',
      excelExport: (account) => account.reviewer?.name ?? '',
      sortBy: AccountSortBy.Reviewer,
    },
    {
      dataFieldName: 'isReconciled',
      heading: 'Do not Reconcile',
      width: '8rem',
      cellClassName: () => 'text-center',
      render: (account) =>
        !account.isReconciled ? (
          <FontAwesomeIcon icon={faCheck} className="text-success" />
        ) : (
          <></>
        ),
      excelExport: (account) => !account.isReconciled,
      sortBy: AccountSortBy.IsReconciled,
    },
    {
      dataFieldName: 'isActive',
      heading: 'Active',
      width: '7rem',
      cellClassName: () => 'text-center',
      render: (account) =>
        account.isActive ? (
          <FontAwesomeIcon icon={faCheck} className="text-success" />
        ) : (
          <></>
        ),
      excelExport: (account) => account.isActive,
      sortBy: AccountSortBy.IsActive,
    },
    {
      dataFieldName: 'isAttachmentMandatory',
      heading: 'Attachment Mandatory',
      width: '9rem',
      cellClassName: () => 'text-center',
      render: (account) =>
        account.isAttachmentMandatory ? (
          <FontAwesomeIcon icon={faCheck} className="text-success" />
        ) : (
          <></>
        ),
      excelExport: (account) => account.isAttachmentMandatory,
      sortBy: AccountSortBy.IsAttachmentMandatory,
    },
    {
      dataFieldName: 'isIncludedInReminderEmails',
      heading: 'Include in Email',
      width: '9rem',
      cellClassName: () => 'text-center',
      render: (account) =>
        account.isIncludedInReminderEmails ? (
          <FontAwesomeIcon icon={faCheck} className="text-success" />
        ) : (
          <></>
        ),
      excelExport: (account) => account.isIncludedInReminderEmails,
      sortBy: AccountSortBy.IsIncludedInReminderEmails,
    },
  ];
};
