import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TruncatingTextWrapperWithPopover from 'components/TruncatingTextWrapperWithPopover';
import React, { CSSProperties } from 'react';
import { SortDirection } from 'types/graphql';
import styles from './DataTable.module.scss';
import { downloadExcel } from './downloadExcel';
import { IDataTableColumnDefinition } from './IDataTableColumnDefinition';

interface IDataTableExportArgs<TItem> {
  items: TItem[];
  filename: string;
  onDone?: () => void;
}

export type DataTableExportFunction<TItem> = (
  args: IDataTableExportArgs<TItem>,
) => Promise<void>;

interface IDataTableProps<TItem, TSortBy> {
  data: TItem[];
  rowClassName?: (item: TItem) => string | undefined;
  columns: IDataTableColumnDefinition<TItem, TSortBy>[];
  onSort?: (sortBy: TSortBy, sortDirection: SortDirection) => void;
  small?: boolean;
  striped?: boolean;
  stickyHeaders?: boolean;
  sort?: {
    sortBy?: TSortBy;
    sortDirection?: SortDirection;
  };
  exportRef?: (fn: DataTableExportFunction<TItem>) => void;
  getDataItemId: (item: TItem, index: number) => any;
  tableWrapperStyle?: CSSProperties;
  isFetching?: boolean;
}

const handleSort =
  <TItem, TSortBy>(props: IDataTableProps<TItem, TSortBy>, sortBy: TSortBy) =>
  () => {
    if (props.onSort) {
      let sortDirection: SortDirection = SortDirection.Asc;
      if (props.sort?.sortBy === sortBy) {
        sortDirection =
          (props.sort?.sortDirection ?? SortDirection.Asc) === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc;
      }
      props.onSort(sortBy, sortDirection);
    }
  };

const createTableHeaderColumn = <TItem, TSortBy>(
  props: IDataTableProps<TItem, TSortBy>,
  column: IDataTableColumnDefinition<TItem, TSortBy>,
) => {
  if (!!column.heading && !!column.multiSortHeadings) {
    throw Error(
      `Cannot specify both simple heading and multi-sort headings for ${column.dataFieldName}`,
    );
  }

  if (!column.multiSortHeadings) {
    if (column.sortBy) {
      return (
        <th
          key={column.dataFieldName}
          className={`sortable pointer ${column.headClassName ?? ''}`}
          style={{
            width: column.width,
            maxWidth: column.width,
            minWidth: column.width,
            position: props.stickyHeaders ? 'sticky' : undefined,
            top: props.stickyHeaders ? 0 : undefined,
          }}
          onClick={handleSort(props, column.sortBy)}
        >
          {column.heading}

          {props.sort?.sortBy === column.sortBy &&
            (props.sort?.sortDirection === SortDirection.Desc ? (
              <FontAwesomeIcon
                className={`${styles['sort-direction-icon']}`}
                icon={faAngleDown}
              />
            ) : (
              <FontAwesomeIcon
                className={`${styles['sort-direction-icon']}`}
                icon={faAngleUp}
              />
            ))}
        </th>
      );
    }

    return (
      <th
        key={column.dataFieldName}
        className={`${
          column.cellClassName ? column.cellClassName() ?? '' : ''
        } ${column.headClassName ?? ''}`}
        style={{
          width: column.width,
          maxWidth: column.width,
          minWidth: column.width,
          position: props.stickyHeaders ? 'sticky' : undefined,
          top: props.stickyHeaders ? 0 : undefined,
        }}
      >
        {column.heading}
      </th>
    );
  }

  if (!!column.multiSortHeadings) {
    return (
      <th
        key={column.dataFieldName}
        className={`${
          column.cellClassName ? column.cellClassName() ?? '' : ''
        } ${column.headClassName ?? ''}`}
        style={{
          width: column.width,
          maxWidth: column.width,
          minWidth: column.width,
          position: props.stickyHeaders ? 'sticky' : undefined,
          top: props.stickyHeaders ? 0 : undefined,
        }}
      >
        {column.multiSortHeadings.map((multiSortHeading, index) => (
          <span
            key={index}
            className="sortable pointer"
            onClick={handleSort(props, multiSortHeading.sortBy)}
          >
            {multiSortHeading.heading}

            {props.sort?.sortBy === multiSortHeading.sortBy &&
              (props.sort?.sortDirection === SortDirection.Desc ? (
                <FontAwesomeIcon
                  className={`${styles['sort-direction-icon']}`}
                  icon={faAngleDown}
                />
              ) : (
                <FontAwesomeIcon
                  className={`${styles['sort-direction-icon']}`}
                  icon={faAngleUp}
                />
              ))}

            {index < (column.multiSortHeadings?.length ?? 0) - 1 && ' / '}
          </span>
        ))}
      </th>
    );
  }
};

const DataTable = <TItem, TSortBy = unknown>(
  props: IDataTableProps<TItem, TSortBy>,
): JSX.Element => {
  const tableClassNames = [];
  if (props.small) {
    tableClassNames.push('table-sm');
  }
  if (props.striped) {
    tableClassNames.push('table-striped');
  }

  const exportToExcel = async (
    args: IDataTableExportArgs<TItem>,
  ): Promise<void> => {
    const columns = props.columns.filter(
      (c) => c.excelExport && !c.excludeFromExport,
    );
    return downloadExcel({
      filename: args.filename,
      columns,
      data: args.items,
      onDone: args.onDone,
    });
  };

  if (props.exportRef) {
    props.exportRef(exportToExcel);
  }

  return (
    <div className="d-flex col-12 pl-0 pr-0" style={props.tableWrapperStyle}>
      <table
        className={`data-table ${styles['data-table']} table ${
          tableClassNames.length ? tableClassNames.join(' ') : ''
        }`}
      >
        <thead>
          <tr>
            {props.columns
              .filter((column) => !column.hideInUI)
              .map((column) => createTableHeaderColumn(props, column))}
          </tr>
        </thead>
        <tbody>
          {!!props.isFetching && 'Loading...'}
          {!props.isFetching &&
            props.data.map((rowData, index) => {
              return (
                <tr
                  key={props.getDataItemId(rowData, index)}
                  className={
                    props.rowClassName ? props.rowClassName(rowData) : undefined
                  }
                >
                  {props.columns
                    .filter((column) => !column.hideInUI)
                    .map((column) => {
                      return (
                        <td
                          key={column.dataFieldName}
                          className={`${
                            column.cellClassName
                              ? column.cellClassName(rowData) ?? ''
                              : ''
                          }`}
                          style={{
                            width: column.width,
                            maxWidth: column.width,
                            minWidth: column.width,
                          }}
                        >
                          {column.truncate ? (
                            <TruncatingTextWrapperWithPopover>
                              {column.render(rowData)}
                            </TruncatingTextWrapperWithPopover>
                          ) : (
                            column.render(rowData)
                          )}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
