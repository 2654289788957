import { LookupSelect } from 'components/Lookups/LookupSelect';
import { PeriodLookupWrapper } from 'components/Lookups/PeriodLookups/PeriodLookupWrapper';
import React from 'react';
import { PeriodLookupModel } from 'types/graphql';

interface IProps {
  includeIds?: number[];
  periodStatusIds?: number[];
  selectedId?: number | null;
  onSelect: (item?: PeriodLookupModel) => void;
  allowEmptySelection?: boolean;
  disabled?: boolean;
  selectFirst?: boolean | false;
}

export const PeriodSelect = (props: IProps): JSX.Element => {
  return (
    <>
      <PeriodLookupWrapper
        includeIds={props.includeIds}
        periodStatusIds={props.periodStatusIds}
      >
        {({ queryDocument, queryVariables, extractLookups }) => (
          <LookupSelect
            extractLookupItems={extractLookups}
            queryDocument={queryDocument}
            queryVariables={queryVariables}
            selectedId={props.selectedId}
            onSelect={props.onSelect}
            allowEmptySelection={props.allowEmptySelection}
            disabled={props.disabled}
            selectFirst={props.selectFirst}
          />
        )}
      </PeriodLookupWrapper>
    </>
  );
};
