import { StandardModal } from 'components/StandardModal/StandardModal';
import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import React, { useRef, useState } from 'react';
import { BalanceSelectionItemFragment } from 'types/graphql';
import {
  BalanceSelectionFilters,
  IBalanceSelectionFilterValues,
} from './components/BalanceSelectionFilters';
import BalanceSelectionTable, {
  IBalanceSelectionTableControl,
} from './components/BalanceSelectionTable';

export interface IBalanceSelectionModalProps {
  periodId: number;
  existingSelectedIds: number[];
  onCancelled: () => void;
  onAddBalances: (balances: BalanceSelectionItemFragment[]) => void;
}

export const BalanceSelectionModal: React.FC<IBalanceSelectionModalProps> = (
  props: IBalanceSelectionModalProps,
) => {
  const tableControl = useRef<IBalanceSelectionTableControl>();

  const [selectedBalances, setSelectedBalances] = useState<
    BalanceSelectionItemFragment[]
  >([]);

  const [filterValues, setFilterValues] =
    useState<IBalanceSelectionFilterValues>({});

  const handleSearch = async (values: IBalanceSelectionFilterValues) => {
    setFilterValues(values);
    tableControl.current?.applyFilter(values);
  };

  const handleSelectedBalancesChanged = (
    balances: BalanceSelectionItemFragment[],
  ) => {
    setSelectedBalances(balances);
  };

  const handleSave = async () => {
    if (selectedBalances.length === 0) {
      props.onCancelled();
      return;
    }

    props.onAddBalances(selectedBalances);
  };

  return (
    <StandardModal
      heading="Add Accounts"
      size="xl"
      saveButtonLabel="Add Accounts"
      readOnly={false}
      onSave={handleSave}
      onCancel={props.onCancelled}
    >
      <SectionHeaderRow>
        <Column>
          <h1>Filter Accounts</h1>
        </Column>
      </SectionHeaderRow>
      <hr />

      <BalanceSelectionFilters
        onSearch={handleSearch}
        filterValues={filterValues}
      />

      <SectionHeaderRow className="mt-2">
        <Column>
          <h1>Select Accounts</h1>
        </Column>
      </SectionHeaderRow>
      <hr />

      <BalanceSelectionTable
        controlRef={tableControl}
        periodId={props.periodId}
        excludeBalanceIds={props.existingSelectedIds}
        selectedBalances={selectedBalances}
        onSelectedBalancesChanged={handleSelectedBalancesChanged}
      />
    </StandardModal>
  );
};
