import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

interface IProps {
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  onCollapsedToggle?: () => void;
  className?: string;
  children: any;
}

export const SectionHeaderRow = (props: IProps) => {
  return (
    <div
      className={`row grid-section-header-row ${props.className ?? ''} ${
        props.isCollapsible ? 'pointer' : ''
      }`}
      onClick={props.isCollapsible ? props.onCollapsedToggle : undefined}
    >
      {props.isCollapsible && (
        <div
          className="col-auto d-flex align-items-center"
          style={{ width: '1.25em' }}
        >
          {props.isCollapsed && <FontAwesomeIcon icon={faChevronRight} />}
          {!props.isCollapsed && <FontAwesomeIcon icon={faChevronDown} />}
        </div>
      )}
      {props.children}
    </div>
  );
};

export default SectionHeaderRow;
