import React, { PropsWithChildren } from 'react';
import SiteHeader from './components/SiteHeader';

export const MainLayout = (
  props: PropsWithChildren<Record<string, any>>,
): JSX.Element => {
  const { children } = props;

  return (
    <>
      <SiteHeader />
      {children}
    </>
  );
};

export default MainLayout;
