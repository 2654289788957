import { IChipOption } from 'components/ChipSelect';
import { LookupChipSelect } from 'components/Lookups/LookupChipSelect';
import React, { useState } from 'react';
import { BalanceSelectionItemFragment } from 'types/graphql';
import { BalanceLookupWrapper } from './BalanceLookupWrapper';
import { BalanceSelectionModal } from './BalanceSelectionModal/BalanceSelectionModal';

interface IProps {
  periodId: number;
  includeIds?: number[];
  selectedIds?: number[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  onAddBalances: (balances: BalanceSelectionItemFragment[]) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const BalanceChipSelect = (props: IProps): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleAddBalances = (balances: BalanceSelectionItemFragment[]) => {
    props.onAddBalances(balances);
    hideModal();
  };

  return (
    <>
      <BalanceLookupWrapper
        periodId={props.periodId}
        includeIds={props.includeIds}
        readOnly={props.disabled}
      >
        {({ queryDocument, queryVariables, extractLookups }) => (
          <LookupChipSelect
            extractLookups={extractLookups}
            queryDocument={queryDocument}
            queryVariables={queryVariables}
            onChange={props.onChange}
            onCustomMenu={showModal}
            selectedIds={props.selectedIds}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
        )}
      </BalanceLookupWrapper>
      {isModalVisible && (
        <BalanceSelectionModal
          periodId={props.periodId}
          existingSelectedIds={props.selectedIds ?? []}
          onCancelled={hideModal}
          onAddBalances={handleAddBalances}
        />
      )}
    </>
  );
};
