import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { CheckBlock } from 'components/Form/CheckBlock';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import Column from 'layouts/components/Grid/Column';
import React, { useEffect, useRef, useState } from 'react';
import {
  BalanceSelectionDocument,
  BalanceSelectionItemFragment,
  BalanceSelectionQuery,
  BalanceSelectionQueryVariables,
  BalanceSortBy,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { IBalanceSelectionFilterValues } from './BalanceSelectionFilters';
import { BalanceStatusEnum } from 'ar-common';

export interface IBalanceSelectionTableControl {
  applyFilter: (values: IBalanceSelectionFilterValues) => void;
}

interface IBalanceSelectionTableProps {
  controlRef?: React.MutableRefObject<
    IBalanceSelectionTableControl | undefined
  >;
  periodId: number;
  excludeBalanceIds: number[];
  selectedBalances: BalanceSelectionItemFragment[];
  onSelectedBalancesChanged: (balances: BalanceSelectionItemFragment[]) => void;
}

export const BalanceSelectionTable: React.FC<IBalanceSelectionTableProps> = (
  props: IBalanceSelectionTableProps,
) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();

  const [totalBalances, setTotalBalances] = useState<number>(0);
  const [balances, setBalances] = useState<BalanceSelectionItemFragment[]>([]);

  const [sortBy, setSortBy] = useState<BalanceSortBy>(
    BalanceSortBy.AccountNumber,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [filterValues, setFilterValues] =
    useState<IBalanceSelectionFilterValues>({});

  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const getFilters = () => ({
    periodId: props.periodId,
    subsidiaryIdIn: filterValues.subsidiaryIds,
    accountIdIn: filterValues.accountIds,
    excludeIds: props.excludeBalanceIds,
    balanceStatusIdIn: [
      BalanceStatusEnum.Incomplete,
      BalanceStatusEnum.ReconciliationComplete,
      BalanceStatusEnum.ReviewComplete,
    ],
  });

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: BalanceSortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<BalanceSelectionQuery, BalanceSelectionQueryVariables>({
      query: BalanceSelectionDocument,
      fetchPolicy: 'no-cache',
      variables: {
        filter: getFilters(),
        paginationOptions:
          options.take === AllRowsPerPage
            ? undefined
            : {
                skip: options.skip,
                take: options.take,
              },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
    });
  };

  const fetchDataTablePage = (skip?: number, take?: number) => {
    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalBalances(value.data.balances.totalItems);
        setBalances(value.data.balances.items);
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const clearAllSelectedBalanceIds = () => {
    props.onSelectedBalancesChanged([]);
  };

  useEffect(clearAllSelectedBalanceIds, [filterValues]);

  const selectAllBalanceIds = async () => {
    client
      .query<BalanceSelectionQuery, BalanceSelectionQueryVariables>({
        query: BalanceSelectionDocument,
        fetchPolicy: 'no-cache',
        variables: {
          filter: getFilters(),
        },
      })
      .then((value) => {
        props.onSelectedBalancesChanged(value.data.balances.items);
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const handleToggleBalanceIsSelected =
    (balance: BalanceSelectionItemFragment) => () => {
      if (
        !!props.selectedBalances.find(
          (selectedBalance) => selectedBalance.id === balance.id,
        )
      ) {
        props.onSelectedBalancesChanged(
          props.selectedBalances.filter(
            (selectedBalance) => selectedBalance !== balance,
          ),
        );
      } else {
        props.onSelectedBalancesChanged(props.selectedBalances.concat(balance));
      }
    };

  const handleSort = (sortBy: BalanceSortBy, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  useEffect(() => {
    if (!!paginationControl.current?.refresh) {
      paginationControl.current.refresh();
    }
  }, [filterValues, sortBy, sortDirection]);

  if (props.controlRef) {
    props.controlRef.current = {
      applyFilter: setFilterValues,
    };
  }
  const columns: IDataTableColumnDefinition<
    BalanceSelectionItemFragment,
    BalanceSortBy
  >[] = [
    {
      dataFieldName: 'isSelected',
      heading: '',
      width: '1em',
      render: (balance) => (
        <CheckBlock
          checked={
            !!props.selectedBalances.find(
              (selectedBalance) => selectedBalance.id === balance.id,
            )
          }
          onCheckedChange={handleToggleBalanceIsSelected(balance)}
        />
      ),
      excludeFromExport: true,
    },
    {
      dataFieldName: 'subsidiary',
      heading: 'Subsidiary',
      width: '15em',
      render: (balance) => balance.subsidiary.name,
      sortBy: BalanceSortBy.SubsidiaryName,
    },
    {
      dataFieldName: 'accountNumber',
      heading: 'Acc.',
      width: '6rem',
      render: (balance) => balance.accountNumber.toString(),
      sortBy: BalanceSortBy.AccountNumber,
    },
    {
      dataFieldName: 'accountNumberDescription',
      heading: 'Account Description',
      render: (balance) => balance.accountNumberDescription ?? '',
      sortBy: BalanceSortBy.AccountNumberDescription,
    },
    {
      dataFieldName: 'balanceStatus',
      heading: 'Status',
      width: '8em',
      render: (balance) => balance.balanceStatus.name,
      sortBy: BalanceSortBy.BalanceStatusId,
    },
  ];

  return (
    <>
      {alertModal}
      <div className="row ml-1 ">
        <Column>
          <div className="row">
            <a className="pointer mr-3" onClick={selectAllBalanceIds}>
              Select All
            </a>
            <a className="pointer" onClick={clearAllSelectedBalanceIds}>
              Clear All
            </a>
          </div>
        </Column>
      </div>
      <Pagination
        totalNumberOfItems={totalBalances}
        fetchDataTablePage={fetchDataTablePage}
        pageSize={5}
        controlRef={paginationControl}
      >
        <DataTable<BalanceSelectionItemFragment, BalanceSortBy>
          columns={columns}
          data={balances}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
        />
      </Pagination>
    </>
  );
};

export default BalanceSelectionTable;
