import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export interface IAccountStatusBlockProps {
  label: string | JSX.Element;
  count: number;
  onClickLabel: () => void;
  emailIcon?: {
    title: string;
    onClick: () => void;
  };
}

export const AccountStatusBlock: React.FC<IAccountStatusBlockProps> = (
  props: IAccountStatusBlockProps,
) => {
  return (
    <div
      className="mr-4 d-flex align-items-center p-2 bg-light pointer"
      style={{ width: '12em' }}
      onClick={props.onClickLabel}
    >
      <div className="font-weight-bold text-nowrap">{props.label}</div>
      <div className="d-flex w-100 align-items-center justify-content-end">
        <div className="text-warning font-weight-bold">{props.count}</div>
        {!!props.emailIcon && (
          <div>
            <FontAwesomeIcon
              icon={faEnvelope}
              className="ml-2 pointer"
              title={props.emailIcon.title}
              onClick={props.emailIcon.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};
