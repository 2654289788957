import { ChipSelect, IChipOption } from 'components/ChipSelect';
import { LookupWrapper } from 'components/Lookups/LookupComponentWrapper';
import { DocumentNode } from 'graphql';
import React from 'react';
import { LookupModel } from 'types/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

interface IProps<TQuery, TQueryVariables> {
  options?: IChipOption[];
  selectedIds?: number[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  onCustomMenu?: () => void;
  queryDocument: DocumentNode;
  queryVariables: TQueryVariables;
  extractLookups: (query: TQuery) => LookupModel[];
  placeholder?: string;
  disabled?: boolean;
  take?: number;
  className?: string;
}

export const LookupChipSelect = <TQuery, TQueryVariables>(
  props: IProps<TQuery, TQueryVariables>,
): JSX.Element => {
  const toChipOption = (lookup: LookupModel): IChipOption => {
    return {
      id: lookup.id.toString(),
      label: lookup.name,
    };
  };

  return (
    <LookupWrapper<TQuery, TQueryVariables>
      queryDocument={props.queryDocument}
      queryVariables={props.queryVariables}
      selectedValues={props.selectedIds}
      extractLookups={props.extractLookups}
    >
      {({ lookups, selectedLookups }) => (
        <div className="d-flex">
          <ChipSelect
            availableOptions={lookups.map(toChipOption)}
            selectedOptions={selectedLookups.map(toChipOption)}
            onSelect={props.onChange}
            onCustomMenu={props.onCustomMenu}
            placeholder={props.placeholder}
            disabled={props.disabled}
            className={props.className}
          />
          {lookups.length === props.take && (
            <div className="d-flex align-items-center ml-1 text-warning">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                title={`Only the first ${props.take} items are shown. Enter more text, if necessary, to narrow the results.`}
              />
            </div>
          )}
        </div>
      )}
    </LookupWrapper>
  );
};
