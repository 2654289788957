import { PeriodStatusEnum } from 'ar-common/lib/period-status.enum';
import {
  LookupPeriodDocument,
  LookupPeriodQuery,
  LookupPeriodQueryVariables,
  PeriodLookupModel,
  PeriodLookupsDocument,
  PeriodLookupsQuery,
  PeriodLookupsQueryVariables,
} from 'types/graphql';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const getFirstAndLastOpenPeriods = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<{
  earliestOpenPeriod: PeriodLookupModel | undefined;
  latestOpenPeriod: PeriodLookupModel | undefined;
}> => {
  return client
    .query<PeriodLookupsQuery, PeriodLookupsQueryVariables>({
      query: PeriodLookupsDocument,
      variables: {},
      fetchPolicy: 'no-cache',
    })
    .then((result) => {
      // NOTE:  PeriodLookupsQuery orders periods in latest to the earliest order
      const latestOpenPeriod = result.data.periodLookups.find(
        (period) => period.periodStatus.id === PeriodStatusEnum.Open,
      );

      const earliestOpenPeriod = result.data.periodLookups
        .reverse()
        .find((period) => period.periodStatus.id === PeriodStatusEnum.Open);

      return {
        earliestOpenPeriod,
        latestOpenPeriod,
      };
    });
};

export const getPeriodById = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: number,
): Promise<PeriodLookupModel | null | undefined> => {
  return client
    .query<LookupPeriodQuery, LookupPeriodQueryVariables>({
      query: LookupPeriodDocument,
      variables: { id },
      fetchPolicy: 'no-cache',
    })
    .then((result) => {
      return result.data.lookupPeriod;
    });
};
