import { useAuthContext } from 'auth/AuthContext';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface SecuredRouteProps extends RouteProps {
  roles?: string[];
}

const SecuredRoute = ({
  roles,
  ...routeProps
}: SecuredRouteProps): JSX.Element => {
  const { isAuthenticated, logout, user } = useAuthContext();

  if (!isAuthenticated) {
    logout();
    return <></>;
  }

  if (roles && !user?.roles.find((userRole) => roles.includes(userRole))) {
    logout();
    return <></>;
  }

  return <Route {...routeProps} />;
};

export default SecuredRoute;
