"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceImportBatchStatusEnum = void 0;
var BalanceImportBatchStatusEnum;
(function (BalanceImportBatchStatusEnum) {
    BalanceImportBatchStatusEnum[BalanceImportBatchStatusEnum["Unknown"] = 0] = "Unknown";
    BalanceImportBatchStatusEnum[BalanceImportBatchStatusEnum["Initiated"] = 1] = "Initiated";
    BalanceImportBatchStatusEnum[BalanceImportBatchStatusEnum["Staging"] = 2] = "Staging";
    BalanceImportBatchStatusEnum[BalanceImportBatchStatusEnum["Importing"] = 3] = "Importing";
    BalanceImportBatchStatusEnum[BalanceImportBatchStatusEnum["Completed"] = 4] = "Completed";
    BalanceImportBatchStatusEnum[BalanceImportBatchStatusEnum["Error"] = 5] = "Error";
})(BalanceImportBatchStatusEnum = exports.BalanceImportBatchStatusEnum || (exports.BalanceImportBatchStatusEnum = {}));
exports.default = BalanceImportBatchStatusEnum;
