import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequiredMarker } from 'components/RequiredMarker/RequiredMarker';
import React, { CSSProperties } from 'react';

interface IProps {
  checked?: boolean;
  label?: string;
  onCheckedChange: (isChecked: boolean) => void;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  isRequired?: boolean;
  style?: CSSProperties;
  title?: string;
}

export const CheckBlock = (props: IProps) => {
  const handleCheckedChange = () => {
    props.onCheckedChange(!props.checked);
  };

  return (
    <div
      className={`${props.className ?? ''} ${
        !props.disabled && !props.readonly ? ' pointer' : ''
      }`}
      style={props.style}
    >
      {props.checked && (
        <FontAwesomeIcon
          icon={faCheckSquare}
          onClick={
            props.disabled || props.readonly ? undefined : handleCheckedChange
          }
          className={`mr-2 ${props.disabled ? 'disabled' : ''}`}
          title={props.title}
        />
      )}
      {!props.checked && (
        <FontAwesomeIcon
          icon={faSquare}
          onClick={
            props.disabled || props.readonly ? undefined : handleCheckedChange
          }
          className={`mr-2 ${props.disabled ? 'disabled' : ''}`}
          title={props.title}
        />
      )}
      {!!props.label && (
        <span>
          {props.label} <RequiredMarker isRequired={props.isRequired} />
        </span>
      )}
    </div>
  );
};
