import { LegendItem, LegendItemProps } from './LegendItem';
import React from 'react';

export interface ILegendProps {
  legendItems: LegendItemProps[];
}

export const Legend: React.FC<ILegendProps> = ({
  legendItems,
}: ILegendProps) => {
  return (
    <div className="d-flex pl-4">
      {legendItems.map((legendItemProps, index) => (
        <LegendItem {...legendItemProps} key={index} />
      ))}
    </div>
  );
};
