import { PeriodStatusEnum, PeriodTransitionEnum } from 'ar-common';
import { standardFormatDate } from 'ar-common/lib/utils/date-utils';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import padLeft from 'pad-left';
import React, { useEffect, useRef, useState } from 'react';
import {
  ClosePeriodDocument,
  ClosePeriodMutation,
  ClosePeriodMutationVariables,
  InitiatePeriodDocument,
  InitiatePeriodMutation,
  InitiatePeriodMutationVariables,
  ManagePeriodsListDocument,
  ManagePeriodsListItemFragment,
  ManagePeriodsListQuery,
  ManagePeriodsListQueryVariables,
  NumberBalancesWithMissingMandatoryAttachmentsForPeriodDocument,
  NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery,
  NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables,
  NumberUnreviewedBalancesForPeriodDocument,
  NumberUnreviewedBalancesForPeriodQuery,
  NumberUnreviewedBalancesForPeriodQueryVariables,
  PeriodSortBy,
  ReopenPeriodDocument,
  ReopenPeriodMutation,
  ReopenPeriodMutationVariables,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { IManagePeriodsFilterValues } from './ManagePeriodsFilters';
import useSpinnerModal from 'components/SpinnerModal/useSpinnerModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faEdit,
  faPlayCircle,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { EditPeriodReportIdsModal } from './EditPeriodReportIdsModal';

export interface IManagePeriodsTableControl {
  applyFilter: (values: IManagePeriodsFilterValues) => void;
  export: (args: { filename: string }) => Promise<void>;
  refresh: () => void;
}

interface IManagePeriodsTableProps {
  controlRef?: React.MutableRefObject<IManagePeriodsTableControl | undefined>;
}

export const ManagePeriodsTable: React.FC<IManagePeriodsTableProps> = (
  props: IManagePeriodsTableProps,
) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();

  const [totalPeriods, setTotalPeriods] = useState<number>(0);
  const [periods, setPeriods] = useState<ManagePeriodsListItemFragment[]>([]);

  const [sortBy, setSortBy] = useState<PeriodSortBy>(
    PeriodSortBy.PeriodYearMonth,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [filterValues, setFilterValues] = useState<IManagePeriodsFilterValues>(
    {},
  );

  const [showEditReportIdModalDetails, setShowEditReportIdModalDetails] =
    useState<
      | {
          periodId: number;
          usdReportId: number | null;
          usdIcReportId: number | null;
          foreignCurrencyReportId: number | null;
          foreignCurrencyIcReportId: number | null;
          usdEquityReportId: number | null;
        }
      | undefined
    >();

  const [alertModal, setAlertModal] = useState<any>();
  const { confirm, success, apolloError } = useModalAlerts(setAlertModal);
  const { openSpinnerModal, closeSpinnerModal } = useSpinnerModal({
    message: 'Processing...',
  });

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: PeriodSortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<
      ManagePeriodsListQuery,
      ManagePeriodsListQueryVariables
    >({
      query: ManagePeriodsListDocument,
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          startPeriod: filterValues.startPeriod?.sortableName,
          endPeriod: filterValues.endPeriod?.sortableName,
        },
        paginationOptions:
          options.take === AllRowsPerPage
            ? undefined
            : {
                skip: options.skip,
                take: options.take,
              },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
    });
  };

  const fetchDataTablePage = (skip?: number, take?: number) => {
    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalPeriods(value.data.periods.totalItems);
        setPeriods(value.data.periods.items);
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const handleSort = (sortBy: PeriodSortBy, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  let exportFunction: DataTableExportFunction<ManagePeriodsListItemFragment>;
  const handleExport = (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection }).then((value) => {
      return exportFunction({
        items: value.data.periods.items,
        filename: args.filename,
      });
    });
  };

  const handleInitiatePeriod =
    (period: ManagePeriodsListItemFragment) => () => {
      confirm({
        message: `You are about to open ${period.name}. Opening a period will commence scanning Netsuite for trial balance data. Are you certain you wish to open this period?`,
        onConfirm: () => {
          initiatePeriod(period.id);
        },
      });
    };

  const initiatePeriod = async (id: number): Promise<void> => {
    openSpinnerModal();

    return client
      .mutate<InitiatePeriodMutation, InitiatePeriodMutationVariables>({
        mutation: InitiatePeriodDocument,
        variables: {
          id,
        },
      })
      .then(() => {
        success({
          message: 'Period initiated.',
        });
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      })
      .finally(() => {
        closeSpinnerModal();

        if (!!paginationControl.current?.refresh) {
          paginationControl.current?.refresh();
        }
      });
  };

  const handleClosePeriod =
    (period: ManagePeriodsListItemFragment) => async () => {
      const numberUnreviewedBalancesForPeriod =
        await fetchNumberUnreviewedBalancesForPeriod(period.id);
      const numberBalancesWithMissingMandatoryAttachmentsForPeriod =
        await fetchNumberBalancesWithMissingMandatoryAttachmentsForPeriod(
          period.id,
        );

      const doClosePeriod = async (): Promise<void> => {
        await confirm({
          message: `You are about to close ${period.name}. Are you certain you wish to close the period?`,
          onConfirm: () => {
            closePeriod(period.id);
          },
        });
      };

      const checkNumberBalancesWithMissingMandatoryAttachmentsForPeriod =
        async (): Promise<void> => {
          if (numberBalancesWithMissingMandatoryAttachmentsForPeriod === 0) {
            await doClosePeriod();
            return;
          }

          await confirm({
            message: `There are accounts for ${period.name} which have the mandatory attachment flag set to True, but do not have an attachment.  Are you certain you wish to close the period?`,
            onConfirm: doClosePeriod,
          });
        };

      if (numberUnreviewedBalancesForPeriod === 0) {
        await checkNumberBalancesWithMissingMandatoryAttachmentsForPeriod();
        return;
      }

      await confirm({
        message: `There are accounts not yet completed for ${period.name}.  Are you certain you wish to close the period?`,
        onConfirm: checkNumberBalancesWithMissingMandatoryAttachmentsForPeriod,
      });
    };

  const fetchNumberUnreviewedBalancesForPeriod = async (periodId: number) => {
    const value = await client.query<
      NumberUnreviewedBalancesForPeriodQuery,
      NumberUnreviewedBalancesForPeriodQueryVariables
    >({
      query: NumberUnreviewedBalancesForPeriodDocument,
      variables: {
        periodId,
      },
    });

    return value.data.numberUnreviewedBalancesForPeriod;
  };

  const fetchNumberBalancesWithMissingMandatoryAttachmentsForPeriod = async (
    periodId: number,
  ) => {
    const value = await client.query<
      NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery,
      NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables
    >({
      query: NumberBalancesWithMissingMandatoryAttachmentsForPeriodDocument,
      variables: {
        periodId,
      },
    });

    return value.data.numberBalancesWithMissingMandatoryAttachmentsForPeriod;
  };

  const handleEditPeriodReportIds =
    (period: ManagePeriodsListItemFragment) => () => {
      setShowEditReportIdModalDetails({
        periodId: period.id,
        usdReportId: period.usdReportId || null,
        usdIcReportId: period.usdIcReportId || null,
        foreignCurrencyReportId: period.foreignCurrencyReportId || null,
        foreignCurrencyIcReportId: period.foreignCurrencyIcReportId || null,
        usdEquityReportId: period.usdEquityReportId || null,
      });
    };

  const handleEditPeriodReportIdsModalClose = async (options: {
    refresh: boolean;
  }): Promise<void> => {
    setShowEditReportIdModalDetails(undefined);

    if (!options.refresh) {
      return;
    }

    refresh();

    success({
      timeout: 1500,
    });
  };

  const closePeriod = (id: number) => {
    client
      .mutate<ClosePeriodMutation, ClosePeriodMutationVariables>({
        mutation: ClosePeriodDocument,
        variables: {
          id,
        },
      })
      .then(() => {
        success({
          timeout: 1500,
        });
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      })
      .finally(() => {
        if (!!paginationControl.current?.refresh) {
          paginationControl.current?.refresh();
        }
      });
  };

  const handleReopenPeriod = (period: ManagePeriodsListItemFragment) => () => {
    confirm({
      message: `You are about to re-open ${period.name}. Doing so will import data for this period, should it be available, and allow users to work balances in this period. Are you certain you wish to re-open this period?`,
      onConfirm: () => {
        reopenPeriod(period.id);
      },
    });
  };

  const reopenPeriod = (id: number) => {
    client
      .mutate<ReopenPeriodMutation, ReopenPeriodMutationVariables>({
        mutation: ReopenPeriodDocument,
        variables: {
          id,
        },
      })
      .then(() => {
        success({
          timeout: 1500,
        });
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      })
      .finally(() => {
        if (!!paginationControl.current?.refresh) {
          paginationControl.current?.refresh();
        }
      });
  };

  const refresh = () => {
    if (paginationControl.current) {
      paginationControl.current?.refresh();
    }
  };

  useEffect(() => {
    if (!!paginationControl.current?.refresh) {
      paginationControl.current?.refresh();
    }
  }, [filterValues, sortBy, sortDirection]);

  useEffect(() => {
    if (props.controlRef) {
      props.controlRef.current = {
        applyFilter: setFilterValues,
        export: handleExport,
        refresh,
      };
    }
  });

  const columns: IDataTableColumnDefinition<
    ManagePeriodsListItemFragment,
    PeriodSortBy
  >[] = [
    {
      dataFieldName: 'period',
      heading: 'Period',
      width: '7rem',
      render: (period) =>
        `${period.year} ${padLeft(period.month.toString(), 2, '0')}`,
      excelExport: (period) => `${period.year} ${period.month}`,
      sortBy: PeriodSortBy.PeriodYearMonth,
    },
    {
      dataFieldName: 'status',
      heading: 'Status',
      width: '10em',
      render: (period) => period.periodStatus.name,
      excelExport: (period) => period.periodStatus.name,
      sortBy: PeriodSortBy.Status,
    },
    {
      dataFieldName: 'reportIds',
      heading: 'USD / USD IC / FC / FC IC / USD Equity Reports',
      width: '20em',
      cellClassName: (period) =>
        !!period &&
        (!period?.usdReportId ||
          !period?.usdIcReportId ||
          !period?.foreignCurrencyReportId ||
          !period?.foreignCurrencyIcReportId ||
          !period?.usdEquityReportId)
          ? 'bg-warning'
          : undefined,
      render: (period) => (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {!!period.usdReportId ? period.usdReportId : '--'} /{' '}
            {!!period.usdIcReportId ? period.usdIcReportId : '--'} /{' '}
            {!!period.foreignCurrencyReportId
              ? period.foreignCurrencyReportId
              : '--'}{' '}
            /{' '}
            {!!period.foreignCurrencyIcReportId
              ? period.foreignCurrencyIcReportId
              : '--'}{' '}
            / {!!period.usdEquityReportId ? period.usdEquityReportId : '--'}
          </div>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={handleEditPeriodReportIds(period)}
            className="pointer ml-2"
            title="Edit"
          />
        </div>
      ),
    },
    {
      dataFieldName: 'usdReportId',
      heading: 'USD Report',
      render: () => '',
      hideInUI: true,
      excelExport: (period) => period.usdReportId,
    },
    {
      dataFieldName: 'usdICReportId',
      heading: 'USD IC Report',
      render: () => '',
      hideInUI: true,
      excelExport: (period) => period.usdIcReportId,
    },
    {
      dataFieldName: 'foreignCurrencyReportId',
      heading: 'FC Report',
      render: () => '',
      hideInUI: true,
      excelExport: (period) => period.foreignCurrencyReportId,
    },
    {
      dataFieldName: 'foreignCurrencyICReportId',
      heading: 'FC IC Report',
      render: () => '',
      hideInUI: true,
      excelExport: (period) => period.foreignCurrencyIcReportId,
    },
    {
      dataFieldName: 'usdEquityReportId',
      heading: 'USD Equity Report',
      render: () => '',
      hideInUI: true,
      excelExport: (period) => period.usdEquityReportId,
    },
    {
      dataFieldName: 'initiateDate',
      heading: 'Initiate Date',
      width: '10rem',
      render: (period) => standardFormatDate(period.initiateDate, 'N/A'),
      excelExport: (period) =>
        period.initiateDate ? new Date(period.initiateDate) : '',
      sortBy: PeriodSortBy.InitiateDate,
    },
    {
      dataFieldName: 'initiatedByUserId',
      heading: 'Initiated By',
      render: (period) =>
        period.initiatedByUser
          ? period.initiatedByUser.name || '(no name)'
          : '-',
      excelExport: (period) =>
        period.initiatedByUser
          ? period.initiatedByUser.name || '(no name)'
          : '',
    },
    {
      dataFieldName: 'closedDate',
      heading: 'Closed Date',
      width: '10rem',
      render: (period) => standardFormatDate(period.closedDate, 'N/A'),
      excelExport: (period) =>
        period.closedDate ? new Date(period.closedDate) : '',
      sortBy: PeriodSortBy.ClosedDate,
    },
    {
      dataFieldName: 'closedByUserId',
      heading: 'Closed By',
      render: (period) =>
        period.closedByUser ? period.closedByUser.name || '(no name)' : '-',
      excelExport: (period) =>
        period.closedByUser ? period.closedByUser.name || '(no name)' : '',
    },
    {
      dataFieldName: 'mostRecentReopenDate',
      heading: 'Most Recent Re-Open Date',
      width: '10rem',
      render: (period) => {
        const t = period.transitionLog
          .filter((l) => l.periodTransitionId === PeriodTransitionEnum.Reopened)
          .reverse()[0];
        return standardFormatDate(t?.transitionDate, 'N/A');
      },
      excelExport: (period) => {
        const t = period.transitionLog
          .filter((l) => l.periodTransitionId === PeriodTransitionEnum.Reopened)
          .reverse()[0];
        return t?.transitionDate ? new Date(t.transitionDate) : '';
      },
    },
    {
      dataFieldName: 'mostRecentReopenBy',
      heading: 'Most Recent Re-Open By',
      render: (period) => {
        const t = period.transitionLog
          .filter((l) => l.periodTransitionId === PeriodTransitionEnum.Reopened)
          .reverse()[0];
        return t?.transitionedByUser
          ? t.transitionedByUser.name || '(no name)'
          : 'N/A';
      },
      excelExport: (period) => {
        const t = period.transitionLog
          .filter((l) => l.periodTransitionId === PeriodTransitionEnum.Reopened)
          .reverse()[0];
        return t?.transitionedByUser
          ? t.transitionedByUser.name || '(no name)'
          : '';
      },
    },
    {
      dataFieldName: 'action',
      heading: 'Action',
      width: '6rem',
      cellClassName: () => 'text-center',
      render: (period) => {
        switch (period.periodStatus.id) {
          case PeriodStatusEnum.NotInitiated:
            return (
              <FontAwesomeIcon
                icon={faPlayCircle}
                onClick={handleInitiatePeriod(period)}
                className="pointer"
                title="Initiate"
                style={{ fontSize: 'larger' }}
              />
            );
          case PeriodStatusEnum.Open:
            return (
              <FontAwesomeIcon
                icon={faStopCircle}
                onClick={handleClosePeriod(period)}
                className="pointer"
                title="Close"
                style={{ fontSize: 'larger' }}
              />
            );
          case PeriodStatusEnum.Closed:
            return (
              <FontAwesomeIcon
                icon={faBackward}
                onClick={handleReopenPeriod(period)}
                className="pointer"
                title="Re-Open"
                style={{ fontSize: 'larger' }}
              />
            );
          default:
            return <></>;
        }
      },
    },
  ];

  return (
    <>
      {alertModal}
      <Pagination
        totalNumberOfItems={totalPeriods}
        fetchDataTablePage={fetchDataTablePage}
        controlRef={paginationControl}
      >
        <DataTable<ManagePeriodsListItemFragment, PeriodSortBy>
          columns={columns}
          data={periods}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          exportRef={(fn) => {
            exportFunction = fn;
          }}
          stickyHeaders={true}
        />
      </Pagination>
      {!!showEditReportIdModalDetails && (
        <EditPeriodReportIdsModal
          periodId={showEditReportIdModalDetails?.periodId || 0}
          usdReportId={showEditReportIdModalDetails?.usdReportId || null}
          usdIcReportId={showEditReportIdModalDetails?.usdIcReportId || null}
          foreignCurrencyReportId={
            showEditReportIdModalDetails?.foreignCurrencyReportId || null
          }
          foreignCurrencyIcReportId={
            showEditReportIdModalDetails?.foreignCurrencyIcReportId || null
          }
          usdEquityReportId={
            showEditReportIdModalDetails?.usdEquityReportId || null
          }
          onClose={handleEditPeriodReportIdsModalClose}
        />
      )}
    </>
  );
};

export default ManagePeriodsTable;
