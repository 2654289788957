import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from 'auth/AuthContext';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoggedOutContent from '../LoggedOutContent';
import SecuredContent from '../SecuredContent';

const CustomToggle = React.forwardRef(
  (
    { children, onClick }: { children?: React.ReactNode; onClick?: any },
    ref: any,
  ) => (
    <Button
      ref={ref}
      variant="link"
      className="menu-toggle"
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ),
);

const CustomMenu = React.forwardRef(
  (
    { style, className, 'aria-labelledby': labeledBy, close }: any,
    ref: any,
  ) => {
    const { logout } = useAuthContext();

    return (
      <div
        ref={ref}
        style={style}
        className={`menu ${className}`}
        aria-labelledby={labeledBy}
      >
        <div className="menu-logo"></div>

        <LoggedOutContent>
          <ul className="list-unstyled">
            <li>
              <Link to="/login" onClick={close}>
                Login
              </Link>{' '}
              <span className="arrow" />
            </li>
          </ul>
        </LoggedOutContent>

        <SecuredContent>
          <ul className="list-unstyled">
            <li>
              <Link to="/" onClick={close}>
                MAIN
              </Link>{' '}
              <span className="arrow" />
            </li>
          </ul>

          <ul className={`list-unstyled titled`}>
            <li className="mb-2">
              <Link to="/view-accounts" onClick={close}>
                View Accounts <span className="arrow" />
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/account-status" onClick={close}>
                Account Status Report <span className="arrow" />
              </Link>
            </li>
          </ul>
        </SecuredContent>

        <SecuredContent roles={[process.env.REACT_APP_ADMIN_ROLE_NAME ?? '']}>
          <div className="list-heading mb-2">Administration</div>
          <ul className={`list-unstyled titled`}>
            <li className="mb-2">
              <Link to="/import-balances" onClick={close}>
                Import Balances <span className="arrow" />
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/audit-trail" onClick={close}>
                Audit Trail <span className="arrow" />
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/manage-accounts" onClick={close}>
                Manage Accounts <span className="arrow" />
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/manage-periods" onClick={close}>
                Manage Periods <span className="arrow" />
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/reactivated-accounts" onClick={close}>
                Reactivated Accounts <span className="arrow" />
              </Link>
            </li>
          </ul>

          <ul className={`list-unstyled titled`}>
            <li>
              <Button variant="link" className="pl-0" onClick={logout}>
                Logout
              </Button>
            </li>
          </ul>
        </SecuredContent>
      </div>
    );
  },
);

export const MenuDropdown = (): JSX.Element => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="main-dropdown-menu">
        <span>
          <FontAwesomeIcon icon={faBars} /> Menu
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}></Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuDropdown;
