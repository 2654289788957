import {
  ApolloClient,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import auth from 'auth/AuthClient';
import { apiBaseUrl } from 'utils/site-util';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const useApolloClient = (): {
  client: ApolloClient<NormalizedCacheObject>;
} => {
  const httpLink = createUploadLink({
    uri: `${apiBaseUrl()}/graphql`,
  });

  const withToken = setContext(async (_, { headers }) => {
    const accessToken = auth.getAccessToken();

    return {
      headers: {
        ...headers,
        Authorization: !!accessToken ? `Bearer ${accessToken}` : null,
      },
    };
  });

  const client = new ApolloClient({
    uri: `${apiBaseUrl()}/graphql`,
    cache: new InMemoryCache(),
    link: from([withToken, httpLink]),
  });

  return {
    client,
  };
};

export default useApolloClient;
