import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React from 'react';
import { Pagination } from 'react-bootstrap';
import RowsPerPageSelect, { AllRowsPerPage } from './RowsPerPageSelect';

export interface IProps {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  showAllRowsOption?: boolean;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage?: number) => void;
}

export default (props: IProps): JSX.Element => {
  const totalPages = Math.ceil(props.count / props.rowsPerPage);

  const showingFromRowNumber =
    props.rowsPerPage === AllRowsPerPage
      ? 0
      : props.count === 0
      ? 0
      : (props.page - 1) * props.rowsPerPage + 1;

  const showingToRowNumber = Math.min(
    props.count,
    props.page * props.rowsPerPage,
  );

  let firstItemNumber = props.page - 2;
  let lastItemNumber = props.page + 2;

  if (firstItemNumber < 1) {
    firstItemNumber = 1;
    lastItemNumber = Math.min(firstItemNumber + 4, totalPages);
  }

  if (lastItemNumber > totalPages) {
    lastItemNumber = totalPages;
    firstItemNumber = Math.max(1, totalPages - 4);
  }

  const showPrecedingEllipses = firstItemNumber > 1;
  const showFollowingEllipses = lastItemNumber < totalPages;

  const pageNumbersToShow = [];
  for (let i = firstItemNumber; i <= lastItemNumber; i++) {
    pageNumbersToShow.push(i);
  }

  const handlePageClick = (page: number) => () => {
    props.onChangePage(page);
  };

  return (
    <div className="paginator">
      <div className="row">
        <FormLabelColumn>
          Displaying{' '}
          {showingFromRowNumber
            ? `${showingFromRowNumber} to ${showingToRowNumber} of`
            : 'All'}{' '}
          {props.count} records
        </FormLabelColumn>

        <div className="col">
          {!!showingFromRowNumber && (
            <Pagination>
              <Pagination.First
                disabled={props.page === 1}
                className="mr-0"
                onClick={handlePageClick(1)}
              />
              <Pagination.Prev
                disabled={props.page === 1}
                className="mr-0"
                onClick={handlePageClick(props.page - 1)}
              />
              {showPrecedingEllipses && (
                <Pagination.Ellipsis className="mr-0" />
              )}
              {pageNumbersToShow.map((pageNumber) => (
                <Pagination.Item
                  key={pageNumber}
                  active={pageNumber === props.page}
                  className="mr-0"
                  onClick={handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              ))}
              {showFollowingEllipses && (
                <Pagination.Ellipsis className="mr-0" />
              )}
              <Pagination.Next
                disabled={props.page === totalPages}
                className="mr-0"
                onClick={handlePageClick(props.page + 1)}
              />
              <Pagination.Last
                disabled={props.page === totalPages}
                className="mr-0"
                onClick={handlePageClick(totalPages)}
              />
            </Pagination>
          )}
        </div>
        <div className="col">
          {props.onChangeRowsPerPage && (
            <RowsPerPageSelect
              labelText={'Rows Per Page'}
              rowsPerPage={props.rowsPerPage}
              showAllRowsOption={props.showAllRowsOption}
              onChangeRowsPerPage={props.onChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
