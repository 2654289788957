import React, { useState } from 'react';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import useApolloClient from 'useApolloClient';
import { IAccountDetailValues } from './AccountDetail';
import {
  AccountNumberLookupsDocument,
  AccountNumberLookupsQuery,
  AccountNumberLookupsQueryVariables,
  AccountUpdateDetailDocument,
  AccountUpdateDetailQuery,
  AccountUpdateDetailQueryVariables,
  UpdateAccountDocument,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from 'types/graphql';
import { AccountDetailDialog } from './AccountDetailDialog';

export interface IEditAccountLinkProps {
  accountId: number;
  accountDescription: string;
  onAccountEdited?: (message: string) => Promise<void>;
  className?: string;
}

export const EditAccountLink: React.FC<IEditAccountLinkProps> = (
  props: IEditAccountLinkProps,
) => {
  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [values, setValues] = useState<IAccountDetailValues>({});

  const handleValuesChange = (newValues: IAccountDetailValues): void => {
    setValues(newValues);
  };

  const handleShow = async (): Promise<void> => {
    return client
      .query<AccountUpdateDetailQuery, AccountUpdateDetailQueryVariables>({
        query: AccountUpdateDetailDocument,
        variables: { id: props.accountId },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const account = result.data.account;
        if (!!account) {
          setValues({
            accountNumberValue: account.accountNumber?.name,
            accountDescription: account.accountNumber?.description ?? undefined,
            subsidiaryId: account.subsidiary?.id,
            dueToFromSubsidiaryId: account.dueToFromSubsidiary?.id,
            isReconciled: account.isReconciled,
            isActive: account.isActive,
            isAttachmentMandatory: account.isAttachmentMandatory,
            isIncludedInReminderEmails: account.isIncludedInReminderEmails,
            responsibleUserId: account.responsible?.id,
            reviewerUserId: account.reviewer?.id,
          });

          setIsDialogVisible(true);
        }
      })
      .catch((reason) => {
        apolloError({
          error: reason,
        });
      });
  };

  const handleSave = async (applyToOpenPeriods?: boolean): Promise<void> => {
    return client
      .mutate<UpdateAccountMutation, UpdateAccountMutationVariables>({
        mutation: UpdateAccountDocument,
        variables: {
          input: {
            applyToOpenPeriods: applyToOpenPeriods ?? false,
            id: props.accountId,
            accountNumber: {
              accountNumber: values.accountNumberValue,
              description: values.accountDescription?.trim(),
            },
            subsidiaryId: values.subsidiaryId,
            dueToFromSubsidiaryId: values.dueToFromSubsidiaryId,
            isReconciled: values.isReconciled,
            isActive: values.isActive ?? false,
            isAttachmentMandatory: values.isAttachmentMandatory ?? false,
            isIncludedInReminderEmails:
              values.isIncludedInReminderEmails ?? false,
            responsibleUserId: values.responsibleUserId,
            reviewerUserId: values.reviewerUserId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(() => {
        handleClose();
        if (!!props.onAccountEdited) {
          return props.onAccountEdited('Account updated successfully.');
        }
      })
      .catch((reason) => {
        apolloError({
          error: reason,
        });
      });
  };

  const handleClose = (): void => {
    setIsDialogVisible(false);
  };

  const handleAccountNumberValueBlur = async (): Promise<void> => {
    if (!values.accountNumberValue) {
      return;
    }

    return client
      .query<AccountNumberLookupsQuery, AccountNumberLookupsQueryVariables>({
        query: AccountNumberLookupsDocument,
        variables: { name: values.accountNumberValue?.toString() },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const match = result.data.accountNumberLookups.find(
          (lookup) => lookup.name === values.accountNumberValue?.toString(),
        );

        if (!match) {
          setValues({
            ...values,
            accountDescription: '',
          });
          return;
        }

        setValues({
          ...values,
          accountDescription: match.description ?? '',
        });
      });
  };

  return (
    <>
      {alertModal}
      <div
        className={`pointer primary-link ${props.className ?? ''}`}
        onClick={handleShow}
      >
        {props.accountDescription || '(no description)'}
      </div>
      {isDialogVisible && (
        <AccountDetailDialog
          dialogTitle="Account Details"
          onSave={handleSave}
          onCancel={handleClose}
          values={values}
          onValuesChange={handleValuesChange}
          onAccountNumberValueBlur={handleAccountNumberValueBlur}
        />
      )}
    </>
  );
};
