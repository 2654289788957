import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PeriodStatusEnum } from 'ar-common';
import React from 'react';
import { ViewAccountsListItemFragment } from 'types/graphql';
import { BalanceFileAttachmentModalTrigger } from '../BalanceFileAttachmentModal/BalanceFileAttachmentModalTrigger';

export interface IBalanceAttachmentsActionProps {
  balance: ViewAccountsListItemFragment;
  onBalancesUpdated: (updatedBalanceIds: number[]) => Promise<void>;
  isSelectedForBulkAttachment?: boolean;
  onSelectedForBulkAttachmentChange?: (isChecked: boolean) => void;
  readOnly?: boolean;
}

export const BalanceAttachmentsAction: React.FC<
  IBalanceAttachmentsActionProps
> = (props: IBalanceAttachmentsActionProps) => {
  const readOnly: boolean =
    props.readOnly ||
    props.balance.period.periodStatus.id !== PeriodStatusEnum.Open;

  const balanceFileAttachmentModalAvailable =
    !readOnly || props.balance.hasFileAttachments;

  if (!balanceFileAttachmentModalAvailable) {
    return <></>;
  }

  return (
    <BalanceFileAttachmentModalTrigger
      preselectedBalanceId={props.balance.id}
      onSaved={props.onBalancesUpdated}
      readOnly={readOnly}
    >
      {(onTrigger) => (
        <div className="col">
          <FontAwesomeIcon
            icon={!props.balance.hasFileAttachments ? faUpload : faPaperclip}
            className={'pointer'}
            onClick={onTrigger}
          />
          {/*{!readOnly && (*/}
          {/*  <CheckBlock*/}
          {/*    checked={props.isSelectedForBulkAttachment}*/}
          {/*    onCheckedChange={*/}
          {/*      props.onSelectedForBulkAttachmentChange ||*/}
          {/*      (() => {*/}
          {/*        return;*/}
          {/*      })*/}
          {/*    }*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      )}
    </BalanceFileAttachmentModalTrigger>
  );
};
