import { useAuthContext } from 'auth/AuthContext';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import {
  BalanceFilters,
  IBalanceFilterValues,
  PeriodSelectionType,
} from 'components/BalanceFilters/BalanceFilters';
import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';
import { getFirstAndLastOpenPeriods } from 'components/Lookups/PeriodLookups/PeriodLookupUtility';
import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useEffect, useRef, useState } from 'react';
import {
  AssigneeLookupsDocument,
  AssigneeLookupsQuery,
  AssigneeLookupsQueryVariables,
  LookupModelFragment,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { updateTitleEffect } from 'utils/page-util';
import ViewAccountsTable, {
  IViewAccountsTableControl,
} from 'views/ViewAccounts/components/ViewAccountsTable';
import { ApolloQueryResult } from '@apollo/client';
import { Legend } from '../../components/Legend/Legend';

export const ViewAccounts: React.FC = () => {
  const tableControl = useRef<IViewAccountsTableControl>();

  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const roles = useAuthContext().user?.roles ?? [];
  const userId = useAuthContext().user?.userId ?? -1;

  const [isFilterSectionCollapsed, setIsFilterSectionCollapsed] =
    useState<boolean>(false);
  const [assigneeLookups, setAssigneeLookups] = useState<
    LookupModelFragment[] | undefined
  >();

  const [filterValues, setFilterValues] = useState<IBalanceFilterValues>({});

  useEffect(updateTitleEffect('View Accounts'), []);

  useEffect(() => {
    const initialFilterValues: IBalanceFilterValues = {
      responsibleIds:
        roles.length === 1 &&
        roles.includes(process.env.REACT_APP_RESPONSIBLE_ROLE_NAME ?? '')
          ? [userId]
          : undefined,
      reviewerIds:
        roles.length === 1 &&
        roles.includes(process.env.REACT_APP_REVIEWER_ROLE_NAME ?? '')
          ? [userId]
          : undefined,
    };

    client
      .query<AssigneeLookupsQuery, AssigneeLookupsQueryVariables>({
        query: AssigneeLookupsDocument,
        variables: {},
      })
      .then((result: ApolloQueryResult<AssigneeLookupsQuery>) => {
        if (result.data.userLookups) {
          setAssigneeLookups(result.data.userLookups);
        }

        getFirstAndLastOpenPeriods(client).then(
          ({ earliestOpenPeriod, latestOpenPeriod }) => {
            handleSearch({
              ...initialFilterValues,
              periodSelectionType: PeriodSelectionType.period,
              period: latestOpenPeriod,
              startPeriod: earliestOpenPeriod,
              endPeriod: latestOpenPeriod,
            });
          },
        );
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  }, []);

  const handleSearch = async (values: IBalanceFilterValues) => {
    setFilterValues(values);
  };

  const handleExport = async () => {
    return tableControl.current?.export({
      filename: 'balances.xlsx',
    });
  };

  if (!assigneeLookups) {
    return <></>;
  }

  return (
    <>
      {alertModal}
      <Page title="View Accounts">
        <SectionHeaderRow
          isCollapsible={false}
          isCollapsed={isFilterSectionCollapsed}
          onCollapsedToggle={() =>
            setIsFilterSectionCollapsed(!isFilterSectionCollapsed)
          }
        >
          <Column>
            <h1>Filter Accounts</h1>
          </Column>
        </SectionHeaderRow>
        <hr />

        {!isFilterSectionCollapsed && (
          <>
            <BalanceFilters
              assigneeLookups={assigneeLookups}
              onSearch={handleSearch}
              filterValues={filterValues}
            />
          </>
        )}

        <SectionHeaderRow className="mt-2">
          <Column width="auto">
            <h1>List of Accounts</h1>
          </Column>
          <Column width="auto" className="d-flex align-items-center">
            <Legend
              legendItems={[
                {
                  label: 'Unexpected, Active',
                  backgroundClassName: 'bg-warning',
                },
                {
                  label: 'Unexpected, Inactive',
                  backgroundClassName: 'bg-unexpected-inactive',
                },
                {
                  label: 'Expected, No data in import',
                  backgroundClassName: 'bg-no-netsuite-data',
                },
              ]}
            />
          </Column>
          <Column className="text-right">
            <ExportToExcelButton onClick={handleExport} />
          </Column>
        </SectionHeaderRow>
        <hr />

        <ViewAccountsTable
          filterValues={filterValues}
          controlRef={tableControl}
          assigneeLookups={assigneeLookups}
        />
      </Page>
    </>
  );
};
