import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TruncatingTextWrapperWithPopover from 'components/TruncatingTextWrapperWithPopover';
import { Column } from 'layouts/components/Grid/Column';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IFileAttachment } from 'components/FileAttachmentInputWrapper/IFileAttachment';
import { FileAttachmentInputWrapper } from 'components/FileAttachmentInputWrapper/FileAttachmentInputWrapper';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons';

export interface IFileAttachmentInputProps {
  fileAttachment: IFileAttachment | null;
  readOnly: boolean;
  onFileAttachmentChanged: (fileAttachment: IFileAttachment | null) => void;
}

export const FileAttachmentInput: React.FC<IFileAttachmentInputProps> = (
  props: IFileAttachmentInputProps,
) => {
  return (
    <FileAttachmentInputWrapper
      fileAttachment={props.fileAttachment}
      onFileAttachmentChanged={props.onFileAttachmentChanged}
    >
      {({
        fileAttachment,
        isFileUploading,
        onSelectAndUploadFile,
        onDownloadFile,
        onRemoveFile,
      }) => (
        <div className="row col-12 pl-0 flex-nowrap align-items-center">
          <FormLabelColumn width={'auto'} className="d-inline-block">
            File:
          </FormLabelColumn>
          <Column className="d-inline-block text-truncate">
            <TruncatingTextWrapperWithPopover>
              {fileAttachment?.fileName}
            </TruncatingTextWrapperWithPopover>
          </Column>
          <Column
            width={'auto'}
            className="d-inline-block"
            style={{ width: '3.5rem', minWidth: '3.5rem', maxWidth: '3.5rem' }}
          >
            {!isFileUploading && (
              <>
                {!fileAttachment && !props.readOnly && (
                  <FontAwesomeIcon
                    icon={faUpload}
                    title="Upload File"
                    className="pointer ml-1"
                    onClick={onSelectAndUploadFile}
                  />
                )}
                {!!fileAttachment && (
                  <FontAwesomeIcon
                    icon={faDownload}
                    title="Download File"
                    className="pointer ml-1"
                    onClick={onDownloadFile}
                  />
                )}
                {!!fileAttachment && !props.readOnly && (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    className="pointer ml-1 text-danger"
                    title="Remove File"
                    onClick={onRemoveFile}
                  />
                )}
              </>
            )}
            {isFileUploading && (
              <Spinner as="span" animation="border" role="status" size="sm" />
            )}
          </Column>
        </div>
      )}
    </FileAttachmentInputWrapper>
  );
};
