import { formatTime } from 'ar-common/lib/utils/date-utils';
import { formatCurrency, formatDate } from 'ar-common';
import { BalanceAttachmentsAction } from 'components/Actions/BalanceAttachmentsAction';
import { BalanceCommentsAction } from 'components/Actions/BalanceCommentsAction';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { IBalanceFilterValues } from 'components/BalanceFilters/BalanceFilters';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import TruncatingTextWrapperWithPopover from 'components/TruncatingTextWrapperWithPopover';
import React, { useEffect, useRef, useState } from 'react';
import {
  BalanceSortBy,
  LookupModelFragment,
  SortDirection,
  ViewAccountsListDocument,
  ViewAccountsListItemFragment,
  ViewAccountsListQuery,
  ViewAccountsListQueryVariables,
  ViewAccountsRefreshListDocument,
  ViewAccountsRefreshListQuery,
  ViewAccountsRefreshListQueryVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { balanceFilterValuesToQueryVariables } from 'components/BalanceFilters/BalanceFilters.util';
import { ReconciliationCompleteCheckBlock } from 'views/ViewAccounts/components/ReconciliationCompleteCheckBlock';
import { ReviewCompleteCheckBlock } from 'views/ViewAccounts/components/ReviewCompleteCheckBlock';

export interface IBalanceStatusTableControl {
  export: (args: { filename: string }) => Promise<void>;
}

interface IBalanceStatusTableProps {
  filterValues: IBalanceFilterValues;
  assigneeLookups: LookupModelFragment[];
  controlRef?: React.MutableRefObject<IBalanceStatusTableControl | undefined>;
}

export const AccountStatusReportTable: React.FC<IBalanceStatusTableProps> = (
  props: IBalanceStatusTableProps,
) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();

  // const [selectedBalanceIds, setSelectedBalanceIds] = useState<number[]>([]);
  const [totalBalances, setTotalBalances] = useState<number>(0);
  const [balances, setBalances] = useState<ViewAccountsListItemFragment[]>([]);

  const [sortBy, setSortBy] = useState<BalanceSortBy>(
    BalanceSortBy.AccountNumber,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: BalanceSortBy;
    sortDirection: SortDirection;
  }) => {
    setIsFetching(true);

    return client
      .query<ViewAccountsListQuery, ViewAccountsListQueryVariables>({
        query: ViewAccountsListDocument,
        fetchPolicy: 'no-cache',
        variables: {
          filter: balanceFilterValuesToQueryVariables(props.filterValues),
          paginationOptions:
            options.take === AllRowsPerPage
              ? undefined
              : {
                  skip: options.skip,
                  take: options.take,
                },
          sortOptions: {
            sortBy: options.sortBy,
            sortDirection: options.sortDirection,
          },
        },
      })
      .finally(() => setIsFetching(false));
  };

  const fetchDataTablePage = (skip?: number, take?: number) => {
    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalBalances(value.data.balances.totalItems);
        setBalances(value.data.balances.items);
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  // const clearAllIdsForBulkDownload = () => {
  //   setSelectedBalanceIds([]);
  // };
  //
  // useEffect(clearAllIdsForBulkDownload, [props.filterValues]);

  // const selectAllIdsForBulkDownload = async () => {
  //   return client
  //     .query<
  //       ViewAccountsListAllIdsWithFileAttachmentsQuery,
  //       ViewAccountsListAllIdsWithFileAttachmentsQueryVariables
  //     >({
  //       query: ViewAccountsListAllIdsWithFileAttachmentsDocument,
  //       fetchPolicy: 'no-cache',
  //       variables: {
  //         filter: {
  //           ...balanceFilterValuesToQueryVariables(props.filterValues),
  //           mustHaveFileAttachments: true,
  //         },
  //       },
  //     })
  //     .then((value) => {
  //       setSelectedBalanceIds(
  //         value.data.balances.items.map((balance) => balance.id),
  //       );
  //     })
  //     .catch((reason: any) =>
  //       apolloError({
  //         error: reason,
  //       }),
  //     );
  // };

  // const handleToggleBalanceIsSelected = (balanceId: number) => () => {
  //   if (selectedBalanceIds.includes(balanceId)) {
  //     setSelectedBalanceIds(
  //       selectedBalanceIds.filter((id) => id !== balanceId),
  //     );
  //   } else {
  //     setSelectedBalanceIds(selectedBalanceIds.concat(balanceId));
  //   }
  // };

  const handleSort = (sortBy: BalanceSortBy, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  let exportFunction: DataTableExportFunction<ViewAccountsListItemFragment>;
  const handleExport = (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection })
      .then((value) => {
        return exportFunction({
          items: value.data.balances.items,
          filename: args.filename,
        });
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const handleBalancesUpdated = (updatedBalanceIds: number[]) => {
    return client
      .query<
        ViewAccountsRefreshListQuery,
        ViewAccountsRefreshListQueryVariables
      >({
        query: ViewAccountsRefreshListDocument,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            idIn: updatedBalanceIds,
          },
        },
      })
      .then((result) => {
        const updatedBalances = result.data.balances.items;

        setBalances(
          balances.map(
            (balance) =>
              updatedBalances.find(
                (updatedBalance: ViewAccountsListItemFragment) =>
                  updatedBalance.id === balance.id,
              ) ?? balance,
          ),
        );
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  useEffect(() => {
    if (!!paginationControl.current?.refresh) {
      paginationControl.current.refresh();
    }
  }, [props.filterValues, sortBy, sortDirection]);

  useEffect(() => {
    if (props.controlRef) {
      props.controlRef.current = {
        export: handleExport,
      };
    }
  });

  const columns: IDataTableColumnDefinition<
    ViewAccountsListItemFragment,
    BalanceSortBy
  >[] = [
    // {
    //   dataFieldName: 'isSelected',
    //   heading: '',
    //   width: '1em',
    //   render: (balance) => (
    //     <CheckBlock
    //       checked={selectedBalanceIds.includes(balance.id)}
    //       onCheckedChange={handleToggleBalanceIsSelected(balance.id)}
    //       disabled={!balance.hasFileAttachments}
    //     />
    //   ),
    //   excludeFromExport: true,
    // },
    {
      dataFieldName: 'periodId',
      heading: 'Period',
      width: '7rem',
      render: (balance) =>
        `${balance.period.month < 10 ? '0' : ''}${
          balance.period.month
        }-${balance.period.year.toString()}`,
      excelExport: (balance) =>
        `${balance.period.month < 10 ? '0' : ''}${
          balance.period.month
        }-${balance.period.year.toString()}`,
      sortBy: BalanceSortBy.PeriodId,
    },
    {
      dataFieldName: 'account',
      multiSortHeadings: [
        { heading: 'Account', sortBy: BalanceSortBy.AccountNumber },
        { heading: 'Sub.', sortBy: BalanceSortBy.SubsidiaryName },
        {
          heading: 'To/From Sub.',
          sortBy: BalanceSortBy.DueToFromSubsidiaryName,
        },
        {
          heading: 'Description',
          sortBy: BalanceSortBy.AccountNumberDescription,
        },
      ],
      render: (balance) => (
        <>
          {balance.accountNumber} ({balance.subsidiary.name}
          {balance.dueToFromSubsidiary
            ? `, to/from ${balance.dueToFromSubsidiary.name}`
            : ''}
          ) {balance.accountNumberDescription ?? ''}
        </>
      ),
    },
    {
      hideInUI: true,
      width: '8rem',
      dataFieldName: 'accountNumber',
      heading: 'Account',
      render: (balance) => balance.accountNumber.toString(),
      excelExport: (balance) => balance.accountNumber,
      sortBy: BalanceSortBy.AccountNumber,
    },
    {
      hideInUI: true,
      dataFieldName: 'subsidiary',
      heading: 'Subsidiary',
      width: '9rem',
      render: (balance) => balance.subsidiary.name,
      excelExport: (balance) => balance.subsidiary.name,
      sortBy: BalanceSortBy.SubsidiaryName,
    },
    {
      hideInUI: true,
      width: '15rem',
      dataFieldName: 'accountNumberDescription',
      heading: 'Description',
      render: (balance) => balance.accountNumberDescription ?? '',
      excelExport: (balance) => balance.accountNumberDescription ?? '',
      sortBy: BalanceSortBy.AccountNumberDescription,
    },
    {
      hideInUI: true,
      dataFieldName: 'dueToFromSubsidiary',
      heading: 'Due To/From',
      width: '8em',
      render: (balance) => balance.dueToFromSubsidiary?.name ?? '',
      excelExport: (balance) => balance.dueToFromSubsidiary?.name ?? '',
      sortBy: BalanceSortBy.DueToFromSubsidiaryName,
    },
    {
      dataFieldName: 'foreignBalance',
      heading: 'For. Period Balance',
      headClassName: 'text-right',
      width: '8.5rem',
      cellClassName: (balance) => (!balance ? '' : 'text-right'),
      render: (balance) =>
        balance.foreignBalance === undefined || balance.foreignBalance === null
          ? '--'
          : formatCurrency(balance.foreignBalance),
      excelExport: (balance) =>
        balance.foreignBalance === undefined
          ? '--'
          : formatCurrency(balance.foreignBalance),
      sortBy: BalanceSortBy.ForeignBalance,
    },
    {
      dataFieldName: 'usdBalance',
      heading: 'USD Balance',
      headClassName: 'text-right',
      width: '9.5rem',
      cellClassName: (balance) => (!balance ? '' : 'text-right'),
      render: (balance) =>
        balance.usdBalance === undefined || balance.usdBalance === null
          ? '--'
          : formatCurrency(balance.usdBalance),
      excelExport: (balance) =>
        balance.usdBalance === undefined
          ? '--'
          : formatCurrency(balance.usdBalance),
      sortBy: BalanceSortBy.UsdBalance,
    },
    {
      dataFieldName: 'reconciledBalance',
      heading: 'Rec. Balance',
      headClassName: 'text-right',
      width: '10rem',
      cellClassName: (balance) => (!balance ? '' : 'text-right'),
      render: (balance) => (
        <>
          {balance.reconciledBalance === undefined ||
          balance.reconciledBalance === null
            ? '--'
            : formatCurrency(balance.reconciledBalance)}
        </>
      ),
      excelExport: (balance) =>
        balance.reconciledBalance === undefined
          ? '--'
          : formatCurrency(balance.reconciledBalance),
      sortBy: BalanceSortBy.ReconciledBalance,
    },
    {
      dataFieldName: 'variance',
      heading: 'Variance',
      headClassName: 'text-right',
      width: '8rem',
      cellClassName: (balance) => (!balance ? '' : 'text-right'),
      render: (balance) =>
        balance.variance === undefined || balance.variance === null
          ? '--'
          : formatCurrency(balance.variance),
      excelExport: (balance) =>
        balance.variance === undefined
          ? '--'
          : formatCurrency(balance.variance),
      sortBy: BalanceSortBy.Variance,
    },

    {
      dataFieldName: 'responsible',
      heading: 'Responsible',
      width: '10em',
      cellClassName: (balance) =>
        !balance ? '' : !balance?.responsible?.id ? 'bg-primary' : '',
      render: (balance) => (
        <TruncatingTextWrapperWithPopover>
          {balance.responsible?.name ? (
            balance.responsible?.name
          ) : (
            <div className="bg-primary text-white">Unassigned</div>
          )}
        </TruncatingTextWrapperWithPopover>
      ),
      excelExport: (balance) => balance.responsible?.name || '',
      sortBy: BalanceSortBy.Responsible,
    },
    {
      dataFieldName: 'isReconciled',
      heading: '',
      width: '1em',
      render: (balance) => (
        <ReconciliationCompleteCheckBlock
          balance={balance}
          onBalancesUpdated={handleBalancesUpdated}
          readonly={true}
        />
      ),
      excludeFromExport: true,
    },
    {
      dataFieldName: 'reconciliationCompleted',
      multiSortHeadings: [
        {
          heading: 'Completed By',
          sortBy: BalanceSortBy.ReconciliationCompletedBy,
        },
        {
          heading: 'Date/Time',
          sortBy: BalanceSortBy.ReconciliationCompletedDate,
        },
      ],
      // width: '13rem',
      render: (balance) => (
        <>
          {balance.reconciliationCompletedBy?.name || ''}
          <br />
          {!balance.reconciliationCompletedBy ? (
            ''
          ) : (
            <div className="text-wrap">
              {formatDate(balance.reconciliationCompletedDate)}{' '}
              {formatTime(balance.reconciliationCompletedDate)}
            </div>
          )}
        </>
      ),
    },
    {
      dataFieldName: 'reconciliationCompletedBy',
      heading: 'Completed By',
      hideInUI: true,
      render: () => '',
      excelExport: (balance) => balance.reconciliationCompletedBy?.name || '',
    },
    {
      dataFieldName: 'reconcileCompletedDate',
      heading: 'Date/Time Completed',
      hideInUI: true,
      render: () => '',
      excelExport: (balance) =>
        balance.reconciliationCompletedDate
          ? new Date(balance.reconciliationCompletedDate)
          : '',
      sortBy: BalanceSortBy.ReconciliationCompletedDate,
    },

    {
      dataFieldName: 'reviewer',
      heading: 'Reviewer',
      width: '10em',
      cellClassName: (balance) =>
        !balance ? '' : !balance?.reviewer?.id ? 'bg-primary' : '',
      render: (balance) => (
        <TruncatingTextWrapperWithPopover>
          {balance.reviewer?.name ? (
            balance.reviewer?.name
          ) : (
            <div className="bg-primary text-white">Unassigned</div>
          )}
        </TruncatingTextWrapperWithPopover>
      ),
      excelExport: (balance) => balance.reviewer?.name || '',
      sortBy: BalanceSortBy.Reviewer,
    },
    {
      dataFieldName: 'isReviewed',
      heading: '',
      width: '1em',
      render: (balance) => (
        <ReviewCompleteCheckBlock
          balance={balance}
          onBalancesUpdated={handleBalancesUpdated}
          readonly={true}
        />
      ),
      excludeFromExport: true,
    },
    {
      dataFieldName: 'reviewCompleted',
      multiSortHeadings: [
        {
          heading: 'Completed By',
          sortBy: BalanceSortBy.ReviewCompletedBy,
        },
        { heading: 'Date/Time', sortBy: BalanceSortBy.ReviewCompletedDate },
      ],
      render: (balance) => (
        <>
          {balance.reviewCompletedBy?.name || ''}
          <br />
          {!balance.reviewCompletedBy ? (
            ''
          ) : (
            <div className="text-wrap">
              {formatDate(balance.reviewCompletedDate)}{' '}
              {formatTime(balance.reviewCompletedDate)}
            </div>
          )}
        </>
      ),
    },
    {
      dataFieldName: 'reviewCompletedBy',
      heading: 'Completed By',
      hideInUI: true,
      render: () => '',
      excelExport: (balance) => balance.reviewCompletedBy?.name || '',
    },
    {
      dataFieldName: 'reconcileCompletedDate',
      heading: 'Date/Time Completed',
      hideInUI: true,
      render: () => '',
      excelExport: (balance) =>
        balance.reviewCompletedDate
          ? new Date(balance.reviewCompletedDate)
          : '',
      sortBy: BalanceSortBy.ReviewCompletedDate,
    },

    {
      dataFieldName: 'attachments',
      heading: '',
      width: '2rem',
      render: (balance) => (
        <BalanceAttachmentsAction
          balance={balance}
          onBalancesUpdated={handleBalancesUpdated}
          readOnly={true}
        />
      ),
      excludeFromExport: true,
    },

    {
      dataFieldName: 'comments',
      heading: '',
      width: '2rem',
      render: (balance) => (
        <BalanceCommentsAction
          balance={balance}
          onBalancesUpdated={handleBalancesUpdated}
        />
      ),
      excludeFromExport: true,
    },
  ];

  return (
    <>
      {alertModal}
      {/*<div className="row mb-3 ml-1 ">*/}
      {/*  <Column width="auto">*/}
      {/*    <div className="row">*/}
      {/*      <BulkDownloadBalanceAttachmentsButton*/}
      {/*        balanceIds={selectedBalanceIds}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </Column>*/}
      {/*  <Column className="ml-4">*/}
      {/*    <div className="row">*/}
      {/*      <a className="pointer mr-3" onClick={selectAllIdsForBulkDownload}>*/}
      {/*        Select All*/}
      {/*      </a>*/}
      {/*      <a className="pointer" onClick={clearAllIdsForBulkDownload}>*/}
      {/*        Clear All*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </Column>*/}
      {/*</div>*/}

      <Pagination
        totalNumberOfItems={totalBalances}
        fetchDataTablePage={fetchDataTablePage}
        controlRef={paginationControl}
      >
        <DataTable<ViewAccountsListItemFragment, BalanceSortBy>
          columns={columns}
          data={balances}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          exportRef={(fn) => {
            exportFunction = fn;
          }}
          isFetching={isFetching}
          stickyHeaders={true}
          rowClassName={(item: ViewAccountsListItemFragment) =>
            !item.usdBalance &&
            item.usdBalance !== 0 &&
            !item.foreignBalance &&
            item.foreignBalance !== 0
              ? 'bg-no-netsuite-data'
              : item.isUnexpected && !item.isAccountActive
              ? 'bg-unexpected-inactive'
              : item.isUnexpected && item.isAccountActive
              ? 'bg-warning'
              : undefined
          }
        />
      </Pagination>
    </>
  );
};

export default AccountStatusReportTable;
