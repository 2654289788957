import useModalAlerts from 'components/Alerts/useModalAlerts';
import useSpinnerModal from 'components/SpinnerModal/useSpinnerModal';
import React, { useRef, useState } from 'react';
import {
  DownloadFileAttachmentDocument,
  DownloadFileAttachmentQuery,
  DownloadFileAttachmentQueryVariables,
  UploadFileDocument,
  UploadFileMutation,
  UploadFileMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { IFileAttachment } from './IFileAttachment';

export interface IFileAttachmentInputWrapperProps {
  fileAttachment: IFileAttachment | null;
  onFileAttachmentChanged: (fileAttachment: IFileAttachment | null) => void;
  children: (params: {
    fileAttachment: IFileAttachment | null;
    isFileUploading: boolean;
    onSelectAndUploadFile: () => void;
    onDownloadFile: () => Promise<void>;
    onRemoveFile: () => void;
  }) => JSX.Element;
}

export const FileAttachmentInputWrapper: React.FC<
  IFileAttachmentInputWrapperProps
> = (props: IFileAttachmentInputWrapperProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { client } = useApolloClient();

  const [modalAlert, setModalAlert] = useState<React.ReactNode>();
  const { apolloError } = useModalAlerts(setModalAlert);

  const [isFileUploading, setIsFileUploading] = useState(false);

  const { openSpinnerModal, closeSpinnerModal } = useSpinnerModal({
    message: 'Downloading...',
  });

  const handleSelectAndUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUploaded = async (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    if (!event.currentTarget.files || event.currentTarget.files.length === 0) {
      return;
    }

    setIsFileUploading(true);

    const file: File | null = event.currentTarget.files.item(0);

    if (file) {
      try {
        const response = await client.mutate<
          UploadFileMutation,
          UploadFileMutationVariables
        >({
          mutation: UploadFileDocument,
          variables: { file: file },
          fetchPolicy: 'no-cache',
        });

        if (response.data?.uploadFile) {
          const uploadedFileInfo: IFileAttachment = {
            fileAttachmentId: response.data.uploadFile,
            fileName: file.name,
            mimeType: file.type,
            fileSizeBytes: file.size,
          };

          props.onFileAttachmentChanged(uploadedFileInfo);
        } else {
          console.error('no uploadFile found in response');
        }
      } catch (error) {
        apolloError({
          error,
        });
      } finally {
        setIsFileUploading(false);
      }
    }
  };

  const fetchBase64Content = async (): Promise<string | undefined> => {
    if (!props.fileAttachment) {
      return;
    }

    return client
      .query<DownloadFileAttachmentQuery, DownloadFileAttachmentQueryVariables>(
        {
          query: DownloadFileAttachmentDocument,
          variables: { id: props.fileAttachment.fileAttachmentId },
          fetchPolicy: 'no-cache',
        },
      )
      .then((result) => {
        return result.data.downloadFileAttachment?.base64Content;
      })
      .catch((error) => {
        apolloError({
          error,
        });
        return undefined;
      });
  };

  const handleDownloadFile = async () => {
    if (!props.fileAttachment) {
      return;
    }

    openSpinnerModal();
    try {
      const base64Content: string | undefined = await fetchBase64Content();

      if (!base64Content) {
        return;
      }

      const navigator: any = window.navigator;
      // need to download this way for pre-html5
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(
          new Blob([base64Content], {
            type: props.fileAttachment.mimeType,
          }),
          props.fileAttachment.fileName,
        );
        return;
      }

      const a = document.createElement('a');
      a.href = `data:${props.fileAttachment.mimeType};base64,${base64Content}`;
      a.download = props.fileAttachment.fileName;
      a.click();
      a.remove();
    } finally {
      closeSpinnerModal();
    }
  };

  const handleRemoveFile = () => {
    props.onFileAttachmentChanged(null);
  };

  return (
    <>
      {modalAlert}
      <input
        type="file"
        ref={fileInputRef}
        value="" //important to set this to empty string, else deleting a file and trying to upload the same file again will not cause onChange to fire
        onChange={handleFileUploaded}
        style={{ display: 'none' }}
      />

      {props.children({
        fileAttachment: props.fileAttachment,
        isFileUploading: isFileUploading,
        onSelectAndUploadFile: handleSelectAndUploadFile,
        onDownloadFile: handleDownloadFile,
        onRemoveFile: handleRemoveFile,
      })}
    </>
  );
};
