import { formatCurrency } from 'ar-common';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import React, { useEffect, useRef, useState } from 'react';
import {
  BalanceImportSummariesForBatchDocument,
  BalanceImportSummariesForBatchQuery,
  BalanceImportSummariesForBatchQueryVariables,
  BalanceImportSummaryListItemFragment,
  BalanceImportSummarySortBy,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';

export interface IBalanceImportSummaryTableControl {
  export: (args: { filename: string }) => Promise<void>;
}

interface IBalanceImportSummaryTableProps {
  balanceImportBatchId: number | null;
  controlRef?: React.MutableRefObject<
    IBalanceImportSummaryTableControl | undefined
  >;
  onSubsidiarySelected: (
    periodId: number,
    periodName: string,
    subsidiaryId: number,
    subsidiaryName: string,
  ) => void;
}

export const BalanceImportSummaryTable: React.FC<
  IBalanceImportSummaryTableProps
> = (props: IBalanceImportSummaryTableProps) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();

  const [totalBalanceImportSummaries, setTotalBalanceImportSummaries] =
    useState<number>(0);
  const [balanceImportSummaries, setBalanceImportSummaries] = useState<
    BalanceImportSummaryListItemFragment[]
  >([]);

  const [sortBy, setSortBy] = useState<BalanceImportSummarySortBy>(
    BalanceImportSummarySortBy.SubsidiaryName,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: BalanceImportSummarySortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<
      BalanceImportSummariesForBatchQuery,
      BalanceImportSummariesForBatchQueryVariables
    >({
      query: BalanceImportSummariesForBatchDocument,
      fetchPolicy: 'no-cache',
      variables: {
        id: props.balanceImportBatchId ?? 0,
        paginationOptions:
          options.take === AllRowsPerPage
            ? undefined
            : {
                skip: options.skip,
                take: options.take,
              },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
    });
  };

  const fetchDataTablePage = async (
    skip?: number,
    take?: number,
  ): Promise<void> => {
    await fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalBalanceImportSummaries(
          value.data.balanceImportSummariesForBatch.totalItems,
        );
        setBalanceImportSummaries(
          value.data.balanceImportSummariesForBatch.items,
        );
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const handleSort = (
    sortBy: BalanceImportSummarySortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  let exportFunction: DataTableExportFunction<BalanceImportSummaryListItemFragment>;
  const handleExport = (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection }).then((value) => {
      return exportFunction({
        items: value.data.balanceImportSummariesForBatch.items,
        filename: args.filename,
      });
    });
  };

  const handleSubsidiarySelected =
    (
      periodId: number,
      periodName: string,
      subsidiaryId: number,
      subsidiaryName: string,
    ) =>
    (): void => {
      props.onSubsidiarySelected(
        periodId,
        periodName,
        subsidiaryId,
        subsidiaryName,
      );
    };

  useEffect(() => {
    if (!!paginationControl.current?.refresh) {
      paginationControl.current?.refresh();
    }
  }, [props.balanceImportBatchId, sortBy, sortDirection]);

  useEffect(() => {
    if (props.controlRef) {
      props.controlRef.current = {
        export: handleExport,
      };
    }
  });

  const getPeriodName = (
    balanceImportSummary: BalanceImportSummaryListItemFragment,
  ): string => {
    return `${balanceImportSummary.period.month < 10 ? '0' : ''}${
      balanceImportSummary.period.month
    }-${balanceImportSummary.period.year.toString()}`;
  };

  const columns: IDataTableColumnDefinition<
    BalanceImportSummaryListItemFragment,
    BalanceImportSummarySortBy
  >[] = [
    {
      dataFieldName: 'periodId',
      heading: 'Period',
      width: '7rem',
      render: (balanceImportSummary) => getPeriodName(balanceImportSummary),
      excelExport: (balanceImportSummary) =>
        getPeriodName(balanceImportSummary),
      sortBy: BalanceImportSummarySortBy.Period,
    },
    {
      dataFieldName: 'subsidiary',
      heading: 'Subsidiary',
      render: (balanceImportSummary) => balanceImportSummary.subsidiary.name,
      excelExport: (balanceImportSummary) =>
        balanceImportSummary.subsidiary.name,
      sortBy: BalanceImportSummarySortBy.SubsidiaryName,
    },
    {
      dataFieldName: 'numberOfBalances',
      heading: 'Accounts',
      headClassName: 'text-right',
      width: '8rem',
      cellClassName: (balanceImport) => (!balanceImport ? '' : 'text-right'),
      render: (balanceImportSummary) => (
        <a
          onClick={handleSubsidiarySelected(
            balanceImportSummary.period.id,
            getPeriodName(balanceImportSummary),
            balanceImportSummary.subsidiary.id,
            balanceImportSummary.subsidiary.name,
          )}
          className="primary-link pointer"
        >
          {balanceImportSummary.numberOfBalances}
        </a>
      ),
      excelExport: (balanceImportSummary) =>
        balanceImportSummary.numberOfBalances,
      sortBy: BalanceImportSummarySortBy.NumberOfBalances,
    },
    {
      dataFieldName: 'totalUsdBalance',
      heading: 'USD Balance Total',
      headClassName: 'text-right',
      width: '20rem',
      cellClassName: (balanceImportSummary) =>
        !balanceImportSummary ? '' : 'text-right',
      render: (balanceImportSummary) =>
        formatCurrency(balanceImportSummary.totalUsdBalance),
      excelExport: (balanceImportSummary) =>
        formatCurrency(balanceImportSummary.totalUsdBalance),
      sortBy: BalanceImportSummarySortBy.TotalUsdBalance,
    },
    {
      dataFieldName: 'totalForeignBalance',
      heading: 'For. Period Balance Total',
      headClassName: 'text-right',
      width: '20rem',
      cellClassName: (balanceImportSummary) =>
        !balanceImportSummary ? '' : 'text-right',
      render: (balanceImportSummary) =>
        formatCurrency(balanceImportSummary.totalForeignBalance),
      excelExport: (balanceImportSummary) =>
        formatCurrency(balanceImportSummary.totalForeignBalance),
      sortBy: BalanceImportSummarySortBy.TotalForeignBalance,
    },
  ];

  return (
    <>
      {alertModal}
      <Pagination
        totalNumberOfItems={totalBalanceImportSummaries}
        fetchDataTablePage={fetchDataTablePage}
        controlRef={paginationControl}
      >
        <DataTable<
          BalanceImportSummaryListItemFragment,
          BalanceImportSummarySortBy
        >
          columns={columns}
          data={balanceImportSummaries}
          getDataItemId={(item) => item.subsidiary.id * 1000 + item.period.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          exportRef={(fn) => {
            exportFunction = fn;
          }}
        />
      </Pagination>
    </>
  );
};

export default BalanceImportSummaryTable;
