import Column, { IColumnProps } from 'layouts/components/Grid/Column';
import { CheckBlock } from './CheckBlock';
import React from 'react';

export interface IBulkUpdateCheckBoxColumnProps extends IColumnProps {
  isVisible?: boolean;
  placeholderOnly?: boolean;
  isChecked?: boolean;
  onCheckedChange?: (value: boolean) => void;
  disabled?: boolean;
}

export const BulkUpdateCheckboxColumn: React.FC<
  IBulkUpdateCheckBoxColumnProps
> = ({
  isVisible,
  placeholderOnly,
  isChecked,
  onCheckedChange,
  disabled,
  ...columnProps
}: IBulkUpdateCheckBoxColumnProps) => {
  return !isVisible ? (
    <></>
  ) : (
    <Column width="auto" {...columnProps}>
      <CheckBlock
        checked={isChecked}
        onCheckedChange={
          !!onCheckedChange
            ? onCheckedChange
            : () => {
                return;
              }
        }
        disabled={disabled}
        style={placeholderOnly ? { visibility: 'hidden' } : undefined}
      />
    </Column>
  );
};
