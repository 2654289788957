import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type Account = {
  accountNumber: AccountNumberLookupModel;
  dueToFromSubsidiary?: Maybe<SubsidiaryLookupModel>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isAttachmentMandatory: Scalars['Boolean'];
  isIncludedInReminderEmails: Scalars['Boolean'];
  isReconciled: Scalars['Boolean'];
  mostRecentOpenPeriodBalance?: Maybe<Balance>;
  responsible?: Maybe<LookupModel>;
  reviewer?: Maybe<LookupModel>;
  subsidiary: SubsidiaryLookupModel;
};

export type AccountAudit = {
  accountAuditEventType?: Maybe<LookupModel>;
  accountId: Scalars['Int'];
  accountNumber: Scalars['String'];
  accountNumberDescription?: Maybe<Scalars['String']>;
  balance?: Maybe<Balance>;
  dueToFromSubsidiary?: Maybe<SubsidiaryLookupModel>;
  event: Scalars['String'];
  eventDate: Scalars['DateTime'];
  eventUser?: Maybe<LookupModel>;
  id: Scalars['Int'];
  subsidiary: SubsidiaryLookupModel;
};

export type AccountAuditFilter = {
  accountAuditEventTypeIdIn?: Maybe<Array<Scalars['Int']>>;
  accountNumberIdIn?: Maybe<Array<Scalars['Int']>>;
  dueToFromSubsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
  endDate?: Maybe<Scalars['DateTime']>;
  endPeriodId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startPeriodId?: Maybe<Scalars['Int']>;
  subsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
};

export enum AccountAuditSortBy {
  AccountNumber = 'AccountNumber',
  AccountNumberDescription = 'AccountNumberDescription',
  DueToFromSubsidiary = 'DueToFromSubsidiary',
  Event = 'Event',
  EventDate = 'EventDate',
  EventUser = 'EventUser',
  ForeignBalance = 'ForeignBalance',
  PeriodId = 'PeriodId',
  Subsidiary = 'Subsidiary',
  UsdBalance = 'UsdBalance',
}

export type AccountAuditSortOptions = {
  sortBy?: Maybe<AccountAuditSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type AccountAudits = {
  items: Array<AccountAudit>;
  totalItems: Scalars['Int'];
};

export type AccountFilter = {
  accountIdIn?: Maybe<Array<Scalars['Int']>>;
  accountNumberIdIn?: Maybe<Array<Scalars['Int']>>;
  dueToFromSubsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAttachmentMandatory?: Maybe<Scalars['Boolean']>;
  isIncludedInReminderEmails?: Maybe<Scalars['Boolean']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  isUnassigned?: Maybe<Scalars['Boolean']>;
  responsibleIdIn?: Maybe<Array<Scalars['Int']>>;
  reviewerIdIn?: Maybe<Array<Scalars['Int']>>;
  subsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
};

export type AccountLookupFilter = {
  includeIds?: Maybe<Array<Scalars['Int']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  subsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
  take?: Maybe<Scalars['Int']>;
};

export type AccountLookupModel = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type AccountNumber = {
  accountNumber: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type AccountNumberFilter = {
  accountNumberIdIn?: Maybe<Array<Scalars['Int']>>;
};

export type AccountNumberInput = {
  accountNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AccountNumberLookupFilter = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
  name?: Maybe<Scalars['String']>;
};

export type AccountNumberLookupModel = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export enum AccountNumberSortBy {
  AccountDescription = 'AccountDescription',
  AccountNumber = 'AccountNumber',
}

export type AccountNumberSortOptions = {
  sortBy?: Maybe<AccountNumberSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type AccountNumbers = {
  items: Array<AccountNumber>;
  totalItems: Scalars['Int'];
};

export enum AccountSortBy {
  AccountNumber = 'AccountNumber',
  AccountNumberDescription = 'AccountNumberDescription',
  DueToFromSubsidiaryName = 'DueToFromSubsidiaryName',
  IsActive = 'IsActive',
  IsReconciled = 'IsReconciled',
  Responsible = 'Responsible',
  Reviewer = 'Reviewer',
  SubsidiaryName = 'SubsidiaryName',
  IsAttachmentMandatory = 'isAttachmentMandatory',
  IsIncludedInReminderEmails = 'isIncludedInReminderEmails',
}

export type AccountSortOptions = {
  sortBy?: Maybe<AccountSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type Accounts = {
  items: Array<Account>;
  totalItems: Scalars['Int'];
};

export type Balance = {
  accountNumber: Scalars['String'];
  accountNumberDescription?: Maybe<Scalars['String']>;
  accountNumberId: Scalars['Int'];
  balanceFileAttachments: Array<BalanceFileAttachment>;
  balanceStatus: LookupModel;
  dueToFromSubsidiary?: Maybe<SubsidiaryLookupModel>;
  foreignBalance?: Maybe<Scalars['Float']>;
  hasComments?: Maybe<Scalars['Boolean']>;
  hasFileAttachments?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isAccountActive: Scalars['Boolean'];
  isUnexpected: Scalars['Boolean'];
  period: Period;
  reconciledBalance?: Maybe<Scalars['Float']>;
  reconciliationCompletedBy?: Maybe<LookupModel>;
  reconciliationCompletedDate?: Maybe<Scalars['DateTime']>;
  responsible?: Maybe<LookupModel>;
  reviewCompletedBy?: Maybe<LookupModel>;
  reviewCompletedDate?: Maybe<Scalars['DateTime']>;
  reviewer?: Maybe<LookupModel>;
  subsidiary: SubsidiaryLookupModel;
  usdBalance?: Maybe<Scalars['Float']>;
  variance?: Maybe<Scalars['Float']>;
};

export type BalanceFileAttachment = {
  balance: Balance;
  fileAttachment: FileAttachment;
  id: Scalars['Int'];
};

export type BalanceFileAttachmentInput = {
  balanceIds?: Maybe<Array<Scalars['Int']>>;
  fileAttachmentId?: Maybe<Scalars['Int']>;
};

export type BalanceFilter = {
  accountIdIn?: Maybe<Array<Scalars['Int']>>;
  accountIsActive?: Maybe<Scalars['Boolean']>;
  accountNumberIdIn?: Maybe<Array<Scalars['Int']>>;
  balanceStatusIdIn?: Maybe<Array<Scalars['Int']>>;
  balanceSummary?: Maybe<BalanceSummaryFilter>;
  dueToFromSubsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
  endPeriodId?: Maybe<Scalars['Int']>;
  excludeIds?: Maybe<Array<Scalars['Int']>>;
  idIn?: Maybe<Array<Scalars['Int']>>;
  includeInactiveAccountsWithNullOrZeroBalance?: Maybe<Scalars['Boolean']>;
  mustHaveFileAttachments?: Maybe<Scalars['Boolean']>;
  periodId?: Maybe<Scalars['Int']>;
  responsibleIdIn?: Maybe<Array<Scalars['Int']>>;
  reviewerIdIn?: Maybe<Array<Scalars['Int']>>;
  startPeriodId?: Maybe<Scalars['Int']>;
  subsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
};

export type BalanceImport = {
  accountNumber: Scalars['String'];
  accountNumberDescription?: Maybe<Scalars['String']>;
  dueToFromSubsidiary?: Maybe<SubsidiaryLookupModel>;
  foreignBalance?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  period?: Maybe<Period>;
  subsidiary?: Maybe<SubsidiaryLookupModel>;
  usdBalance?: Maybe<Scalars['Float']>;
};

export type BalanceImportBatch = {
  errorSummary?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  importDate: Scalars['DateTime'];
  importStatus?: Maybe<LookupModel>;
  importedByUser?: Maybe<LookupModel>;
};

export type BalanceImportFilter = {
  balanceImportBatchId?: Maybe<Scalars['Int']>;
  periodId?: Maybe<Scalars['Int']>;
  subsidiaryId?: Maybe<Scalars['Int']>;
};

export enum BalanceImportSortBy {
  AccountNumber = 'AccountNumber',
  AccountNumberDescription = 'AccountNumberDescription',
  DueToFromSubsidiaryName = 'DueToFromSubsidiaryName',
  ForeignBalance = 'ForeignBalance',
  ForeignCurrency = 'ForeignCurrency',
  Period = 'Period',
  SubsidiaryName = 'SubsidiaryName',
  UsdBalance = 'UsdBalance',
}

export type BalanceImportSortOptions = {
  sortBy?: Maybe<BalanceImportSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type BalanceImportSummaries = {
  items: Array<BalanceImportSummary>;
  totalItems: Scalars['Int'];
};

export type BalanceImportSummary = {
  foreignCurrency?: Maybe<LookupModel>;
  numberOfBalances: Scalars['Int'];
  period: Period;
  subsidiary: SubsidiaryLookupModel;
  totalForeignBalance?: Maybe<Scalars['Float']>;
  totalUsdBalance?: Maybe<Scalars['Float']>;
};

export enum BalanceImportSummarySortBy {
  ForeignCurrency = 'ForeignCurrency',
  NumberOfBalances = 'NumberOfBalances',
  Period = 'Period',
  SubsidiaryName = 'SubsidiaryName',
  TotalForeignBalance = 'TotalForeignBalance',
  TotalUsdBalance = 'TotalUsdBalance',
}

export type BalanceImportSummarySortOptions = {
  sortBy?: Maybe<BalanceImportSummarySortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type BalanceImportUnrecognizedAccount = {
  foreignBalance?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  netsuiteAccountNumber: Scalars['String'];
  netsuiteDueToFromSubsidiary?: Maybe<Scalars['String']>;
  netsuiteSubsidiary: Scalars['String'];
  period?: Maybe<Period>;
  usdBalance?: Maybe<Scalars['Float']>;
};

export type BalanceImportUnrecognizedAccountFilter = {
  balanceImportBatchId?: Maybe<Scalars['Int']>;
};

export enum BalanceImportUnrecognizedAccountSortBy {
  AccountNumber = 'AccountNumber',
  DueToFromSubsidiaryName = 'DueToFromSubsidiaryName',
  ForeignBalance = 'ForeignBalance',
  Period = 'Period',
  SubsidiaryName = 'SubsidiaryName',
  UsdBalance = 'UsdBalance',
}

export type BalanceImportUnrecognizedAccountSortOptions = {
  sortBy?: Maybe<BalanceImportUnrecognizedAccountSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type BalanceImportUnrecognizedAccounts = {
  items: Array<BalanceImportUnrecognizedAccount>;
  totalItems: Scalars['Int'];
};

export type BalanceImports = {
  items: Array<BalanceImport>;
  totalItems: Scalars['Int'];
};

export enum BalanceSortBy {
  AccountNumber = 'AccountNumber',
  AccountNumberDescription = 'AccountNumberDescription',
  BalanceStatusId = 'BalanceStatusId',
  DueToFromSubsidiaryName = 'DueToFromSubsidiaryName',
  ForeignBalance = 'ForeignBalance',
  ForeignCurrency = 'ForeignCurrency',
  PeriodId = 'PeriodId',
  ReconciledBalance = 'ReconciledBalance',
  ReconciliationCompletedBy = 'ReconciliationCompletedBy',
  ReconciliationCompletedDate = 'ReconciliationCompletedDate',
  Responsible = 'Responsible',
  ReviewCompletedBy = 'ReviewCompletedBy',
  ReviewCompletedDate = 'ReviewCompletedDate',
  Reviewer = 'Reviewer',
  SubsidiaryName = 'SubsidiaryName',
  UsdBalance = 'UsdBalance',
  Variance = 'Variance',
}

export type BalanceSortOptions = {
  sortBy?: Maybe<BalanceSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export enum BalanceSummaryFilter {
  All = 'All',
  NoActivity = 'NoActivity',
  ReconciledAndReviewed = 'ReconciledAndReviewed',
  ReconciledOnly = 'ReconciledOnly',
}

export type Balances = {
  items: Array<Balance>;
  totalItems: Scalars['Int'];
};

export type BalancesSummary = {
  totalBalances: Scalars['Int'];
  totalReconciledAndReviewed: Scalars['Int'];
  totalReconciledOnly: Scalars['Int'];
  totalWithNoActivity: Scalars['Int'];
};

export type BatchImportStatus = {
  status: Scalars['Int'];
  totalImported: Scalars['Int'];
  totalProcessed: Scalars['Int'];
  totalToProcess: Scalars['Int'];
};

export type BulkUpdateAccountsInput = {
  accountIds?: Maybe<Array<Scalars['Int']>>;
  accountNumberDescription?: Maybe<Scalars['String']>;
  applyToOpenPeriods?: Maybe<Scalars['Boolean']>;
  dueToFromSubsidiaryId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAttachmentMandatory?: Maybe<Scalars['Boolean']>;
  isIncludedInReminderEmails?: Maybe<Scalars['Boolean']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  nullNumberValue?: Maybe<Scalars['Int']>;
  responsibleUserId?: Maybe<Scalars['Int']>;
  reviewerUserId?: Maybe<Scalars['Int']>;
  subsidiaryId?: Maybe<Scalars['Int']>;
};

export type BulkUpdateCommonAccountValues = {
  accountNumber?: Maybe<AccountNumberLookupModel>;
  dueToFromSubsidiary?: Maybe<SubsidiaryLookupModel>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAttachmentMandatory?: Maybe<Scalars['Boolean']>;
  isIncludedInReminderEmails?: Maybe<Scalars['Boolean']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  responsible?: Maybe<LookupModel>;
  reviewer?: Maybe<LookupModel>;
  subsidiary?: Maybe<SubsidiaryLookupModel>;
};

export type Comment = {
  body: Scalars['String'];
  date: Scalars['DateTime'];
  editedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  parentCommentId?: Maybe<Scalars['Int']>;
  period?: Maybe<Period>;
  user: User;
};

export type CommentInput = {
  body?: Maybe<Scalars['String']>;
  parentCommentId?: Maybe<Scalars['Int']>;
};

export type Comments = {
  comments: Array<Comment>;
  totalComments: Scalars['Int'];
};

export type CreateAccountInput = {
  accountNumber: AccountNumberInput;
  applyToOpenPeriods?: Maybe<Scalars['Boolean']>;
  dueToFromSubsidiaryId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAttachmentMandatory?: Maybe<Scalars['Boolean']>;
  isIncludedInReminderEmails?: Maybe<Scalars['Boolean']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  responsibleUserId?: Maybe<Scalars['Int']>;
  reviewerUserId?: Maybe<Scalars['Int']>;
  subsidiaryId?: Maybe<Scalars['Int']>;
};

export type DownloadFile = {
  base64Content: Scalars['String'];
  fileName: Scalars['String'];
  fileSizeBytes: Scalars['Int'];
  mimeType: Scalars['String'];
};

export type FileAttachment = {
  balanceFileAttachments: Array<BalanceFileAttachment>;
  fileName: Scalars['String'];
  fileSizeBytes: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  mimeType: Scalars['String'];
  uploadedDate: Scalars['DateTime'];
};

export type InitiateBalanceImportResult = {
  batchId?: Maybe<Scalars['Int']>;
  isError: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type LookupFilter = {
  includeIds?: Maybe<Array<Scalars['Int']>>;
  name?: Maybe<Scalars['String']>;
};

export type LookupModel = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type Mutation = {
  addBalanceComment: Comment;
  addBalanceFileAttachments: Array<Scalars['Int']>;
  bulkUpdateAccounts: Scalars['Boolean'];
  closePeriod: Period;
  createAccount: Account;
  createAccountNumber: AccountNumber;
  createPeriods: Array<Period>;
  deleteBalanceComment: Comment;
  initiateBalancesImport: InitiateBalanceImportResult;
  initiatePeriod: Period;
  reopenPeriod: Period;
  sendRemindersForBalancesAwaitingReconciliation: Scalars['Boolean'];
  sendRemindersForBalancesAwaitingReview: Scalars['Boolean'];
  toggleIsBalanceReconciliationComplete: Balance;
  toggleIsBalanceReviewComplete: Balance;
  updateAccount: Account;
  updateAccountNumber: AccountNumber;
  updateBalanceComment: Comment;
  updateBalanceFileAttachments: Array<Scalars['Int']>;
  updatePeriodReportIds: Period;
  updateReconciledBalance: Balance;
  uploadFile: Scalars['Int'];
};

export type MutationAddBalanceCommentArgs = {
  id: Scalars['Int'];
  input: CommentInput;
};

export type MutationAddBalanceFileAttachmentsArgs = {
  balanceFileAttachments: Array<BalanceFileAttachmentInput>;
  periodId: Scalars['Int'];
};

export type MutationBulkUpdateAccountsArgs = {
  input: BulkUpdateAccountsInput;
};

export type MutationClosePeriodArgs = {
  id: Scalars['Int'];
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationCreateAccountNumberArgs = {
  input: AccountNumberInput;
};

export type MutationCreatePeriodsArgs = {
  totalPeriods: Scalars['Int'];
};

export type MutationDeleteBalanceCommentArgs = {
  id: Scalars['Int'];
};

export type MutationInitiatePeriodArgs = {
  id: Scalars['Int'];
};

export type MutationReopenPeriodArgs = {
  id: Scalars['Int'];
};

export type MutationSendRemindersForBalancesAwaitingReconciliationArgs = {
  filter?: Maybe<BalanceFilter>;
};

export type MutationSendRemindersForBalancesAwaitingReviewArgs = {
  filter?: Maybe<BalanceFilter>;
};

export type MutationToggleIsBalanceReconciliationCompleteArgs = {
  id: Scalars['Int'];
};

export type MutationToggleIsBalanceReviewCompleteArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateAccountNumberArgs = {
  id: Scalars['Int'];
  input: AccountNumberInput;
};

export type MutationUpdateBalanceCommentArgs = {
  body: Scalars['String'];
  id: Scalars['Int'];
};

export type MutationUpdateBalanceFileAttachmentsArgs = {
  anchorBalanceId: Scalars['Int'];
  balanceFileAttachments: Array<BalanceFileAttachmentInput>;
  periodId: Scalars['Int'];
};

export type MutationUpdatePeriodReportIdsArgs = {
  input: UpdatePeriodReportIdsInput;
};

export type MutationUpdateReconciledBalanceArgs = {
  id: Scalars['Int'];
  reconciledBalance?: Maybe<Scalars['Float']>;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type PaginationOptions = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Period = {
  closedByUser?: Maybe<User>;
  closedDate?: Maybe<Scalars['DateTime']>;
  foreignCurrencyIcReportId?: Maybe<Scalars['Int']>;
  foreignCurrencyReportId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  initiateDate?: Maybe<Scalars['DateTime']>;
  initiatedByUser?: Maybe<User>;
  month: Scalars['Int'];
  name: Scalars['String'];
  periodStatus: LookupModel;
  transitionLog: Array<PeriodTransitionLog>;
  usdEquityReportId?: Maybe<Scalars['Int']>;
  usdIcReportId?: Maybe<Scalars['Int']>;
  usdReportId?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type PeriodFilter = {
  endPeriod?: Maybe<Scalars['String']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
  name?: Maybe<Scalars['String']>;
  periodStatusIds?: Maybe<Array<Scalars['Int']>>;
  startPeriod?: Maybe<Scalars['String']>;
};

export type PeriodLookupModel = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  month: Scalars['Int'];
  name: Scalars['String'];
  periodStatus: LookupModel;
  sortableName: Scalars['String'];
  year: Scalars['Int'];
};

export enum PeriodSortBy {
  ClosedDate = 'ClosedDate',
  InitiateDate = 'InitiateDate',
  PeriodYearMonth = 'PeriodYearMonth',
  Status = 'Status',
}

export type PeriodSortOptions = {
  sortBy?: Maybe<PeriodSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type PeriodTransitionLog = {
  id: Scalars['Int'];
  periodId: Scalars['Int'];
  periodTransitionId: Scalars['Int'];
  transitionDate: Scalars['DateTime'];
  transitionedByUser?: Maybe<User>;
};

export type PeriodTransitionLogFilter = {
  periodId?: Maybe<Scalars['Int']>;
};

export type Periods = {
  items: Array<Period>;
  totalItems: Scalars['Int'];
};

export type Query = {
  BalanceImportBatchStatusLookups: Array<LookupModel>;
  account?: Maybe<Account>;
  accountAuditEventTypeLookups: Array<LookupModel>;
  accountAudits: AccountAudits;
  accountLookups: Array<AccountLookupModel>;
  accountNumber?: Maybe<AccountNumber>;
  accountNumberLookups: Array<AccountNumberLookupModel>;
  accountNumbers: AccountNumbers;
  accounts: Accounts;
  balance?: Maybe<Balance>;
  balanceCommentsForAccountPeriod: Comments;
  balanceFileAttachments: Array<BalanceFileAttachment>;
  balanceImport?: Maybe<BalanceImport>;
  balanceImportSummariesForBatch: BalanceImportSummaries;
  balanceImportUnrecognizedAccounts: BalanceImportUnrecognizedAccounts;
  balanceImports: BalanceImports;
  balanceStatusLookups: Array<LookupModel>;
  balances: Balances;
  balancesSummary: BalancesSummary;
  batchImportStatus: BatchImportStatus;
  bulkUpdateCommonAccountValues?: Maybe<BulkUpdateCommonAccountValues>;
  downloadFileAttachment?: Maybe<DownloadFile>;
  downloadFileAttachments: Array<DownloadFile>;
  foreignCurrencyLookups: Array<LookupModel>;
  latestBalanceImportBatch?: Maybe<BalanceImportBatch>;
  lookupPeriod?: Maybe<PeriodLookupModel>;
  numberBalancesWithMissingMandatoryAttachmentsForPeriod: Scalars['Int'];
  numberUnreviewedBalancesForPeriod: Scalars['Int'];
  periodLookups: Array<PeriodLookupModel>;
  periodStatusLookups: Array<LookupModel>;
  periodTransitionLogs: Array<PeriodTransitionLog>;
  periods: Periods;
  periodsForBalances: Array<Period>;
  subsidiaryLookups: Array<SubsidiaryLookupModel>;
  user?: Maybe<User>;
  userLookups: Array<LookupModel>;
  users: Users;
};

export type QueryBalanceImportBatchStatusLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryAccountArgs = {
  id: Scalars['Int'];
};

export type QueryAccountAuditEventTypeLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryAccountAuditsArgs = {
  filter?: Maybe<AccountAuditFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<AccountAuditSortOptions>;
};

export type QueryAccountLookupsArgs = {
  filter?: Maybe<AccountLookupFilter>;
};

export type QueryAccountNumberArgs = {
  id: Scalars['Int'];
};

export type QueryAccountNumberLookupsArgs = {
  filter?: Maybe<AccountNumberLookupFilter>;
};

export type QueryAccountNumbersArgs = {
  filter?: Maybe<AccountNumberFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<AccountNumberSortOptions>;
};

export type QueryAccountsArgs = {
  filter?: Maybe<AccountFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<AccountSortOptions>;
};

export type QueryBalanceArgs = {
  id: Scalars['Int'];
};

export type QueryBalanceCommentsForAccountPeriodArgs = {
  balanceId: Scalars['Int'];
};

export type QueryBalanceFileAttachmentsArgs = {
  balanceIds: Array<Scalars['Int']>;
};

export type QueryBalanceImportArgs = {
  id: Scalars['Int'];
};

export type QueryBalanceImportSummariesForBatchArgs = {
  id: Scalars['Int'];
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<BalanceImportSummarySortOptions>;
};

export type QueryBalanceImportUnrecognizedAccountsArgs = {
  filter: BalanceImportUnrecognizedAccountFilter;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<BalanceImportUnrecognizedAccountSortOptions>;
};

export type QueryBalanceImportsArgs = {
  filter: BalanceImportFilter;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<BalanceImportSortOptions>;
};

export type QueryBalanceStatusLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryBalancesArgs = {
  filter?: Maybe<BalanceFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<BalanceSortOptions>;
};

export type QueryBalancesSummaryArgs = {
  filter?: Maybe<BalanceFilter>;
};

export type QueryBatchImportStatusArgs = {
  batchId: Scalars['Int'];
};

export type QueryBulkUpdateCommonAccountValuesArgs = {
  accountIds: Array<Scalars['Int']>;
};

export type QueryDownloadFileAttachmentArgs = {
  id: Scalars['Int'];
};

export type QueryDownloadFileAttachmentsArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryForeignCurrencyLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryLookupPeriodArgs = {
  id: Scalars['Int'];
};

export type QueryNumberBalancesWithMissingMandatoryAttachmentsForPeriodArgs = {
  periodId: Scalars['Int'];
};

export type QueryNumberUnreviewedBalancesForPeriodArgs = {
  periodId: Scalars['Int'];
};

export type QueryPeriodLookupsArgs = {
  filter?: Maybe<PeriodFilter>;
};

export type QueryPeriodStatusLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryPeriodTransitionLogsArgs = {
  filter?: Maybe<PeriodTransitionLogFilter>;
};

export type QueryPeriodsArgs = {
  filter?: Maybe<PeriodFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<PeriodSortOptions>;
};

export type QueryPeriodsForBalancesArgs = {
  balanceIds: Array<Scalars['Int']>;
};

export type QuerySubsidiaryLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryUserArgs = {
  id: Scalars['Int'];
};

export type QueryUserLookupsArgs = {
  filter?: Maybe<LookupFilter>;
};

export type QueryUsersArgs = {
  filter?: Maybe<UserFilter>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<UserSortOptions>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SubsidiaryLookupModel = {
  description?: Maybe<Scalars['String']>;
  foreignCurrency?: Maybe<SubsidiaryLookupModel>;
  id: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type UpdateAccountInput = {
  accountNumber: AccountNumberInput;
  applyToOpenPeriods?: Maybe<Scalars['Boolean']>;
  dueToFromSubsidiaryId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  isAttachmentMandatory?: Maybe<Scalars['Boolean']>;
  isIncludedInReminderEmails?: Maybe<Scalars['Boolean']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  responsibleUserId?: Maybe<Scalars['Int']>;
  reviewerUserId?: Maybe<Scalars['Int']>;
  subsidiaryId?: Maybe<Scalars['Int']>;
};

export type UpdatePeriodReportIdsInput = {
  foreignCurrencyIcReportId?: Maybe<Scalars['Int']>;
  foreignCurrencyReportId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  usdEquityReportId?: Maybe<Scalars['Int']>;
  usdIcReportId?: Maybe<Scalars['Int']>;
  usdReportId?: Maybe<Scalars['Int']>;
};

export type User = {
  email?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type UserFilter = {
  roleIdIn?: Maybe<Array<Scalars['Int']>>;
};

export enum UserSortBy {
  Email = 'Email',
  Name = 'Name',
}

export type UserSortOptions = {
  sortBy?: Maybe<UserSortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type Users = {
  items: Array<User>;
  totalItems: Scalars['Int'];
};

export type BalanceCommentsForAccountPeriodQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type BalanceCommentsForAccountPeriodQuery = {
  balanceCommentsForAccountPeriod: {
    totalComments: number;
    comments: Array<CommentFragment>;
  };
};

export type AddBalanceCommentMutationVariables = Exact<{
  id: Scalars['Int'];
  input: CommentInput;
}>;

export type AddBalanceCommentMutation = { addBalanceComment: CommentFragment };

export type UpdateBalanceCommentMutationVariables = Exact<{
  id: Scalars['Int'];
  body: Scalars['String'];
}>;

export type UpdateBalanceCommentMutation = {
  updateBalanceComment: CommentFragment;
};

export type DeleteBalanceCommentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteBalanceCommentMutation = {
  deleteBalanceComment: CommentFragment;
};

export type BalanceFileAttachmentDetailFragment = {
  fileAttachment: {
    id: number;
    fileName: string;
    fileSizeBytes: number;
    mimeType: string;
    balanceFileAttachments: Array<{ balance: BalanceLookupItemFragment }>;
  };
};

export type BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables =
  Exact<{
    balanceId: Scalars['Int'];
  }>;

export type BalanceFileAttachmentModalAttachmentListForBalanceQuery = {
  balance?: Maybe<
    {
      period: {
        id: number;
        year: number;
        month: number;
        periodStatus: { id: number };
      };
      balanceFileAttachments: Array<BalanceFileAttachmentDetailFragment>;
    } & BalanceLookupItemFragment
  >;
};

export type BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables =
  Exact<{
    balanceIds: Array<Scalars['Int']>;
  }>;

export type BalanceFileAttachmentModalBalancesForBulkAttachmentQuery = {
  balances: { items: Array<BalanceLookupItemFragment> };
};

export type AddBalanceFileAttachmentsMutationVariables = Exact<{
  periodId: Scalars['Int'];
  balanceFileAttachments: Array<BalanceFileAttachmentInput>;
}>;

export type AddBalanceFileAttachmentsMutation = {
  addBalanceFileAttachments: Array<number>;
};

export type UpdateBalanceFileAttachmentsMutationVariables = Exact<{
  periodId: Scalars['Int'];
  balanceFileAttachments: Array<BalanceFileAttachmentInput>;
  anchorBalanceId: Scalars['Int'];
}>;

export type UpdateBalanceFileAttachmentsMutation = {
  updateBalanceFileAttachments: Array<number>;
};

export type PeriodsForBalancesQueryVariables = Exact<{
  balanceIds: Array<Scalars['Int']>;
}>;

export type PeriodsForBalancesQuery = {
  periodsForBalances: Array<{ id: number; periodStatus: { id: number } }>;
};

export type BalanceLookupItemFragment = {
  id: number;
  accountNumber: string;
  accountNumberDescription?: Maybe<string>;
  subsidiary: { id: number; name: string };
  balanceStatus: { id: number; name: string };
};

export type BalanceLookupQueryVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
  sortOptions?: Maybe<BalanceSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type BalanceLookupQuery = {
  balances: { items: Array<BalanceLookupItemFragment> };
};

export type BalanceSelectionItemFragment = {
  id: number;
  accountNumber: string;
  accountNumberDescription?: Maybe<string>;
  balanceStatus: { id: number; name: string };
  subsidiary: { id: number; name: string };
};

export type BalanceSelectionQueryVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
  sortOptions?: Maybe<BalanceSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type BalanceSelectionQuery = {
  balances: { totalItems: number; items: Array<BalanceSelectionItemFragment> };
};

export type FileAttachmentIdsForBalancesQueryVariables = Exact<{
  balanceIds: Array<Scalars['Int']>;
}>;

export type FileAttachmentIdsForBalancesQuery = {
  balanceFileAttachments: Array<{ fileAttachment: { id: number } }>;
};

export type DownloadBalanceFileAttachmentsQueryVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;

export type DownloadBalanceFileAttachmentsQuery = {
  downloadFileAttachments: Array<{
    fileName: string;
    mimeType: string;
    base64Content: string;
  }>;
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadFileMutation = { uploadFile: number };

export type DownloadFileAttachmentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DownloadFileAttachmentQuery = {
  downloadFileAttachment?: Maybe<{ base64Content: string }>;
};

export type AccountLookupModelFragment = {
  id: number;
  name: string;
  description?: Maybe<string>;
  isDeleted?: Maybe<boolean>;
};

export type AccountLookupsQueryVariables = Exact<{
  take: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  subsidiaryIdIn?: Maybe<Array<Scalars['Int']>>;
}>;

export type AccountLookupsQuery = {
  accountLookups: Array<AccountLookupModelFragment>;
};

export type AccountNumberLookupModelFragment = {
  id: number;
  name: string;
  description?: Maybe<string>;
  isDeleted?: Maybe<boolean>;
};

export type AccountNumberLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type AccountNumberLookupsQuery = {
  accountNumberLookups: Array<AccountNumberLookupModelFragment>;
};

export type BalanceStatusLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
}>;

export type BalanceStatusLookupsQuery = {
  balanceStatusLookups: Array<LookupModelFragment>;
};

export type PeriodLookupModelFragment = {
  id: number;
  name: string;
  description?: Maybe<string>;
  isDeleted?: Maybe<boolean>;
  sortableName: string;
  year: number;
  month: number;
  periodStatus: { id: number; name: string };
};

export type PeriodLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
  periodStatusIds?: Maybe<Array<Scalars['Int']>>;
}>;

export type PeriodLookupsQuery = {
  periodLookups: Array<PeriodLookupModelFragment>;
};

export type LookupPeriodQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type LookupPeriodQuery = {
  lookupPeriod?: Maybe<PeriodLookupModelFragment>;
};

export type SubsidiaryLookupModelFragment = {
  id: number;
  name: string;
  description?: Maybe<string>;
  isDeleted?: Maybe<boolean>;
};

export type SubsidiaryLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
}>;

export type SubsidiaryLookupsQuery = {
  subsidiaryLookups: Array<SubsidiaryLookupModelFragment>;
};

export type UserLookupsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  includeIds?: Maybe<Array<Scalars['Int']>>;
}>;

export type UserLookupsQuery = { userLookups: Array<LookupModelFragment> };

export type ViewAccountsListItemFragment = {
  id: number;
  accountNumberId: number;
  accountNumber: string;
  accountNumberDescription?: Maybe<string>;
  reconciliationCompletedDate?: Maybe<any>;
  reviewCompletedDate?: Maybe<any>;
  usdBalance?: Maybe<number>;
  foreignBalance?: Maybe<number>;
  reconciledBalance?: Maybe<number>;
  variance?: Maybe<number>;
  hasComments?: Maybe<boolean>;
  hasFileAttachments?: Maybe<boolean>;
  isUnexpected: boolean;
  isAccountActive: boolean;
  period: {
    id: number;
    year: number;
    month: number;
    periodStatus: { id: number };
  };
  subsidiary: { id: number; name: string };
  dueToFromSubsidiary?: Maybe<{ id: number; name: string }>;
  balanceStatus: { id: number; name: string };
  responsible?: Maybe<{ id: number; name: string }>;
  reviewer?: Maybe<{ id: number; name: string }>;
  reconciliationCompletedBy?: Maybe<{ id: number; name: string }>;
  reviewCompletedBy?: Maybe<{ id: number; name: string }>;
};

export type ViewAccountsListQueryVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
  sortOptions?: Maybe<BalanceSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type ViewAccountsListQuery = {
  balances: { totalItems: number; items: Array<ViewAccountsListItemFragment> };
};

export type ViewAccountsListAllIdsWithFileAttachmentsQueryVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
}>;

export type ViewAccountsListAllIdsWithFileAttachmentsQuery = {
  balances: { items: Array<{ id: number }> };
};

export type ViewAccountsListAllIdsQueryVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
}>;

export type ViewAccountsListAllIdsQuery = {
  balances: { items: Array<{ id: number }> };
};

export type ViewAccountsRefreshListQueryVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
}>;

export type ViewAccountsRefreshListQuery = {
  balances: { items: Array<ViewAccountsListItemFragment> };
};

export type LookupModelFragment = {
  id: number;
  name: string;
  description?: Maybe<string>;
  isDeleted?: Maybe<boolean>;
};

export type CommentFragment = {
  id: number;
  date: any;
  editedDate?: Maybe<any>;
  body: string;
  isEditable: boolean;
  isDeletable: boolean;
  user: { id: number; name?: Maybe<string> };
  period?: Maybe<{ month: number; year: number }>;
};

export type BalancesSummaryFragment = {
  totalBalances: number;
  totalWithNoActivity: number;
  totalReconciledOnly: number;
  totalReconciledAndReviewed: number;
};

export type BalancesSummaryQueryVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
}>;

export type BalancesSummaryQuery = { balancesSummary: BalancesSummaryFragment };

export type SendRemindersForBalancesAwaitingReconciliationMutationVariables =
  Exact<{
    filter?: Maybe<BalanceFilter>;
  }>;

export type SendRemindersForBalancesAwaitingReconciliationMutation = {
  sendRemindersForBalancesAwaitingReconciliation: boolean;
};

export type SendRemindersForBalancesAwaitingReviewMutationVariables = Exact<{
  filter?: Maybe<BalanceFilter>;
}>;

export type SendRemindersForBalancesAwaitingReviewMutation = {
  sendRemindersForBalancesAwaitingReview: boolean;
};

export type AuditTrailListItemFragment = {
  id: number;
  accountNumber: string;
  accountNumberDescription?: Maybe<string>;
  event: string;
  eventDate: any;
  subsidiary: { name: string };
  dueToFromSubsidiary?: Maybe<{ name: string }>;
  balance?: Maybe<{ period: { year: number; month: number } }>;
  eventUser?: Maybe<{ name: string }>;
};

export type AuditTrailListQueryVariables = Exact<{
  filter?: Maybe<AccountAuditFilter>;
  sortOptions?: Maybe<AccountAuditSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type AuditTrailListQuery = {
  accountAudits: {
    totalItems: number;
    items: Array<AuditTrailListItemFragment>;
  };
};

export type BalanceImportOpenPeriodsListItemFragment = {
  id: number;
  month: number;
  year: number;
  usdReportId?: Maybe<number>;
  usdIcReportId?: Maybe<number>;
  foreignCurrencyReportId?: Maybe<number>;
  foreignCurrencyIcReportId?: Maybe<number>;
  usdEquityReportId?: Maybe<number>;
};

export type BalanceImportOpenPeriodsListQueryVariables = Exact<{
  filter?: Maybe<PeriodFilter>;
  sortOptions?: Maybe<PeriodSortOptions>;
}>;

export type BalanceImportOpenPeriodsListQuery = {
  periods: { items: Array<BalanceImportOpenPeriodsListItemFragment> };
};

export type LatestBalanceImportBatchQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LatestBalanceImportBatchQuery = {
  latestBalanceImportBatch?: Maybe<{
    id: number;
    importDate: any;
    errorSummary?: Maybe<string>;
    importedByUser?: Maybe<{ name: string }>;
  }>;
};

export type BalanceImportSummaryListItemFragment = {
  numberOfBalances: number;
  totalUsdBalance?: Maybe<number>;
  totalForeignBalance?: Maybe<number>;
  period: { id: number; month: number; year: number };
  subsidiary: { id: number; name: string };
};

export type BalanceImportSummariesForBatchQueryVariables = Exact<{
  id: Scalars['Int'];
  sortOptions?: Maybe<BalanceImportSummarySortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type BalanceImportSummariesForBatchQuery = {
  balanceImportSummariesForBatch: {
    totalItems: number;
    items: Array<BalanceImportSummaryListItemFragment>;
  };
};

export type BalanceImportDetailListItemFragment = {
  id: number;
  accountNumber: string;
  accountNumberDescription?: Maybe<string>;
  usdBalance?: Maybe<number>;
  foreignBalance?: Maybe<number>;
  period?: Maybe<{ month: number; year: number }>;
  subsidiary?: Maybe<{ name: string }>;
  dueToFromSubsidiary?: Maybe<{ name: string }>;
};

export type BalanceImportDetailQueryVariables = Exact<{
  filter: BalanceImportFilter;
  sortOptions?: Maybe<BalanceImportSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type BalanceImportDetailQuery = {
  balanceImports: {
    totalItems: number;
    items: Array<BalanceImportDetailListItemFragment>;
  };
};

export type BalanceImportUnrecognizedAccountsListItemFragment = {
  id: number;
  netsuiteSubsidiary: string;
  netsuiteAccountNumber: string;
  netsuiteDueToFromSubsidiary?: Maybe<string>;
  usdBalance?: Maybe<number>;
  foreignBalance?: Maybe<number>;
  period?: Maybe<{ month: number; year: number }>;
};

export type BalanceImportUnrecognizedAccountsQueryVariables = Exact<{
  filter: BalanceImportUnrecognizedAccountFilter;
  sortOptions?: Maybe<BalanceImportUnrecognizedAccountSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type BalanceImportUnrecognizedAccountsQuery = {
  balanceImportUnrecognizedAccounts: {
    totalItems: number;
    items: Array<BalanceImportUnrecognizedAccountsListItemFragment>;
  };
};

export type InitiateBalancesImportMutationVariables = Exact<{
  [key: string]: never;
}>;

export type InitiateBalancesImportMutation = {
  initiateBalancesImport: {
    batchId?: Maybe<number>;
    message?: Maybe<string>;
    isError: boolean;
  };
};

export type BatchImportStatusFragment = {
  totalToProcess: number;
  totalProcessed: number;
  totalImported: number;
  status: number;
};

export type BatchImportStatusQueryVariables = Exact<{
  batchId: Scalars['Int'];
}>;

export type BatchImportStatusQuery = {
  batchImportStatus: BatchImportStatusFragment;
};

export type ManageAccountsListItemFragment = {
  id: number;
  isReconciled: boolean;
  isActive: boolean;
  isAttachmentMandatory: boolean;
  isIncludedInReminderEmails: boolean;
  accountNumber: { name: string; description?: Maybe<string> };
  subsidiary: { name: string; foreignCurrency?: Maybe<{ name: string }> };
  dueToFromSubsidiary?: Maybe<{ name: string }>;
  responsible?: Maybe<{ name: string }>;
  reviewer?: Maybe<{ name: string }>;
};

export type ManageAccountsListQueryVariables = Exact<{
  filter?: Maybe<AccountFilter>;
  sortOptions?: Maybe<AccountSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type ManageAccountsListQuery = {
  accounts: {
    totalItems: number;
    items: Array<ManageAccountsListItemFragment>;
  };
};

export type AssigneeLookupsQueryVariables = Exact<{ [key: string]: never }>;

export type AssigneeLookupsQuery = { userLookups: Array<LookupModelFragment> };

export type AccountUpdateDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type AccountUpdateDetailQuery = {
  account?: Maybe<{
    id: number;
    isReconciled: boolean;
    isActive: boolean;
    isAttachmentMandatory: boolean;
    isIncludedInReminderEmails: boolean;
    accountNumber: { name: string; description?: Maybe<string> };
    subsidiary: { id: number };
    dueToFromSubsidiary?: Maybe<{ id: number }>;
    responsible?: Maybe<{ id: number }>;
    reviewer?: Maybe<{ id: number }>;
  }>;
};

export type BulkUpdateAccountCommonValuesQueryVariables = Exact<{
  accountIds: Array<Scalars['Int']>;
}>;

export type BulkUpdateAccountCommonValuesQuery = {
  bulkUpdateCommonAccountValues?: Maybe<{
    isReconciled?: Maybe<boolean>;
    isActive?: Maybe<boolean>;
    isAttachmentMandatory?: Maybe<boolean>;
    isIncludedInReminderEmails?: Maybe<boolean>;
    accountNumber?: Maybe<{ id: number; description?: Maybe<string> }>;
    subsidiary?: Maybe<{ id: number }>;
    dueToFromSubsidiary?: Maybe<{ id: number }>;
    responsible?: Maybe<{ id: number }>;
    reviewer?: Maybe<{ id: number }>;
  }>;
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;

export type CreateAccountMutation = { createAccount: { id: number } };

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;

export type UpdateAccountMutation = { updateAccount: { id: number } };

export type BulkUpdateAccountsMutationVariables = Exact<{
  input: BulkUpdateAccountsInput;
}>;

export type BulkUpdateAccountsMutation = { bulkUpdateAccounts: boolean };

export type ManagePeriodsListItemFragment = {
  id: number;
  name: string;
  month: number;
  year: number;
  initiateDate?: Maybe<any>;
  closedDate?: Maybe<any>;
  usdReportId?: Maybe<number>;
  usdIcReportId?: Maybe<number>;
  foreignCurrencyReportId?: Maybe<number>;
  foreignCurrencyIcReportId?: Maybe<number>;
  usdEquityReportId?: Maybe<number>;
  periodStatus: { id: number; name: string };
  initiatedByUser?: Maybe<{ id: number; name?: Maybe<string> }>;
  closedByUser?: Maybe<{ id: number; name?: Maybe<string> }>;
  transitionLog: Array<{
    periodTransitionId: number;
    transitionDate: any;
    transitionedByUser?: Maybe<{ name?: Maybe<string> }>;
  }>;
};

export type ManagePeriodsListQueryVariables = Exact<{
  filter?: Maybe<PeriodFilter>;
  sortOptions?: Maybe<PeriodSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type ManagePeriodsListQuery = {
  periods: { totalItems: number; items: Array<ManagePeriodsListItemFragment> };
};

export type InitiatePeriodMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type InitiatePeriodMutation = { initiatePeriod: { id: number } };

export type ClosePeriodMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ClosePeriodMutation = { closePeriod: { id: number } };

export type ReopenPeriodMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ReopenPeriodMutation = { reopenPeriod: { id: number } };

export type UpdatePeriodReportIdsMutationVariables = Exact<{
  input: UpdatePeriodReportIdsInput;
}>;

export type UpdatePeriodReportIdsMutation = {
  updatePeriodReportIds: { id: number };
};

export type NumberUnreviewedBalancesForPeriodQueryVariables = Exact<{
  periodId: Scalars['Int'];
}>;

export type NumberUnreviewedBalancesForPeriodQuery = {
  numberUnreviewedBalancesForPeriod: number;
};

export type NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables =
  Exact<{
    periodId: Scalars['Int'];
  }>;

export type NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery = {
  numberBalancesWithMissingMandatoryAttachmentsForPeriod: number;
};

export type CreatePeriodsMutationVariables = Exact<{
  totalPeriods: Scalars['Int'];
}>;

export type CreatePeriodsMutation = { createPeriods: Array<{ id: number }> };

export type ReactivatedAccountsListItemFragment = {
  id: number;
  accountId: number;
  accountNumber: string;
  accountNumberDescription?: Maybe<string>;
  eventDate: any;
  subsidiary: { name: string };
  dueToFromSubsidiary?: Maybe<{ name: string }>;
  balance?: Maybe<{
    usdBalance?: Maybe<number>;
    foreignBalance?: Maybe<number>;
    period: { year: number; month: number };
  }>;
  eventUser?: Maybe<{ name: string }>;
};

export type ReactivatedAccountsListQueryVariables = Exact<{
  filter?: Maybe<AccountAuditFilter>;
  sortOptions?: Maybe<AccountAuditSortOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type ReactivatedAccountsListQuery = {
  accountAudits: {
    totalItems: number;
    items: Array<ReactivatedAccountsListItemFragment>;
  };
};

export type UpdateReconciledBalanceMutationVariables = Exact<{
  id: Scalars['Int'];
  reconciledBalance?: Maybe<Scalars['Float']>;
}>;

export type UpdateReconciledBalanceMutation = {
  updateReconciledBalance: { id: number };
};

export type ToggleIsBalanceReconciliationCompleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ToggleIsBalanceReconciliationCompleteMutation = {
  toggleIsBalanceReconciliationComplete: { id: number };
};

export type ToggleIsBalanceReviewCompleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ToggleIsBalanceReviewCompleteMutation = {
  toggleIsBalanceReviewComplete: { id: number };
};

export const BalanceLookupItemFragmentDoc = gql`
  fragment balanceLookupItem on Balance {
    id
    accountNumber
    accountNumberDescription
    subsidiary {
      id
      name
    }
    balanceStatus {
      id
      name
    }
  }
`;
export const BalanceFileAttachmentDetailFragmentDoc = gql`
  fragment balanceFileAttachmentDetail on BalanceFileAttachment {
    fileAttachment {
      id
      fileName
      fileSizeBytes
      mimeType
      balanceFileAttachments {
        balance {
          ...balanceLookupItem
        }
      }
    }
  }
  ${BalanceLookupItemFragmentDoc}
`;
export const BalanceSelectionItemFragmentDoc = gql`
  fragment balanceSelectionItem on Balance {
    id
    balanceStatus {
      id
      name
    }
    accountNumber
    accountNumberDescription
    subsidiary {
      id
      name
    }
  }
`;
export const AccountLookupModelFragmentDoc = gql`
  fragment accountLookupModel on AccountLookupModel {
    id
    name
    description
    isDeleted
  }
`;
export const AccountNumberLookupModelFragmentDoc = gql`
  fragment accountNumberLookupModel on AccountNumberLookupModel {
    id
    name
    description
    isDeleted
  }
`;
export const PeriodLookupModelFragmentDoc = gql`
  fragment periodLookupModel on PeriodLookupModel {
    id
    name
    description
    isDeleted
    sortableName
    year
    month
    periodStatus {
      id
      name
    }
  }
`;
export const SubsidiaryLookupModelFragmentDoc = gql`
  fragment subsidiaryLookupModel on SubsidiaryLookupModel {
    id
    name
    description
    isDeleted
  }
`;
export const ViewAccountsListItemFragmentDoc = gql`
  fragment viewAccountsListItem on Balance {
    id
    period {
      id
      year
      month
      periodStatus {
        id
      }
    }
    accountNumberId
    accountNumber
    accountNumberDescription
    subsidiary {
      id
      name
    }
    dueToFromSubsidiary {
      id
      name
    }
    balanceStatus {
      id
      name
    }
    responsible {
      id
      name
    }
    reviewer {
      id
      name
    }
    reconciliationCompletedBy {
      id
      name
    }
    reconciliationCompletedDate
    reviewCompletedBy {
      id
      name
    }
    reviewCompletedDate
    usdBalance
    foreignBalance
    reconciledBalance
    variance
    hasComments
    hasFileAttachments
    isUnexpected
    isAccountActive
  }
`;
export const LookupModelFragmentDoc = gql`
  fragment lookupModel on LookupModel {
    id
    name
    description
    isDeleted
  }
`;
export const CommentFragmentDoc = gql`
  fragment comment on Comment {
    id
    date
    editedDate
    body
    user {
      id
      name
    }
    isEditable
    isDeletable
    period {
      month
      year
    }
  }
`;
export const BalancesSummaryFragmentDoc = gql`
  fragment balancesSummary on BalancesSummary {
    totalBalances
    totalWithNoActivity
    totalReconciledOnly
    totalReconciledAndReviewed
  }
`;
export const AuditTrailListItemFragmentDoc = gql`
  fragment auditTrailListItem on AccountAudit {
    id
    accountNumber
    accountNumberDescription
    subsidiary {
      name
    }
    dueToFromSubsidiary {
      name
    }
    balance {
      period {
        year
        month
      }
    }
    event
    eventUser {
      name
    }
    eventDate
  }
`;
export const BalanceImportOpenPeriodsListItemFragmentDoc = gql`
  fragment balanceImportOpenPeriodsListItem on Period {
    id
    month
    year
    usdReportId
    usdIcReportId
    foreignCurrencyReportId
    foreignCurrencyIcReportId
    usdEquityReportId
  }
`;
export const BalanceImportSummaryListItemFragmentDoc = gql`
  fragment balanceImportSummaryListItem on BalanceImportSummary {
    period {
      id
      month
      year
    }
    subsidiary {
      id
      name
    }
    numberOfBalances
    totalUsdBalance
    totalForeignBalance
  }
`;
export const BalanceImportDetailListItemFragmentDoc = gql`
  fragment balanceImportDetailListItem on BalanceImport {
    id
    period {
      month
      year
    }
    accountNumber
    accountNumberDescription
    subsidiary {
      name
    }
    dueToFromSubsidiary {
      name
    }
    usdBalance
    foreignBalance
  }
`;
export const BalanceImportUnrecognizedAccountsListItemFragmentDoc = gql`
  fragment balanceImportUnrecognizedAccountsListItem on BalanceImportUnrecognizedAccount {
    id
    period {
      month
      year
    }
    netsuiteSubsidiary
    netsuiteAccountNumber
    netsuiteDueToFromSubsidiary
    usdBalance
    foreignBalance
  }
`;
export const BatchImportStatusFragmentDoc = gql`
  fragment batchImportStatus on BatchImportStatus {
    totalToProcess
    totalProcessed
    totalImported
    status
  }
`;
export const ManageAccountsListItemFragmentDoc = gql`
  fragment manageAccountsListItem on Account {
    id
    accountNumber {
      name
      description
    }
    subsidiary {
      name
      foreignCurrency {
        name
      }
    }
    dueToFromSubsidiary {
      name
    }
    responsible {
      name
    }
    reviewer {
      name
    }
    isReconciled
    isActive
    isAttachmentMandatory
    isIncludedInReminderEmails
  }
`;
export const ManagePeriodsListItemFragmentDoc = gql`
  fragment managePeriodsListItem on Period {
    id
    name
    month
    year
    periodStatus {
      id
      name
    }
    initiateDate
    initiatedByUser {
      id
      name
    }
    closedDate
    closedByUser {
      id
      name
    }
    usdReportId
    usdIcReportId
    foreignCurrencyReportId
    foreignCurrencyIcReportId
    usdEquityReportId
    transitionLog {
      periodTransitionId
      transitionDate
      transitionedByUser {
        name
      }
    }
  }
`;
export const ReactivatedAccountsListItemFragmentDoc = gql`
  fragment reactivatedAccountsListItem on AccountAudit {
    id
    accountId
    accountNumber
    accountNumberDescription
    subsidiary {
      name
    }
    dueToFromSubsidiary {
      name
    }
    balance {
      period {
        year
        month
      }
      usdBalance
      foreignBalance
    }
    eventUser {
      name
    }
    eventDate
  }
`;
export const BalanceCommentsForAccountPeriodDocument = gql`
  query balanceCommentsForAccountPeriod($id: Int!) {
    balanceCommentsForAccountPeriod(balanceId: $id) {
      totalComments
      comments {
        ...comment
      }
    }
  }
  ${CommentFragmentDoc}
`;

/**
 * __useBalanceCommentsForAccountPeriodQuery__
 *
 * To run a query within a React component, call `useBalanceCommentsForAccountPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceCommentsForAccountPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceCommentsForAccountPeriodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBalanceCommentsForAccountPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceCommentsForAccountPeriodQuery,
    BalanceCommentsForAccountPeriodQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceCommentsForAccountPeriodQuery,
    BalanceCommentsForAccountPeriodQueryVariables
  >(BalanceCommentsForAccountPeriodDocument, baseOptions);
}
export function useBalanceCommentsForAccountPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceCommentsForAccountPeriodQuery,
    BalanceCommentsForAccountPeriodQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceCommentsForAccountPeriodQuery,
    BalanceCommentsForAccountPeriodQueryVariables
  >(BalanceCommentsForAccountPeriodDocument, baseOptions);
}
export type BalanceCommentsForAccountPeriodQueryHookResult = ReturnType<
  typeof useBalanceCommentsForAccountPeriodQuery
>;
export type BalanceCommentsForAccountPeriodLazyQueryHookResult = ReturnType<
  typeof useBalanceCommentsForAccountPeriodLazyQuery
>;
export type BalanceCommentsForAccountPeriodQueryResult = Apollo.QueryResult<
  BalanceCommentsForAccountPeriodQuery,
  BalanceCommentsForAccountPeriodQueryVariables
>;
export const AddBalanceCommentDocument = gql`
  mutation addBalanceComment($id: Int!, $input: CommentInput!) {
    addBalanceComment(input: $input, id: $id) {
      ...comment
    }
  }
  ${CommentFragmentDoc}
`;
export type AddBalanceCommentMutationFn = Apollo.MutationFunction<
  AddBalanceCommentMutation,
  AddBalanceCommentMutationVariables
>;

/**
 * __useAddBalanceCommentMutation__
 *
 * To run a mutation, you first call `useAddBalanceCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBalanceCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBalanceCommentMutation, { data, loading, error }] = useAddBalanceCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBalanceCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBalanceCommentMutation,
    AddBalanceCommentMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddBalanceCommentMutation,
    AddBalanceCommentMutationVariables
  >(AddBalanceCommentDocument, baseOptions);
}
export type AddBalanceCommentMutationHookResult = ReturnType<
  typeof useAddBalanceCommentMutation
>;
export type AddBalanceCommentMutationResult =
  Apollo.MutationResult<AddBalanceCommentMutation>;
export type AddBalanceCommentMutationOptions = Apollo.BaseMutationOptions<
  AddBalanceCommentMutation,
  AddBalanceCommentMutationVariables
>;
export const UpdateBalanceCommentDocument = gql`
  mutation updateBalanceComment($id: Int!, $body: String!) {
    updateBalanceComment(id: $id, body: $body) {
      ...comment
    }
  }
  ${CommentFragmentDoc}
`;
export type UpdateBalanceCommentMutationFn = Apollo.MutationFunction<
  UpdateBalanceCommentMutation,
  UpdateBalanceCommentMutationVariables
>;

/**
 * __useUpdateBalanceCommentMutation__
 *
 * To run a mutation, you first call `useUpdateBalanceCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBalanceCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBalanceCommentMutation, { data, loading, error }] = useUpdateBalanceCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateBalanceCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBalanceCommentMutation,
    UpdateBalanceCommentMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateBalanceCommentMutation,
    UpdateBalanceCommentMutationVariables
  >(UpdateBalanceCommentDocument, baseOptions);
}
export type UpdateBalanceCommentMutationHookResult = ReturnType<
  typeof useUpdateBalanceCommentMutation
>;
export type UpdateBalanceCommentMutationResult =
  Apollo.MutationResult<UpdateBalanceCommentMutation>;
export type UpdateBalanceCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateBalanceCommentMutation,
  UpdateBalanceCommentMutationVariables
>;
export const DeleteBalanceCommentDocument = gql`
  mutation deleteBalanceComment($id: Int!) {
    deleteBalanceComment(id: $id) {
      ...comment
    }
  }
  ${CommentFragmentDoc}
`;
export type DeleteBalanceCommentMutationFn = Apollo.MutationFunction<
  DeleteBalanceCommentMutation,
  DeleteBalanceCommentMutationVariables
>;

/**
 * __useDeleteBalanceCommentMutation__
 *
 * To run a mutation, you first call `useDeleteBalanceCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBalanceCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBalanceCommentMutation, { data, loading, error }] = useDeleteBalanceCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBalanceCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBalanceCommentMutation,
    DeleteBalanceCommentMutationVariables
  >,
) {
  return Apollo.useMutation<
    DeleteBalanceCommentMutation,
    DeleteBalanceCommentMutationVariables
  >(DeleteBalanceCommentDocument, baseOptions);
}
export type DeleteBalanceCommentMutationHookResult = ReturnType<
  typeof useDeleteBalanceCommentMutation
>;
export type DeleteBalanceCommentMutationResult =
  Apollo.MutationResult<DeleteBalanceCommentMutation>;
export type DeleteBalanceCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteBalanceCommentMutation,
  DeleteBalanceCommentMutationVariables
>;
export const BalanceFileAttachmentModalAttachmentListForBalanceDocument = gql`
  query balanceFileAttachmentModalAttachmentListForBalance($balanceId: Int!) {
    balance(id: $balanceId) {
      ...balanceLookupItem
      period {
        id
        year
        month
        periodStatus {
          id
        }
      }
      balanceFileAttachments {
        ...balanceFileAttachmentDetail
      }
    }
  }
  ${BalanceLookupItemFragmentDoc}
  ${BalanceFileAttachmentDetailFragmentDoc}
`;

/**
 * __useBalanceFileAttachmentModalAttachmentListForBalanceQuery__
 *
 * To run a query within a React component, call `useBalanceFileAttachmentModalAttachmentListForBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceFileAttachmentModalAttachmentListForBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceFileAttachmentModalAttachmentListForBalanceQuery({
 *   variables: {
 *      balanceId: // value for 'balanceId'
 *   },
 * });
 */
export function useBalanceFileAttachmentModalAttachmentListForBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceFileAttachmentModalAttachmentListForBalanceQuery,
    BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceFileAttachmentModalAttachmentListForBalanceQuery,
    BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables
  >(BalanceFileAttachmentModalAttachmentListForBalanceDocument, baseOptions);
}
export function useBalanceFileAttachmentModalAttachmentListForBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceFileAttachmentModalAttachmentListForBalanceQuery,
    BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceFileAttachmentModalAttachmentListForBalanceQuery,
    BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables
  >(BalanceFileAttachmentModalAttachmentListForBalanceDocument, baseOptions);
}
export type BalanceFileAttachmentModalAttachmentListForBalanceQueryHookResult =
  ReturnType<typeof useBalanceFileAttachmentModalAttachmentListForBalanceQuery>;
export type BalanceFileAttachmentModalAttachmentListForBalanceLazyQueryHookResult =
  ReturnType<
    typeof useBalanceFileAttachmentModalAttachmentListForBalanceLazyQuery
  >;
export type BalanceFileAttachmentModalAttachmentListForBalanceQueryResult =
  Apollo.QueryResult<
    BalanceFileAttachmentModalAttachmentListForBalanceQuery,
    BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables
  >;
export const BalanceFileAttachmentModalBalancesForBulkAttachmentDocument = gql`
  query balanceFileAttachmentModalBalancesForBulkAttachment(
    $balanceIds: [Int!]!
  ) {
    balances(filter: { idIn: $balanceIds }) {
      items {
        ...balanceLookupItem
      }
    }
  }
  ${BalanceLookupItemFragmentDoc}
`;

/**
 * __useBalanceFileAttachmentModalBalancesForBulkAttachmentQuery__
 *
 * To run a query within a React component, call `useBalanceFileAttachmentModalBalancesForBulkAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceFileAttachmentModalBalancesForBulkAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceFileAttachmentModalBalancesForBulkAttachmentQuery({
 *   variables: {
 *      balanceIds: // value for 'balanceIds'
 *   },
 * });
 */
export function useBalanceFileAttachmentModalBalancesForBulkAttachmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceFileAttachmentModalBalancesForBulkAttachmentQuery,
    BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceFileAttachmentModalBalancesForBulkAttachmentQuery,
    BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables
  >(BalanceFileAttachmentModalBalancesForBulkAttachmentDocument, baseOptions);
}
export function useBalanceFileAttachmentModalBalancesForBulkAttachmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceFileAttachmentModalBalancesForBulkAttachmentQuery,
    BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceFileAttachmentModalBalancesForBulkAttachmentQuery,
    BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables
  >(BalanceFileAttachmentModalBalancesForBulkAttachmentDocument, baseOptions);
}
export type BalanceFileAttachmentModalBalancesForBulkAttachmentQueryHookResult =
  ReturnType<
    typeof useBalanceFileAttachmentModalBalancesForBulkAttachmentQuery
  >;
export type BalanceFileAttachmentModalBalancesForBulkAttachmentLazyQueryHookResult =
  ReturnType<
    typeof useBalanceFileAttachmentModalBalancesForBulkAttachmentLazyQuery
  >;
export type BalanceFileAttachmentModalBalancesForBulkAttachmentQueryResult =
  Apollo.QueryResult<
    BalanceFileAttachmentModalBalancesForBulkAttachmentQuery,
    BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables
  >;
export const AddBalanceFileAttachmentsDocument = gql`
  mutation addBalanceFileAttachments(
    $periodId: Int!
    $balanceFileAttachments: [BalanceFileAttachmentInput!]!
  ) {
    addBalanceFileAttachments(
      periodId: $periodId
      balanceFileAttachments: $balanceFileAttachments
    )
  }
`;
export type AddBalanceFileAttachmentsMutationFn = Apollo.MutationFunction<
  AddBalanceFileAttachmentsMutation,
  AddBalanceFileAttachmentsMutationVariables
>;

/**
 * __useAddBalanceFileAttachmentsMutation__
 *
 * To run a mutation, you first call `useAddBalanceFileAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBalanceFileAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBalanceFileAttachmentsMutation, { data, loading, error }] = useAddBalanceFileAttachmentsMutation({
 *   variables: {
 *      periodId: // value for 'periodId'
 *      balanceFileAttachments: // value for 'balanceFileAttachments'
 *   },
 * });
 */
export function useAddBalanceFileAttachmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBalanceFileAttachmentsMutation,
    AddBalanceFileAttachmentsMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddBalanceFileAttachmentsMutation,
    AddBalanceFileAttachmentsMutationVariables
  >(AddBalanceFileAttachmentsDocument, baseOptions);
}
export type AddBalanceFileAttachmentsMutationHookResult = ReturnType<
  typeof useAddBalanceFileAttachmentsMutation
>;
export type AddBalanceFileAttachmentsMutationResult =
  Apollo.MutationResult<AddBalanceFileAttachmentsMutation>;
export type AddBalanceFileAttachmentsMutationOptions =
  Apollo.BaseMutationOptions<
    AddBalanceFileAttachmentsMutation,
    AddBalanceFileAttachmentsMutationVariables
  >;
export const UpdateBalanceFileAttachmentsDocument = gql`
  mutation updateBalanceFileAttachments(
    $periodId: Int!
    $balanceFileAttachments: [BalanceFileAttachmentInput!]!
    $anchorBalanceId: Int!
  ) {
    updateBalanceFileAttachments(
      periodId: $periodId
      balanceFileAttachments: $balanceFileAttachments
      anchorBalanceId: $anchorBalanceId
    )
  }
`;
export type UpdateBalanceFileAttachmentsMutationFn = Apollo.MutationFunction<
  UpdateBalanceFileAttachmentsMutation,
  UpdateBalanceFileAttachmentsMutationVariables
>;

/**
 * __useUpdateBalanceFileAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateBalanceFileAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBalanceFileAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBalanceFileAttachmentsMutation, { data, loading, error }] = useUpdateBalanceFileAttachmentsMutation({
 *   variables: {
 *      periodId: // value for 'periodId'
 *      balanceFileAttachments: // value for 'balanceFileAttachments'
 *      anchorBalanceId: // value for 'anchorBalanceId'
 *   },
 * });
 */
export function useUpdateBalanceFileAttachmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBalanceFileAttachmentsMutation,
    UpdateBalanceFileAttachmentsMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateBalanceFileAttachmentsMutation,
    UpdateBalanceFileAttachmentsMutationVariables
  >(UpdateBalanceFileAttachmentsDocument, baseOptions);
}
export type UpdateBalanceFileAttachmentsMutationHookResult = ReturnType<
  typeof useUpdateBalanceFileAttachmentsMutation
>;
export type UpdateBalanceFileAttachmentsMutationResult =
  Apollo.MutationResult<UpdateBalanceFileAttachmentsMutation>;
export type UpdateBalanceFileAttachmentsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateBalanceFileAttachmentsMutation,
    UpdateBalanceFileAttachmentsMutationVariables
  >;
export const PeriodsForBalancesDocument = gql`
  query periodsForBalances($balanceIds: [Int!]!) {
    periodsForBalances(balanceIds: $balanceIds) {
      id
      periodStatus {
        id
      }
    }
  }
`;

/**
 * __usePeriodsForBalancesQuery__
 *
 * To run a query within a React component, call `usePeriodsForBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodsForBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodsForBalancesQuery({
 *   variables: {
 *      balanceIds: // value for 'balanceIds'
 *   },
 * });
 */
export function usePeriodsForBalancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PeriodsForBalancesQuery,
    PeriodsForBalancesQueryVariables
  >,
) {
  return Apollo.useQuery<
    PeriodsForBalancesQuery,
    PeriodsForBalancesQueryVariables
  >(PeriodsForBalancesDocument, baseOptions);
}
export function usePeriodsForBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodsForBalancesQuery,
    PeriodsForBalancesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    PeriodsForBalancesQuery,
    PeriodsForBalancesQueryVariables
  >(PeriodsForBalancesDocument, baseOptions);
}
export type PeriodsForBalancesQueryHookResult = ReturnType<
  typeof usePeriodsForBalancesQuery
>;
export type PeriodsForBalancesLazyQueryHookResult = ReturnType<
  typeof usePeriodsForBalancesLazyQuery
>;
export type PeriodsForBalancesQueryResult = Apollo.QueryResult<
  PeriodsForBalancesQuery,
  PeriodsForBalancesQueryVariables
>;
export const BalanceLookupDocument = gql`
  query balanceLookup(
    $filter: BalanceFilter
    $sortOptions: BalanceSortOptions
    $paginationOptions: PaginationOptions
  ) {
    balances(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      items {
        ...balanceLookupItem
      }
    }
  }
  ${BalanceLookupItemFragmentDoc}
`;

/**
 * __useBalanceLookupQuery__
 *
 * To run a query within a React component, call `useBalanceLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceLookupQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useBalanceLookupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BalanceLookupQuery,
    BalanceLookupQueryVariables
  >,
) {
  return Apollo.useQuery<BalanceLookupQuery, BalanceLookupQueryVariables>(
    BalanceLookupDocument,
    baseOptions,
  );
}
export function useBalanceLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceLookupQuery,
    BalanceLookupQueryVariables
  >,
) {
  return Apollo.useLazyQuery<BalanceLookupQuery, BalanceLookupQueryVariables>(
    BalanceLookupDocument,
    baseOptions,
  );
}
export type BalanceLookupQueryHookResult = ReturnType<
  typeof useBalanceLookupQuery
>;
export type BalanceLookupLazyQueryHookResult = ReturnType<
  typeof useBalanceLookupLazyQuery
>;
export type BalanceLookupQueryResult = Apollo.QueryResult<
  BalanceLookupQuery,
  BalanceLookupQueryVariables
>;
export const BalanceSelectionDocument = gql`
  query balanceSelection(
    $filter: BalanceFilter
    $sortOptions: BalanceSortOptions
    $paginationOptions: PaginationOptions
  ) {
    balances(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...balanceSelectionItem
      }
    }
  }
  ${BalanceSelectionItemFragmentDoc}
`;

/**
 * __useBalanceSelectionQuery__
 *
 * To run a query within a React component, call `useBalanceSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceSelectionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useBalanceSelectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BalanceSelectionQuery,
    BalanceSelectionQueryVariables
  >,
) {
  return Apollo.useQuery<BalanceSelectionQuery, BalanceSelectionQueryVariables>(
    BalanceSelectionDocument,
    baseOptions,
  );
}
export function useBalanceSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceSelectionQuery,
    BalanceSelectionQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceSelectionQuery,
    BalanceSelectionQueryVariables
  >(BalanceSelectionDocument, baseOptions);
}
export type BalanceSelectionQueryHookResult = ReturnType<
  typeof useBalanceSelectionQuery
>;
export type BalanceSelectionLazyQueryHookResult = ReturnType<
  typeof useBalanceSelectionLazyQuery
>;
export type BalanceSelectionQueryResult = Apollo.QueryResult<
  BalanceSelectionQuery,
  BalanceSelectionQueryVariables
>;
export const FileAttachmentIdsForBalancesDocument = gql`
  query fileAttachmentIdsForBalances($balanceIds: [Int!]!) {
    balanceFileAttachments(balanceIds: $balanceIds) {
      fileAttachment {
        id
      }
    }
  }
`;

/**
 * __useFileAttachmentIdsForBalancesQuery__
 *
 * To run a query within a React component, call `useFileAttachmentIdsForBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileAttachmentIdsForBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileAttachmentIdsForBalancesQuery({
 *   variables: {
 *      balanceIds: // value for 'balanceIds'
 *   },
 * });
 */
export function useFileAttachmentIdsForBalancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FileAttachmentIdsForBalancesQuery,
    FileAttachmentIdsForBalancesQueryVariables
  >,
) {
  return Apollo.useQuery<
    FileAttachmentIdsForBalancesQuery,
    FileAttachmentIdsForBalancesQueryVariables
  >(FileAttachmentIdsForBalancesDocument, baseOptions);
}
export function useFileAttachmentIdsForBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileAttachmentIdsForBalancesQuery,
    FileAttachmentIdsForBalancesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    FileAttachmentIdsForBalancesQuery,
    FileAttachmentIdsForBalancesQueryVariables
  >(FileAttachmentIdsForBalancesDocument, baseOptions);
}
export type FileAttachmentIdsForBalancesQueryHookResult = ReturnType<
  typeof useFileAttachmentIdsForBalancesQuery
>;
export type FileAttachmentIdsForBalancesLazyQueryHookResult = ReturnType<
  typeof useFileAttachmentIdsForBalancesLazyQuery
>;
export type FileAttachmentIdsForBalancesQueryResult = Apollo.QueryResult<
  FileAttachmentIdsForBalancesQuery,
  FileAttachmentIdsForBalancesQueryVariables
>;
export const DownloadBalanceFileAttachmentsDocument = gql`
  query downloadBalanceFileAttachments($ids: [Int!]!) {
    downloadFileAttachments(ids: $ids) {
      fileName
      mimeType
      base64Content
    }
  }
`;

/**
 * __useDownloadBalanceFileAttachmentsQuery__
 *
 * To run a query within a React component, call `useDownloadBalanceFileAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadBalanceFileAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadBalanceFileAttachmentsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDownloadBalanceFileAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadBalanceFileAttachmentsQuery,
    DownloadBalanceFileAttachmentsQueryVariables
  >,
) {
  return Apollo.useQuery<
    DownloadBalanceFileAttachmentsQuery,
    DownloadBalanceFileAttachmentsQueryVariables
  >(DownloadBalanceFileAttachmentsDocument, baseOptions);
}
export function useDownloadBalanceFileAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadBalanceFileAttachmentsQuery,
    DownloadBalanceFileAttachmentsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    DownloadBalanceFileAttachmentsQuery,
    DownloadBalanceFileAttachmentsQueryVariables
  >(DownloadBalanceFileAttachmentsDocument, baseOptions);
}
export type DownloadBalanceFileAttachmentsQueryHookResult = ReturnType<
  typeof useDownloadBalanceFileAttachmentsQuery
>;
export type DownloadBalanceFileAttachmentsLazyQueryHookResult = ReturnType<
  typeof useDownloadBalanceFileAttachmentsLazyQuery
>;
export type DownloadBalanceFileAttachmentsQueryResult = Apollo.QueryResult<
  DownloadBalanceFileAttachmentsQuery,
  DownloadBalanceFileAttachmentsQueryVariables
>;
export const UploadFileDocument = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >,
) {
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    baseOptions,
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const DownloadFileAttachmentDocument = gql`
  query downloadFileAttachment($id: Int!) {
    downloadFileAttachment(id: $id) {
      base64Content
    }
  }
`;

/**
 * __useDownloadFileAttachmentQuery__
 *
 * To run a query within a React component, call `useDownloadFileAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFileAttachmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadFileAttachmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadFileAttachmentQuery,
    DownloadFileAttachmentQueryVariables
  >,
) {
  return Apollo.useQuery<
    DownloadFileAttachmentQuery,
    DownloadFileAttachmentQueryVariables
  >(DownloadFileAttachmentDocument, baseOptions);
}
export function useDownloadFileAttachmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadFileAttachmentQuery,
    DownloadFileAttachmentQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    DownloadFileAttachmentQuery,
    DownloadFileAttachmentQueryVariables
  >(DownloadFileAttachmentDocument, baseOptions);
}
export type DownloadFileAttachmentQueryHookResult = ReturnType<
  typeof useDownloadFileAttachmentQuery
>;
export type DownloadFileAttachmentLazyQueryHookResult = ReturnType<
  typeof useDownloadFileAttachmentLazyQuery
>;
export type DownloadFileAttachmentQueryResult = Apollo.QueryResult<
  DownloadFileAttachmentQuery,
  DownloadFileAttachmentQueryVariables
>;
export const AccountLookupsDocument = gql`
  query accountLookups(
    $take: Int!
    $name: String
    $includeIds: [Int!]
    $isActive: Boolean
    $subsidiaryIdIn: [Int!]
  ) {
    accountLookups(
      filter: {
        take: $take
        name: $name
        includeIds: $includeIds
        isActive: $isActive
        subsidiaryIdIn: $subsidiaryIdIn
      }
    ) {
      ...accountLookupModel
    }
  }
  ${AccountLookupModelFragmentDoc}
`;

/**
 * __useAccountLookupsQuery__
 *
 * To run a query within a React component, call `useAccountLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLookupsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      includeIds: // value for 'includeIds'
 *      isActive: // value for 'isActive'
 *      subsidiaryIdIn: // value for 'subsidiaryIdIn'
 *   },
 * });
 */
export function useAccountLookupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountLookupsQuery,
    AccountLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<AccountLookupsQuery, AccountLookupsQueryVariables>(
    AccountLookupsDocument,
    baseOptions,
  );
}
export function useAccountLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountLookupsQuery,
    AccountLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AccountLookupsQuery, AccountLookupsQueryVariables>(
    AccountLookupsDocument,
    baseOptions,
  );
}
export type AccountLookupsQueryHookResult = ReturnType<
  typeof useAccountLookupsQuery
>;
export type AccountLookupsLazyQueryHookResult = ReturnType<
  typeof useAccountLookupsLazyQuery
>;
export type AccountLookupsQueryResult = Apollo.QueryResult<
  AccountLookupsQuery,
  AccountLookupsQueryVariables
>;
export const AccountNumberLookupsDocument = gql`
  query accountNumberLookups(
    $name: String
    $includeIds: [Int!]
    $includeDeleted: Boolean
  ) {
    accountNumberLookups(
      filter: {
        name: $name
        includeIds: $includeIds
        includeDeleted: $includeDeleted
      }
    ) {
      ...accountNumberLookupModel
    }
  }
  ${AccountNumberLookupModelFragmentDoc}
`;

/**
 * __useAccountNumberLookupsQuery__
 *
 * To run a query within a React component, call `useAccountNumberLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountNumberLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountNumberLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeIds: // value for 'includeIds'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useAccountNumberLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountNumberLookupsQuery,
    AccountNumberLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<
    AccountNumberLookupsQuery,
    AccountNumberLookupsQueryVariables
  >(AccountNumberLookupsDocument, baseOptions);
}
export function useAccountNumberLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountNumberLookupsQuery,
    AccountNumberLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AccountNumberLookupsQuery,
    AccountNumberLookupsQueryVariables
  >(AccountNumberLookupsDocument, baseOptions);
}
export type AccountNumberLookupsQueryHookResult = ReturnType<
  typeof useAccountNumberLookupsQuery
>;
export type AccountNumberLookupsLazyQueryHookResult = ReturnType<
  typeof useAccountNumberLookupsLazyQuery
>;
export type AccountNumberLookupsQueryResult = Apollo.QueryResult<
  AccountNumberLookupsQuery,
  AccountNumberLookupsQueryVariables
>;
export const BalanceStatusLookupsDocument = gql`
  query balanceStatusLookups($name: String, $includeIds: [Int!]) {
    balanceStatusLookups(filter: { name: $name, includeIds: $includeIds }) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useBalanceStatusLookupsQuery__
 *
 * To run a query within a React component, call `useBalanceStatusLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceStatusLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceStatusLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeIds: // value for 'includeIds'
 *   },
 * });
 */
export function useBalanceStatusLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BalanceStatusLookupsQuery,
    BalanceStatusLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceStatusLookupsQuery,
    BalanceStatusLookupsQueryVariables
  >(BalanceStatusLookupsDocument, baseOptions);
}
export function useBalanceStatusLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceStatusLookupsQuery,
    BalanceStatusLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceStatusLookupsQuery,
    BalanceStatusLookupsQueryVariables
  >(BalanceStatusLookupsDocument, baseOptions);
}
export type BalanceStatusLookupsQueryHookResult = ReturnType<
  typeof useBalanceStatusLookupsQuery
>;
export type BalanceStatusLookupsLazyQueryHookResult = ReturnType<
  typeof useBalanceStatusLookupsLazyQuery
>;
export type BalanceStatusLookupsQueryResult = Apollo.QueryResult<
  BalanceStatusLookupsQuery,
  BalanceStatusLookupsQueryVariables
>;
export const PeriodLookupsDocument = gql`
  query periodLookups(
    $name: String
    $includeIds: [Int!]
    $periodStatusIds: [Int!]
  ) {
    periodLookups(
      filter: {
        name: $name
        includeIds: $includeIds
        periodStatusIds: $periodStatusIds
      }
    ) {
      ...periodLookupModel
    }
  }
  ${PeriodLookupModelFragmentDoc}
`;

/**
 * __usePeriodLookupsQuery__
 *
 * To run a query within a React component, call `usePeriodLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeIds: // value for 'includeIds'
 *      periodStatusIds: // value for 'periodStatusIds'
 *   },
 * });
 */
export function usePeriodLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PeriodLookupsQuery,
    PeriodLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<PeriodLookupsQuery, PeriodLookupsQueryVariables>(
    PeriodLookupsDocument,
    baseOptions,
  );
}
export function usePeriodLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeriodLookupsQuery,
    PeriodLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<PeriodLookupsQuery, PeriodLookupsQueryVariables>(
    PeriodLookupsDocument,
    baseOptions,
  );
}
export type PeriodLookupsQueryHookResult = ReturnType<
  typeof usePeriodLookupsQuery
>;
export type PeriodLookupsLazyQueryHookResult = ReturnType<
  typeof usePeriodLookupsLazyQuery
>;
export type PeriodLookupsQueryResult = Apollo.QueryResult<
  PeriodLookupsQuery,
  PeriodLookupsQueryVariables
>;
export const LookupPeriodDocument = gql`
  query lookupPeriod($id: Int!) {
    lookupPeriod(id: $id) {
      ...periodLookupModel
    }
  }
  ${PeriodLookupModelFragmentDoc}
`;

/**
 * __useLookupPeriodQuery__
 *
 * To run a query within a React component, call `useLookupPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupPeriodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLookupPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    LookupPeriodQuery,
    LookupPeriodQueryVariables
  >,
) {
  return Apollo.useQuery<LookupPeriodQuery, LookupPeriodQueryVariables>(
    LookupPeriodDocument,
    baseOptions,
  );
}
export function useLookupPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookupPeriodQuery,
    LookupPeriodQueryVariables
  >,
) {
  return Apollo.useLazyQuery<LookupPeriodQuery, LookupPeriodQueryVariables>(
    LookupPeriodDocument,
    baseOptions,
  );
}
export type LookupPeriodQueryHookResult = ReturnType<
  typeof useLookupPeriodQuery
>;
export type LookupPeriodLazyQueryHookResult = ReturnType<
  typeof useLookupPeriodLazyQuery
>;
export type LookupPeriodQueryResult = Apollo.QueryResult<
  LookupPeriodQuery,
  LookupPeriodQueryVariables
>;
export const SubsidiaryLookupsDocument = gql`
  query subsidiaryLookups($name: String, $includeIds: [Int!]) {
    subsidiaryLookups(filter: { name: $name, includeIds: $includeIds }) {
      ...subsidiaryLookupModel
    }
  }
  ${SubsidiaryLookupModelFragmentDoc}
`;

/**
 * __useSubsidiaryLookupsQuery__
 *
 * To run a query within a React component, call `useSubsidiaryLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubsidiaryLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubsidiaryLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeIds: // value for 'includeIds'
 *   },
 * });
 */
export function useSubsidiaryLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubsidiaryLookupsQuery,
    SubsidiaryLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<
    SubsidiaryLookupsQuery,
    SubsidiaryLookupsQueryVariables
  >(SubsidiaryLookupsDocument, baseOptions);
}
export function useSubsidiaryLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubsidiaryLookupsQuery,
    SubsidiaryLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    SubsidiaryLookupsQuery,
    SubsidiaryLookupsQueryVariables
  >(SubsidiaryLookupsDocument, baseOptions);
}
export type SubsidiaryLookupsQueryHookResult = ReturnType<
  typeof useSubsidiaryLookupsQuery
>;
export type SubsidiaryLookupsLazyQueryHookResult = ReturnType<
  typeof useSubsidiaryLookupsLazyQuery
>;
export type SubsidiaryLookupsQueryResult = Apollo.QueryResult<
  SubsidiaryLookupsQuery,
  SubsidiaryLookupsQueryVariables
>;
export const UserLookupsDocument = gql`
  query userLookups($name: String, $includeIds: [Int!]) {
    userLookups(filter: { name: $name, includeIds: $includeIds }) {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useUserLookupsQuery__
 *
 * To run a query within a React component, call `useUserLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLookupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeIds: // value for 'includeIds'
 *   },
 * });
 */
export function useUserLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserLookupsQuery,
    UserLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<UserLookupsQuery, UserLookupsQueryVariables>(
    UserLookupsDocument,
    baseOptions,
  );
}
export function useUserLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserLookupsQuery,
    UserLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<UserLookupsQuery, UserLookupsQueryVariables>(
    UserLookupsDocument,
    baseOptions,
  );
}
export type UserLookupsQueryHookResult = ReturnType<typeof useUserLookupsQuery>;
export type UserLookupsLazyQueryHookResult = ReturnType<
  typeof useUserLookupsLazyQuery
>;
export type UserLookupsQueryResult = Apollo.QueryResult<
  UserLookupsQuery,
  UserLookupsQueryVariables
>;
export const ViewAccountsListDocument = gql`
  query viewAccountsList(
    $filter: BalanceFilter
    $sortOptions: BalanceSortOptions
    $paginationOptions: PaginationOptions
  ) {
    balances(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...viewAccountsListItem
      }
    }
  }
  ${ViewAccountsListItemFragmentDoc}
`;

/**
 * __useViewAccountsListQuery__
 *
 * To run a query within a React component, call `useViewAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAccountsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useViewAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewAccountsListQuery,
    ViewAccountsListQueryVariables
  >,
) {
  return Apollo.useQuery<ViewAccountsListQuery, ViewAccountsListQueryVariables>(
    ViewAccountsListDocument,
    baseOptions,
  );
}
export function useViewAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewAccountsListQuery,
    ViewAccountsListQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ViewAccountsListQuery,
    ViewAccountsListQueryVariables
  >(ViewAccountsListDocument, baseOptions);
}
export type ViewAccountsListQueryHookResult = ReturnType<
  typeof useViewAccountsListQuery
>;
export type ViewAccountsListLazyQueryHookResult = ReturnType<
  typeof useViewAccountsListLazyQuery
>;
export type ViewAccountsListQueryResult = Apollo.QueryResult<
  ViewAccountsListQuery,
  ViewAccountsListQueryVariables
>;
export const ViewAccountsListAllIdsWithFileAttachmentsDocument = gql`
  query viewAccountsListAllIdsWithFileAttachments($filter: BalanceFilter) {
    balances(filter: $filter) {
      items {
        id
      }
    }
  }
`;

/**
 * __useViewAccountsListAllIdsWithFileAttachmentsQuery__
 *
 * To run a query within a React component, call `useViewAccountsListAllIdsWithFileAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAccountsListAllIdsWithFileAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAccountsListAllIdsWithFileAttachmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewAccountsListAllIdsWithFileAttachmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewAccountsListAllIdsWithFileAttachmentsQuery,
    ViewAccountsListAllIdsWithFileAttachmentsQueryVariables
  >,
) {
  return Apollo.useQuery<
    ViewAccountsListAllIdsWithFileAttachmentsQuery,
    ViewAccountsListAllIdsWithFileAttachmentsQueryVariables
  >(ViewAccountsListAllIdsWithFileAttachmentsDocument, baseOptions);
}
export function useViewAccountsListAllIdsWithFileAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewAccountsListAllIdsWithFileAttachmentsQuery,
    ViewAccountsListAllIdsWithFileAttachmentsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ViewAccountsListAllIdsWithFileAttachmentsQuery,
    ViewAccountsListAllIdsWithFileAttachmentsQueryVariables
  >(ViewAccountsListAllIdsWithFileAttachmentsDocument, baseOptions);
}
export type ViewAccountsListAllIdsWithFileAttachmentsQueryHookResult =
  ReturnType<typeof useViewAccountsListAllIdsWithFileAttachmentsQuery>;
export type ViewAccountsListAllIdsWithFileAttachmentsLazyQueryHookResult =
  ReturnType<typeof useViewAccountsListAllIdsWithFileAttachmentsLazyQuery>;
export type ViewAccountsListAllIdsWithFileAttachmentsQueryResult =
  Apollo.QueryResult<
    ViewAccountsListAllIdsWithFileAttachmentsQuery,
    ViewAccountsListAllIdsWithFileAttachmentsQueryVariables
  >;
export const ViewAccountsListAllIdsDocument = gql`
  query viewAccountsListAllIds($filter: BalanceFilter) {
    balances(filter: $filter) {
      items {
        id
      }
    }
  }
`;

/**
 * __useViewAccountsListAllIdsQuery__
 *
 * To run a query within a React component, call `useViewAccountsListAllIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAccountsListAllIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAccountsListAllIdsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewAccountsListAllIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewAccountsListAllIdsQuery,
    ViewAccountsListAllIdsQueryVariables
  >,
) {
  return Apollo.useQuery<
    ViewAccountsListAllIdsQuery,
    ViewAccountsListAllIdsQueryVariables
  >(ViewAccountsListAllIdsDocument, baseOptions);
}
export function useViewAccountsListAllIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewAccountsListAllIdsQuery,
    ViewAccountsListAllIdsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ViewAccountsListAllIdsQuery,
    ViewAccountsListAllIdsQueryVariables
  >(ViewAccountsListAllIdsDocument, baseOptions);
}
export type ViewAccountsListAllIdsQueryHookResult = ReturnType<
  typeof useViewAccountsListAllIdsQuery
>;
export type ViewAccountsListAllIdsLazyQueryHookResult = ReturnType<
  typeof useViewAccountsListAllIdsLazyQuery
>;
export type ViewAccountsListAllIdsQueryResult = Apollo.QueryResult<
  ViewAccountsListAllIdsQuery,
  ViewAccountsListAllIdsQueryVariables
>;
export const ViewAccountsRefreshListDocument = gql`
  query viewAccountsRefreshList($filter: BalanceFilter) {
    balances(filter: $filter) {
      items {
        ...viewAccountsListItem
      }
    }
  }
  ${ViewAccountsListItemFragmentDoc}
`;

/**
 * __useViewAccountsRefreshListQuery__
 *
 * To run a query within a React component, call `useViewAccountsRefreshListQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAccountsRefreshListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAccountsRefreshListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewAccountsRefreshListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewAccountsRefreshListQuery,
    ViewAccountsRefreshListQueryVariables
  >,
) {
  return Apollo.useQuery<
    ViewAccountsRefreshListQuery,
    ViewAccountsRefreshListQueryVariables
  >(ViewAccountsRefreshListDocument, baseOptions);
}
export function useViewAccountsRefreshListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewAccountsRefreshListQuery,
    ViewAccountsRefreshListQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ViewAccountsRefreshListQuery,
    ViewAccountsRefreshListQueryVariables
  >(ViewAccountsRefreshListDocument, baseOptions);
}
export type ViewAccountsRefreshListQueryHookResult = ReturnType<
  typeof useViewAccountsRefreshListQuery
>;
export type ViewAccountsRefreshListLazyQueryHookResult = ReturnType<
  typeof useViewAccountsRefreshListLazyQuery
>;
export type ViewAccountsRefreshListQueryResult = Apollo.QueryResult<
  ViewAccountsRefreshListQuery,
  ViewAccountsRefreshListQueryVariables
>;
export const BalancesSummaryDocument = gql`
  query balancesSummary($filter: BalanceFilter) {
    balancesSummary(filter: $filter) {
      ...balancesSummary
    }
  }
  ${BalancesSummaryFragmentDoc}
`;

/**
 * __useBalancesSummaryQuery__
 *
 * To run a query within a React component, call `useBalancesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancesSummaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBalancesSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BalancesSummaryQuery,
    BalancesSummaryQueryVariables
  >,
) {
  return Apollo.useQuery<BalancesSummaryQuery, BalancesSummaryQueryVariables>(
    BalancesSummaryDocument,
    baseOptions,
  );
}
export function useBalancesSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalancesSummaryQuery,
    BalancesSummaryQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalancesSummaryQuery,
    BalancesSummaryQueryVariables
  >(BalancesSummaryDocument, baseOptions);
}
export type BalancesSummaryQueryHookResult = ReturnType<
  typeof useBalancesSummaryQuery
>;
export type BalancesSummaryLazyQueryHookResult = ReturnType<
  typeof useBalancesSummaryLazyQuery
>;
export type BalancesSummaryQueryResult = Apollo.QueryResult<
  BalancesSummaryQuery,
  BalancesSummaryQueryVariables
>;
export const SendRemindersForBalancesAwaitingReconciliationDocument = gql`
  mutation sendRemindersForBalancesAwaitingReconciliation(
    $filter: BalanceFilter
  ) {
    sendRemindersForBalancesAwaitingReconciliation(filter: $filter)
  }
`;
export type SendRemindersForBalancesAwaitingReconciliationMutationFn =
  Apollo.MutationFunction<
    SendRemindersForBalancesAwaitingReconciliationMutation,
    SendRemindersForBalancesAwaitingReconciliationMutationVariables
  >;

/**
 * __useSendRemindersForBalancesAwaitingReconciliationMutation__
 *
 * To run a mutation, you first call `useSendRemindersForBalancesAwaitingReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRemindersForBalancesAwaitingReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRemindersForBalancesAwaitingReconciliationMutation, { data, loading, error }] = useSendRemindersForBalancesAwaitingReconciliationMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSendRemindersForBalancesAwaitingReconciliationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRemindersForBalancesAwaitingReconciliationMutation,
    SendRemindersForBalancesAwaitingReconciliationMutationVariables
  >,
) {
  return Apollo.useMutation<
    SendRemindersForBalancesAwaitingReconciliationMutation,
    SendRemindersForBalancesAwaitingReconciliationMutationVariables
  >(SendRemindersForBalancesAwaitingReconciliationDocument, baseOptions);
}
export type SendRemindersForBalancesAwaitingReconciliationMutationHookResult =
  ReturnType<typeof useSendRemindersForBalancesAwaitingReconciliationMutation>;
export type SendRemindersForBalancesAwaitingReconciliationMutationResult =
  Apollo.MutationResult<SendRemindersForBalancesAwaitingReconciliationMutation>;
export type SendRemindersForBalancesAwaitingReconciliationMutationOptions =
  Apollo.BaseMutationOptions<
    SendRemindersForBalancesAwaitingReconciliationMutation,
    SendRemindersForBalancesAwaitingReconciliationMutationVariables
  >;
export const SendRemindersForBalancesAwaitingReviewDocument = gql`
  mutation sendRemindersForBalancesAwaitingReview($filter: BalanceFilter) {
    sendRemindersForBalancesAwaitingReview(filter: $filter)
  }
`;
export type SendRemindersForBalancesAwaitingReviewMutationFn =
  Apollo.MutationFunction<
    SendRemindersForBalancesAwaitingReviewMutation,
    SendRemindersForBalancesAwaitingReviewMutationVariables
  >;

/**
 * __useSendRemindersForBalancesAwaitingReviewMutation__
 *
 * To run a mutation, you first call `useSendRemindersForBalancesAwaitingReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRemindersForBalancesAwaitingReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRemindersForBalancesAwaitingReviewMutation, { data, loading, error }] = useSendRemindersForBalancesAwaitingReviewMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSendRemindersForBalancesAwaitingReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRemindersForBalancesAwaitingReviewMutation,
    SendRemindersForBalancesAwaitingReviewMutationVariables
  >,
) {
  return Apollo.useMutation<
    SendRemindersForBalancesAwaitingReviewMutation,
    SendRemindersForBalancesAwaitingReviewMutationVariables
  >(SendRemindersForBalancesAwaitingReviewDocument, baseOptions);
}
export type SendRemindersForBalancesAwaitingReviewMutationHookResult =
  ReturnType<typeof useSendRemindersForBalancesAwaitingReviewMutation>;
export type SendRemindersForBalancesAwaitingReviewMutationResult =
  Apollo.MutationResult<SendRemindersForBalancesAwaitingReviewMutation>;
export type SendRemindersForBalancesAwaitingReviewMutationOptions =
  Apollo.BaseMutationOptions<
    SendRemindersForBalancesAwaitingReviewMutation,
    SendRemindersForBalancesAwaitingReviewMutationVariables
  >;
export const AuditTrailListDocument = gql`
  query auditTrailList(
    $filter: AccountAuditFilter
    $sortOptions: AccountAuditSortOptions
    $paginationOptions: PaginationOptions
  ) {
    accountAudits(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...auditTrailListItem
      }
    }
  }
  ${AuditTrailListItemFragmentDoc}
`;

/**
 * __useAuditTrailListQuery__
 *
 * To run a query within a React component, call `useAuditTrailListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditTrailListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditTrailListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useAuditTrailListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuditTrailListQuery,
    AuditTrailListQueryVariables
  >,
) {
  return Apollo.useQuery<AuditTrailListQuery, AuditTrailListQueryVariables>(
    AuditTrailListDocument,
    baseOptions,
  );
}
export function useAuditTrailListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuditTrailListQuery,
    AuditTrailListQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AuditTrailListQuery, AuditTrailListQueryVariables>(
    AuditTrailListDocument,
    baseOptions,
  );
}
export type AuditTrailListQueryHookResult = ReturnType<
  typeof useAuditTrailListQuery
>;
export type AuditTrailListLazyQueryHookResult = ReturnType<
  typeof useAuditTrailListLazyQuery
>;
export type AuditTrailListQueryResult = Apollo.QueryResult<
  AuditTrailListQuery,
  AuditTrailListQueryVariables
>;
export const BalanceImportOpenPeriodsListDocument = gql`
  query balanceImportOpenPeriodsList(
    $filter: PeriodFilter
    $sortOptions: PeriodSortOptions
  ) {
    periods(filter: $filter, sortOptions: $sortOptions) {
      items {
        ...balanceImportOpenPeriodsListItem
      }
    }
  }
  ${BalanceImportOpenPeriodsListItemFragmentDoc}
`;

/**
 * __useBalanceImportOpenPeriodsListQuery__
 *
 * To run a query within a React component, call `useBalanceImportOpenPeriodsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceImportOpenPeriodsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceImportOpenPeriodsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *   },
 * });
 */
export function useBalanceImportOpenPeriodsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BalanceImportOpenPeriodsListQuery,
    BalanceImportOpenPeriodsListQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceImportOpenPeriodsListQuery,
    BalanceImportOpenPeriodsListQueryVariables
  >(BalanceImportOpenPeriodsListDocument, baseOptions);
}
export function useBalanceImportOpenPeriodsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceImportOpenPeriodsListQuery,
    BalanceImportOpenPeriodsListQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceImportOpenPeriodsListQuery,
    BalanceImportOpenPeriodsListQueryVariables
  >(BalanceImportOpenPeriodsListDocument, baseOptions);
}
export type BalanceImportOpenPeriodsListQueryHookResult = ReturnType<
  typeof useBalanceImportOpenPeriodsListQuery
>;
export type BalanceImportOpenPeriodsListLazyQueryHookResult = ReturnType<
  typeof useBalanceImportOpenPeriodsListLazyQuery
>;
export type BalanceImportOpenPeriodsListQueryResult = Apollo.QueryResult<
  BalanceImportOpenPeriodsListQuery,
  BalanceImportOpenPeriodsListQueryVariables
>;
export const LatestBalanceImportBatchDocument = gql`
  query latestBalanceImportBatch {
    latestBalanceImportBatch {
      id
      importDate
      importedByUser {
        name
      }
      errorSummary
    }
  }
`;

/**
 * __useLatestBalanceImportBatchQuery__
 *
 * To run a query within a React component, call `useLatestBalanceImportBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestBalanceImportBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestBalanceImportBatchQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestBalanceImportBatchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestBalanceImportBatchQuery,
    LatestBalanceImportBatchQueryVariables
  >,
) {
  return Apollo.useQuery<
    LatestBalanceImportBatchQuery,
    LatestBalanceImportBatchQueryVariables
  >(LatestBalanceImportBatchDocument, baseOptions);
}
export function useLatestBalanceImportBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestBalanceImportBatchQuery,
    LatestBalanceImportBatchQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    LatestBalanceImportBatchQuery,
    LatestBalanceImportBatchQueryVariables
  >(LatestBalanceImportBatchDocument, baseOptions);
}
export type LatestBalanceImportBatchQueryHookResult = ReturnType<
  typeof useLatestBalanceImportBatchQuery
>;
export type LatestBalanceImportBatchLazyQueryHookResult = ReturnType<
  typeof useLatestBalanceImportBatchLazyQuery
>;
export type LatestBalanceImportBatchQueryResult = Apollo.QueryResult<
  LatestBalanceImportBatchQuery,
  LatestBalanceImportBatchQueryVariables
>;
export const BalanceImportSummariesForBatchDocument = gql`
  query balanceImportSummariesForBatch(
    $id: Int!
    $sortOptions: BalanceImportSummarySortOptions
    $paginationOptions: PaginationOptions
  ) {
    balanceImportSummariesForBatch(
      id: $id
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...balanceImportSummaryListItem
      }
    }
  }
  ${BalanceImportSummaryListItemFragmentDoc}
`;

/**
 * __useBalanceImportSummariesForBatchQuery__
 *
 * To run a query within a React component, call `useBalanceImportSummariesForBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceImportSummariesForBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceImportSummariesForBatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useBalanceImportSummariesForBatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceImportSummariesForBatchQuery,
    BalanceImportSummariesForBatchQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceImportSummariesForBatchQuery,
    BalanceImportSummariesForBatchQueryVariables
  >(BalanceImportSummariesForBatchDocument, baseOptions);
}
export function useBalanceImportSummariesForBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceImportSummariesForBatchQuery,
    BalanceImportSummariesForBatchQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceImportSummariesForBatchQuery,
    BalanceImportSummariesForBatchQueryVariables
  >(BalanceImportSummariesForBatchDocument, baseOptions);
}
export type BalanceImportSummariesForBatchQueryHookResult = ReturnType<
  typeof useBalanceImportSummariesForBatchQuery
>;
export type BalanceImportSummariesForBatchLazyQueryHookResult = ReturnType<
  typeof useBalanceImportSummariesForBatchLazyQuery
>;
export type BalanceImportSummariesForBatchQueryResult = Apollo.QueryResult<
  BalanceImportSummariesForBatchQuery,
  BalanceImportSummariesForBatchQueryVariables
>;
export const BalanceImportDetailDocument = gql`
  query balanceImportDetail(
    $filter: BalanceImportFilter!
    $sortOptions: BalanceImportSortOptions
    $paginationOptions: PaginationOptions
  ) {
    balanceImports(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...balanceImportDetailListItem
      }
    }
  }
  ${BalanceImportDetailListItemFragmentDoc}
`;

/**
 * __useBalanceImportDetailQuery__
 *
 * To run a query within a React component, call `useBalanceImportDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceImportDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceImportDetailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useBalanceImportDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceImportDetailQuery,
    BalanceImportDetailQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceImportDetailQuery,
    BalanceImportDetailQueryVariables
  >(BalanceImportDetailDocument, baseOptions);
}
export function useBalanceImportDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceImportDetailQuery,
    BalanceImportDetailQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceImportDetailQuery,
    BalanceImportDetailQueryVariables
  >(BalanceImportDetailDocument, baseOptions);
}
export type BalanceImportDetailQueryHookResult = ReturnType<
  typeof useBalanceImportDetailQuery
>;
export type BalanceImportDetailLazyQueryHookResult = ReturnType<
  typeof useBalanceImportDetailLazyQuery
>;
export type BalanceImportDetailQueryResult = Apollo.QueryResult<
  BalanceImportDetailQuery,
  BalanceImportDetailQueryVariables
>;
export const BalanceImportUnrecognizedAccountsDocument = gql`
  query balanceImportUnrecognizedAccounts(
    $filter: BalanceImportUnrecognizedAccountFilter!
    $sortOptions: BalanceImportUnrecognizedAccountSortOptions
    $paginationOptions: PaginationOptions
  ) {
    balanceImportUnrecognizedAccounts(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...balanceImportUnrecognizedAccountsListItem
      }
    }
  }
  ${BalanceImportUnrecognizedAccountsListItemFragmentDoc}
`;

/**
 * __useBalanceImportUnrecognizedAccountsQuery__
 *
 * To run a query within a React component, call `useBalanceImportUnrecognizedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceImportUnrecognizedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceImportUnrecognizedAccountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useBalanceImportUnrecognizedAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceImportUnrecognizedAccountsQuery,
    BalanceImportUnrecognizedAccountsQueryVariables
  >,
) {
  return Apollo.useQuery<
    BalanceImportUnrecognizedAccountsQuery,
    BalanceImportUnrecognizedAccountsQueryVariables
  >(BalanceImportUnrecognizedAccountsDocument, baseOptions);
}
export function useBalanceImportUnrecognizedAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceImportUnrecognizedAccountsQuery,
    BalanceImportUnrecognizedAccountsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BalanceImportUnrecognizedAccountsQuery,
    BalanceImportUnrecognizedAccountsQueryVariables
  >(BalanceImportUnrecognizedAccountsDocument, baseOptions);
}
export type BalanceImportUnrecognizedAccountsQueryHookResult = ReturnType<
  typeof useBalanceImportUnrecognizedAccountsQuery
>;
export type BalanceImportUnrecognizedAccountsLazyQueryHookResult = ReturnType<
  typeof useBalanceImportUnrecognizedAccountsLazyQuery
>;
export type BalanceImportUnrecognizedAccountsQueryResult = Apollo.QueryResult<
  BalanceImportUnrecognizedAccountsQuery,
  BalanceImportUnrecognizedAccountsQueryVariables
>;
export const InitiateBalancesImportDocument = gql`
  mutation initiateBalancesImport {
    initiateBalancesImport {
      batchId
      message
      isError
    }
  }
`;
export type InitiateBalancesImportMutationFn = Apollo.MutationFunction<
  InitiateBalancesImportMutation,
  InitiateBalancesImportMutationVariables
>;

/**
 * __useInitiateBalancesImportMutation__
 *
 * To run a mutation, you first call `useInitiateBalancesImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateBalancesImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateBalancesImportMutation, { data, loading, error }] = useInitiateBalancesImportMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitiateBalancesImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateBalancesImportMutation,
    InitiateBalancesImportMutationVariables
  >,
) {
  return Apollo.useMutation<
    InitiateBalancesImportMutation,
    InitiateBalancesImportMutationVariables
  >(InitiateBalancesImportDocument, baseOptions);
}
export type InitiateBalancesImportMutationHookResult = ReturnType<
  typeof useInitiateBalancesImportMutation
>;
export type InitiateBalancesImportMutationResult =
  Apollo.MutationResult<InitiateBalancesImportMutation>;
export type InitiateBalancesImportMutationOptions = Apollo.BaseMutationOptions<
  InitiateBalancesImportMutation,
  InitiateBalancesImportMutationVariables
>;
export const BatchImportStatusDocument = gql`
  query batchImportStatus($batchId: Int!) {
    batchImportStatus(batchId: $batchId) {
      ...batchImportStatus
    }
  }
  ${BatchImportStatusFragmentDoc}
`;

/**
 * __useBatchImportStatusQuery__
 *
 * To run a query within a React component, call `useBatchImportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchImportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchImportStatusQuery({
 *   variables: {
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useBatchImportStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    BatchImportStatusQuery,
    BatchImportStatusQueryVariables
  >,
) {
  return Apollo.useQuery<
    BatchImportStatusQuery,
    BatchImportStatusQueryVariables
  >(BatchImportStatusDocument, baseOptions);
}
export function useBatchImportStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatchImportStatusQuery,
    BatchImportStatusQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BatchImportStatusQuery,
    BatchImportStatusQueryVariables
  >(BatchImportStatusDocument, baseOptions);
}
export type BatchImportStatusQueryHookResult = ReturnType<
  typeof useBatchImportStatusQuery
>;
export type BatchImportStatusLazyQueryHookResult = ReturnType<
  typeof useBatchImportStatusLazyQuery
>;
export type BatchImportStatusQueryResult = Apollo.QueryResult<
  BatchImportStatusQuery,
  BatchImportStatusQueryVariables
>;
export const ManageAccountsListDocument = gql`
  query manageAccountsList(
    $filter: AccountFilter
    $sortOptions: AccountSortOptions
    $paginationOptions: PaginationOptions
  ) {
    accounts(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...manageAccountsListItem
      }
    }
  }
  ${ManageAccountsListItemFragmentDoc}
`;

/**
 * __useManageAccountsListQuery__
 *
 * To run a query within a React component, call `useManageAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageAccountsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useManageAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManageAccountsListQuery,
    ManageAccountsListQueryVariables
  >,
) {
  return Apollo.useQuery<
    ManageAccountsListQuery,
    ManageAccountsListQueryVariables
  >(ManageAccountsListDocument, baseOptions);
}
export function useManageAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManageAccountsListQuery,
    ManageAccountsListQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ManageAccountsListQuery,
    ManageAccountsListQueryVariables
  >(ManageAccountsListDocument, baseOptions);
}
export type ManageAccountsListQueryHookResult = ReturnType<
  typeof useManageAccountsListQuery
>;
export type ManageAccountsListLazyQueryHookResult = ReturnType<
  typeof useManageAccountsListLazyQuery
>;
export type ManageAccountsListQueryResult = Apollo.QueryResult<
  ManageAccountsListQuery,
  ManageAccountsListQueryVariables
>;
export const AssigneeLookupsDocument = gql`
  query assigneeLookups {
    userLookups {
      ...lookupModel
    }
  }
  ${LookupModelFragmentDoc}
`;

/**
 * __useAssigneeLookupsQuery__
 *
 * To run a query within a React component, call `useAssigneeLookupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssigneeLookupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssigneeLookupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssigneeLookupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssigneeLookupsQuery,
    AssigneeLookupsQueryVariables
  >,
) {
  return Apollo.useQuery<AssigneeLookupsQuery, AssigneeLookupsQueryVariables>(
    AssigneeLookupsDocument,
    baseOptions,
  );
}
export function useAssigneeLookupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssigneeLookupsQuery,
    AssigneeLookupsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AssigneeLookupsQuery,
    AssigneeLookupsQueryVariables
  >(AssigneeLookupsDocument, baseOptions);
}
export type AssigneeLookupsQueryHookResult = ReturnType<
  typeof useAssigneeLookupsQuery
>;
export type AssigneeLookupsLazyQueryHookResult = ReturnType<
  typeof useAssigneeLookupsLazyQuery
>;
export type AssigneeLookupsQueryResult = Apollo.QueryResult<
  AssigneeLookupsQuery,
  AssigneeLookupsQueryVariables
>;
export const AccountUpdateDetailDocument = gql`
  query accountUpdateDetail($id: Int!) {
    account(id: $id) {
      id
      accountNumber {
        name
        description
      }
      subsidiary {
        id
      }
      dueToFromSubsidiary {
        id
      }
      responsible {
        id
      }
      reviewer {
        id
      }
      isReconciled
      isActive
      isAttachmentMandatory
      isIncludedInReminderEmails
    }
  }
`;

/**
 * __useAccountUpdateDetailQuery__
 *
 * To run a query within a React component, call `useAccountUpdateDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountUpdateDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUpdateDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountUpdateDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountUpdateDetailQuery,
    AccountUpdateDetailQueryVariables
  >,
) {
  return Apollo.useQuery<
    AccountUpdateDetailQuery,
    AccountUpdateDetailQueryVariables
  >(AccountUpdateDetailDocument, baseOptions);
}
export function useAccountUpdateDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountUpdateDetailQuery,
    AccountUpdateDetailQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    AccountUpdateDetailQuery,
    AccountUpdateDetailQueryVariables
  >(AccountUpdateDetailDocument, baseOptions);
}
export type AccountUpdateDetailQueryHookResult = ReturnType<
  typeof useAccountUpdateDetailQuery
>;
export type AccountUpdateDetailLazyQueryHookResult = ReturnType<
  typeof useAccountUpdateDetailLazyQuery
>;
export type AccountUpdateDetailQueryResult = Apollo.QueryResult<
  AccountUpdateDetailQuery,
  AccountUpdateDetailQueryVariables
>;
export const BulkUpdateAccountCommonValuesDocument = gql`
  query bulkUpdateAccountCommonValues($accountIds: [Int!]!) {
    bulkUpdateCommonAccountValues(accountIds: $accountIds) {
      accountNumber {
        id
        description
      }
      subsidiary {
        id
      }
      dueToFromSubsidiary {
        id
      }
      responsible {
        id
      }
      reviewer {
        id
      }
      isReconciled
      isActive
      isAttachmentMandatory
      isIncludedInReminderEmails
    }
  }
`;

/**
 * __useBulkUpdateAccountCommonValuesQuery__
 *
 * To run a query within a React component, call `useBulkUpdateAccountCommonValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAccountCommonValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkUpdateAccountCommonValuesQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useBulkUpdateAccountCommonValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    BulkUpdateAccountCommonValuesQuery,
    BulkUpdateAccountCommonValuesQueryVariables
  >,
) {
  return Apollo.useQuery<
    BulkUpdateAccountCommonValuesQuery,
    BulkUpdateAccountCommonValuesQueryVariables
  >(BulkUpdateAccountCommonValuesDocument, baseOptions);
}
export function useBulkUpdateAccountCommonValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BulkUpdateAccountCommonValuesQuery,
    BulkUpdateAccountCommonValuesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    BulkUpdateAccountCommonValuesQuery,
    BulkUpdateAccountCommonValuesQueryVariables
  >(BulkUpdateAccountCommonValuesDocument, baseOptions);
}
export type BulkUpdateAccountCommonValuesQueryHookResult = ReturnType<
  typeof useBulkUpdateAccountCommonValuesQuery
>;
export type BulkUpdateAccountCommonValuesLazyQueryHookResult = ReturnType<
  typeof useBulkUpdateAccountCommonValuesLazyQuery
>;
export type BulkUpdateAccountCommonValuesQueryResult = Apollo.QueryResult<
  BulkUpdateAccountCommonValuesQuery,
  BulkUpdateAccountCommonValuesQueryVariables
>;
export const CreateAccountDocument = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
    }
  }
`;
export type CreateAccountMutationFn = Apollo.MutationFunction<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >(CreateAccountDocument, baseOptions);
}
export type CreateAccountMutationHookResult = ReturnType<
  typeof useCreateAccountMutation
>;
export type CreateAccountMutationResult =
  Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UpdateAccountDocument, baseOptions);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const BulkUpdateAccountsDocument = gql`
  mutation bulkUpdateAccounts($input: BulkUpdateAccountsInput!) {
    bulkUpdateAccounts(input: $input)
  }
`;
export type BulkUpdateAccountsMutationFn = Apollo.MutationFunction<
  BulkUpdateAccountsMutation,
  BulkUpdateAccountsMutationVariables
>;

/**
 * __useBulkUpdateAccountsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAccountsMutation, { data, loading, error }] = useBulkUpdateAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateAccountsMutation,
    BulkUpdateAccountsMutationVariables
  >,
) {
  return Apollo.useMutation<
    BulkUpdateAccountsMutation,
    BulkUpdateAccountsMutationVariables
  >(BulkUpdateAccountsDocument, baseOptions);
}
export type BulkUpdateAccountsMutationHookResult = ReturnType<
  typeof useBulkUpdateAccountsMutation
>;
export type BulkUpdateAccountsMutationResult =
  Apollo.MutationResult<BulkUpdateAccountsMutation>;
export type BulkUpdateAccountsMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateAccountsMutation,
  BulkUpdateAccountsMutationVariables
>;
export const ManagePeriodsListDocument = gql`
  query managePeriodsList(
    $filter: PeriodFilter
    $sortOptions: PeriodSortOptions
    $paginationOptions: PaginationOptions
  ) {
    periods(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...managePeriodsListItem
      }
    }
  }
  ${ManagePeriodsListItemFragmentDoc}
`;

/**
 * __useManagePeriodsListQuery__
 *
 * To run a query within a React component, call `useManagePeriodsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagePeriodsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagePeriodsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useManagePeriodsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManagePeriodsListQuery,
    ManagePeriodsListQueryVariables
  >,
) {
  return Apollo.useQuery<
    ManagePeriodsListQuery,
    ManagePeriodsListQueryVariables
  >(ManagePeriodsListDocument, baseOptions);
}
export function useManagePeriodsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManagePeriodsListQuery,
    ManagePeriodsListQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ManagePeriodsListQuery,
    ManagePeriodsListQueryVariables
  >(ManagePeriodsListDocument, baseOptions);
}
export type ManagePeriodsListQueryHookResult = ReturnType<
  typeof useManagePeriodsListQuery
>;
export type ManagePeriodsListLazyQueryHookResult = ReturnType<
  typeof useManagePeriodsListLazyQuery
>;
export type ManagePeriodsListQueryResult = Apollo.QueryResult<
  ManagePeriodsListQuery,
  ManagePeriodsListQueryVariables
>;
export const InitiatePeriodDocument = gql`
  mutation initiatePeriod($id: Int!) {
    initiatePeriod(id: $id) {
      id
    }
  }
`;
export type InitiatePeriodMutationFn = Apollo.MutationFunction<
  InitiatePeriodMutation,
  InitiatePeriodMutationVariables
>;

/**
 * __useInitiatePeriodMutation__
 *
 * To run a mutation, you first call `useInitiatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePeriodMutation, { data, loading, error }] = useInitiatePeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInitiatePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiatePeriodMutation,
    InitiatePeriodMutationVariables
  >,
) {
  return Apollo.useMutation<
    InitiatePeriodMutation,
    InitiatePeriodMutationVariables
  >(InitiatePeriodDocument, baseOptions);
}
export type InitiatePeriodMutationHookResult = ReturnType<
  typeof useInitiatePeriodMutation
>;
export type InitiatePeriodMutationResult =
  Apollo.MutationResult<InitiatePeriodMutation>;
export type InitiatePeriodMutationOptions = Apollo.BaseMutationOptions<
  InitiatePeriodMutation,
  InitiatePeriodMutationVariables
>;
export const ClosePeriodDocument = gql`
  mutation closePeriod($id: Int!) {
    closePeriod(id: $id) {
      id
    }
  }
`;
export type ClosePeriodMutationFn = Apollo.MutationFunction<
  ClosePeriodMutation,
  ClosePeriodMutationVariables
>;

/**
 * __useClosePeriodMutation__
 *
 * To run a mutation, you first call `useClosePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClosePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closePeriodMutation, { data, loading, error }] = useClosePeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClosePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClosePeriodMutation,
    ClosePeriodMutationVariables
  >,
) {
  return Apollo.useMutation<ClosePeriodMutation, ClosePeriodMutationVariables>(
    ClosePeriodDocument,
    baseOptions,
  );
}
export type ClosePeriodMutationHookResult = ReturnType<
  typeof useClosePeriodMutation
>;
export type ClosePeriodMutationResult =
  Apollo.MutationResult<ClosePeriodMutation>;
export type ClosePeriodMutationOptions = Apollo.BaseMutationOptions<
  ClosePeriodMutation,
  ClosePeriodMutationVariables
>;
export const ReopenPeriodDocument = gql`
  mutation reopenPeriod($id: Int!) {
    reopenPeriod(id: $id) {
      id
    }
  }
`;
export type ReopenPeriodMutationFn = Apollo.MutationFunction<
  ReopenPeriodMutation,
  ReopenPeriodMutationVariables
>;

/**
 * __useReopenPeriodMutation__
 *
 * To run a mutation, you first call `useReopenPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenPeriodMutation, { data, loading, error }] = useReopenPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReopenPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReopenPeriodMutation,
    ReopenPeriodMutationVariables
  >,
) {
  return Apollo.useMutation<
    ReopenPeriodMutation,
    ReopenPeriodMutationVariables
  >(ReopenPeriodDocument, baseOptions);
}
export type ReopenPeriodMutationHookResult = ReturnType<
  typeof useReopenPeriodMutation
>;
export type ReopenPeriodMutationResult =
  Apollo.MutationResult<ReopenPeriodMutation>;
export type ReopenPeriodMutationOptions = Apollo.BaseMutationOptions<
  ReopenPeriodMutation,
  ReopenPeriodMutationVariables
>;
export const UpdatePeriodReportIdsDocument = gql`
  mutation updatePeriodReportIds($input: UpdatePeriodReportIdsInput!) {
    updatePeriodReportIds(input: $input) {
      id
    }
  }
`;
export type UpdatePeriodReportIdsMutationFn = Apollo.MutationFunction<
  UpdatePeriodReportIdsMutation,
  UpdatePeriodReportIdsMutationVariables
>;

/**
 * __useUpdatePeriodReportIdsMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodReportIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodReportIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodReportIdsMutation, { data, loading, error }] = useUpdatePeriodReportIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeriodReportIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePeriodReportIdsMutation,
    UpdatePeriodReportIdsMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdatePeriodReportIdsMutation,
    UpdatePeriodReportIdsMutationVariables
  >(UpdatePeriodReportIdsDocument, baseOptions);
}
export type UpdatePeriodReportIdsMutationHookResult = ReturnType<
  typeof useUpdatePeriodReportIdsMutation
>;
export type UpdatePeriodReportIdsMutationResult =
  Apollo.MutationResult<UpdatePeriodReportIdsMutation>;
export type UpdatePeriodReportIdsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePeriodReportIdsMutation,
  UpdatePeriodReportIdsMutationVariables
>;
export const NumberUnreviewedBalancesForPeriodDocument = gql`
  query numberUnreviewedBalancesForPeriod($periodId: Int!) {
    numberUnreviewedBalancesForPeriod(periodId: $periodId)
  }
`;

/**
 * __useNumberUnreviewedBalancesForPeriodQuery__
 *
 * To run a query within a React component, call `useNumberUnreviewedBalancesForPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberUnreviewedBalancesForPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberUnreviewedBalancesForPeriodQuery({
 *   variables: {
 *      periodId: // value for 'periodId'
 *   },
 * });
 */
export function useNumberUnreviewedBalancesForPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    NumberUnreviewedBalancesForPeriodQuery,
    NumberUnreviewedBalancesForPeriodQueryVariables
  >,
) {
  return Apollo.useQuery<
    NumberUnreviewedBalancesForPeriodQuery,
    NumberUnreviewedBalancesForPeriodQueryVariables
  >(NumberUnreviewedBalancesForPeriodDocument, baseOptions);
}
export function useNumberUnreviewedBalancesForPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NumberUnreviewedBalancesForPeriodQuery,
    NumberUnreviewedBalancesForPeriodQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    NumberUnreviewedBalancesForPeriodQuery,
    NumberUnreviewedBalancesForPeriodQueryVariables
  >(NumberUnreviewedBalancesForPeriodDocument, baseOptions);
}
export type NumberUnreviewedBalancesForPeriodQueryHookResult = ReturnType<
  typeof useNumberUnreviewedBalancesForPeriodQuery
>;
export type NumberUnreviewedBalancesForPeriodLazyQueryHookResult = ReturnType<
  typeof useNumberUnreviewedBalancesForPeriodLazyQuery
>;
export type NumberUnreviewedBalancesForPeriodQueryResult = Apollo.QueryResult<
  NumberUnreviewedBalancesForPeriodQuery,
  NumberUnreviewedBalancesForPeriodQueryVariables
>;
export const NumberBalancesWithMissingMandatoryAttachmentsForPeriodDocument = gql`
  query numberBalancesWithMissingMandatoryAttachmentsForPeriod(
    $periodId: Int!
  ) {
    numberBalancesWithMissingMandatoryAttachmentsForPeriod(periodId: $periodId)
  }
`;

/**
 * __useNumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery__
 *
 * To run a query within a React component, call `useNumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery({
 *   variables: {
 *      periodId: // value for 'periodId'
 *   },
 * });
 */
export function useNumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery,
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables
  >,
) {
  return Apollo.useQuery<
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery,
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables
  >(
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodDocument,
    baseOptions,
  );
}
export function useNumberBalancesWithMissingMandatoryAttachmentsForPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery,
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery,
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables
  >(
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodDocument,
    baseOptions,
  );
}
export type NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryHookResult =
  ReturnType<
    typeof useNumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery
  >;
export type NumberBalancesWithMissingMandatoryAttachmentsForPeriodLazyQueryHookResult =
  ReturnType<
    typeof useNumberBalancesWithMissingMandatoryAttachmentsForPeriodLazyQuery
  >;
export type NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryResult =
  Apollo.QueryResult<
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQuery,
    NumberBalancesWithMissingMandatoryAttachmentsForPeriodQueryVariables
  >;
export const CreatePeriodsDocument = gql`
  mutation createPeriods($totalPeriods: Int!) {
    createPeriods(totalPeriods: $totalPeriods) {
      id
    }
  }
`;
export type CreatePeriodsMutationFn = Apollo.MutationFunction<
  CreatePeriodsMutation,
  CreatePeriodsMutationVariables
>;

/**
 * __useCreatePeriodsMutation__
 *
 * To run a mutation, you first call `useCreatePeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeriodsMutation, { data, loading, error }] = useCreatePeriodsMutation({
 *   variables: {
 *      totalPeriods: // value for 'totalPeriods'
 *   },
 * });
 */
export function useCreatePeriodsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePeriodsMutation,
    CreatePeriodsMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreatePeriodsMutation,
    CreatePeriodsMutationVariables
  >(CreatePeriodsDocument, baseOptions);
}
export type CreatePeriodsMutationHookResult = ReturnType<
  typeof useCreatePeriodsMutation
>;
export type CreatePeriodsMutationResult =
  Apollo.MutationResult<CreatePeriodsMutation>;
export type CreatePeriodsMutationOptions = Apollo.BaseMutationOptions<
  CreatePeriodsMutation,
  CreatePeriodsMutationVariables
>;
export const ReactivatedAccountsListDocument = gql`
  query reactivatedAccountsList(
    $filter: AccountAuditFilter
    $sortOptions: AccountAuditSortOptions
    $paginationOptions: PaginationOptions
  ) {
    accountAudits(
      filter: $filter
      sortOptions: $sortOptions
      paginationOptions: $paginationOptions
    ) {
      totalItems
      items {
        ...reactivatedAccountsListItem
      }
    }
  }
  ${ReactivatedAccountsListItemFragmentDoc}
`;

/**
 * __useReactivatedAccountsListQuery__
 *
 * To run a query within a React component, call `useReactivatedAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReactivatedAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReactivatedAccountsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortOptions: // value for 'sortOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useReactivatedAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReactivatedAccountsListQuery,
    ReactivatedAccountsListQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReactivatedAccountsListQuery,
    ReactivatedAccountsListQueryVariables
  >(ReactivatedAccountsListDocument, baseOptions);
}
export function useReactivatedAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReactivatedAccountsListQuery,
    ReactivatedAccountsListQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReactivatedAccountsListQuery,
    ReactivatedAccountsListQueryVariables
  >(ReactivatedAccountsListDocument, baseOptions);
}
export type ReactivatedAccountsListQueryHookResult = ReturnType<
  typeof useReactivatedAccountsListQuery
>;
export type ReactivatedAccountsListLazyQueryHookResult = ReturnType<
  typeof useReactivatedAccountsListLazyQuery
>;
export type ReactivatedAccountsListQueryResult = Apollo.QueryResult<
  ReactivatedAccountsListQuery,
  ReactivatedAccountsListQueryVariables
>;
export const UpdateReconciledBalanceDocument = gql`
  mutation updateReconciledBalance($id: Int!, $reconciledBalance: Float) {
    updateReconciledBalance(id: $id, reconciledBalance: $reconciledBalance) {
      id
    }
  }
`;
export type UpdateReconciledBalanceMutationFn = Apollo.MutationFunction<
  UpdateReconciledBalanceMutation,
  UpdateReconciledBalanceMutationVariables
>;

/**
 * __useUpdateReconciledBalanceMutation__
 *
 * To run a mutation, you first call `useUpdateReconciledBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReconciledBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReconciledBalanceMutation, { data, loading, error }] = useUpdateReconciledBalanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reconciledBalance: // value for 'reconciledBalance'
 *   },
 * });
 */
export function useUpdateReconciledBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReconciledBalanceMutation,
    UpdateReconciledBalanceMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateReconciledBalanceMutation,
    UpdateReconciledBalanceMutationVariables
  >(UpdateReconciledBalanceDocument, baseOptions);
}
export type UpdateReconciledBalanceMutationHookResult = ReturnType<
  typeof useUpdateReconciledBalanceMutation
>;
export type UpdateReconciledBalanceMutationResult =
  Apollo.MutationResult<UpdateReconciledBalanceMutation>;
export type UpdateReconciledBalanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateReconciledBalanceMutation,
  UpdateReconciledBalanceMutationVariables
>;
export const ToggleIsBalanceReconciliationCompleteDocument = gql`
  mutation toggleIsBalanceReconciliationComplete($id: Int!) {
    toggleIsBalanceReconciliationComplete(id: $id) {
      id
    }
  }
`;
export type ToggleIsBalanceReconciliationCompleteMutationFn =
  Apollo.MutationFunction<
    ToggleIsBalanceReconciliationCompleteMutation,
    ToggleIsBalanceReconciliationCompleteMutationVariables
  >;

/**
 * __useToggleIsBalanceReconciliationCompleteMutation__
 *
 * To run a mutation, you first call `useToggleIsBalanceReconciliationCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIsBalanceReconciliationCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIsBalanceReconciliationCompleteMutation, { data, loading, error }] = useToggleIsBalanceReconciliationCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleIsBalanceReconciliationCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleIsBalanceReconciliationCompleteMutation,
    ToggleIsBalanceReconciliationCompleteMutationVariables
  >,
) {
  return Apollo.useMutation<
    ToggleIsBalanceReconciliationCompleteMutation,
    ToggleIsBalanceReconciliationCompleteMutationVariables
  >(ToggleIsBalanceReconciliationCompleteDocument, baseOptions);
}
export type ToggleIsBalanceReconciliationCompleteMutationHookResult =
  ReturnType<typeof useToggleIsBalanceReconciliationCompleteMutation>;
export type ToggleIsBalanceReconciliationCompleteMutationResult =
  Apollo.MutationResult<ToggleIsBalanceReconciliationCompleteMutation>;
export type ToggleIsBalanceReconciliationCompleteMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleIsBalanceReconciliationCompleteMutation,
    ToggleIsBalanceReconciliationCompleteMutationVariables
  >;
export const ToggleIsBalanceReviewCompleteDocument = gql`
  mutation toggleIsBalanceReviewComplete($id: Int!) {
    toggleIsBalanceReviewComplete(id: $id) {
      id
    }
  }
`;
export type ToggleIsBalanceReviewCompleteMutationFn = Apollo.MutationFunction<
  ToggleIsBalanceReviewCompleteMutation,
  ToggleIsBalanceReviewCompleteMutationVariables
>;

/**
 * __useToggleIsBalanceReviewCompleteMutation__
 *
 * To run a mutation, you first call `useToggleIsBalanceReviewCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIsBalanceReviewCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIsBalanceReviewCompleteMutation, { data, loading, error }] = useToggleIsBalanceReviewCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleIsBalanceReviewCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleIsBalanceReviewCompleteMutation,
    ToggleIsBalanceReviewCompleteMutationVariables
  >,
) {
  return Apollo.useMutation<
    ToggleIsBalanceReviewCompleteMutation,
    ToggleIsBalanceReviewCompleteMutationVariables
  >(ToggleIsBalanceReviewCompleteDocument, baseOptions);
}
export type ToggleIsBalanceReviewCompleteMutationHookResult = ReturnType<
  typeof useToggleIsBalanceReviewCompleteMutation
>;
export type ToggleIsBalanceReviewCompleteMutationResult =
  Apollo.MutationResult<ToggleIsBalanceReviewCompleteMutation>;
export type ToggleIsBalanceReviewCompleteMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleIsBalanceReviewCompleteMutation,
    ToggleIsBalanceReviewCompleteMutationVariables
  >;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
