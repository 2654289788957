import { utils, writeFile } from 'xlsx';
import { IDataTableColumnDefinition } from './IDataTableColumnDefinition';

export const downloadExcel = <
  T extends Record<string, any>,
  TSortBy = any,
>(args: {
  filename: string;
  columns: IDataTableColumnDefinition<T, TSortBy>[];
  data: T[];
  onDone?: () => void;
}): Promise<void> => {
  let resolve: ((value?: void) => void) | undefined;
  let reject: ((reason?: any) => void) | undefined;

  const result = new Promise<void>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  try {
    const excelContent: any[][] = [];

    excelContent.push(
      args.columns.map(
        (column: IDataTableColumnDefinition<T, TSortBy>) => column.heading,
      ),
    );

    args.data.forEach((row: T) =>
      excelContent.push(
        args.columns.map((column: IDataTableColumnDefinition<T, TSortBy>) =>
          column.excelExport
            ? column.excelExport(row)
            : row[column.dataFieldName],
        ),
      ),
    );

    const workSheet = utils.aoa_to_sheet(excelContent);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, 'Sheet1');

    writeFile(workBook, args.filename);
    if (resolve) resolve();
  } catch (ex) {
    if (reject) reject(ex);
  } finally {
    if (args.onDone) {
      args.onDone();
    }
  }

  return result;
};
