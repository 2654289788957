import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { zeroTime } from 'ar-common/lib/utils/date-utils';
import faker from 'faker';
import React from 'react';

export interface IDatePickerProps {
  value?: ParsableDate;
  disabled?: boolean;
  onChange?: (value: Date | null) => void;
}

export const DatePicker = (props: IDatePickerProps): JSX.Element => {
  const handleChange = (value: Date | null) => {
    if (props.onChange) {
      props.onChange(zeroTime(value));
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={`date-picker-${faker.random.alphaNumeric(10)}`} // randomize to throw off browser auto-fill of fields
        disabled={props.disabled}
        autoOk={true}
        variant="inline"
        inputVariant="outlined"
        value={props.value}
        format="MM-dd-yyyy"
        InputAdornmentProps={{ position: 'end' }}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
};
