import { DocumentNode } from 'graphql';
import React from 'react';
import {
  AccountLookupsDocument,
  AccountLookupsQuery,
  AccountLookupsQueryVariables,
  LookupModel,
} from 'types/graphql';

interface IProps {
  take: number;
  includeIds?: number[];
  isActive?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: AccountLookupsQueryVariables;
    extractLookups: (query: AccountLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const AccountLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: AccountLookupsDocument,
        queryVariables: {
          take: props.take,
          includeIds: props.includeIds,
          isActive: props.isActive,
        },
        extractLookups: (query) => query.accountLookups,
      })}
    </>
  );
};
