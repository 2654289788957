import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Button';
import React from 'react';
import { Modal } from 'react-bootstrap';

export interface IAddEditModalProps {
  children: any;
  className?: string;
  size?: 'sm' | 'lg' | 'xl';
  heading: string;
  readOnly: boolean;
  saveButtonLabel?: string;
  onCancel: () => void;
  onSave: () => Promise<void>;
}

export const StandardModal: React.FC<IAddEditModalProps> = (
  props: IAddEditModalProps,
) => {
  const handleClose = (): void => {
    props.onCancel();
  };

  return (
    <Modal
      show={true}
      className={`add-edit-modal ${props.className ?? ''}`}
      size={props.size}
    >
      <Modal.Header>
        <div className="row col justify-content-between ml-1 mr-2">
          <div className="w-75 text-wrap">
            <h1>{props.heading}</h1>
          </div>
          <div className="row pointer" onClick={handleClose}>
            <div>Cancel</div>
            <div className="ml-2">
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          <hr />
          {props.children}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col">
          <div>
            <hr />
          </div>
          <div className="text-center">
            <Button
              variant="primary"
              size="lg"
              className="text-uppercase font-weight-bold"
              onClick={props.readOnly ? props.onCancel : props.onSave}
            >
              {props.readOnly ? 'Close' : props.saveButtonLabel ?? 'Save'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
