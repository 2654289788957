import { PeriodStatusEnum } from 'ar-common/lib/period-status.enum';
import { useAuthContext } from 'auth/AuthContext';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { CheckBlock } from 'components/Form/CheckBlock';
import React, { useState } from 'react';
import {
  ToggleIsBalanceReviewCompleteDocument,
  ToggleIsBalanceReviewCompleteMutation,
  ToggleIsBalanceReviewCompleteMutationVariables,
  ViewAccountsListItemFragment,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { BalanceStatusEnum } from 'ar-common';

export interface ICompleteCheckBlockProps {
  balance: ViewAccountsListItemFragment;
  onBalancesUpdated: (updatedBalanceIds: number[]) => void;
  readonly?: boolean;
}

export const ReviewCompleteCheckBlock: React.FC<ICompleteCheckBlockProps> = (
  props: ICompleteCheckBlockProps,
) => {
  const auth = useAuthContext();
  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { success, apolloError } = useModalAlerts(setAlertModal);

  const canToggleComplete = (): boolean => {
    const isPeriodOpen: boolean =
      props.balance.period?.periodStatus.id === PeriodStatusEnum.Open;
    if (!isPeriodOpen) {
      return false;
    }

    const isCurrentUserTheReviewer: boolean =
      auth.user?.userId === props.balance.reviewer?.id;
    const isCurrentUserAReviewer: boolean =
      auth.user?.roles.includes(
        process.env.REACT_APP_REVIEWER_ROLE_NAME ?? '',
      ) ?? false;
    const isCurrentUserSystemAdmin: boolean =
      auth.user?.roles.includes(process.env.REACT_APP_ADMIN_ROLE_NAME ?? '') ??
      false;

    return (
      isCurrentUserTheReviewer ||
      isCurrentUserAReviewer ||
      isCurrentUserSystemAdmin
    );
  };

  const executeToggle = () => {
    client
      .mutate<
        ToggleIsBalanceReviewCompleteMutation,
        ToggleIsBalanceReviewCompleteMutationVariables
      >({
        mutation: ToggleIsBalanceReviewCompleteDocument,
        fetchPolicy: 'no-cache',
        variables: {
          id: props.balance.id,
        },
      })
      .then(() => {
        props.onBalancesUpdated([props.balance.id]);
        success({
          message: `Balance review ${
            !!props.balance.reviewCompletedBy ? 'undone' : 'completed'
          }`,
        });
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const handleToggle = () => {
    executeToggle();
  };

  return (
    <>
      {alertModal}
      <CheckBlock
        checked={!!props.balance.reviewCompletedBy}
        onCheckedChange={handleToggle}
        disabled={!canToggleComplete()}
        readonly={props.readonly}
        title={
          props.balance.balanceStatus.id !==
          BalanceStatusEnum.ReconciliationComplete
            ? 'Balance has not yet been prepared'
            : undefined
        }
      />
    </>
  );
};
