import React from 'react';
import Avatar from 'components/Avatar';
import Clearfix from 'components/Clearfix/Clearfix';
import {
  isoDateTimeStringToLocalDateObject,
  standardFormatDate,
} from 'ar-common/lib/utils/date-utils';
import CommentInput from './CommentInput';
import TextAreaInput from 'components/Form/TextAreaInput';
import { IComment } from './IComment';
import { format } from 'date-fns';
import { Button, Modal } from 'react-bootstrap';

interface ICommentContentProps {
  comment: IComment;
  onUpdateComment: (commentId: number, body: string) => Promise<boolean>;
  onDeleteComment: (commentId: number) => Promise<boolean>;
}

interface ICommentContentState {
  showEditInput: boolean;
  editBody: string;
  showDeleteConfirmation: boolean;
}

export default class CommentContent extends React.PureComponent<
  ICommentContentProps,
  ICommentContentState
> {
  constructor(props: ICommentContentProps) {
    super(props);
    this.state = {
      showEditInput: false,
      editBody: '',
      showDeleteConfirmation: false,
    };
  }

  public render = () => {
    const { comment } = this.props;

    let postedDate = comment.date;
    if (typeof postedDate === 'string') {
      postedDate = isoDateTimeStringToLocalDateObject(comment.date as string);
    }

    let editedDate = comment.editedDate;
    if (typeof editedDate === 'string') {
      editedDate = isoDateTimeStringToLocalDateObject(
        comment.editedDate as string,
      );
    }

    return (
      <div id={comment.id?.toString()} className="comment-content-wrapper">
        <div className="avatar-wrapper">
          <Avatar name={comment.user.name || '(no name)'} size="2em" />
        </div>
        <div className="comment-detail-wrapper">
          <div className="comment-heading">
            <span className="comment-posted-by-name">{comment.user.name}</span>{' '}
            :{' '}
            <span className="comment-posted-date">
              {this.getHowLongAgo(postedDate)}
            </span>
            {!!editedDate && (
              <span className="comment-edited-date">
                <i title={this.getHowLongAgo(editedDate)}>(edited)</i>
              </span>
            )}
            {!!comment.period && (
              <span style={{ float: 'right' }}>
                Period: <b>{this.formatPeriodDisplay(comment)}</b>
              </span>
            )}
          </div>
          <div className="comment-display">
            <TextAreaInput value={comment.body} />
          </div>
          <ul className="comment-actions-list">
            {comment.isEditable && (
              <li className="comment-action-list-item">
                <span onClick={this.handleEditOnClick}>Edit</span>
              </li>
            )}
            {comment.isDeletable && (
              <li className="comment-action-list-item">
                <span onClick={this.handleDeleteOnClick}>Delete</span>
              </li>
            )}
          </ul>
          <Clearfix />
          <CommentInput
            visible={this.state.showEditInput}
            onSave={this.handleSaveUpdate}
            onCancel={this.handleCancelEdit}
            onChange={(params) => {
              this.setState({
                editBody: params.body,
              });
            }}
            body={this.state.editBody}
            saveButtonText="Save"
          />
          <Modal
            size="sm"
            show={this.state.showDeleteConfirmation}
            centered={true}
            scrollable={false}
            onHide={this.handleCancelDeleteOnClick}
          >
            <Modal.Header closeButton={true}>
              <Modal.Title>Delete Comment</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Are you sure you wish to delete this comment?</p>
              <div className="text-right">
                <Button
                  className="mr-3"
                  onClick={this.handleCancelDeleteOnClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={this.handleConfirmDeleteOnClick}
                >
                  Delete
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  };

  private getHowLongAgo = (postedDate: Date | null | undefined) => {
    if (!postedDate) {
      return '';
    }

    return standardFormatDate(postedDate);
  };

  private formatPeriodDisplay = (comment: IComment) => {
    if (!comment.period) {
      return '';
    }

    return format(
      new Date(comment.period.year, comment.period.month - 1),
      'MMMM, yyyy',
    );
  };

  private handleEditOnClick = () => {
    this.setState({
      showEditInput: true,
      editBody: this.props.comment.body,
    });
  };

  private handleDeleteOnClick = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  private handleCancelDeleteOnClick = () => {
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  private handleConfirmDeleteOnClick = () => {
    if (this.props.onDeleteComment) {
      this.props.onDeleteComment(this.props.comment.id).then(() => {
        this.setState({
          showDeleteConfirmation: false,
        });
      });
    }
  };

  private handleSaveUpdate = async (): Promise<boolean> => {
    if (this.props.onUpdateComment) {
      const succeeded = await this.props.onUpdateComment(
        this.props.comment.id,
        this.state.editBody,
      );
      if (!succeeded) {
        return false;
      }

      this.setState({
        showEditInput: false,
      });

      return true;
    }

    return true;
  };

  private handleCancelEdit = () => {
    this.setState({
      showEditInput: false,
    });
  };
}
