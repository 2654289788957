import React from 'react';

export interface LegendItemProps {
  label: string;
  backgroundClassName: string;
}

export const LegendItem: React.FC<LegendItemProps> = ({
  label,
  backgroundClassName,
}: LegendItemProps) => {
  return (
    <div className="d-flex align-items-center mr-4">
      <div
        className={backgroundClassName}
        style={{ border: '1px solid black', width: '1em', height: '1em' }}
      />
      <div className="ml-1">{label}</div>
    </div>
  );
};
