import AuthorizedLayout from 'layouts/AuthorizedLayout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SecuredRoute from 'layouts/components/SecuredRoute';
import { apiBaseUrl } from 'utils/site-util';
import { ViewAccounts } from 'views/ViewAccounts';
import { ManageAccounts } from 'views/ManageAccounts';
import { ManagePeriods } from 'views/ManagePeriods';
import { AuditTrail } from 'views/AuditTrail';
import { AccountStatusReport } from 'views/AccountStatusReport';
import { ImportBalances } from 'views/ImportBalances';
import MainLayout from 'layouts/MainLayout';
import { ReactivatedAccounts } from 'views/ReactivatedAccounts';

const Routes = (): JSX.Element => {
  return (
    <MainLayout>
      <Switch>
        <Route path="/healthz">ok</Route>
        <Route
          path="/login"
          component={() => {
            window.location.href = `${apiBaseUrl()}/auth/login`;
            return <>Redirecting to {apiBaseUrl()}</>;
          }}
        />
        <AuthorizedLayout>
          <Switch>
            {/*<Redirect exact from="/" to="/view-accounts" />*/}

            <Route path="/view-accounts">
              <ViewAccounts />
            </Route>

            <SecuredRoute
              roles={[process.env.REACT_APP_ADMIN_ROLE_NAME ?? '']}
              path="/manage-accounts"
            >
              <ManageAccounts />
            </SecuredRoute>
            <SecuredRoute
              roles={[process.env.REACT_APP_ADMIN_ROLE_NAME ?? '']}
              path="/manage-periods"
            >
              <ManagePeriods />
            </SecuredRoute>
            <SecuredRoute path="/audit-trail">
              <AuditTrail />
            </SecuredRoute>
            <SecuredRoute path="/account-status">
              <AccountStatusReport />
            </SecuredRoute>
            <SecuredRoute
              roles={[process.env.REACT_APP_ADMIN_ROLE_NAME ?? '']}
              path="/import-balances"
            >
              <ImportBalances />
            </SecuredRoute>
            <SecuredRoute path="/reactivated-accounts">
              <ReactivatedAccounts />
            </SecuredRoute>
          </Switch>
        </AuthorizedLayout>
      </Switch>
    </MainLayout>
  );
};

export default Routes;
