import { BalanceStatusEnum } from 'ar-common';
import { DocumentNode } from 'graphql';
import React from 'react';
import {
  BalanceLookupDocument,
  BalanceLookupQuery,
  BalanceLookupQueryVariables,
  LookupModel,
} from 'types/graphql';

interface IProps {
  periodId: number;
  includeIds?: number[];
  readOnly?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: BalanceLookupQueryVariables;
    extractLookups: (query: BalanceLookupQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const BalanceLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: BalanceLookupDocument,
        queryVariables: {
          filter: {
            periodId: props.periodId,
            idIn: props.includeIds,
          },
        },
        extractLookups: (query) =>
          query.balances.items.map((item) => ({
            id: item.id,
            name: `${item.subsidiary.name}/${item.accountNumber}${
              !!item.accountNumberDescription
                ? ` (${item.accountNumberDescription})`
                : ''
            }${
              props.readOnly ||
              item.balanceStatus.id === BalanceStatusEnum.Incomplete
                ? ''
                : ` [${item.balanceStatus.name}]`
            }`,
          })),
      })}
    </>
  );
};
