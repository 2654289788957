import React from 'react';

import { ErrorResponse } from 'apollo-link-error';
import { ServerError } from 'apollo-link-http-common';
import {
  isForbiddenErrorResponse,
  isUnauthorizedErrorResponse,
} from './errorResponseHelper';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import './Alerts.scss';
import { AlertModal, IAlertModalProps } from './AlertModal';

export interface IAlertOptions {
  title?: string | null;
  label?: string | null;
  message?: React.ReactElement | string | null;
  onConfirm?: () => Promise<void> | null;
  onCancel?: () => Promise<void> | null;
  variant?: 'danger' | 'warning' | 'success' | 'primary';
}

export interface IErrorsAlertOptions extends IAlertOptions {
  errors: string[];
}

export interface ISuccessAlertOptions extends IAlertOptions {
  timeout?: number | null;
}

export interface IApolloErrorOptions extends IAlertOptions {
  error: ErrorResponse;
}

const renderModal = (props: IAlertModalProps) => {
  return <AlertModal {...props} />;
};

const useModalAlerts = (
  setAlertModal: (modal: React.ReactNode | null) => void,
): any => {
  const clickAndClose =
    (onOkClick?: () => void | Promise<void> | null) => async () => {
      // Prevent the function from being called more than once in case where the
      // user clicks the "ok" button at the same time the timeout goes to execute it
      let executed = false;
      if (executed) {
        return;
      }
      executed = true;

      setAlertModal(null);

      if (onOkClick) {
        await onOkClick();
      }
    };

  const error = (props: IAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label ?? 'An error has been encountered',
        icon: faExclamationTriangle,
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'danger',
      }),
    );
  };

  const errors = (props: IErrorsAlertOptions) => {
    const body = (
      <div>
        {props.message && <p>{props.message}</p>}
        <ul>
          {props.errors.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    );

    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label ?? 'The following errors have been encountered',
        icon: faExclamationTriangle,
        body,
        onCloseClick: clickAndClose(),
        variant: 'danger',
      }),
    );
  };

  const apolloError = (props: IApolloErrorOptions) => {
    console.error(props.error);

    const { graphQLErrors, networkError } = props.error;

    const validationErrors: string[] = [];
    if (!!graphQLErrors?.length) {
      validationErrors.push(
        ...graphQLErrors
          .flatMap((error) => {
            const message =
              error.extensions?.response?.message ??
              // error.extensions?.exception?.response?.message ??
              error.message;
            if (Array.isArray(message)) {
              return error.extensions?.response?.message?.filter(
                (message: string) => !!message,
              );
            } else {
              return message;
            }
          })
          .filter((m) => !!m),
      );
    }

    if (validationErrors.length) {
      errors({
        errors: validationErrors,
      });
      return;
    } else if (isUnauthorizedErrorResponse(props.error)) {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Unauthorized',
          body:
            props.message ??
            props.error.networkError?.message ??
            'You must log in to access this resource',
          onOkClick: clickAndClose(),
          variant: 'danger',
        }),
      );

      return;
    } else if (isForbiddenErrorResponse(props.error)) {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Forbidden',
          body:
            props.message ??
            'You do not have permission to access this resource',
          onOkClick: clickAndClose(),
          variant: 'danger',
        }),
      );

      return;
    }

    // The networkError can be one of 3 different types.
    // A ServerError is encountered for example if a mutation is
    // sent to the server but maybe an invalid input field is specified
    // or a required field was not provided or if there is no subfields
    // specified to return in the result.
    //const error = networkError as Error;
    const serverError = networkError as ServerError;
    //const serverParseError = networkError as ServerParseError;

    const authError: string | null = null;

    const body = (
      <div>
        {props.message && <p>{props.message}</p>}
        {authError && <p>{authError}</p>}
        {!!(validationErrors && validationErrors.length) && (
          <>
            <label>Validation Errors</label>
            <ul>
              {validationErrors.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </>
        )}
        {!!(graphQLErrors && graphQLErrors.length) && (
          <ul style={{ display: 'none' }}>
            {graphQLErrors.map((graphQLError, index) => (
              <li key={index}>{graphQLError.message}</li>
            ))}
          </ul>
        )}
        {!!serverError?.result?.errors && (
          <>
            <label>Server Errors</label>
            <ul>
              {serverError.result.errors.map(
                (error: { message: string }, index: number) => (
                  <li key={index}>{error.message}</li>
                ),
              )}
            </ul>
          </>
        )}

        {(networkError as ServerError)?.statusCode && (
          <p>status: {(networkError as ServerError)?.statusCode}</p>
        )}
      </div>
    );

    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        body,
        icon: faExclamationTriangle,
        onOkClick: clickAndClose(),
        variant: 'danger',
      }),
    );
  };

  const warning = (props: IAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Warning',
        icon: faExclamationCircle,
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'warning',
      }),
    );
  };

  const success = (props: ISuccessAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Success',
        icon: faCheckCircle,
        label: 'The operation was successful',
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'success',
      }),
    );

    setTimeout(() => {
      setAlertModal(null);
    }, props.timeout || 1200);
  };

  const confirm = (props: IAlertOptions) => {
    return new Promise<void>((resolve, reject) => {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Confirmation',
          icon: faQuestionCircle,
          body: props.message ?? '',
          onOkClick: () => {
            clickAndClose(props.onConfirm)();
            resolve();
          },
          onCancelClick: () => {
            clickAndClose(props.onCancel)();
            reject('cancelled');
          },
          variant: props.variant ?? 'primary',
        }),
      );
    });
  };

  return {
    apolloError,
    confirm,
    error,
    errors,
    success,
    warning,
  };
};

export default useModalAlerts;
