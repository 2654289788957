import { Button } from 'components/Button';
import { IChipOption } from 'components/ChipSelect';
import { SubsidiaryChipSelect } from 'components/Lookups/SubsidiaryLookups/SubsidiaryChipSelect';
import { BooleanSelect } from 'components/Lookups/BooleanLookup/BooleanSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useState } from 'react';
import { AccountNumberChipSelect } from 'components/Lookups/AccountNumberLookups/AccountNumberChipSelect';
import { NonQueryChipSelect } from 'components/Lookups/NonQueryLookups/NonQueryChipSelect';
import { LookupModelFragment } from 'types/graphql';

export interface IManageAssigneesFilterValues {
  subsidiaryIds?: number[];
  dueToFromSubsidiaryIds?: number[];
  accountNumberIds?: number[];
  isReconciled?: boolean;
  isActive?: boolean;
  isAttachmentMandatory?: boolean;
  isIncludedInReminderEmails?: boolean;
  responsibleIds?: number[];
  reviewerIds?: number[];
}

interface IProps {
  assigneeLookups: LookupModelFragment[];
  filterValues?: IManageAssigneesFilterValues;
  refreshAccountNumbersTrigger: number;
  onSearch: (filterValues: IManageAssigneesFilterValues) => Promise<void>;
}

export const ManageAccountsFilters = (props: IProps): JSX.Element => {
  const [isSearching, setIsSearching] = useState(false);

  const [selectedSubsidiaryIds, setSelectedSubsidiaryIds] = useState<number[]>(
    [],
  );
  const [selectedDueToFromSubsidiaryIds, setSelectedDueToFromSubsidiaryIds] =
    useState<number[]>([]);
  const [selectedAccountNumberIds, setSelectedAccountNumberIds] = useState<
    number[]
  >([]);
  const [selectedIsReconciled, setSelectedIsReconciled] = useState<
    boolean | undefined
  >();
  const [selectedIsActive, setSelectedIsActive] = useState<
    boolean | undefined
  >();
  const [selectedIsAttachmentMandatory, setSelectedIsAttachmentMandatory] =
    useState<boolean | undefined>();
  const [
    selectedIsIncludedInEmailReminders,
    setSelectedIsIncludedInEmailReminders,
  ] = useState<boolean | undefined>();
  const [selectedResponsibleIds, setSelectedResponsibleIds] = useState<
    number[]
  >([]);
  const [selectedReviewerIds, setSelectedReviewerIds] = useState<number[]>([]);

  const handleSubsidiaryFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedSubsidiaryIds(options.map((o) => parseInt(o.id)));
  };

  const handleDueToFromSubsidiaryFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    setSelectedDueToFromSubsidiaryIds(options.map((o) => parseInt(o.id)));
  };

  const handleAccountNumberFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    setSelectedAccountNumberIds(options.map((o) => parseInt(o.id)));
  };

  const handleIsReconciledFilterChange = (value: boolean | undefined) => {
    setSelectedIsReconciled(value);
  };

  const handleIsActiveFilterChange = (value: boolean | undefined) => {
    setSelectedIsActive(value);
  };

  const handleResponsibleFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedResponsibleIds(options.map((o) => parseInt(o.id)));
  };

  const handleReviewerFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedReviewerIds(options.map((o) => parseInt(o.id)));
  };

  const handleIsAttachmentMandatoryFilterChange = (
    value: boolean | undefined,
  ) => {
    setSelectedIsAttachmentMandatory(value);
  };

  const handleIsIncludedInEmailRemindersFilterChange = (
    value: boolean | undefined,
  ) => {
    setSelectedIsIncludedInEmailReminders(value);
  };

  const handleClearSearchClick = () => {
    setSelectedSubsidiaryIds([]);
    setSelectedDueToFromSubsidiaryIds([]);
    setSelectedAccountNumberIds([]);
    setSelectedIsReconciled(undefined);
    setSelectedIsActive(undefined);
    setSelectedIsAttachmentMandatory(undefined);
    setSelectedIsIncludedInEmailReminders(undefined);
    setSelectedResponsibleIds([]);
    setSelectedReviewerIds([]);
  };

  const handleSearchClick = () => {
    search();
  };

  const search = () => {
    setIsSearching(true);
    props
      .onSearch({
        subsidiaryIds: selectedSubsidiaryIds.length
          ? selectedSubsidiaryIds
          : undefined,
        dueToFromSubsidiaryIds: selectedDueToFromSubsidiaryIds.length
          ? selectedDueToFromSubsidiaryIds
          : undefined,
        accountNumberIds: selectedAccountNumberIds.length
          ? selectedAccountNumberIds
          : undefined,
        isReconciled:
          selectedIsReconciled !== undefined ? selectedIsReconciled : undefined,
        isActive: selectedIsActive !== undefined ? selectedIsActive : undefined,
        isAttachmentMandatory:
          selectedIsAttachmentMandatory !== undefined
            ? selectedIsAttachmentMandatory
            : undefined,
        isIncludedInReminderEmails:
          selectedIsIncludedInEmailReminders !== undefined
            ? selectedIsIncludedInEmailReminders
            : undefined,
        responsibleIds: selectedResponsibleIds.length
          ? selectedResponsibleIds
          : undefined,
        reviewerIds: selectedReviewerIds.length
          ? selectedReviewerIds
          : undefined,
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  return (
    <>
      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn>Subsidiary</FormLabelColumn>
            <Column>
              <SubsidiaryChipSelect
                selectedIds={selectedSubsidiaryIds}
                onChange={handleSubsidiaryFilterChanged}
                placeholder="All Subsidiaries"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Account</FormLabelColumn>
            <Column>
              <AccountNumberChipSelect
                selectedIds={selectedAccountNumberIds}
                onChange={handleAccountNumberFilterChanged}
                placeholder="All Accounts"
                refreshAccountNumbersTrigger={
                  props.refreshAccountNumbersTrigger
                }
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Due To/From Subsidiary</FormLabelColumn>
            <Column>
              <SubsidiaryChipSelect
                selectedIds={selectedDueToFromSubsidiaryIds}
                onChange={handleDueToFromSubsidiaryFilterChanged}
                placeholder="All Subsidiaries"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Reconcile</FormLabelColumn>
            <Column>
              <BooleanSelect
                trueLabel="Yes"
                falseLabel="No"
                isChecked={selectedIsReconciled}
                onCheck={handleIsReconciledFilterChange}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Active</FormLabelColumn>
            <Column>
              <BooleanSelect
                trueLabel="Yes"
                falseLabel="No"
                isChecked={selectedIsActive}
                onCheck={handleIsActiveFilterChange}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Mandatory Attachment</FormLabelColumn>
            <Column>
              <BooleanSelect
                trueLabel="Yes"
                falseLabel="No"
                isChecked={selectedIsAttachmentMandatory}
                onCheck={handleIsAttachmentMandatoryFilterChange}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Include in Reminder Email</FormLabelColumn>
            <Column>
              <BooleanSelect
                trueLabel="Yes"
                falseLabel="No"
                isChecked={selectedIsIncludedInEmailReminders}
                onCheck={handleIsIncludedInEmailRemindersFilterChange}
              />
            </Column>
          </div>
        </FieldSetColumn>
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={3}>Responsible</FormLabelColumn>
            <Column>
              <NonQueryChipSelect
                lookups={props.assigneeLookups.map((l) => ({
                  id: l.id,
                  name: `${l.name}`.trim() || '(no name)',
                  isDeleted: false,
                }))}
                selectedIds={selectedResponsibleIds}
                onChange={handleResponsibleFilterChanged}
                placeholder="All Responsibles"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>Reviewer</FormLabelColumn>
            <Column>
              <NonQueryChipSelect
                lookups={props.assigneeLookups.map((l) => ({
                  id: l.id,
                  name: `${l.name}`.trim() || '(no name)',
                  isDeleted: false,
                }))}
                selectedIds={selectedReviewerIds}
                onChange={handleReviewerFilterChanged}
                placeholder="All Reviewers"
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row">
        <Column className="text-right mt-3">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            disabled={isSearching}
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            disabled={isSearching}
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
    </>
  );
};
