import { BalanceStatusEnum, PeriodStatusEnum } from 'ar-common';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { IFileAttachment } from 'components/FileAttachmentInputWrapper/IFileAttachment';
import {
  getFirstAndLastOpenPeriods,
  getPeriodById,
} from 'components/Lookups/PeriodLookups/PeriodLookupUtility';
import { PeriodSelect } from 'components/Lookups/PeriodLookups/PeriodSelect';
import { RequiredMarker } from 'components/RequiredMarker/RequiredMarker';
import { StandardModal } from 'components/StandardModal/StandardModal';
import { format } from 'date-fns';
import { Column } from 'layouts/components/Grid/Column';
import { FieldSetColumn } from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useEffect, useState } from 'react';
import {
  AddBalanceFileAttachmentsDocument,
  AddBalanceFileAttachmentsMutation,
  AddBalanceFileAttachmentsMutationVariables,
  BalanceFileAttachmentModalAttachmentListForBalanceDocument,
  BalanceFileAttachmentModalAttachmentListForBalanceQuery,
  BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables,
  BalanceFileAttachmentModalBalancesForBulkAttachmentDocument,
  BalanceFileAttachmentModalBalancesForBulkAttachmentQuery,
  BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables,
  BalanceLookupItemFragment,
  PeriodLookupModel,
  UpdateBalanceFileAttachmentsDocument,
  UpdateBalanceFileAttachmentsMutation,
  UpdateBalanceFileAttachmentsMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { BalanceFileAttachmentRow } from './components/BalanceFileAttachmentRow';
import { IBalanceFileAttachment } from './IBalanceFileAttachment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export interface IBalanceFileAttachmentModalPreselectionSpecificBalance {
  specificBalanceId: number;
}
export interface IBalanceFileAttachmentModalPreselectionBulkAttachment {
  bulkAttachmentPeriodId: number;
  bulkAttachmentBalanceIds: number[];
}

export interface IBalanceFileAttachmentModalProps {
  preselection?:
    | IBalanceFileAttachmentModalPreselectionSpecificBalance
    | IBalanceFileAttachmentModalPreselectionBulkAttachment;
  onSaved?: (updatedBalanceIds: number[]) => Promise<void>;
  onCancelled: () => void;
  readOnly?: boolean;
}

export const BalanceFileAttachmentModal: React.FC<
  IBalanceFileAttachmentModalProps
> = (props: IBalanceFileAttachmentModalProps) => {
  const preselectedSpecificBalanceId: number | undefined = (
    props.preselection as IBalanceFileAttachmentModalPreselectionSpecificBalance
  ).specificBalanceId;
  const preselectedBalanceIdsForBulkAttachment: number[] | undefined = (
    props.preselection as IBalanceFileAttachmentModalPreselectionBulkAttachment
  ).bulkAttachmentBalanceIds;

  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<React.ReactNode>(null);
  const { confirm, error, apolloError } = useModalAlerts(setAlertModal);

  const [period, setPeriod] = useState<
    { id: number; year: number; month: number; isOpen: boolean } | undefined
  >(undefined);
  const [preselectedSpecificBalance, setPreselectedSpecificBalance] = useState<
    BalanceLookupItemFragment | undefined
  >();
  const [
    preselectedBalancesForBulkAttachment,
    setPreselectedBalancesForBulkAttachment,
  ] = useState<BalanceLookupItemFragment[]>([]);
  const [balanceFileAttachments, setBalanceFileAttachments] = useState<
    IBalanceFileAttachment[]
  >(
    !!props.preselection
      ? []
      : [
          {
            fileAttachment: null,
            balances: [],
          },
        ],
  );
  const [originalBalanceFileAttachments, setOriginalBalanceFileAttachments] =
    useState<IBalanceFileAttachment[]>([]);

  const handlePeriodSelected = (item?: PeriodLookupModel) => {
    if (!item || period?.id === item?.id) {
      return;
    }

    if (!period) {
      setPeriod({
        id: item.id,
        year: item.year,
        month: item.month,
        isOpen: item.periodStatus?.id === PeriodStatusEnum.Open,
      });
    }

    if (period) {
      confirm({
        message:
          'Changing the period will cause your existing file attachment selections to be cleared.  Do you wish to continue?',
        onConfirm: () => {
          setPeriod({
            id: item.id,
            year: item.year,
            month: item.month,
            isOpen: item.periodStatus?.id === PeriodStatusEnum.Open,
          });

          setBalanceFileAttachments([]);
        },
      });
    }
  };

  const initializeSelectedPeriod = async (periodId?: number) => {
    let initialPeriod: PeriodLookupModel | null | undefined;
    if (!!periodId) {
      initialPeriod = await getPeriodById(client, periodId);
    }

    if (!periodId) {
      getFirstAndLastOpenPeriods(client)
        .then(({ latestOpenPeriod }) => {
          initialPeriod = latestOpenPeriod;
        })
        .catch((reason: any) =>
          apolloError({
            error: reason,
          }),
        );
    }

    if (!!initialPeriod) {
      setPeriod({
        id: initialPeriod.id,
        year: initialPeriod.year,
        month: initialPeriod.month,
        isOpen: initialPeriod.periodStatus?.id === PeriodStatusEnum.Open,
      });
    }
  };

  const initializePreselectedSpecificBalance = () => {
    client
      .query<
        BalanceFileAttachmentModalAttachmentListForBalanceQuery,
        BalanceFileAttachmentModalAttachmentListForBalanceQueryVariables
      >({
        query: BalanceFileAttachmentModalAttachmentListForBalanceDocument,
        variables: {
          balanceId: preselectedSpecificBalanceId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        if (!result.data.balance) {
          return;
        }

        const retrievedBalanceFileAttachments: IBalanceFileAttachment[] =
          result.data.balance?.balanceFileAttachments?.map(
            (balanceFileAttachment) =>
              ({
                fileAttachment: {
                  fileAttachmentId: balanceFileAttachment.fileAttachment.id,
                  fileName: balanceFileAttachment.fileAttachment.fileName,
                  fileSizeBytes:
                    balanceFileAttachment.fileAttachment.fileSizeBytes,
                  mimeType: balanceFileAttachment.fileAttachment.mimeType,
                },
                balances:
                  balanceFileAttachment.fileAttachment.balanceFileAttachments.map(
                    (balanceFileAttachment) => balanceFileAttachment.balance,
                  ),
              } as IBalanceFileAttachment),
          ) ?? [];

        setPeriod({
          id: result.data.balance.period.id,
          year: result.data.balance.period.year,
          month: result.data.balance.period.month,
          isOpen:
            result.data.balance.period.periodStatus?.id ===
            PeriodStatusEnum.Open,
        });
        setPreselectedSpecificBalance(result.data.balance);
        setOriginalBalanceFileAttachments([
          ...retrievedBalanceFileAttachments.map((balanceFileAttachment) => ({
            fileAttachment: balanceFileAttachment.fileAttachment,
            balances: [...balanceFileAttachment.balances],
          })),
        ]);

        const readOnly: boolean =
          !!props.readOnly ||
          result.data.balance.period.periodStatus?.id !== PeriodStatusEnum.Open;

        setBalanceFileAttachments(
          retrievedBalanceFileAttachments.concat(
            readOnly || retrievedBalanceFileAttachments.length > 0
              ? []
              : [
                  {
                    fileAttachment: null,
                    balances: !!result.data.balance
                      ? [result.data.balance]
                      : [],
                  },
                ],
          ),
        );
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const initializePreselectedBalancesForBulkAttachment = () => {
    if (preselectedBalanceIdsForBulkAttachment.length === 0) {
      return;
    }

    client
      .query<
        BalanceFileAttachmentModalBalancesForBulkAttachmentQuery,
        BalanceFileAttachmentModalBalancesForBulkAttachmentQueryVariables
      >({
        query: BalanceFileAttachmentModalBalancesForBulkAttachmentDocument,
        variables: {
          balanceIds: preselectedBalanceIdsForBulkAttachment,
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        if (result.data.balances.items.length === 0) {
          return;
        }

        setPreselectedBalancesForBulkAttachment(result.data.balances.items);
        setBalanceFileAttachments([
          {
            fileAttachment: null,
            balances: result.data.balances.items,
          },
        ]);
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  useEffect(() => {
    if (!!preselectedSpecificBalanceId) {
      initializePreselectedSpecificBalance();
      return;
    }

    if (!!preselectedBalanceIdsForBulkAttachment) {
      initializeSelectedPeriod(
        (
          props.preselection as IBalanceFileAttachmentModalPreselectionBulkAttachment
        ).bulkAttachmentPeriodId,
      ).then(() => initializePreselectedBalancesForBulkAttachment());

      return;
    }

    initializeSelectedPeriod();
  }, []);

  const handleAddBalanceFileAttachment = () => {
    setBalanceFileAttachments(
      balanceFileAttachments.concat([
        {
          fileAttachment: null,
          balances: !!preselectedSpecificBalance
            ? [preselectedSpecificBalance]
            : preselectedBalancesForBulkAttachment ?? [],
        },
      ]),
    );
  };

  const handleRemoveBalanceFileAttachment =
    (balanceFileAttachment: IBalanceFileAttachment) => () => {
      confirm({
        message:
          'This will remove this attachment from all associated accounts.  Do you wish to continue?',
        onConfirm: () => {
          setBalanceFileAttachments(
            balanceFileAttachments.filter(
              (thisBalanceFileAttachment) =>
                thisBalanceFileAttachment !== balanceFileAttachment,
            ),
          );
        },
      });
    };

  const handleFileAttachmentChanged =
    (balanceFileAttachment: IBalanceFileAttachment) =>
    (fileAttachment: IFileAttachment | null) => {
      balanceFileAttachment.fileAttachment = fileAttachment;
      setBalanceFileAttachments([...balanceFileAttachments]);
    };

  const handleBalancesChanged =
    (balanceFileAttachment: IBalanceFileAttachment) =>
    (balances: BalanceLookupItemFragment[]) => {
      balanceFileAttachment.balances = balances;
      setBalanceFileAttachments([...balanceFileAttachments]);
    };

  const handleSave = async () => {
    if (props.readOnly) {
      return;
    }

    const hasMissingAttachments = balanceFileAttachments.find(
      (balanceFileAttachment) => !balanceFileAttachment.fileAttachment,
    );
    if (hasMissingAttachments) {
      error({
        message:
          'One or more attachments have not be uploaded.  Please upload the required attachments or remove the row(s).',
      });
      return;
    }

    const hasMissingBalances = balanceFileAttachments.find(
      (balanceFileAttachment) => balanceFileAttachment.balances.length === 0,
    );
    if (hasMissingBalances) {
      error({
        message:
          'One or more attachments do not have any associated accounts.  Please select accounts on these, or remove the row(s).',
      });
      return;
    }

    const originalBalances: {
      fileAttachmentId: number;
      balanceId: number;
      balanceStatusId: number;
      accountLabel: string;
    }[] = originalBalanceFileAttachments
      .map((balanceFileAttachment) =>
        balanceFileAttachment.balances.map((balance) => ({
          fileAttachmentId:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            balanceFileAttachment.fileAttachment!.fileAttachmentId,
          balanceId: balance.id,
          balanceStatusId: balance.balanceStatus.id,
          accountLabel: `${balance.subsidiary.name}/${balance.accountNumber}${
            !!balance.accountNumberDescription
              ? ` (${balance.accountNumberDescription})`
              : ''
          }`,
        })),
      )
      .flat();

    const updatedBalances: {
      fileAttachmentId: number;
      balanceId: number;
      balanceStatusId: number;
      accountLabel: string;
    }[] = balanceFileAttachments
      .map((balanceFileAttachment) =>
        balanceFileAttachment.balances.map((balance) => ({
          fileAttachmentId:
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            balanceFileAttachment.fileAttachment!.fileAttachmentId,
          balanceId: balance.id,
          balanceStatusId: balance.balanceStatus.id,
          accountLabel: `${balance.subsidiary.name}/${balance.accountNumber}${
            !!balance.accountNumberDescription
              ? ` (${balance.accountNumberDescription})`
              : ''
          }`,
        })),
      )
      .flat();

    const removedOriginalBalancesThatWereCompletedOrReviewed = originalBalances
      .filter(
        (originalBalance) =>
          originalBalance.balanceStatusId !== BalanceStatusEnum.Incomplete,
      )
      .filter(
        (originalBalance) =>
          !updatedBalances.find(
            (updatedBalance) =>
              updatedBalance.balanceId === originalBalance.balanceId &&
              updatedBalance.fileAttachmentId ===
                originalBalance.fileAttachmentId,
          ),
      );

    const addedOrUpdatedBalancesThatWereCompletedOrReviewed = updatedBalances
      .filter(
        (updatedBalance) =>
          updatedBalance.balanceStatusId !== BalanceStatusEnum.Incomplete,
      )
      .filter(
        (updatedBalance) =>
          !originalBalances.find(
            (originalBalance) =>
              originalBalance.balanceId === updatedBalance.balanceId &&
              originalBalance.fileAttachmentId ===
                updatedBalance.fileAttachmentId,
          ),
      );

    const updatedAccountsThatWereCompletedOrReviewed =
      removedOriginalBalancesThatWereCompletedOrReviewed
        .concat(addedOrUpdatedBalancesThatWereCompletedOrReviewed)
        .map((balance) => balance.accountLabel)
        .sort()
        .filter((value, index, self) => self.indexOf(value) === index);

    const proceedWithUpdate = () => {
      if (!preselectedSpecificBalanceId) {
        // No balance was specified, so using the generic Add Balances action
        return client
          .mutate<
            AddBalanceFileAttachmentsMutation,
            AddBalanceFileAttachmentsMutationVariables
          >({
            mutation: AddBalanceFileAttachmentsDocument,
            variables: {
              periodId: period?.id ?? 0,
              balanceFileAttachments: balanceFileAttachments.map(
                (balanceFileAttachment) => ({
                  fileAttachmentId:
                    balanceFileAttachment.fileAttachment?.fileAttachmentId,
                  balanceIds: balanceFileAttachment.balances.map(
                    (balance) => balance.id,
                  ),
                }),
              ),
            },
            fetchPolicy: 'no-cache',
          })
          .then((result) => {
            if (props.onSaved) {
              props.onSaved(result.data?.addBalanceFileAttachments ?? []);
            }
          })
          .catch((reason: any) =>
            apolloError({
              error: reason,
            }),
          );
      }

      // A balance was specified, so using Updating attachments to an existing balance
      return client
        .mutate<
          UpdateBalanceFileAttachmentsMutation,
          UpdateBalanceFileAttachmentsMutationVariables
        >({
          mutation: UpdateBalanceFileAttachmentsDocument,
          variables: {
            periodId: period?.id ?? 0,
            balanceFileAttachments: balanceFileAttachments.map(
              (balanceFileAttachment) => ({
                fileAttachmentId:
                  balanceFileAttachment.fileAttachment?.fileAttachmentId,
                balanceIds: balanceFileAttachment.balances.map(
                  (balance) => balance.id,
                ),
              }),
            ),
            anchorBalanceId: preselectedSpecificBalanceId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          if (props.onSaved) {
            props.onSaved(result.data?.updateBalanceFileAttachments ?? []);
          }
        })
        .catch((reason: any) =>
          apolloError({
            error: reason,
          }),
        );
    };

    if (updatedAccountsThatWereCompletedOrReviewed.length > 0) {
      return confirm({
        message: `You are editing the attachment list for the following account${
          updatedAccountsThatWereCompletedOrReviewed.length > 1 ? 's' : ''
        } which ${
          updatedAccountsThatWereCompletedOrReviewed.length > 1 ? 'have' : 'has'
        } has already been completed and/or reviewed: ${updatedAccountsThatWereCompletedOrReviewed.join(
          ', ',
        )}. If you do make this edit, the preparation and review will be need to be re-done. Do you wish to proceed?`,
        onConfirm: proceedWithUpdate,
      });
    } else {
      return proceedWithUpdate();
    }
  };

  const periodName = period
    ? `${format(new Date(2021, period.month - 1, 1), 'MMMM')}, ${period.year}`
    : '';

  const readOnly: boolean = !!props.readOnly || !period?.isOpen;

  return (
    <StandardModal
      heading={
        !!preselectedSpecificBalance?.accountNumber && !!period
          ? `${readOnly ? 'View' : 'Manage'} Attachments for: Account ${
              preselectedSpecificBalance?.accountNumber
            } for: ${periodName}`
          : !!period
          ? `${readOnly ? 'View' : 'Manage'}  Attachments for: ${periodName}`
          : 'Manage Attachments'
      }
      size="xl"
      className="add-attachment-modal"
      readOnly={readOnly}
      onSave={handleSave}
      onCancel={props.onCancelled}
    >
      {alertModal}
      {!preselectedSpecificBalanceId && (
        <div className="row">
          <FieldSetColumn className="col-sm-12 col-lg-6">
            <div className="row mt-2">
              <FormLabelColumn width={3}>
                Period <RequiredMarker />
              </FormLabelColumn>
              <Column>
                <PeriodSelect
                  periodStatusIds={[PeriodStatusEnum.Open]}
                  selectedId={period?.id}
                  onSelect={handlePeriodSelected}
                />
              </Column>
            </div>
          </FieldSetColumn>
        </div>
      )}
      {(!!preselectedSpecificBalanceId ||
        !!preselectedBalancesForBulkAttachment ||
        !!period) && (
        <>
          <div className="row mt-3 mb-3">
            <Column>
              {readOnly &&
                balanceFileAttachments.length === 0 &&
                'No attachments associated'}
              {balanceFileAttachments.length > 0 && (
                <div className="row mb-2">
                  <FormLabelColumn width={5}></FormLabelColumn>
                  <FormLabelColumn className="d-flex align-items-end">
                    Associated accounts
                  </FormLabelColumn>
                  {!props.readOnly && (
                    <FormLabelColumn
                      width={'auto'}
                      style={{ minWidth: '9em' }}
                    />
                  )}
                </div>
              )}
              {balanceFileAttachments.map((balanceFileAttachment, index) => (
                <BalanceFileAttachmentRow
                  key={index}
                  balanceFileAttachment={balanceFileAttachment}
                  periodId={period?.id ?? 0}
                  readOnly={readOnly}
                  onRemoveBalanceFileAttachment={handleRemoveBalanceFileAttachment(
                    balanceFileAttachment,
                  )}
                  onBalancesChanged={handleBalancesChanged(
                    balanceFileAttachment,
                  )}
                  onFileAttachmentChanged={handleFileAttachmentChanged(
                    balanceFileAttachment,
                  )}
                />
              ))}
            </Column>
          </div>
          {!readOnly && (
            <div className="row mt-n4">
              <Column width={5} />
              <Column />
              <Column
                width="auto"
                className="d-flex align-items-center"
                style={{ width: '9.6em' }}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className="pointer ml-1 text-primary"
                  style={{ fontSize: 'x-large' }}
                  onClick={handleAddBalanceFileAttachment}
                />
                <span className="ml-2 font-weight-bold">Add Attachment</span>
              </Column>
            </div>
          )}
        </>
      )}
    </StandardModal>
  );
};
