import React from 'react';
import { Form } from 'react-bootstrap';

interface IProps {
  value: number | null;
  minimumValue?: number;
  maximumValue?: number;
  maximumDecimalPlaces?: number;
  className?: string;
  onChange: (value: number | null) => void;
  readOnly?: boolean;
}

export default (props: IProps) => {
  const effectiveMinimumValue = props.minimumValue ?? -9999999999;
  const effectiveMaximumValue = props.maximumValue ?? 9999999999;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const originalValue = props.value;
    const newValue = event.currentTarget.value;

    if (newValue?.trim() === '') {
      // value is blank, set value to null
      props.onChange(null);
      return;
    }

    if (isNaN(Number(newValue))) {
      // user has pressed space or a non-numeric character; replace with the original value
      props.onChange(originalValue);
      return;
    }

    if (Number(newValue) < effectiveMinimumValue) {
      // user has gone below the minimum value; replace with the minimum value
      props.onChange(effectiveMinimumValue);
      return;
    }

    if (Number(newValue) > effectiveMaximumValue) {
      // user has gone above the maximum value; replace with the maximum value
      props.onChange(effectiveMaximumValue);
      return;
    }

    const formatted = Number(newValue).toString();

    if (!!props.maximumDecimalPlaces && formatted.includes('.')) {
      const numberOfDecimalPlaces =
        formatted.length - (formatted.indexOf('.') + 1);
      if (numberOfDecimalPlaces > props.maximumDecimalPlaces) {
        // user has entered too main decimal digits; replace with the original value
        props.onChange(originalValue);
        return;
      }
    }

    if (
      (originalValue ? Number(originalValue) : null) ===
      (newValue ? Number(newValue) : null)
    ) {
      // The value is effectively the same, so do nothing.
      // This will handle cases where the user changes "0" to "0." which
      // if props.onChange is called will result in the INPUT showing "0" again.
      return;
    }

    // user has entered a new valid value; register the new value.
    props.onChange(!!newValue ? Number(newValue) : null);
  };

  return (
    <Form.Control
      type="number"
      className={`decimal-input ${props.className ?? ''} text-right`}
      value={props.value ?? ''}
      onChange={handleOnChange}
      disabled={props.readOnly}
    />
  );
};
