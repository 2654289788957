import { formatCurrency } from 'ar-common';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import React, { useEffect, useRef, useState } from 'react';
import {
  BalanceImportDetailDocument,
  BalanceImportDetailListItemFragment,
  BalanceImportDetailQuery,
  BalanceImportDetailQueryVariables,
  BalanceImportSortBy,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import useSpinnerModal from 'components/SpinnerModal/useSpinnerModal';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import Column from 'layouts/components/Grid/Column';
import TruncatingTextWrapperWithPopover from 'components/TruncatingTextWrapperWithPopover';

interface IBalanceImportDetailTableProps {
  selectedSubsidiary?: {
    balanceImportBatchId: number;
    periodId: number;
    periodName: string;
    subsidiaryId: number;
    subsidiaryName: string;
  };
}

export const BalanceImportDetailTable: React.FC<
  IBalanceImportDetailTableProps
> = (props: IBalanceImportDetailTableProps) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);
  const { openSpinnerModal, closeSpinnerModal } = useSpinnerModal({
    message: 'Retrieving imported balance totals...',
  });

  const [totalBalanceImports, setTotalBalanceImports] = useState<number>(0);
  const [balanceImports, setBalanceImports] = useState<
    BalanceImportDetailListItemFragment[]
  >([]);
  const [sortBy, setSortBy] = useState<BalanceImportSortBy>(
    BalanceImportSortBy.SubsidiaryName,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: BalanceImportSortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<
      BalanceImportDetailQuery,
      BalanceImportDetailQueryVariables
    >({
      query: BalanceImportDetailDocument,
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          balanceImportBatchId:
            props.selectedSubsidiary?.balanceImportBatchId ?? 0,
          periodId: props.selectedSubsidiary?.periodId ?? 0,
          subsidiaryId: props.selectedSubsidiary?.subsidiaryId ?? 0,
        },
        paginationOptions:
          options.take === AllRowsPerPage
            ? undefined
            : {
                skip: options.skip,
                take: options.take,
              },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
    });
  };

  const fetchDataTablePage = async (skip?: number, take?: number) => {
    openSpinnerModal();
    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalBalanceImports(value.data.balanceImports.totalItems);
        setBalanceImports(value.data.balanceImports.items);
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      })
      .finally(() => {
        closeSpinnerModal();
      });
  };

  const handleSort = (
    sortBy: BalanceImportSortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  // let exportFunction: DataTableExportFunction<BalanceImportDetailListItemFragment>;
  // const handleBatchImportDetailExport = async () => {
  //   return fetch({ sortBy, sortDirection }).then((value) => {
  //     return exportFunction({
  //       items: value.data.balanceImports.items,
  //       filename: 'balance-import-detail.xlsx',
  //     });
  //   });
  // };

  useEffect(() => {
    if (!props.selectedSubsidiary) {
      return;
    }

    if (!!paginationControl.current?.refresh) {
      paginationControl.current.refresh();
    }
  }, [props.selectedSubsidiary, sortBy, sortDirection]);

  const columns: IDataTableColumnDefinition<
    BalanceImportDetailListItemFragment,
    BalanceImportSortBy
  >[] = [
    {
      dataFieldName: 'periodId',
      heading: 'Period',
      width: '7rem',
      render: (balanceImport) =>
        !balanceImport.period
          ? 'Not recog.'
          : `${balanceImport.period.month < 10 ? '0' : ''}${
              balanceImport.period.month
            }-${balanceImport.period.year.toString()}`,
      excelExport: (balanceImport) =>
        !balanceImport.period
          ? 'Not recog.'
          : `${balanceImport.period.month < 10 ? '0' : ''}${
              balanceImport.period.month
            }-${balanceImport.period.year.toString()}`,
      sortBy: BalanceImportSortBy.Period,
    },
    {
      dataFieldName: 'account',
      multiSortHeadings: [
        { heading: 'Account', sortBy: BalanceImportSortBy.AccountNumber },
        { heading: 'Sub.', sortBy: BalanceImportSortBy.SubsidiaryName },
        {
          heading: 'To/From Sub.',
          sortBy: BalanceImportSortBy.DueToFromSubsidiaryName,
        },
        {
          heading: 'Description',
          sortBy: BalanceImportSortBy.AccountNumberDescription,
        },
      ],
      render: (balanceImport) => (
        <TruncatingTextWrapperWithPopover>
          {balanceImport.accountNumber} (
          {balanceImport.subsidiary?.name ?? 'Not recognized'}
          {balanceImport.dueToFromSubsidiary
            ? `, to/from ${balanceImport.dueToFromSubsidiary.name}`
            : ''}
          ) {balanceImport.accountNumberDescription ?? ''}
        </TruncatingTextWrapperWithPopover>
      ),
    },
    {
      hideInUI: true,
      width: '8rem',
      dataFieldName: 'accountNumber',
      heading: 'Account',
      render: (balanceImport) => balanceImport.accountNumber.toString(),
      excelExport: (balanceImport) => balanceImport.accountNumber,
      sortBy: BalanceImportSortBy.AccountNumber,
    },
    {
      hideInUI: true,
      dataFieldName: 'subsidiary',
      heading: 'Subsidiary',
      width: '9rem',
      render: (balanceImport) =>
        balanceImport.subsidiary?.name ?? 'Not recognized',
      excelExport: (balanceImport) =>
        balanceImport.subsidiary?.name ?? 'Not recognized',
      sortBy: BalanceImportSortBy.SubsidiaryName,
    },
    {
      hideInUI: true,
      width: '15rem',
      dataFieldName: 'accountNumberDescription',
      heading: 'Description',
      render: (balanceImport) => balanceImport.accountNumberDescription ?? '',
      excelExport: (balanceImport) =>
        balanceImport.accountNumberDescription ?? '',
      sortBy: BalanceImportSortBy.AccountNumberDescription,
    },
    {
      hideInUI: true,
      dataFieldName: 'dueToFromSubsidiary',
      heading: 'Due To/From',
      width: '8em',
      render: (balanceImport) => balanceImport.dueToFromSubsidiary?.name ?? '',
      excelExport: (balanceImport) =>
        balanceImport.dueToFromSubsidiary?.name ?? '',
      sortBy: BalanceImportSortBy.DueToFromSubsidiaryName,
    },
    {
      dataFieldName: 'usdBalance',
      heading: 'USD Balance',
      headClassName: 'text-right',
      width: '10rem',
      cellClassName: (balanceImport) => (!balanceImport ? '' : 'text-right'),
      render: (balanceImport) =>
        balanceImport.usdBalance === undefined
          ? '--'
          : formatCurrency(balanceImport.usdBalance),
      excelExport: (balanceImport) =>
        balanceImport.usdBalance === undefined
          ? '--'
          : formatCurrency(balanceImport.usdBalance),
      sortBy: BalanceImportSortBy.UsdBalance,
    },
    {
      dataFieldName: 'foreignBalance',
      heading: 'For. Period Balance',
      headClassName: 'text-right',
      width: '20rem',
      cellClassName: (balanceImport) => (!balanceImport ? '' : 'text-right'),
      render: (balanceImport) =>
        balanceImport.foreignBalance === undefined
          ? '--'
          : formatCurrency(balanceImport.foreignBalance),
      excelExport: (balanceImport) =>
        balanceImport.foreignBalance === undefined
          ? '--'
          : formatCurrency(balanceImport.foreignBalance),
      sortBy: BalanceImportSortBy.ForeignBalance,
    },
  ];

  return (
    <div
      style={{
        visibility: !!props.selectedSubsidiary ? 'visible' : 'hidden',
      }}
    >
      {alertModal}
      <SectionHeaderRow className="mt-4">
        <Column width="auto">
          <h1>
            Imported Balances
            {/*Imported Balance Totals For: {props.selectedSubsidiary?.periodName}{' '}*/}
            {/*{props.selectedSubsidiary?.subsidiaryName}*/}
          </h1>
        </Column>
        <Column className="d-flex align-content-end justify-content-end ml-5">
          {/*<ExportToExcelButton onClick={handleBatchImportDetailExport} />*/}
        </Column>
      </SectionHeaderRow>
      <hr />

      <Pagination
        totalNumberOfItems={totalBalanceImports}
        fetchDataTablePage={fetchDataTablePage}
        controlRef={paginationControl}
      >
        <DataTable<BalanceImportDetailListItemFragment, BalanceImportSortBy>
          columns={columns}
          data={balanceImports}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          // exportRef={(fn) => {
          //   exportFunction = fn;
          // }}
        />
      </Pagination>
    </div>
  );
};

export default BalanceImportDetailTable;
