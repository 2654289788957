import useModalAlerts from 'components/Alerts/useModalAlerts';
import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';
import { Column } from 'layouts/components/Grid/Column';
import { SectionHeaderRow } from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useEffect, useRef, useState } from 'react';
import { updateTitleEffect } from 'utils/page-util';
import AddPeriodsButton from './components/AddPeriodsButton';
import {
  IManagePeriodsFilterValues,
  ManagePeriodsFilters,
} from './components/ManagePeriodsFilters';
import ManagePeriodsTable, {
  IManagePeriodsTableControl,
} from './components/ManagePeriodsTable';

export const ManagePeriods: React.FC = () => {
  const tableControl = useRef<IManagePeriodsTableControl>();

  const [filterValues, setFilterValues] = useState<IManagePeriodsFilterValues>(
    {},
  );

  const [modalAlert, setModalAlert] = useState<React.ReactNode>();
  const { success } = useModalAlerts(setModalAlert);

  useEffect(updateTitleEffect('Manage Periods'), []);

  const handleSearch = async (values: IManagePeriodsFilterValues) => {
    setFilterValues(values);
    tableControl.current?.applyFilter(values);
  };

  const handleExport = async () => {
    return tableControl.current?.export({
      filename: 'periods.xlsx',
    });
  };

  const handlePeriodsCreated = () => {
    tableControl.current?.refresh();
    success({
      message: 'Periods have been added to the system',
    });
  };

  return (
    <>
      {modalAlert}
      <Page title="Manage Periods">
        <SectionHeaderRow>
          <Column>
            <h1>Filter Periods</h1>
          </Column>
        </SectionHeaderRow>
        <hr />
        <ManagePeriodsFilters
          onSearch={handleSearch}
          filterValues={filterValues}
        />

        <SectionHeaderRow className="mt-2">
          <Column width="auto">
            <h1>List of Periods</h1>
          </Column>
          <Column className="text-right">
            <ExportToExcelButton onClick={handleExport} />
          </Column>
        </SectionHeaderRow>
        <hr />

        <div className="row mb-2">
          <Column>
            <strong>NOTE:</strong> The report ID listed is the value that
            appears at the end of "customsearch" value in the "Id" field when
            editing the report, not the ID appearing in the report URL in
            Netsuite.
          </Column>
          <Column className="col-auto text-right">
            <AddPeriodsButton onPeriodsCreated={handlePeriodsCreated} />
          </Column>
        </div>

        <ManagePeriodsTable controlRef={tableControl} />
      </Page>
    </>
  );
};
