import useModalAlerts from 'components/Alerts/useModalAlerts';
import { format } from 'date-fns';
import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useEffect, useRef, useState } from 'react';
import {
  BatchImportStatusFragment,
  LatestBalanceImportBatchDocument,
  LatestBalanceImportBatchQuery,
  LatestBalanceImportBatchQueryVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { updateTitleEffect } from 'utils/page-util';
import {
  BalanceImportSummaryTable,
  IBalanceImportSummaryTableControl,
} from 'views/ImportBalances/components/BalanceImportSummaryTable';
import ImportButton from './components/ImportButton';
import useSpinnerModal from 'components/SpinnerModal/useSpinnerModal';
import { BalanceImportBatchStatusEnum } from 'ar-common';
import BalanceImportUnrecognizedAccountsTable from './components/BalanceImportUnrecognizedAccountsTable';
import BalanceImportDetailTable from './components/BalanceImportDetailTable';

export const ImportBalances: React.FC = () => {
  const balanceImportSummaryTableControl =
    useRef<IBalanceImportSummaryTableControl>();

  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { error, success, apolloError } = useModalAlerts(setAlertModal);
  const fetchLatestImportInfoSpinner = useSpinnerModal({
    message: 'Retrieving latest import information...',
  });

  const [latestBalanceImportBatchId, setLatestBalanceImportBatchId] = useState<
    number | null
  >(null);
  const [latestImportInfo, setLatestImportInfo] = useState<string>('');
  const [latestImportError, setLatestImportError] = useState<string>('');
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<
    | {
        periodId: number;
        periodName: string;
        subsidiaryId: number;
        subsidiaryName: string;
      }
    | undefined
  >();

  useEffect(updateTitleEffect('Import Balances'), []);

  const fetchLatestImportInfo = async () => {
    setLatestBalanceImportBatchId(null);
    setLatestImportInfo('');
    setLatestImportError('');
    setSelectedSubsidiary(undefined);

    fetchLatestImportInfoSpinner.openSpinnerModal();

    await client
      .query<
        LatestBalanceImportBatchQuery,
        LatestBalanceImportBatchQueryVariables
      >({
        query: LatestBalanceImportBatchDocument,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        setLatestImportInfo(
          !!result.data.latestBalanceImportBatch
            ? `Last import done: ${format(
                new Date(result.data.latestBalanceImportBatch.importDate),
                'MMMM dd, yyyy',
              )} by ${
                result.data.latestBalanceImportBatch.importedByUser?.name
              }`
            : '',
        );
        setLatestImportError(
          result.data.latestBalanceImportBatch?.errorSummary
            ? `Error:  ${result.data.latestBalanceImportBatch.errorSummary}`
            : '',
        );
        setLatestBalanceImportBatchId(
          result.data.latestBalanceImportBatch?.id ?? null,
        );
        setSelectedSubsidiary(undefined);
        fetchLatestImportInfoSpinner.closeSpinnerModal();
      })
      .catch((reason: any) => {
        fetchLatestImportInfoSpinner.closeSpinnerModal();
        apolloError({
          error: reason,
        });
      });
  };

  useEffect(() => {
    fetchLatestImportInfo();
  }, []);

  // const handleBatchImportSummaryExport = async () => {
  //   return balanceImportSummaryTableControl.current?.export({
  //     filename: 'balance-import-summary.xlsx',
  //   });
  // };

  const handleSubsidiarySelected = async (
    periodId: number,
    periodName: string,
    subsidiaryId: number,
    subsidiaryName: string,
  ) => {
    setSelectedSubsidiary({
      periodId,
      periodName,
      subsidiaryId,
      subsidiaryName,
    });
  };

  const handleOnImportComplete = async (
    status: BatchImportStatusFragment,
  ): Promise<void> => {
    if (status.status === BalanceImportBatchStatusEnum.Error) {
      error({
        message:
          'Balance import failed unexpectedly.  No balances were updated.',
      });
    }

    if (status.status === BalanceImportBatchStatusEnum.Completed) {
      success({
        message: status.totalToProcess
          ? `${status.totalProcessed} balances imported`
          : 'Nothing to import',
      });
    }

    await fetchLatestImportInfo();
  };

  return (
    <>
      {alertModal}
      <Page title="Import Balances">
        <div className="row d-flex align-items-center">
          <div className="col-auto">
            <ImportButton onImportComplete={handleOnImportComplete} />
          </div>
          <div className="col ml-2">
            <div className="row"> {latestImportInfo}</div>
            {latestImportError && (
              <div className="row text-danger"> {latestImportError}</div>
            )}
          </div>
        </div>

        <SectionHeaderRow className="mt-4">
          <Column width="auto">
            <h1>Imported Balance Totals by Subsidiary</h1>
          </Column>
          <Column className="d-flex align-content-end justify-content-end ml-5">
            {/*<ExportToExcelButton onClick={handleBatchImportSummaryExport} />*/}
          </Column>
        </SectionHeaderRow>
        <hr />

        <BalanceImportSummaryTable
          balanceImportBatchId={latestBalanceImportBatchId}
          controlRef={balanceImportSummaryTableControl}
          onSubsidiarySelected={handleSubsidiarySelected}
        />

        <BalanceImportUnrecognizedAccountsTable
          balanceImportBatchId={latestBalanceImportBatchId}
        />

        <BalanceImportDetailTable
          selectedSubsidiary={
            !latestBalanceImportBatchId || !selectedSubsidiary
              ? undefined
              : {
                  balanceImportBatchId: latestBalanceImportBatchId ?? 0,
                  ...selectedSubsidiary,
                }
          }
        />
      </Page>
    </>
  );
};
