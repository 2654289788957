import React, {
  PropsWithChildren,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { Overlay, Popover, PopoverContent } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface IProps extends PropsWithChildren<any> {
  disablePopover?: boolean;
  popoverPlacement?: Placement;
}

const TruncatingTextWrapperWithPopover = (props: IProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const popoverPlacement = props.popoverPlacement ?? 'auto';

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = (event: SyntheticEvent<HTMLDivElement>) => {
    if (!props.disablePopover) {
      setShowTooltip(
        event.currentTarget.scrollWidth > event.currentTarget.clientWidth,
      );
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div
        ref={ref}
        className="text-truncate"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.children}
      </div>
      <Overlay
        target={ref.current}
        show={showTooltip}
        placement={popoverPlacement}
        flip={true}
      >
        <Popover id="truncating-text-overlay">
          <PopoverContent className="truncating-text-overlay-content bg-secondary shadow">
            {props.children}
          </PopoverContent>
        </Popover>
      </Overlay>
    </>
  );
};

export default TruncatingTextWrapperWithPopover;
