import React, { useState } from 'react';
import { Button } from 'components/Button';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { AccountDetailDialog } from './AccountDetailDialog';
import { IAccountDetailValues } from './AccountDetail';
import useApolloClient from 'useApolloClient';
import {
  AccountNumberLookupsDocument,
  AccountNumberLookupsQuery,
  AccountNumberLookupsQueryVariables,
  CreateAccountDocument,
  CreateAccountMutation,
  CreateAccountMutationVariables,
} from 'types/graphql';

export interface IAddAccountButtonProps {
  onAccountAdded?: (message: string) => Promise<void>;
  className?: string;
}

export const AddAccountButton: React.FC<IAddAccountButtonProps> = (
  props: IAddAccountButtonProps,
) => {
  const defaultValues: IAccountDetailValues = {
    isReconciled: true,
    isActive: true,
    isAttachmentMandatory: true,
    isIncludedInReminderEmails: true,
  };

  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [values, setValues] = useState<IAccountDetailValues>({
    ...defaultValues,
  });

  const handleValuesChange = (newValues: IAccountDetailValues): void => {
    setValues(newValues);
  };

  const handleShow = () => {
    setValues({ ...defaultValues });
    setIsDialogVisible(true);
  };

  const handleClose = (): void => {
    setIsDialogVisible(false);
  };

  const handleAccountNumberValueBlur = async (): Promise<void> => {
    if (!values.accountNumberValue) {
      return;
    }

    return client
      .query<AccountNumberLookupsQuery, AccountNumberLookupsQueryVariables>({
        query: AccountNumberLookupsDocument,
        variables: { name: values.accountNumberValue?.trim() },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const match = result.data.accountNumberLookups.find(
          (lookup) => lookup.name === values.accountNumberValue?.toString(),
        );

        if (!match) {
          setValues({
            ...values,
            accountDescription: '',
          });
          return;
        }

        setValues({
          ...values,
          accountDescription: match.description ?? '',
        });
      });
  };

  const handleSave = async (): Promise<void> => {
    return client
      .mutate<CreateAccountMutation, CreateAccountMutationVariables>({
        mutation: CreateAccountDocument,
        variables: {
          input: {
            applyToOpenPeriods: false,
            accountNumber: {
              accountNumber: values.accountNumberValue?.trim(),
              description: values.accountDescription?.trim(),
            },
            subsidiaryId: values.subsidiaryId,
            dueToFromSubsidiaryId: values.dueToFromSubsidiaryId,
            isReconciled: values.isReconciled,
            isActive: values.isActive ?? false,
            isAttachmentMandatory: values.isAttachmentMandatory ?? false,
            isIncludedInReminderEmails:
              values.isIncludedInReminderEmails ?? false,
            responsibleUserId: values.responsibleUserId,
            reviewerUserId: values.reviewerUserId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(() => {
        handleClose();
        if (!!props.onAccountAdded) {
          return props.onAccountAdded('Account added successfully.');
        }
      })
      .catch((reason) => {
        apolloError({
          error: reason,
        });
      });
  };

  return (
    <>
      {alertModal}
      <Button
        variant="primary"
        size="sm"
        className={`text-uppercase font-weight-bold ${props.className ?? ''}`}
        onClick={handleShow}
      >
        Add Account
      </Button>
      {isDialogVisible && (
        <AccountDetailDialog
          dialogTitle="Add Account"
          onSave={handleSave}
          onCancel={handleClose}
          values={values}
          onValuesChange={handleValuesChange}
          onAccountNumberValueBlur={handleAccountNumberValueBlur}
          hideSaveAndApply={true}
        />
      )}
    </>
  );
};
