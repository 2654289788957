import { faComment, faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentsTrigger } from 'components/Comments/CommentsTrigger';
import React from 'react';
import {
  AddBalanceCommentDocument,
  BalanceCommentsForAccountPeriodDocument,
  BalanceCommentsForAccountPeriodQuery,
  DeleteBalanceCommentDocument,
  UpdateBalanceCommentDocument,
  ViewAccountsListItemFragment,
} from 'types/graphql';
import { PeriodStatusEnum } from 'ar-common';

export interface ICommentsActionProps {
  balance: ViewAccountsListItemFragment;
  onBalancesUpdated: (updatedBalanceIds: number[]) => Promise<void>;
}

export const BalanceCommentsAction: React.FC<ICommentsActionProps> = (
  props: ICommentsActionProps,
) => {
  return (
    <CommentsTrigger<BalanceCommentsForAccountPeriodQuery>
      title="Account Comments"
      objectId={props.balance.id}
      readOnly={
        props.balance.period.periodStatus.id === PeriodStatusEnum.Closed
      }
      commentsQuery={BalanceCommentsForAccountPeriodDocument}
      addCommentMutation={AddBalanceCommentDocument}
      updateCommentMutation={UpdateBalanceCommentDocument}
      deleteCommentMutation={DeleteBalanceCommentDocument}
      extractCommentsFromQueryResultData={(data) =>
        data.balanceCommentsForAccountPeriod
      }
      onCommentSaved={() => props.onBalancesUpdated([props.balance.id])}
    >
      {({ toggleModal }) => (
        <FontAwesomeIcon
          icon={props.balance.hasComments ? faCommentLines : faComment}
          className="pointer"
          onClick={toggleModal}
        />
      )}
    </CommentsTrigger>
  );
};
