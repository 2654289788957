import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { render, unmountComponentAtNode } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

interface ISpinnerModalOptions {
  id?: string;
  title?: string;
  message?: string;
  verticallyCentered?: boolean;
}

export default (props: ISpinnerModalOptions) => {
  const id = props.id ?? uuidv4();

  const modal = (container: HTMLDivElement, options?: ISpinnerModalOptions) => (
    <Modal
      size="sm"
      show={true}
      backdrop={'static'}
      centered={options?.verticallyCentered ?? props?.verticallyCentered}
      keyboard={false}
      scrollable={false}
      container={container}
    >
      {!!(options?.title ?? props?.title) && (
        <Modal.Title>
          <div className="d-flex">
            <div className="modal-icon-wrapper">
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            </div>
            <div className="modal-title-text text-uppercase">
              {options?.title ?? props?.title}
            </div>
          </div>
        </Modal.Title>
      )}
      <Modal.Body>
        {!(options?.title ?? props?.title) && (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          </div>
        )}
        <div className="text-center">
          {!!(options?.message ?? props?.message) && (
            <span>{options?.message ?? props?.message}</span>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );

  const openSpinnerModal = (options?: ISpinnerModalOptions) => {
    //close the previous spinner if it is already open
    closeSpinnerModal();

    const container = document.createElement('div');
    container.setAttribute('id', id);
    document.body.appendChild(container);
    render(modal(container, options), container);
  };

  const closeSpinnerModal = () => {
    const container = document.getElementById(id);
    if (container) {
      unmountComponentAtNode(container);
      document.body.removeChild(container);
    }
  };

  return {
    openSpinnerModal,
    closeSpinnerModal,
  };
};
