import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import { PeriodSelect } from 'components/Lookups/PeriodLookups/PeriodSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useState } from 'react';
import { PeriodLookupModel } from 'types/graphql';

export interface IManagePeriodsFilterValues {
  startPeriod?: PeriodLookupModel | null;
  endPeriod?: PeriodLookupModel | null;
}

interface IProps {
  filterValues?: IManagePeriodsFilterValues;
  onSearch: (filterValues: IManagePeriodsFilterValues) => Promise<void>;
}

export const ManagePeriodsFilters = (props: IProps): JSX.Element => {
  const [isSearching, setIsSearching] = useState(false);

  const [startPeriod, setStartPeriod] = useState<
    PeriodLookupModel | null | undefined
  >(null);
  const [endPeriod, setEndPeriod] = useState<
    PeriodLookupModel | null | undefined
  >(null);

  const [alertModal, setAlertModal] = useState<any>();
  const { error } = useModalAlerts(setAlertModal);

  const onStartPeriodSelect = (
    period: PeriodLookupModel | null | undefined,
  ) => {
    setStartPeriod(period);
  };

  const onEndPeriodSelect = (period: PeriodLookupModel | null | undefined) => {
    setEndPeriod(period);
  };

  const handleClearSearchClick = () => {
    setStartPeriod(null);
    setEndPeriod(null);
  };

  const handleSearchClick = () => {
    if (
      !!endPeriod &&
      !!startPeriod &&
      startPeriod.sortableName > endPeriod.sortableName
    ) {
      error({
        message:
          'The selected start period is later than the end period. Please set the start period to a period earlier or equal to the end period.',
      });

      return;
    }

    search();
  };

  const search = () => {
    setIsSearching(true);
    props
      .onSearch({
        startPeriod,
        endPeriod,
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  return (
    <>
      {alertModal}
      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={2}>Start Period</FormLabelColumn>
            <Column width={2}>
              <PeriodSelect
                selectedId={startPeriod?.id}
                onSelect={onStartPeriodSelect}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={2}>End Period</FormLabelColumn>
            <Column width={2}>
              <PeriodSelect
                selectedId={endPeriod?.id}
                onSelect={onEndPeriodSelect}
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row">
        <Column className="text-right mt-3">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            disabled={isSearching}
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            disabled={isSearching}
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
    </>
  );
};
