import { BalanceStatusEnum } from 'ar-common';
import { IChipOption } from 'components/ChipSelect';
import React from 'react';
import { NonQueryChipSelect } from '../NonQueryLookups/NonQueryChipSelect';

interface IProps {
  selectedBalanceStatuses: BalanceStatusEnum[] | undefined;
  onSelect: (selectedBalanceStatuses?: BalanceStatusEnum[]) => void;
  disabled?: boolean;
  allowEmptySelection?: boolean;
}

export const BalanceStatusChipSelect = (props: IProps): JSX.Element => {
  const handleSelect = (items?: readonly IChipOption[] | undefined) => {
    props.onSelect(items?.map((i) => Number(i.id)));
  };

  return (
    <NonQueryChipSelect
      selectedIds={props.selectedBalanceStatuses}
      lookups={[
        { id: BalanceStatusEnum.Incomplete, name: 'Incomplete' },
        {
          id: BalanceStatusEnum.ReconciliationComplete,
          name: 'Reconciled',
        },
        {
          id: BalanceStatusEnum.ReviewComplete,
          name: 'Reviewed',
        },
      ]}
      onChange={handleSelect}
      disabled={props.disabled}
      placeholder="All"
    />
  );
};
