import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React from 'react';
import { Form } from 'react-bootstrap';

export const AllRowsPerPage = 0;

export interface ILabelDisplayedRowsArgs {
  from: number;
  to: number;
  count: number;
}

export interface ILabelRowsPerPageArgs extends ILabelDisplayedRowsArgs {
  page: number;
}

interface IRowsPerPageSelectionOption {
  label: string;
  value: number;
}

export interface IRowsPerPageSelectProps {
  labelText: string;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  showAllRowsOption?: boolean;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
}

export default (props: IRowsPerPageSelectProps): JSX.Element => {
  const { rowsPerPage } = props;

  const rowsPerPageOptions: number[] = props.rowsPerPageOptions?.length
    ? props.rowsPerPageOptions
    : [5, 10, 25, 50, 100];

  // If, for some reason, the specified rows per page is not specified
  // in the options, then add it to the option so the select doesn't
  // appear blank.
  if (
    rowsPerPage !== AllRowsPerPage &&
    !rowsPerPageOptions.includes(rowsPerPage)
  ) {
    rowsPerPageOptions.push(rowsPerPage);
  }

  const rowsPerPageSelectionOptions: IRowsPerPageSelectionOption[] =
    rowsPerPageOptions
      .map((val) => ({
        label: val === AllRowsPerPage ? 'All' : val.toString(),
        value: val,
      }))
      .sort((a, b) => {
        return Number(a.value) - Number(b.value);
      });

  if (props.showAllRowsOption) {
    rowsPerPageSelectionOptions.push({
      label: 'All',
      value: AllRowsPerPage,
    });
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(event.target.value);
    props.onChangeRowsPerPage(value);
  };

  return (
    <div className="row">
      <FormLabelColumn className="text-right">
        {props.labelText}
      </FormLabelColumn>

      <div className="col" style={{ maxWidth: '7rem' }}>
        <Form.Control
          as="select"
          value={props.rowsPerPage}
          onChange={handleOnChange}
        >
          {rowsPerPageSelectionOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label || 'All'}
            </option>
          ))}
        </Form.Control>
      </div>
    </div>
  );
};
