"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceStatusEnum = void 0;
var BalanceStatusEnum;
(function (BalanceStatusEnum) {
    // NotImported = 1,
    BalanceStatusEnum[BalanceStatusEnum["Incomplete"] = 2] = "Incomplete";
    BalanceStatusEnum[BalanceStatusEnum["ReconciliationComplete"] = 3] = "ReconciliationComplete";
    BalanceStatusEnum[BalanceStatusEnum["ReviewComplete"] = 4] = "ReviewComplete";
})(BalanceStatusEnum = exports.BalanceStatusEnum || (exports.BalanceStatusEnum = {}));
exports.default = BalanceStatusEnum;
