import React from 'react';
import Avatar from 'react-avatar';

interface IAvatarProps {
  name?: string;
  size?: string;
}

const hashCode = (str: string) => {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

interface RGB {
  b: number;
  g: number;
  r: number;
}

const rgbToYIQ = ({ r, g, b }: RGB): number => {
  return (r * 299 + g * 587 + b * 114) / 1000;
};

const hexToRgb = (hex: string): RGB | undefined => {
  if (!hex || hex === undefined || hex === '') {
    return undefined;
  }

  const result: RegExpExecArray | null =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
};

const contrast = (colorHex: string | undefined, threshold = 128): string => {
  if (colorHex === undefined) {
    return '#000';
  }

  const rgb: RGB | undefined = hexToRgb(colorHex);
  if (rgb === undefined) {
    return '#000';
  }

  return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
};

export default (props: IAvatarProps) => {
  if (props.name) {
    const bgColor = `#${intToRGB(hashCode(props.name))}`;
    const fgColor = contrast(bgColor);

    return (
      <Avatar
        name={props.name}
        round={true}
        size={props.size}
        color={bgColor}
        fgColor={fgColor}
      />
    );
  }

  return null;
};
