import { LookupSelect } from 'components/Lookups/LookupSelect';
import { UserLookupWrapper } from 'components/Lookups/UserLookups/UserLookupWrapper';
import React from 'react';
import { LookupModel } from 'types/graphql';

interface IProps {
  includeIds?: number[];
  selectedId?: number | null;
  onSelect: (item?: LookupModel) => void;
  disabled?: boolean;
}

export const UserSelect = (props: IProps) => {
  return (
    <UserLookupWrapper includeIds={props.includeIds}>
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupSelect
          extractLookupItems={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          selectedId={props.selectedId}
          onSelect={props.onSelect}
          allowEmptySelection={true}
          disabled={props.disabled}
        />
      )}
    </UserLookupWrapper>
  );
};
