"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriodStatusEnum = void 0;
var PeriodStatusEnum;
(function (PeriodStatusEnum) {
    PeriodStatusEnum[PeriodStatusEnum["NotInitiated"] = 1] = "NotInitiated";
    PeriodStatusEnum[PeriodStatusEnum["Open"] = 2] = "Open";
    PeriodStatusEnum[PeriodStatusEnum["Closed"] = 3] = "Closed";
})(PeriodStatusEnum = exports.PeriodStatusEnum || (exports.PeriodStatusEnum = {}));
exports.default = PeriodStatusEnum;
