import { ErrorResponse } from 'apollo-link-error';

export const isUnauthorizedErrorResponse = (error: ErrorResponse): boolean => {
  return isStatusCodeErrorResponse(error, 401);
};

export const isForbiddenErrorResponse = (error: ErrorResponse): boolean => {
  return isStatusCodeErrorResponse(error, 403);
};

export const isStatusCodeErrorResponse = (
  errorResponse: ErrorResponse,
  statusCode: number,
): boolean => {
  if (
    !!errorResponse.graphQLErrors?.find(
      (error) => error.extensions?.exception?.status === statusCode,
    )
  ) {
    return true;
  }

  return false;
};
