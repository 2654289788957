import { SubsidiaryLookupWrapper } from 'components/Lookups/SubsidiaryLookups/SubsidiaryLookupWrapper';
import { LookupSelect } from 'components/Lookups/LookupSelect';
import React from 'react';
import { LookupModel } from 'types/graphql';

interface IProps {
  includeIds?: number[];
  selectedId?: number | null;
  onSelect: (item?: LookupModel) => void;
  disabled?: boolean;
  allowEmptySelection?: boolean;
}

export const SubsidiarySelect = (props: IProps): JSX.Element => {
  return (
    <SubsidiaryLookupWrapper includeIds={props.includeIds}>
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupSelect
          extractLookupItems={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          selectedId={props.selectedId}
          onSelect={props.onSelect}
          disabled={props.disabled}
          allowEmptySelection={props.allowEmptySelection}
        />
      )}
    </SubsidiaryLookupWrapper>
  );
};
