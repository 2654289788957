import React, { PropsWithChildren } from 'react';
import { useAuthContext } from 'auth/AuthContext';

export const AuthorizedLayout = (
  props: PropsWithChildren<Record<string, unknown>>,
): JSX.Element => {
  const { children } = props;

  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return <></>;
  }

  return <>{children}</>;
};

export default AuthorizedLayout;
