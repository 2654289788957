import React, { useState } from 'react';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import {
  UpdateReconciledBalanceDocument,
  UpdateReconciledBalanceMutation,
  UpdateReconciledBalanceMutationVariables,
} from 'types/graphql';
import DecimalInput from 'components/Form/DecimalInput';
import { Button } from 'components/Button';
import { Modal } from 'react-bootstrap';
import useApolloClient from 'useApolloClient';

export interface IEditReconciledBalanceModalProps {
  balanceId: number;
  reconciledBalance: number | null;
  onClose: (isCancelled: boolean) => Promise<void>;
}

export const EditReconciledBalanceModal: React.FC<
  IEditReconciledBalanceModalProps
> = (props: IEditReconciledBalanceModalProps) => {
  const [reconciledBalance, setReconciledBalance] = useState<number | null>(
    props.reconciledBalance,
  );
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const handleCancel = async (): Promise<void> => {
    return props.onClose(true);
  };

  const handleSave = async (): Promise<void> => {
    const { client } = useApolloClient();

    return client
      .mutate<
        UpdateReconciledBalanceMutation,
        UpdateReconciledBalanceMutationVariables
      >({
        mutation: UpdateReconciledBalanceDocument,
        variables: {
          id: props.balanceId,
          reconciledBalance: reconciledBalance,
        },
      })
      .then(() => {
        props.onClose(false);
      })
      .catch((reason: any) => {
        apolloError({
          error: reason,
        });
      });
  };

  const handleReconciledBalanceChange = (value: number | null) => {
    setReconciledBalance(value);
  };

  return (
    <>
      {alertModal}
      <Modal show={true} onHide={handleCancel} keyboard={false}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Edit Reconciled Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <DecimalInput
              value={reconciledBalance}
              minimumValue={-999999999.99}
              maximumValue={999999999.99}
              onChange={handleReconciledBalanceChange}
              className="border border-dark focus"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
