import faker from 'faker';
import React from 'react';
import { Form } from 'react-bootstrap';

interface IProps {
  value: number | null;
  minimumValue?: number;
  maximumValue?: number;
  className?: string;
  onChange: (value: number | null) => void;
  readOnly?: boolean;
}

export const IntegerInput = (props: IProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.', '');

    if (
      value.trim() &&
      props.minimumValue !== undefined &&
      Number(value) < props.minimumValue
    ) {
      value = props.minimumValue.toString();
    }

    if (
      value.trim() &&
      props.maximumValue !== undefined &&
      Number(value) > props.maximumValue
    ) {
      value = props.maximumValue.toString();
    }

    props.onChange(value ? Number(value) : null);
  };

  return (
    <Form.Control
      id={`integer-input-${faker.random.alphaNumeric(10)}`} // randomize to throw off browser auto-fill of fields
      type="number"
      className={props.className}
      value={props.value !== null ? props.value.toString() : ''}
      onChange={handleOnChange}
      readOnly={props.readOnly}
      style={{ backgroundColor: 'white' }}
    />
  );
};
