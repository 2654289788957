import React from 'react';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import Column from 'layouts/components/Grid/Column';
import { SubsidiarySelect } from 'components/Lookups/SubsidiaryLookups/SubsidiarySelect';
import { CheckBlock } from 'components/Form/CheckBlock';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import TextInput from 'components/Form/TextInput';
import { UserSelect } from 'components/Lookups/UserLookups/UserSelect';
import { BulkUpdateCheckboxColumn } from 'components/Form/BulkUpdateCheckboxColumn';
import { LookupModel } from 'types/graphql';
import { AccountChipSelect } from 'components/Lookups/AccountLookups/AccountChipSelect';

export interface IAccountDetailValues {
  accountIds?: number[];
  accountNumberValue?: string;
  accountDescription?: string;
  subsidiaryId?: number;
  dueToFromSubsidiaryId?: number;
  isReconciled?: boolean;
  isActive?: boolean;
  isAttachmentMandatory?: boolean;
  isIncludedInReminderEmails?: boolean;
  responsibleUserId?: number;
  reviewerUserId?: number;
}

export interface IAccountDetailBulkUpdateSelections {
  isAccountNumberSelectedForBulkUpdate?: boolean;
  isAccountDescriptionSelectedForBulkUpdate?: boolean;
  isSubsidiaryIdSelectedForBulkUpdate?: boolean;
  isDueToFromSubsidiaryIdSelectedForBulkUpdate?: boolean;
  isIsReconciledSelectedForBulkUpdate?: boolean;
  isIsActiveSelectedForBulkUpdate?: boolean;
  isIsAttachmentMandatorySelectedForBulkUpdate?: boolean;
  isIsIncludedInReminderEmailsSelectedForBulkUpdate?: boolean;
  isResponsibleUserIdSelectedForBulkUpdate?: boolean;
  isReviewerUserIdSelectedForBulkUpdate?: boolean;
}

export interface IAccountDetailProps {
  isBulkUpdateMode?: boolean;
  values: IAccountDetailValues;
  onValuesChange: (values: IAccountDetailValues) => void;
  onAccountNumberValueBlur?: () => Promise<void>;
  bulkUpdateSelections?: IAccountDetailBulkUpdateSelections;
  onBulkUpdateSelectionsChange?: (
    values: IAccountDetailBulkUpdateSelections,
  ) => void;
}

export const AccountDetail: React.FC<IAccountDetailProps> = (
  props: IAccountDetailProps,
) => {
  return (
    <>
      {props.isBulkUpdateMode && (
        <>
          <div className="row">
            <Column>
              <SectionHeaderRow>
                <Column>
                  <h2>Accounts Bulk Update</h2>
                </Column>
              </SectionHeaderRow>
              <hr />
            </Column>
          </div>
          <div className="row pt-3 mb-3">
            <FieldSetColumn>
              <div className="row mb-3 align-items-center">
                <FormLabelColumn width={2} className="mr-5">
                  Accounts to Update
                </FormLabelColumn>
                <Column className="ml-4">
                  <AccountChipSelect
                    take={50}
                    selectedIds={props.values.accountIds}
                    includeIds={props.values.accountIds}
                    onChange={(selectedOptions) => {
                      props.onValuesChange({
                        ...props.values,
                        accountIds: selectedOptions.map((option) =>
                          Number(option.id),
                        ),
                      });
                    }}
                  />
                </Column>
              </div>
            </FieldSetColumn>
          </div>
        </>
      )}
      <div className="row">
        <Column width={7}>
          <SectionHeaderRow>
            <Column>
              <h2>Main Information</h2>
            </Column>
          </SectionHeaderRow>
          <hr />
        </Column>
        <Column width={5}>
          <SectionHeaderRow>
            <Column>
              <h2>Account Assignments</h2>
            </Column>
          </SectionHeaderRow>
          <hr />
        </Column>
      </div>
      <div className="row pt-3">
        <FieldSetColumn width={7}>
          {!props.isBulkUpdateMode && (
            <div className="row mb-3 align-items-center">
              <BulkUpdateCheckboxColumn
                isVisible={props.isBulkUpdateMode}
                placeholderOnly={true}
              />
              <FormLabelColumn>Account</FormLabelColumn>
              <Column width={7}>
                {!props.isBulkUpdateMode && (
                  <TextInput
                    value={props.values.accountNumberValue || ''}
                    onChange={(value) =>
                      props.onValuesChange({
                        ...props.values,
                        accountNumberValue: value,
                      })
                    }
                    maxLength={12}
                    className="text-uppercase"
                    onBlur={props.onAccountNumberValueBlur}
                  />
                )}
              </Column>
            </div>
          )}
          <div className="row align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections?.isSubsidiaryIdSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isSubsidiaryIdSelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn>Subsidiary</FormLabelColumn>
            <Column width={7}>
              <SubsidiarySelect
                selectedId={props.values.subsidiaryId}
                onSelect={(value: LookupModel | undefined) =>
                  props.onValuesChange({
                    ...props.values,
                    subsidiaryId: value?.id,
                  })
                }
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections
                    ?.isSubsidiaryIdSelectedForBulkUpdate
                }
              />
            </Column>
          </div>
          <div className="row mt-3 align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections
                  ?.isDueToFromSubsidiaryIdSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isDueToFromSubsidiaryIdSelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn>Due To/From Subsidiary</FormLabelColumn>
            <Column width={7}>
              <SubsidiarySelect
                selectedId={props.values.dueToFromSubsidiaryId}
                onSelect={(value: LookupModel | undefined) =>
                  props.onValuesChange({
                    ...props.values,
                    dueToFromSubsidiaryId: value?.id,
                  })
                }
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections
                    ?.isDueToFromSubsidiaryIdSelectedForBulkUpdate
                }
                allowEmptySelection={true}
              />
            </Column>
          </div>
          <div className="row mt-3 align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections
                  ?.isAccountDescriptionSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isAccountDescriptionSelectedForBulkUpdate: value,
                  });
                }
              }}
              disabled={props.values.accountDescription === undefined}
            />
            <FormLabelColumn>Description</FormLabelColumn>
            <Column width={7}>
              <TextInput
                value={props.values.accountDescription ?? ''}
                onChange={(value) => {
                  props.onValuesChange({
                    ...props.values,
                    accountDescription: value,
                  });
                }}
                disabled={
                  props.isBulkUpdateMode &&
                  (!props.bulkUpdateSelections
                    ?.isAccountDescriptionSelectedForBulkUpdate ||
                    props.values.accountDescription === undefined)
                }
              />
            </Column>
          </div>
          <div className="row mt-3 align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections?.isIsReconciledSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isIsReconciledSelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn>Do Not Reconcile</FormLabelColumn>
            <Column width={7}>
              <CheckBlock
                checked={props.values.isReconciled === false}
                onCheckedChange={(value) => {
                  props.onValuesChange({
                    ...props.values,
                    isReconciled: !value,
                  });
                }}
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections
                    ?.isIsReconciledSelectedForBulkUpdate
                }
              />
            </Column>
          </div>
          <div className="row mt-3 align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections?.isIsActiveSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isIsActiveSelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn>Active</FormLabelColumn>
            <Column width={7}>
              <CheckBlock
                checked={props.values.isActive}
                onCheckedChange={(value) => {
                  props.onValuesChange({
                    ...props.values,
                    isActive: value,
                  });
                }}
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections?.isIsActiveSelectedForBulkUpdate
                }
              />
            </Column>
          </div>
          <div className="row mt-3 align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections
                  ?.isIsAttachmentMandatorySelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isIsAttachmentMandatorySelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn>Mandatory Attachment</FormLabelColumn>
            <Column width={7}>
              <CheckBlock
                checked={props.values.isAttachmentMandatory}
                onCheckedChange={(value) => {
                  props.onValuesChange({
                    ...props.values,
                    isAttachmentMandatory: value,
                  });
                }}
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections
                    ?.isIsAttachmentMandatorySelectedForBulkUpdate
                }
              />
            </Column>
          </div>
          <div className="row mt-3 align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections
                  ?.isIsIncludedInReminderEmailsSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isIsIncludedInReminderEmailsSelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn>Include in Reminder Email</FormLabelColumn>
            <Column width={7}>
              <CheckBlock
                checked={props.values.isIncludedInReminderEmails}
                onCheckedChange={(value) => {
                  props.onValuesChange({
                    ...props.values,
                    isIncludedInReminderEmails: value,
                  });
                }}
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections
                    ?.isIsIncludedInReminderEmailsSelectedForBulkUpdate
                }
              />
            </Column>
          </div>
        </FieldSetColumn>
        <FieldSetColumn width={5}>
          <div className="row align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections
                  ?.isResponsibleUserIdSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isResponsibleUserIdSelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn width={3}>Responsible</FormLabelColumn>
            <Column>
              <UserSelect
                selectedId={props.values.responsibleUserId}
                includeIds={
                  props.values.responsibleUserId
                    ? [props.values.responsibleUserId]
                    : undefined
                }
                onSelect={(value: LookupModel | undefined) =>
                  props.onValuesChange({
                    ...props.values,
                    responsibleUserId: value?.id,
                  })
                }
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections
                    ?.isResponsibleUserIdSelectedForBulkUpdate
                }
              />
            </Column>
          </div>
          <div className="row mt-3 align-items-center">
            <BulkUpdateCheckboxColumn
              isVisible={props.isBulkUpdateMode}
              isChecked={
                props.bulkUpdateSelections
                  ?.isReviewerUserIdSelectedForBulkUpdate
              }
              onCheckedChange={(value: boolean) => {
                if (props.onBulkUpdateSelectionsChange) {
                  props.onBulkUpdateSelectionsChange({
                    ...props.bulkUpdateSelections,
                    isReviewerUserIdSelectedForBulkUpdate: value,
                  });
                }
              }}
            />
            <FormLabelColumn width={3}>Reviewer</FormLabelColumn>
            <Column>
              <UserSelect
                selectedId={props.values.reviewerUserId}
                includeIds={
                  props.values.reviewerUserId
                    ? [props.values.reviewerUserId]
                    : undefined
                }
                onSelect={(value: LookupModel | undefined) =>
                  props.onValuesChange({
                    ...props.values,
                    reviewerUserId: value?.id,
                  })
                }
                disabled={
                  props.isBulkUpdateMode &&
                  !props.bulkUpdateSelections
                    ?.isReviewerUserIdSelectedForBulkUpdate
                }
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>
    </>
  );
};
