import { DocumentNode } from 'graphql';
import React from 'react';
import {
  LookupModel,
  SubsidiaryLookupsDocument,
  SubsidiaryLookupsQuery,
  SubsidiaryLookupsQueryVariables,
} from 'types/graphql';

interface IProps {
  includeIds?: number[];
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: SubsidiaryLookupsQueryVariables;
    extractLookups: (query: SubsidiaryLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const SubsidiaryLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: SubsidiaryLookupsDocument,
        queryVariables: {
          includeIds: props.includeIds,
        },
        extractLookups: (query) => query.subsidiaryLookups,
      })}
    </>
  );
};
