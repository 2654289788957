import { PeriodStatusEnum } from 'ar-common/lib/period-status.enum';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import { PeriodSelect } from 'components/Lookups/PeriodLookups/PeriodSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useEffect, useState } from 'react';
import { PeriodLookupModel } from 'types/graphql';
import { Form } from 'react-bootstrap';
import { DatePicker } from 'components/Form/DatePicker';

export interface IReactivatedAccountsFilterValues {
  isPeriodRangeSelected: boolean;
  startPeriod?: PeriodLookupModel;
  endPeriod?: PeriodLookupModel;
  startDate?: Date;
  endDate?: Date;
}

interface IProps {
  filterValues?: IReactivatedAccountsFilterValues;
  onSearch: (filterValues: IReactivatedAccountsFilterValues) => Promise<void>;
}

export const ReactivatedAccountsFilters = (props: IProps): JSX.Element => {
  const [isSearching, setIsSearching] = useState(false);

  const [selectedStartPeriod, setSelectedStartPeriod] = useState<
    PeriodLookupModel | null | undefined
  >();
  const [selectedEndPeriod, setSelectedEndPeriod] = useState<
    PeriodLookupModel | null | undefined
  >();
  const [selectedStartDate, setSelectedStartDate] = useState<
    Date | null | undefined
  >(null);
  const [selectedEndDate, setSelectedEndDate] = useState<
    Date | null | undefined
  >(new Date());
  const [isPeriodRangeSelected, setIsPeriodRangeSelected] =
    useState<boolean>(true);

  const [alertModal, setAlertModal] = useState<any>();
  const { error } = useModalAlerts(setAlertModal);

  useEffect(() => {
    setSelectedStartPeriod(props.filterValues?.startPeriod);
    setSelectedEndPeriod(props.filterValues?.endPeriod);
  }, [props.filterValues]);

  const handleStartPeriodFilterChanged = (
    period: PeriodLookupModel | null | undefined,
  ) => {
    setSelectedStartPeriod(period);
  };

  const handleEndPeriodFilterChanged = (
    period: PeriodLookupModel | null | undefined,
  ) => {
    setSelectedEndPeriod(period);
  };

  const handleStartDateFilterChanged = (date: Date | null | undefined) => {
    setSelectedStartDate(date);
  };

  const handleEndDateFilterChanged = (date: Date | null | undefined) => {
    setSelectedEndDate(date);
  };

  const handleClearSearchClick = () => {
    setSelectedStartPeriod(null);
    setSelectedEndPeriod(null);
  };

  const handleSearchClick = () => {
    if (
      isPeriodRangeSelected &&
      !!selectedEndPeriod &&
      !!selectedStartPeriod &&
      selectedStartPeriod.sortableName > selectedEndPeriod.sortableName
    ) {
      error({
        message:
          'The selected start period is later than the end period. Please set the start period to a period earlier or equal to the end period.',
      });
      return;
    }

    if (
      !isPeriodRangeSelected &&
      !!selectedEndDate &&
      !!selectedStartDate &&
      selectedStartDate > selectedEndDate
    ) {
      error({
        message:
          'The selected start date is later than the end date. Please set the start date to a date earlier or equal to the end date.',
      });
      return;
    }

    if (!isPeriodRangeSelected && !selectedEndDate && !selectedStartDate) {
      error({
        message: 'You must enter a start and end date for an audit search',
      });
      return;
    }

    search();
  };

  const search = () => {
    setIsSearching(true);
    props
      .onSearch({
        isPeriodRangeSelected,
        startPeriod: selectedStartPeriod || undefined,
        endPeriod: selectedEndPeriod || undefined,
        startDate: selectedStartDate || undefined,
        endDate: selectedEndDate || undefined,
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  return (
    <>
      {alertModal}
      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={2}>
              <Form.Check
                type="radio"
                label="Period Range"
                checked={isPeriodRangeSelected}
                onChange={() => setIsPeriodRangeSelected(true)}
              />
            </FormLabelColumn>
            <Column width={5}>
              <div className="row">
                {isPeriodRangeSelected && (
                  <>
                    <FormLabelColumn>Start Period</FormLabelColumn>
                    <Column>
                      <PeriodSelect
                        selectedId={selectedStartPeriod?.id}
                        onSelect={handleStartPeriodFilterChanged}
                        periodStatusIds={[
                          PeriodStatusEnum.Closed,
                          PeriodStatusEnum.Open,
                        ]}
                      />
                    </Column>
                    <FormLabelColumn>End Period</FormLabelColumn>
                    <Column>
                      <PeriodSelect
                        selectedId={selectedEndPeriod?.id}
                        onSelect={handleEndPeriodFilterChanged}
                        periodStatusIds={[
                          PeriodStatusEnum.Closed,
                          PeriodStatusEnum.Open,
                        ]}
                      />
                    </Column>
                  </>
                )}
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={2}>
              <Form.Check
                type="radio"
                label="Date Range"
                checked={!isPeriodRangeSelected}
                onChange={() => setIsPeriodRangeSelected(false)}
              />
            </FormLabelColumn>
            <Column width={5}>
              <div className="row">
                {!isPeriodRangeSelected && (
                  <>
                    <FormLabelColumn>Start Date</FormLabelColumn>
                    <Column>
                      <DatePicker
                        value={selectedStartDate}
                        onChange={handleStartDateFilterChanged}
                      />
                    </Column>
                    <FormLabelColumn>End Date</FormLabelColumn>
                    <Column>
                      <DatePicker
                        value={selectedEndDate}
                        onChange={handleEndDateFilterChanged}
                      />
                    </Column>
                  </>
                )}
              </div>
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row">
        <Column className="text-right mt-3">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            disabled={isSearching}
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            disabled={isSearching}
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
    </>
  );
};
