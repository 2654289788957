import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IChipOption } from 'components/ChipSelect';
import { IFileAttachment } from 'components/FileAttachmentInputWrapper/IFileAttachment';
import { Column } from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import React from 'react';
import {
  BalanceLookupItemFragment,
  BalanceSelectionItemFragment,
} from 'types/graphql';
import { BalanceChipSelect } from './BalanceLookup/BalanceChipSelect';
import { FileAttachmentInput } from './FileAttachmentInput';
import { IBalanceFileAttachment } from '../IBalanceFileAttachment';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export interface IBalanceFileAttachmentRowProps {
  periodId: number;
  balanceFileAttachment: IBalanceFileAttachment;
  readOnly: boolean;
  onRemoveBalanceFileAttachment: () => void;
  onFileAttachmentChanged: (fileAttachment: IFileAttachment | null) => void;
  onBalancesChanged: (balances: BalanceLookupItemFragment[]) => void;
}

export const BalanceFileAttachmentRow: React.FC<
  IBalanceFileAttachmentRowProps
> = (props: IBalanceFileAttachmentRowProps) => {
  const handleChipSelectionChange = (
    selectedOptions: readonly IChipOption[],
  ) => {
    return props.onBalancesChanged(
      props.balanceFileAttachment.balances.filter(
        (balance) =>
          !!selectedOptions.find(
            (option) => option.id === balance.id.toString(),
          ),
      ),
    );
  };

  const handleAddBalances = (balances: BalanceSelectionItemFragment[]) => {
    props.onBalancesChanged(
      props.balanceFileAttachment.balances.concat(balances),
    );
  };

  return (
    <div className="row mb-3">
      <FieldSetColumn>
        <div className="row">
          <Column width={5}>
            <FileAttachmentInput
              fileAttachment={props.balanceFileAttachment.fileAttachment}
              readOnly={props.readOnly}
              onFileAttachmentChanged={props.onFileAttachmentChanged}
            />
          </Column>
          <Column>
            <BalanceChipSelect
              onChange={handleChipSelectionChange}
              onAddBalances={handleAddBalances}
              periodId={props.periodId}
              selectedIds={props.balanceFileAttachment.balances.map(
                (balance) => balance.id,
              )}
              disabled={props.readOnly}
            />
          </Column>
          {!props.readOnly && (
            <Column
              width={'auto'}
              className="d-flex align-items-center ml-2 mr-n2 mt-n3 mb-n3"
              style={{
                width: '9em',
                borderLeft: '1px solid silver',
              }}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="pointer ml-1 text-danger"
                style={{ fontSize: 'x-large' }}
                onClick={props.onRemoveBalanceFileAttachment}
              />
              <span className="ml-2 font-weight-bold">Remove Attachment</span>
            </Column>
          )}
        </div>
      </FieldSetColumn>
    </div>
  );
};
