import React, { ReactElement } from 'react';
import { ICommentsProps } from './ICommentsProps';
import { Card } from 'react-bootstrap';
import Comments from './Comments';

export interface ICommentsCardProps extends ICommentsProps {
  title?: string;
}

export default (props: ICommentsCardProps): ReactElement => {
  return (
    <Card className="comments-card">
      {!!props.title && (
        <Card.Header className="pb-0">
          <h1>{props.title}</h1>
        </Card.Header>
      )}
      <Card.Body className="pt-0">
        <Comments
          onAddComment={props.onAddComment}
          onUpdateComment={props.onUpdateComment}
          onDeleteComment={props.onDeleteComment}
          onCommentsChanged={props.onCommentsChanged}
          comments={props.comments}
          refresh={props.refresh}
          readOnly={props.readOnly}
        />
      </Card.Body>
    </Card>
  );
};
