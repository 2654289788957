import React from 'react';
import { ICommentsProps } from './ICommentsProps';
import { Button, Modal } from 'react-bootstrap';
import CommentsCard from './CommentsCard';

export interface ICommentsModalProps extends ICommentsProps {
  title: string;
  show?: boolean;
  onClose: () => void;
}

export default (props: ICommentsModalProps) => {
  return (
    <Modal
      size="lg"
      show={props.show}
      centered={true}
      scrollable={true}
      className="comments-modal"
      onHide={props.onClose}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CommentsCard
          onAddComment={props.onAddComment}
          onUpdateComment={props.onUpdateComment}
          onDeleteComment={props.onDeleteComment}
          onCommentsChanged={props.onCommentsChanged}
          comments={props.comments}
          refresh={props.refresh}
          readOnly={props.readOnly}
        />
        <div className="text-center">
          <Button onClick={props.onClose}>Close</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
