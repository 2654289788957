import React, { PropsWithChildren, useEffect, useState } from 'react';

import { AuthContext } from './AuthContext';
import authClient from './AuthClient';

export default ({ children }: PropsWithChildren<any>) => {
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);

  useEffect(() => {
    authClient.initialize().then(() => {
      setIsAuthInitialized(true);
    });
  }, []);

  if (!isAuthInitialized) {
    return <div>Initializing Authentication Provider</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authClient.isAuthenticated(),
        logout: authClient.signOut,
        user: !authClient.isAuthenticated()
          ? undefined
          : {
              userId: authClient.getUserId(),
              name: authClient.getName(),
              roles: authClient.getRoles(),
            },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
