import useModalAlerts from 'components/Alerts/useModalAlerts';
import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';
import {
  getFirstAndLastOpenPeriods,
  getPeriodById,
} from 'components/Lookups/PeriodLookups/PeriodLookupUtility';
import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useApolloClient from 'useApolloClient';
import { updateTitleEffect } from 'utils/page-util';
import qs from 'qs';
import {
  IReactivatedAccountsFilterValues,
  ReactivatedAccountsFilters,
} from './components/ReactivatedAccountsFilters';
import ReactivatedAccountsTable, {
  IReactivatedAccountsTableControl,
} from './components/ReactivatedAccountsTable';

export const ReactivatedAccounts: React.FC = () => {
  const tableControl = useRef<IReactivatedAccountsTableControl>();

  const { search } = useLocation();

  const params = qs.parse(search, { ignoreQueryPrefix: true });

  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<React.ReactNode>(null);
  const { apolloError } = useModalAlerts(setAlertModal);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const [filterValues, setFilterValues] =
    useState<IReactivatedAccountsFilterValues>({
      isPeriodRangeSelected: true,
    });

  useEffect(updateTitleEffect('Reactivated Accounts'), []);

  const init = async () => {
    let startPeriod = !!params?.startPeriod
      ? await getPeriodById(client, Number(params.startPeriod))
      : undefined;
    let endPeriod = !!params?.endPeriod
      ? await getPeriodById(client, Number(params.endPeriod))
      : undefined;

    try {
      if (!startPeriod || endPeriod) {
        const { latestOpenPeriod } = await getFirstAndLastOpenPeriods(client);
        startPeriod = startPeriod ?? latestOpenPeriod;
        endPeriod = endPeriod ?? latestOpenPeriod;
      }

      const searchArgs = {
        isPeriodRangeSelected: true,
        startPeriod: startPeriod,
        endPeriod: endPeriod ?? undefined,
        startDate: undefined,
        endDate: undefined,
        accountNumberIds: !!params?.accountNumberId
          ? [Number(params.accountNumberId)]
          : undefined,
        subsidiaryIds: !!params?.subsidiaryId
          ? [Number(params.subsidiaryId)]
          : undefined,
        dueToFromSubsidiaryIds: !!params?.dueToFromSubsidiaryId
          ? [Number(params.dueToFromSubsidiaryId)]
          : undefined,
      };

      await handleSearch(searchArgs);

      setIsInitializing(false);
    } catch (err) {
      apolloError({
        error: err,
      });
    }
  };

  useEffect(() => {
    init();
  }, [search]);

  const handleSearch = async (values: IReactivatedAccountsFilterValues) => {
    setFilterValues(values);
  };

  const handleExport = async () => {
    return tableControl.current?.export({
      filename: 'reactivated-accounts.xlsx',
    });
  };

  return (
    <>
      {alertModal}
      {!!!isInitializing && (
        <Page title="Reactivated Accounts">
          <SectionHeaderRow>
            <Column>
              <h1>Filters</h1>
            </Column>
          </SectionHeaderRow>
          <hr />

          <ReactivatedAccountsFilters
            onSearch={handleSearch}
            filterValues={filterValues}
          />

          <SectionHeaderRow className="mt-2">
            <Column>
              <h1>Reactivated Accounts</h1>
            </Column>
            <Column className="text-right">
              <ExportToExcelButton onClick={handleExport} />
            </Column>
          </SectionHeaderRow>
          <hr />

          <ReactivatedAccountsTable
            controlRef={tableControl}
            filter={filterValues}
          />
        </Page>
      )}
    </>
  );
};
