import React, { CSSProperties } from 'react';
import { Card } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import Column, { IColumnProps } from './Column';

interface IFieldSetColumnProps extends IColumnProps {
  compact?: boolean;
  variant?: Variant;
  style?: CSSProperties;
}

export const FieldSetColumn = (props: IFieldSetColumnProps): JSX.Element => {
  const { children, className, compact, ...rest } = props;
  return (
    <Column
      className={`field-set-column ${compact ? 'compact' : ''} ${
        className ?? ''
      }`}
      {...rest}
    >
      <Card className="p3" bg={`${props.variant ?? 'light'}`}>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Column>
  );
};

export default FieldSetColumn;
