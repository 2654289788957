import { formatDateAndTime } from 'ar-common';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { IPaginationControl, Pagination } from 'components/Pagination';
import { AllRowsPerPage } from 'components/Pagination/components/RowsPerPageSelect';
import TruncatingTextWrapperWithPopover from 'components/TruncatingTextWrapperWithPopover';
import React, { useEffect, useRef, useState } from 'react';
import {
  AccountAuditSortBy,
  AuditTrailListDocument,
  AuditTrailListItemFragment,
  AuditTrailListQuery,
  AuditTrailListQueryVariables,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { IAuditTrailFilterValues } from 'views/AuditTrail/components/AuditTrailFilters';

export interface IAuditTrailTableControl {
  export: (args: { filename: string }) => Promise<void>;
}

interface IAuditTrailTableProps {
  filter?: IAuditTrailFilterValues;
  controlRef?: React.MutableRefObject<IAuditTrailTableControl | undefined>;
}

export const AuditTrailTable: React.FC<IAuditTrailTableProps> = (
  props: IAuditTrailTableProps,
) => {
  const paginationControl = useRef<IPaginationControl>();
  const { client } = useApolloClient();

  const [totalAccountAudits, setTotalAccountAudits] = useState<number>(0);
  const [accountAudits, setAccountAudits] = useState<
    AuditTrailListItemFragment[]
  >([]);

  const [sortBy, setSortBy] = useState<AccountAuditSortBy>(
    AccountAuditSortBy.EventDate,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Desc,
  );

  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: AccountAuditSortBy;
    sortDirection: SortDirection;
  }) => {
    setIsFetching(true);

    return client
      .query<AuditTrailListQuery, AuditTrailListQueryVariables>({
        query: AuditTrailListDocument,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            startPeriodId:
              props.filter?.isPeriodRangeSelected === true
                ? props.filter?.startPeriod?.id
                : undefined,
            endPeriodId:
              props.filter?.isPeriodRangeSelected === true
                ? props.filter?.endPeriod?.id
                : undefined,
            startDate:
              props.filter?.isPeriodRangeSelected === false
                ? props.filter?.startDate
                : undefined,
            endDate:
              props.filter?.isPeriodRangeSelected === false
                ? props.filter?.endDate
                : undefined,
            subsidiaryIdIn: props.filter?.subsidiaryIds,
            dueToFromSubsidiaryIdIn: props.filter?.dueToFromSubsidiaryIds,
            accountNumberIdIn: props.filter?.accountNumberIds,
          },
          paginationOptions:
            options.take === AllRowsPerPage
              ? undefined
              : {
                  skip: options.skip,
                  take: options.take,
                },
          sortOptions: {
            sortBy: options.sortBy,
            sortDirection: options.sortDirection,
          },
        },
      })
      .finally(() => setIsFetching(false));
  };

  const fetchDataTablePage = (skip?: number, take?: number) => {
    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalAccountAudits(value.data.accountAudits.totalItems);
        setAccountAudits(value.data.accountAudits.items);
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const handleSort = (
    sortBy: AccountAuditSortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  let exportFunction: DataTableExportFunction<AuditTrailListItemFragment>;
  const handleExport = (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection }).then((value) => {
      return exportFunction({
        items: value.data.accountAudits.items,
        filename: args.filename,
      });
    });
  };

  useEffect(() => {
    if (!!paginationControl.current?.refresh) {
      paginationControl.current.refresh();
    }
  }, [props.filter, sortBy, sortDirection]);

  useEffect(() => {
    if (props.controlRef) {
      props.controlRef.current = {
        export: handleExport,
      };
    }
  });

  const columns: IDataTableColumnDefinition<
    AuditTrailListItemFragment,
    AccountAuditSortBy
  >[] = [
    {
      dataFieldName: 'subsidiary',
      heading: 'Subsidiary',
      width: '9rem',
      render: (accountAudit) => accountAudit.subsidiary.name,
      excelExport: (accountAudit) => accountAudit.subsidiary.name,
      sortBy: AccountAuditSortBy.Subsidiary,
    },
    {
      dataFieldName: 'accountNumber',
      heading: 'Account',
      width: '9rem',
      render: (accountAudit) => accountAudit.accountNumber.toString(),
      excelExport: (accountAudit) => accountAudit.accountNumber,
      sortBy: AccountAuditSortBy.AccountNumber,
    },
    {
      dataFieldName: 'dueToFromSubsidiary',
      heading: 'Due To/From',
      width: '8rem',
      render: (accountAudit) => accountAudit.dueToFromSubsidiary?.name || '',
      excelExport: (accountAudit) =>
        accountAudit.dueToFromSubsidiary?.name || '',
      sortBy: AccountAuditSortBy.DueToFromSubsidiary,
    },
    {
      dataFieldName: 'accountNumberDescription',
      heading: 'Description',
      width: '20rem',
      render: (accountAudit) => (
        <TruncatingTextWrapperWithPopover>
          {accountAudit.accountNumberDescription ?? ''}
        </TruncatingTextWrapperWithPopover>
      ),
      excelExport: (accountAudit) =>
        accountAudit.accountNumberDescription ?? '',
      sortBy: AccountAuditSortBy.AccountNumberDescription,
    },
    {
      dataFieldName: 'event',
      heading: 'Account Event',
      render: (accountAudit) => (
        <div className="text-wrap">{accountAudit.event}</div>
      ),
      excelExport: (accountAudit) => accountAudit.event,
      sortBy: AccountAuditSortBy.Event,
    },
    {
      dataFieldName: 'periodId',
      heading: 'Period',
      width: '7rem',
      render: (accountAudit) =>
        !accountAudit.balance
          ? 'NA'
          : `${accountAudit.balance.period.year} ${
              accountAudit.balance.period.month < 10 ? '0' : ''
            }${accountAudit.balance.period.month}`,
      excelExport: (accountAudit) =>
        !accountAudit.balance
          ? 'NA'
          : `${accountAudit.balance.period.year} ${
              accountAudit.balance.period.month < 10 ? '0' : ''
            }${accountAudit.balance.period.month}`,
      sortBy: AccountAuditSortBy.PeriodId,
    },
    {
      dataFieldName: 'eventUser',
      heading: 'Updated By',
      width: '15em',
      render: (accountAudit) => (
        <TruncatingTextWrapperWithPopover>
          {accountAudit.eventUser?.name ?? ''}
        </TruncatingTextWrapperWithPopover>
      ),
      excelExport: (accountAudit) => accountAudit.eventUser?.name ?? '',
      sortBy: AccountAuditSortBy.EventUser,
    },
    {
      dataFieldName: 'eventDate',
      heading: 'Date',
      width: '12em',
      render: (accountAudit) => formatDateAndTime(accountAudit.eventDate),
      excelExport: (accountAudit) => new Date(accountAudit.eventDate),
      sortBy: AccountAuditSortBy.EventDate,
    },
  ];

  return (
    <>
      {alertModal}
      <Pagination
        totalNumberOfItems={totalAccountAudits}
        fetchDataTablePage={fetchDataTablePage}
        controlRef={paginationControl}
      >
        <DataTable<AuditTrailListItemFragment, AccountAuditSortBy>
          columns={columns}
          data={accountAudits}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          exportRef={(fn) => {
            exportFunction = fn;
          }}
          isFetching={isFetching}
          stickyHeaders={true}
        />
      </Pagination>
    </>
  );
};

export default AuditTrailTable;
