import { Button } from 'components/Button';
import { IChipOption } from 'components/ChipSelect';
import { AccountChipSelect } from 'components/Lookups/AccountLookups/AccountChipSelect';
import { SubsidiaryChipSelect } from 'components/Lookups/SubsidiaryLookups/SubsidiaryChipSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useState } from 'react';

export interface IBalanceSelectionFilterValues {
  subsidiaryIds?: number[];
  accountIds?: number[];
}

interface IProps {
  filterValues?: IBalanceSelectionFilterValues;
  onSearch: (filterValues: IBalanceSelectionFilterValues) => Promise<void>;
}

export const BalanceSelectionFilters = (props: IProps): JSX.Element => {
  const [isSearching, setIsSearching] = useState(false);

  const [selectedSubsidiaryIds, setSelectedSubsidiaryIds] = useState<number[]>(
    [],
  );
  const [selectedAccountIds, setSelectedAccountIds] = useState<number[]>([]);

  const handleSubsidiaryFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedSubsidiaryIds(options.map((o) => parseInt(o.id)));
  };

  const handleAccountFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedAccountIds(options.map((o) => parseInt(o.id)));
  };

  const handleClearSearchClick = () => {
    setSelectedSubsidiaryIds([]);
    setSelectedAccountIds([]);
  };

  const handleSearchClick = () => {
    search();
  };

  const search = () => {
    setIsSearching(true);
    props
      .onSearch({
        subsidiaryIds: selectedSubsidiaryIds.length
          ? selectedSubsidiaryIds
          : undefined,
        accountIds: selectedAccountIds.length ? selectedAccountIds : undefined,
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  return (
    <>
      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn width={3}>Subsidiary</FormLabelColumn>
            <Column>
              <SubsidiaryChipSelect
                selectedIds={selectedSubsidiaryIds}
                onChange={handleSubsidiaryFilterChanged}
                placeholder="All Subsidiaries"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={3}>Account</FormLabelColumn>
            <Column>
              <AccountChipSelect
                take={25}
                selectedIds={selectedAccountIds}
                onChange={handleAccountFilterChanged}
                placeholder="All Accounts"
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row">
        <Column className="text-right mt-3">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            disabled={isSearching}
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            disabled={isSearching}
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
    </>
  );
};
