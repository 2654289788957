import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';
import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useEffect, useRef, useState } from 'react';
import { updateTitleEffect } from 'utils/page-util';
import {
  IManageAssigneesFilterValues,
  ManageAccountsFilters,
} from 'views/ManageAccounts/components/ManageAccountsFilters';
import ManageAccountsTable, {
  IManageAccountsTableControl,
} from './components/ManageAccountsTable';
import {
  AssigneeLookupsDocument,
  AssigneeLookupsQuery,
  AssigneeLookupsQueryVariables,
  LookupModelFragment,
} from 'types/graphql';
import { ApolloQueryResult } from '@apollo/client';
import useApolloClient from 'useApolloClient';
import useModalAlerts from 'components/Alerts/useModalAlerts';

export const ManageAccounts: React.FC = () => {
  const tableControl = useRef<IManageAccountsTableControl>();

  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const [filterValues, setFilterValues] =
    useState<IManageAssigneesFilterValues>({});
  const [assigneeLookups, setAssigneeLookups] = useState<
    LookupModelFragment[] | undefined
  >();
  const [refreshAccountNumbersTrigger, setRefreshAccountNumbersTrigger] =
    useState<number>(0);

  useEffect(updateTitleEffect('Manage Accounts'), []);

  useEffect(() => {
    client
      .query<AssigneeLookupsQuery, AssigneeLookupsQueryVariables>({
        query: AssigneeLookupsDocument,
        variables: {},
      })
      .then((result: ApolloQueryResult<AssigneeLookupsQuery>) => {
        if (result.data.userLookups) {
          setAssigneeLookups(result.data.userLookups);
        }
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  }, []);

  const handleSearch = async (values: IManageAssigneesFilterValues) => {
    setFilterValues(values);
    tableControl.current?.applyFilter(values);
  };

  const handleExport = async () => {
    return tableControl.current?.export({
      filename: 'accounts.xlsx',
    });
  };

  const handleRefreshAccountNumbers = (): void => {
    setRefreshAccountNumbersTrigger(refreshAccountNumbersTrigger + 1);
  };

  if (!assigneeLookups) {
    return <></>;
  }

  return (
    <>
      {alertModal}
      <Page title="Manage Accounts">
        <SectionHeaderRow>
          <Column>
            <h1>Filters</h1>
          </Column>
        </SectionHeaderRow>
        <hr />
        <ManageAccountsFilters
          assigneeLookups={assigneeLookups}
          onSearch={handleSearch}
          filterValues={filterValues}
          refreshAccountNumbersTrigger={refreshAccountNumbersTrigger}
        />

        <SectionHeaderRow className="mt-2">
          <Column width="auto">
            <h1>List of Accounts</h1>
          </Column>
          <Column className="text-right">
            <ExportToExcelButton onClick={handleExport} />
          </Column>
        </SectionHeaderRow>
        <hr />

        <ManageAccountsTable
          controlRef={tableControl}
          onRefreshAccountNumbers={handleRefreshAccountNumbers}
        />
      </Page>
    </>
  );
};
