import { IChipOption } from 'components/ChipSelect';
import { SubsidiaryLookupWrapper } from 'components/Lookups/SubsidiaryLookups/SubsidiaryLookupWrapper';
import React from 'react';
import { LookupChipSelect } from '../LookupChipSelect';

interface IProps {
  includeIds?: number[];
  selectedIds?: number[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  placeholder?: string;
}

export const SubsidiaryChipSelect = (props: IProps): JSX.Element => {
  return (
    <SubsidiaryLookupWrapper includeIds={props.includeIds}>
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupChipSelect
          extractLookups={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          onChange={props.onChange}
          selectedIds={props.selectedIds}
          placeholder={props.placeholder}
        />
      )}
    </SubsidiaryLookupWrapper>
  );
};
