import React, { PropsWithChildren } from 'react';
import { PageHeader } from './components/PageHeader';

interface IProps extends PropsWithChildren<Record<string, any>> {
  title: string;
}

export const Page = (props: IProps): JSX.Element => {
  return (
    <>
      <PageHeader title={props.title}></PageHeader>
      <div className="container-fluid pl-4 pr-4 pt-4">
        <div className="row">
          <div className="col">{props.children}</div>
        </div>
      </div>
    </>
  );
};
