import { PeriodStatusEnum } from 'ar-common/lib/period-status.enum';
import { useAuthContext } from 'auth/AuthContext';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { CheckBlock } from 'components/Form/CheckBlock';
import React, { useState } from 'react';
import {
  ToggleIsBalanceReconciliationCompleteDocument,
  ToggleIsBalanceReconciliationCompleteMutation,
  ToggleIsBalanceReconciliationCompleteMutationVariables,
  ViewAccountsListItemFragment,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';

export interface ICompleteCheckBlockProps {
  balance: ViewAccountsListItemFragment;
  onBalancesUpdated: (updatedBalanceIds: number[]) => void;
  readonly?: boolean;
}

export const ReconciliationCompleteCheckBlock: React.FC<
  ICompleteCheckBlockProps
> = (props: ICompleteCheckBlockProps) => {
  const auth = useAuthContext();
  const { client } = useApolloClient();
  const [alertModal, setAlertModal] = useState<any>();
  const { success, apolloError } = useModalAlerts(setAlertModal);

  const canToggleComplete = (): boolean => {
    const isPeriodOpen: boolean =
      props.balance.period?.periodStatus.id === PeriodStatusEnum.Open;
    if (!isPeriodOpen) {
      return false;
    }

    const isCurrentUserTheResponsible: boolean =
      auth.user?.userId === props.balance.responsible?.id;
    const isCurrentUserAResponsible: boolean =
      auth.user?.roles.includes(
        process.env.REACT_APP_RESPONSIBLE_ROLE_NAME ?? '',
      ) ?? false;
    const isCurrentUserSystemAdmin: boolean =
      auth.user?.roles.includes(process.env.REACT_APP_ADMIN_ROLE_NAME ?? '') ??
      false;

    return (
      isCurrentUserTheResponsible ||
      isCurrentUserAResponsible ||
      isCurrentUserSystemAdmin
    );
  };

  const executeToggle = () => {
    client
      .mutate<
        ToggleIsBalanceReconciliationCompleteMutation,
        ToggleIsBalanceReconciliationCompleteMutationVariables
      >({
        mutation: ToggleIsBalanceReconciliationCompleteDocument,
        fetchPolicy: 'no-cache',
        variables: {
          id: props.balance.id,
        },
      })
      .then(() => {
        props.onBalancesUpdated([props.balance.id]);
        success({
          message: `Balance reconciliation ${
            !!props.balance.reconciliationCompletedBy ? 'undone' : 'completed'
          }`,
        });
      })
      .catch((reason: any) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const handleToggle = () => {
    executeToggle();
  };

  return (
    <>
      {alertModal}
      <CheckBlock
        checked={!!props.balance.reconciliationCompletedBy}
        onCheckedChange={handleToggle}
        disabled={!canToggleComplete()}
        readonly={props.readonly}
      />
    </>
  );
};
